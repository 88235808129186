import {useEffect} from "react";
import {EntityListPage} from "base/entity";
import {useAppConfig} from "base/provider";
import {ChangesAlert} from "changes/presentational";
import {useLocation} from "react-router-dom";
import {useSisbagalAppConfig} from "sisbagal/provider";

const ListInfraestructuraPage = ({
    entityName,
    entityPath,
    service,
    tableColumns,
    createButton = true,
    filterForm = null,
}) => {
    const {setSubpagePath} = useAppConfig();
    const {consolidated} = useSisbagalAppConfig();
    let location = useLocation();

    useEffect(() => {
        // Getting path from location last element
        setSubpagePath([
            {
                name: `Listado de ${entityName}`,
                path: entityPath,
            },
        ]);
        return () => {
            setSubpagePath([]);
        };
    }, []);

    if (!consolidated) {
        const existControlChangesColumn = tableColumns.some(
            column => column.id === "pending_status"
        );
        if (!existControlChangesColumn) {
            tableColumns.push({
                id: "pending_status",
                label: "Control cambios",
                width: 5,
                formatFunction: element => (
                    <ChangesAlert status={element.pending_status} />
                ),
            });
        }
    } else {
        const controlChangesColumnIndex = tableColumns.findIndex(
            column => column.id === "pending_status"
        );
        if (controlChangesColumnIndex >= 0) {
            tableColumns.splice(controlChangesColumnIndex, 1);
        }
    }

    return (
        <EntityListPage
            views={["table"]}
            entityName={entityName}
            service={service}
            tableColumns={tableColumns}
            createButton={createButton}
            filterForm={filterForm}
        />
    );
};
export default ListInfraestructuraPage;
