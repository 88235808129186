import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewConcelloDocumentsSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/concellos/")[0];

    return (
        concello && (
            <ViewDocumentsSubPage
                entity={concello}
                basePath={`${basePath}/concellos`}
                lookupIdField="codigo"
            />
        )
    );
};

export default ViewConcelloDocumentsSubPage;
