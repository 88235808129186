import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const VertidoSubPageMenu = ({vertido}) => {
    return (
        <ChangesSubPageMenu
            pending_status={vertido.pending_status}
            headingPrimaryText={vertido.codigo || vertido.id}
            headingSecondaryText="Vertido"
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Ficha"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="detail"
                text="Datos"
                icon={<ViewListOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={vertido} />
        </ChangesSubPageMenu>
    );
};

export default VertidoSubPageMenu;
