import {createEntityService} from "base/entity/service";
import {
    createDeposito,
    createDepositos,
    deposito_api_adapter,
    depositos_api_adapter,
} from "abastecemento/model";

const entityService = createEntityService(
    "/api/infrastructures/depositos",
    createDeposito,
    createDepositos,
    deposito_api_adapter,
    depositos_api_adapter
);

const DepositoService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(deposito) {
        return entityService.create(deposito);
    },

    update(deposito) {
        return entityService.update(deposito);
    },

    updateWithPatch(deposito) {
        return entityService.updateWithPatch(deposito);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default DepositoService;
