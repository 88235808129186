import {useParams} from "react-router-dom";
import {ViewDocumentPanel} from "document/component/container";
import {SubaglomeracionService} from "saneamiento/service";

const ViewSubaglomeracionDocumentPanel = () => {
    const {idSubaglomeracion} = useParams();

    const handleSetFeaturedImage = documentId => {
        return SubaglomeracionService.updateWithPatch({
            id: idSubaglomeracion,
            featured_image: documentId,
        });
    };

    const handleSetFeaturedDocument = documentId => {
        return SubaglomeracionService.updateWithPatch({
            id: idSubaglomeracion,
            featured_document: documentId,
        });
    };

    return (
        <ViewDocumentPanel
            onSetFeaturedImage={handleSetFeaturedImage}
            onSetFeaturedDocument={handleSetFeaturedDocument}
        />
    );
};

export default ViewSubaglomeracionDocumentPanel;
