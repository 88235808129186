import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaInnovacionSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`gobernanza_innovadora/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Realízanse experiencias piloto nos servizos:",
            value: gobernanza.realizanse_experiencias_piloto_label,
        },
        {
            label: "Foméntanse mecanismos de cooperación innovadora nos servizos:",
            value: gobernanza.fomentanse_mecanismos_cooperacion_innovadores_label,
        },
        {
            label: "Foméntanse ferramentas e procesos innovadores nos servizos:",
            value: gobernanza.fomentanse_ferramentas_innovadoras_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Gobernanza innovadora"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_gobernanza_innovadora}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_gobernanza_innovadora,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaInnovacionSection;
