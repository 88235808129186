import {useParams} from "react-router-dom";
import {TanqueService} from "saneamiento/service";
import {useTanqueTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListTanquePage = () => {
    const {codigo} = useParams();
    const codigoConcello = codigo;
    const {tableColumns} = useTanqueTable();

    return (
        <ListInfraestructuraPage
            entityName="Tanques"
            entityPath="tanques"
            service={TanqueService}
            tableColumns={tableColumns}
            createButton={!!codigoConcello}
        />
    );
};
export default ListTanquePage;
