import {mapOverlayPanes} from "base/component/geo";
import L from "leaflet";
import {useRef} from "react";

export class EditLayer {
    #layerRef;
    #mapRef;
    #geom;
    #onClick;

    get layerRef() {
        return this.#layerRef;
    }

    get mapRef() {
        return this.#mapRef;
    }

    get geom() {
        return this.#geom;
    }

    constructor(layerRef, mapRef, geom, onClick) {
        this.#layerRef = layerRef;
        this.#mapRef = mapRef;
        this.#geom = geom;
        this.#onClick = onClick;
    }

    createLayer(map, fitBounds = true) {
        if (this.geom) {
            this.clearLayer();
            this.loading = true;
            this.layerRef.current = L.Proj.geoJson(this.geom, {
                pointToLayer: (feature, latlng) => {
                    return L.circleMarker(latlng, {
                        radius: 16,
                        fillColor: "white",
                        color: "darkblue",
                        weight: 3,
                        opacity: 0.8,
                        fillOpacity: 1,
                        dashArray: "2,4",
                        pane: mapOverlayPanes[1],
                    });
                },
            });
            this.layerRef.current.addTo(map);

            if (fitBounds) {
                map.setView(this.layerRef.current.getBounds().getCenter(), 12);
            }
        }
        this.mapRef.current = map;

        if (this.#onClick) {
            map.on("click", event => {
                this.#onClick(event.latlng);
            });
        }
    }

    updateLayer() {
        if (this.mapRef.current) {
            this.createLayer(this.mapRef.current, false);
        }
    }

    clearLayer() {
        if (this.mapRef && this.mapRef.current) {
            this.mapRef.current.removeLayer(this.layerRef.current);
            this.mapRef.current.off("click");
        }
    }
}

export function useEditLayerObject({geom = null, onClick = null}) {
    return new EditLayer(useRef(L.layerGroup()), useRef(null), geom, onClick);
}
