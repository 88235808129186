import {Route} from "react-router-dom";
import {ViewSaneamentoConexionsSubPage} from "saneamiento/container";
import {ConexionesPanel, ViewConexionPanel} from "conexion/container";
import {ViewSubaglomeracionPanel} from "saneamiento/subaglomeracion/container";
import {ViewEDARPanel} from "saneamiento/edar/container";
import {ViewBombeoPanel} from "saneamiento/bombeo/container";
import {ViewTanquePanel} from "saneamiento/tanque/container";
import {ViewColectorPanel} from "saneamiento/colector/container";
import {ViewAglomeracionPanel} from "saneamiento/aglomeracion/container";
import {ViewNucleoPanel} from "nucleo/container";

const conexionesRoutes = [
    <Route
        key="concello-saneam-conexions-viewer"
        path="saneam-conexions"
        element={<ViewSaneamentoConexionsSubPage />}
    >
        <Route
            key="concellos-saneam-conexion-info"
            path="info/conexions/:idConexion"
            element={<ViewConexionPanel />}
        />
        <Route
            key="concellos-saneam-conexions-aglomeracion-info"
            path="info/aglomeracions/:idAglomeracion"
            element={<ViewAglomeracionPanel />}
        />
        <Route
            key="concellos-saneam-conexions-subaglomeracion-info"
            path="info/subaglomeracions/:idSubaglomeracion"
            element={<ViewSubaglomeracionPanel />}
        />
        <Route
            key="concellos-saneam-conexions-edar-info"
            path="info/edars/:idEdar"
            element={<ViewEDARPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-saneam-conexions-bombeo-info"
            path="info/bombeos/:idBombeo"
            element={<ViewBombeoPanel />}
        />
        <Route
            key="concellos-saneam-conexions-tanque-info"
            path="info/tanques/:idTanque"
            element={<ViewTanquePanel />}
        />
        <Route
            key="concellos-saneam-conexions-colector-info"
            path="info/colectores/:idColector"
            element={<ViewColectorPanel />}
        />
        <Route
            key="concellos-saneam-conexions-nucleo-info"
            path="info/nucleos/:idNucleo"
            element={<ViewNucleoPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-saneam-conexions-connections-form"
            path="conexions/:connectionEnd/:elementType/:idElement"
            element={<ConexionesPanel isSaneamiento={true} />}
        />
    </Route>,
];

export default conexionesRoutes;
