import {PageLayout} from "base/layout";
import {
    AlertError,
    PaperContainer,
    SectionHeading,
} from "base/component/presentational";

const EntityCreatePage = ({form, title, error = null}) => {
    return (
        <PageLayout subPage={true}>
            <PaperContainer maxWidth="xl" mx="auto">
                <SectionHeading label={false}>{title}</SectionHeading>
                {error && <AlertError error={error} />}
                {form}
            </PaperContainer>
        </PageLayout>
    );
};

export default EntityCreatePage;
