import {EntityFormGeneralDataSection, FormStepper} from "base/component/form";
import {
    EDARTratamentoFormSection,
    EDARExplotacionFormSection,
    EDARFormGeneralDataFields,
    EDARFormLocationFields,
} from ".";
import Box from "@mui/material/Box";

const EDARCreationForm = ({onSubmit, onCancel = null}) => {
    const steps = ["Datos xerais", "Tratamento", "Explotación e deseño"];

    const stepComponents = [
        <EntityFormGeneralDataSection
            fields={<EDARFormGeneralDataFields creation={true} />}
            location={<EDARFormLocationFields isCreationForm={true} />}
        />,
        <EDARTratamentoFormSection />,
        <EDARExplotacionFormSection />,
    ];

    return (
        <Box component="form" width="100%" mt={4}>
            <FormStepper
                onSubmit={onSubmit}
                onCancel={onCancel}
                steps={steps}
                stepComponents={stepComponents}
            />
        </Box>
    );
};

export default EDARCreationForm;
