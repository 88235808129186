import {useNavigate, useParams} from "react-router-dom";
import {MasaAugaService} from "medioreceptor/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";

const ViewMasaAugaPanel = () => {
    const {idMasaAuga} = useParams();
    const navigate = useNavigate();

    const handleClickDetail = masaAuga => {
        navigate(`/medioreceptor/masaauga/${masaAuga.id}/principal`);
    };

    const getSectionData = masaAuga => {
        return (
            <>
                <SectionField label="Código:" value={masaAuga.codigo} />
                <SectionField label="Código europeo:" value={masaAuga.codigo_europeo} />
                <SectionField
                    label="Categoría:"
                    value={masaAuga.categoria_label}
                    tooltipText="Categoria de bacía segundo plans hidrolóxicos."
                />
                <SectionField
                    label="Caudal medio anual:"
                    value={NumberUtil.formatDecimal(masaAuga.caudal_medio_anual)}
                    unit="l/s"
                    tooltipText="Caudal medio anual no punto de peche"
                />
                <SectionField
                    label="Caudal medio mínimo:"
                    value={NumberUtil.formatDecimal(masaAuga.caudal_medio_minimo)}
                    unit="l/s"
                    tooltipText="Caudal medio minimo anual no punto de peche"
                />
                <SectionField
                    label="Estado global:"
                    value={masaAuga.estado_global_label}
                    tooltipText="Estado global da bacía segundo plans hidrolóxicos"
                />
                <SectionField
                    label="Estado ecolóxico:"
                    value={masaAuga.estado_ecoloxico_label}
                    tooltipText="Estado ecolóxico da bacía segundo plans hidrolóxicos"
                />
                <SectionField
                    label="Estado químico:"
                    value={masaAuga.estado_quimico_label}
                    tooltipText="Estado químico da bacía segundo plans hidrolóxicos"
                />
                <SectionField
                    label="Sistema explotación:"
                    value={masaAuga.sistema_explotacion_label}
                    linkPath={`/medioreceptor/sistemaexplotacion/${masaAuga.sistema_explotacion}`}
                    tooltipText="Sistema de explotación da bacía segundo plans hidrolóxicos"
                />
                <SectionField
                    label="Demarcación:"
                    value={masaAuga.demarcacion_label}
                    tooltipText="Demarcación hidrográfica da bacía"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={MasaAugaService}
            id={idMasaAuga}
            title="Resumo de bacía"
            getSectionTitle={entity => entity?.nome || entity?.codigo}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
        />
    );
};

export default ViewMasaAugaPanel;
