import {ETAPService} from "abastecemento/service";
import {useETAPTable} from "../hooks";
import {EntityWithSistemaFilterForm} from "abastecemento/sistema/presentational";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListETAPPage = () => {
    const {tableColumns} = useETAPTable();

    return (
        <ListInfraestructuraPage
            entityName="ETAP"
            entityPath="etaps"
            service={ETAPService}
            tableColumns={tableColumns}
            filterForm={<EntityWithSistemaFilterForm entityName="ETAP" />}
        />
    );
};
export default ListETAPPage;
