import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {bombeo_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {BombeoForm} from "../presentational/form";

const CreateBombeoPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = bombeo => {
        BombeoService.create(bombeo_view_adapter({...bombeo}))
            .then(createdBombeo => {
                navigate(`${basePath}/${createdBombeo.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <BombeoForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de Bombeo"
            error={error}
        />
    );
};

export default CreateBombeoPage;
