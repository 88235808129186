import {Route} from "react-router-dom";
import {
    CreateCaptacionPage,
    ListCaptacionPage,
    ViewCaptacionPanel,
    ViewCaptacionPage,
    ViewCaptacionLocationSubPage,
    ViewCaptacionDocumentsSubPage,
    ViewCaptacionSummarySubPage,
    ViewCaptacionDetailSubPage,
    UpdateCaptacionPanel,
    ManageCaptacionPage,
    ViewCaptacionDocumentPanel,
    ViewCaptacionChangesSubPage,
} from "abastecemento/captacion/container";

const captacionRoutes = [
    <Route
        key="captacion-new"
        path="captacions/new"
        element={<CreateCaptacionPage />}
    />,
    <Route key="captacion-manage" path="captacions" element={<ManageCaptacionPage />}>
        <Route key="captacion-list" path="" element={<ListCaptacionPage />}>
            <Route
                key="captacion-info"
                path="info/:idCaptacion"
                element={<ViewCaptacionPanel />}
            />
        </Route>
        <Route
            key="captacion-detail"
            path=":idCaptacion"
            element={<ViewCaptacionPage />}
        >
            <Route
                key="captacion-location"
                path="location"
                element={<ViewCaptacionLocationSubPage />}
            >
                <Route
                    key="captacion-location-edit"
                    path=":section/:action"
                    element={<UpdateCaptacionPanel />}
                />
            </Route>
            <Route
                key="captacion-documents"
                path="documents/*"
                element={<ViewCaptacionDocumentsSubPage />}
            >
                <Route
                    key="captacion-documents-view"
                    path="detail/*"
                    element={<ViewCaptacionDocumentPanel />}
                />
            </Route>
            <Route
                key="captacion-summary"
                path="summary"
                element={<ViewCaptacionSummarySubPage />}
            >
                <Route
                    key="captacion-general-edit"
                    path=":section/:action"
                    element={<UpdateCaptacionPanel />}
                />
            </Route>
            <Route
                key="captacion-detail"
                path="detail"
                element={<ViewCaptacionDetailSubPage />}
            >
                <Route
                    key="captacion-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateCaptacionPanel />}
                />
            </Route>
            <Route
                key="captacion-changes"
                path="changes"
                element={<ViewCaptacionChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default captacionRoutes;
