import {useBombeoMap} from "saneamiento/bombeo/hooks";
import {EntityLocationSection} from "base/entity";

const BombeoLocationSection = ({bombeo}) => {
    const {mapConfig} = useBombeoMap();

    return <EntityLocationSection entity={bombeo} mapConfig={mapConfig} />;
};

export default BombeoLocationSection;
