import {AuthApiService} from "base/service";
import {ServiceRequestFormat, ServiceUtil} from "base/utilities";

const StatsService = {
    getInfrastructuresCountGeoData(filter, format = null) {
        return AuthApiService.get(
            `/api/infrastructures/stats/infrastructures_count?${ServiceUtil.getGeoFilterQueryString(
                filter
            )}`,
            ServiceUtil.getAcceptHeader(ServiceRequestFormat.GEOJSON)
        ).then(response => {
            // TODO: get crs from config
            response["crs"] = {
                type: "name",
                properties: {
                    name: "urn:ogc:def:crs:EPSG::25829",
                },
            };
            return response;
        });
    },
    getInfrastructuresCountData(filter, format = null) {
        return AuthApiService.get(
            `/api/infrastructures/stats/infrastructures_count?${ServiceUtil.getGeoFilterQueryString(
                filter
            )}`
        );
    },
    getInfrastructuresTypeData(filter, format = null) {
        return AuthApiService.get(
            `/api/infrastructures/stats/infrastructures_type?${ServiceUtil.getGeoFilterQueryString(
                filter
            )}`
        );
    },
    getAbastecementoVolumeData(filter, format = null) {
        return AuthApiService.get(
            `/api/infrastructures/stats/abastecemento_volume?${ServiceUtil.getGeoFilterQueryString(
                filter
            )}`
        );
    },
    getConcelloCargasData(filter, format = ServiceRequestFormat.JSON.toString()) {
        return AuthApiService.get(
            `/api/infrastructures/stats/concello_cargas?${ServiceUtil.getGeoFilterQueryString(
                filter
            )}`,
            ServiceUtil.getAcceptHeader(format)
        ).then(response => {
            // TODO: get crs from config
            if (format === ServiceRequestFormat.GEOJSON) {
                response["crs"] = {
                    type: "name",
                    properties: {
                        name: "urn:ogc:def:crs:EPSG::25829",
                    },
                };
            }
            return response;
        });
    },
};

export default StatsService;
