import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const TanqueGeneralDataSection = ({tanque}) => {
    const {ROLES} = useAuth();

    const sections = [
        {label: "Código", value: tanque.codigo},
        {
            label: "Concello",
            value: tanque.concello_label,
            linkPath: `/concellos/${tanque.concello}`,
        },
        {
            label: "Aglomeración",
            value: tanque.aglomeracion_label,
            linkPath: `/aglomeracions/${tanque.aglomeracion}/summary`,
        },
        {label: "Notas", value: tanque.notas},
    ];

    return (
        <>
            <EntityGeneralDataSection
                featured_image={tanque.featured_image}
                featured_document={tanque.featured_document}
                name={tanque.nome || tanque.codigo}
                sections={sections}
            />
        </>
    );
};

export default TanqueGeneralDataSection;
