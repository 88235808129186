import {FormSelect, FormSelectMultiple} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const SubaglomeracionFormExplotacionFields = ({layout = "row"}) => {
    const {
        dominioaglomeracionreutilizacion,
        dominionaglomeracionexplotacionmantemento,
        dominioaglomeraciontelecontrol,
        dominioaglomeracionmedicionalivios,
        dominioaglomeracionlimpezacolectores,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telecontrol"
                    label="Telecontrol"
                    options={dominioaglomeraciontelecontrol}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="plan_mantemento"
                    label="Plan de mantemento"
                    options={dominionaglomeracionexplotacionmantemento}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="manual_explotacion"
                    label="Manual de explotación"
                    options={dominionaglomeracionexplotacionmantemento}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="limpeza_colectores"
                    label="Limpeza de colectores"
                    options={dominioaglomeracionlimpezacolectores}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="control_alivios"
                    label="Control de alivios"
                    options={dominioaglomeracionmedicionalivios}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="reutilizacion_outras_instalacions"
                    label="Reutilización outras instalacións"
                    options={dominioaglomeracionreutilizacion}
                />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormExplotacionFields;
