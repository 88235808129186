import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewETAPLocationSubPage = () => {
    let etap;
    [etap] = useOutletContext();

    const sections = [<EntityLocationSection geom={etap.geom} />];

    return etap && <EntityViewSubPage sections={sections} />;
};

export default ViewETAPLocationSubPage;
