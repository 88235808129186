import {useEffect, useState} from "react";

import styled from "@mui/material/styles/styled";
import {alpha} from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import {TextUtil} from "base/utilities";

const MIN_CHARS = 3;
const WAIT_INTERVAL = 500;
let timerID;

const AutocompleteStyled = styled(Autocomplete)(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    // backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
        // backgroundColor: "rgba(0, 0, 0, 0.10)",
        border: 0,
    },
    marginLeft: 0,
    width: "100%",
    minWidth: "250px",
    "& .MuiOutlinedInput-root": {
        paddingRight: "0!important",
    },
}));

const TextFieldStyled = styled(TextField)(({theme}) => ({
    margin: 0,
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    },
    "& .MuiInputBase-root": {
        padding: "2px 8px",
        color: "inherit",
    },
}));

const SearchAutocompleteExpansive = ({
    options = [],
    onSearch,
    onSelect,
    onClear,
    loading = null,
    optionLabelAttribute = "name",
    optionGroupBy = "",
    optionComponent,
    disabled = false,
    searchIconColor = "white",
}) => {
    const [value, setValue] = useState("");

    const clearSearchValue = () => {
        setValue("");
        onClear();
    };

    const handleSelectOption = (event, item) => {
        onSelect(item?.url);
        clearSearchValue();
    };

    const searchData = value => {
        onSearch(value);
    };

    const handleSearchChange = value => {
        setValue(value);

        clearTimeout(timerID);

        if (value.length >= MIN_CHARS) {
            timerID = setTimeout(() => {
                searchData(value);
            }, WAIT_INTERVAL);
        } else {
            onClear();
        }
    };

    const getEndAdornmentIcon = () => {
        if (loading) {
            return (
                <Box sx={{pr: 1}}>
                    <CircularProgress size={20} sx={{color: "white"}} />
                </Box>
            );
        } else if (value.length >= 1) {
            return (
                <IconButton sx={{color: "white"}} onClick={clearSearchValue}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            );
        } else return null;
    };

    //TO-DO: Fix MUI warning when typing on input: MUI: The value provided to Autocomplete is invalid. None of the options match with `"xxxx"`.You can use the `isOptionEqualToValue` prop to customize the equality test.

    const getNoOptionsText = () => {
        return (
            <Typography sx={{color: "grey", fontSize: "0.8em"}}>
                {value.length < MIN_CHARS
                    ? `Introduza ${MIN_CHARS} ou máis caracteres`
                    : "Non hai resultados"}
            </Typography>
        );
    };

    const getLoadingText = () => {
        return (
            <Typography sx={{color: "grey", fontSize: "0.8em"}}>Buscando...</Typography>
        );
    };

    return (
        <FormControl sx={{m: 0, pr: 0}}>
            <AutocompleteStyled
                id="check-autocomplete"
                value={value}
                options={options}
                onChange={handleSelectOption}
                onKeyDown={e => e.stopPropagation()}
                blurOnSelect={true}
                clearOnBlur={true}
                noOptionsText={getNoOptionsText()}
                getOptionLabel={option =>
                    option && option[optionLabelAttribute]
                        ? option[optionLabelAttribute]
                        : option
                }
                renderOption={(props, option, {selected}) => {
                    return (
                        <Box component="li" {...props} key={option.codigo || option.id}>
                            {optionComponent(option)}
                        </Box>
                    );
                }}
                groupBy={option => TextUtil.capitalize(option[optionGroupBy])}
                renderGroup={params => (
                    <Box key={params.key}>
                        <Typography
                            sx={{
                                fontSize: "0.8em",
                                fontStyle: "italic",
                                color: "grey",
                                pl: 1,
                                pt: 1,
                                borderBottom: "1px solid #ccc",
                            }}
                        >
                            {params.group}
                        </Typography>
                        {params.children}
                    </Box>
                )}
                forcePopupIcon={false}
                loading={loading}
                loadingText={getLoadingText()}
                disabled={disabled}
                renderInput={params => (
                    <TextFieldStyled
                        {...params}
                        id="searchText"
                        onChange={ev => {
                            // dont fire API if the user delete or not entered anything
                            if (ev.target.value !== "" || ev.target.value !== null) {
                                handleSearchChange(ev.target.value);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{color: searchIconColor}} />
                                </InputAdornment>
                            ),
                            endAdornment: (params.InputProps.endAdornment = getEndAdornmentIcon()),
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

export default SearchAutocompleteExpansive;
