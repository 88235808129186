import {
    FormInputInteger,
    FormInputText,
    FormSelect,
    FormSelectMultiple,
} from "base/component/form";

import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";
import {ConcelloHiddenAuth} from "concello/auth";

const EDARFormTratamentoSecundarioFields = ({layout = "row"}) => {
    const {
        dominiosino,
        dominioedarsecundarioextensivos,
        dominioedarsecundariopequenas,
        dominioedarsecundariooutros,
    } = useDomain();
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelect
                        name="instalacion_compacta"
                        label="Compacto"
                        options={dominiosino}
                        tooltipText="Instalación de tipo compacto"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormInputInteger
                        name="trat_secundario_lineas"
                        label="Liñas secundario"
                        tooltipText="Número de liñas de tratamento secundario"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelectMultiple
                        name="trat_secundario_pequenas"
                        label="Intensivos"
                        options={dominioedarsecundariopequenas}
                    />
                </Grid>
            </ConcelloHiddenAuth>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelectMultiple
                        name="trat_secundario_extensivos"
                        label="Extensivos"
                        options={dominioedarsecundarioextensivos}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelectMultiple
                        name="trat_secundario_outros"
                        label="Grandes instalacións"
                        options={dominioedarsecundariooutros}
                    />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormInputText
                    name="trat_secundario_outrost"
                    label="Tratamento secundario (outro)"
                    tooltipText="Outro tratamento non especificado"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormTratamentoSecundarioFields;
