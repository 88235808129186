import {Outlet} from "react-router-dom";

import {Header, IdleLogoutTimer} from "base/layout";
import Box from "@mui/material/Box";
import {FOOTER_HEIGHT, HEADER_HEIGHT} from "base/config/measurements";

const AppLayout = ({hero = null, menu = null, footer = null}) => {
    return (
        <IdleLogoutTimer>
            <Header hero={hero} menu={menu} />
            <Box sx={{minHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`}}>
                <Outlet />
            </Box>
            {footer}
        </IdleLogoutTimer>
    );
};

export default AppLayout;
