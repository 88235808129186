import {Route} from "react-router-dom";
import {
    ViewStatsInfrastructuresCountSubPage,
    ViewStatsInfrastructuresTypeSubPage,
    ViewStatsAbastecementoVolumeSubPage,
    ViewStatsPage,
    ViewStatsConcellosCargasSubPage,
    ViewStatsNoSelectedSubPage,
} from "stats/container";

const statsRoutes = [
    <Route key="stats-page" path="" element={<ViewStatsPage />}>
        <Route
            key="stats-saneamento-infrastructures-count-page"
            path="saneamento_infrastructures_count"
            element={<ViewStatsInfrastructuresCountSubPage sector="saneamento" />}
        ></Route>
        <Route
            key="stats-saneamento-infrastructures-type-page"
            path="saneamento_infrastructures_type"
            element={<ViewStatsInfrastructuresTypeSubPage sector="saneamento" />}
        ></Route>
        <Route
            key="stats-saneamento-concellos-cargas-page"
            path="saneamento_concellos_cargas"
            element={<ViewStatsConcellosCargasSubPage />}
        ></Route>
        <Route
            key="stats-abastecemento-infrastructures-count-page"
            path="abastecemento_volume"
            element={<ViewStatsAbastecementoVolumeSubPage />}
        ></Route>
        <Route
            key="stats-abastecemento-infrastructures-count-page"
            path="abastecemento_infrastructures_count"
            element={<ViewStatsInfrastructuresCountSubPage sector="abastecemento" />}
        ></Route>
        <Route
            key="stats-abastecemento-infrastructures-type-page"
            path="abastecemento_infrastructures_type"
            element={<ViewStatsInfrastructuresTypeSubPage sector="abastecemento" />}
        ></Route>
        <Route
            key="stats-no-selected"
            path=""
            element={<ViewStatsNoSelectedSubPage />}
        />
    </Route>,
];

export default statsRoutes;
