import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {useLayerObjectWithLegend} from "base/map";
import {NumberUtil} from "base/utilities";

export function useLayer(detail = false, fitBounds = false) {
    const navigate = useNavigate();

    return useLayerObjectWithLegend({
        legendOptions: {
            layerName: "Concellos",
            defaultMarkerOptions: {
                marker: "l",
                addStroke: false,
                fontSize: 35,
                weight: 2,
                color: "#F79256",
                fillOpacity: 0,
                opacity: 0.3,
                lineJoin: "round",
                pane: mapOverlayPanes[0],
            },
        },
        layerOptions: {
            detail: detail,
            fitBounds: fitBounds,
            getTooltip: ({feature}) => {
                let data = feature.properties;
                let tooltip = `<b>Concello: ${
                    data["nome"] ? data["nome"] : "---"
                }</b><ul class="attributes">`;
                tooltip += `<li><i>Poboación fixa</i>: ${
                    data["poboacion_fixa"]
                        ? `${NumberUtil.formatInteger(data["poboacion_fixa"])} habs.`
                        : "---"
                }</li>`;
                return tooltip + "</ul>";
            },
            getCustomMarkerOptions: ({options}) =>
                detail ? {...options, weight: 3, opacity: 1} : options,
            defaultOnClick: ({feature}) => navigate(`info/concellos/${feature.id}`),
            onMouseOver: ({layer}) =>
                layer.setStyle({
                    opacity: 0.8,
                }),
            onMouseOut: ({layer}) =>
                layer.setStyle({
                    opacity: 0.3,
                }),
        },
    });
}
