import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";
import {SisbagalMap} from "sisbagal/map";
import {useCallback, useRef} from "react";
import {useController, useFormContext} from "react-hook-form";
import {ProjUtil} from "base/utilities";
import {crsType} from "../geo";
import {AlertError} from "../presentational";

// https://github.com/PaulLeCam/react-leaflet/issues/697
export function useMutableCallback(callback) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const stableCallback = useCallback((...args) => {
        return callbackRef.current(...args);
    }, []);

    return stableCallback;
}

const FormMapGeojsonPoint = ({name: propsName, mapConfig, rules = {}}) => {
    const {getValues, reset} = useFormContext();

    const {
        fieldState: {error},
    } = useController({
        name: propsName,
        rules,
    });

    const geom = getValues()[propsName];

    const updateFormValues = (lat, lng) => {
        const values = getValues();
        let geom = values[propsName];
        if (!geom) {
            geom = {
                type: "Point",
                crs: crsType,
            };
        }
        values[propsName] = {
            ...geom,
            coordinates: [lat, lng],
        };
        reset({
            ...values,
        });
    };

    const handleMapChangeLocation = latlng => {
        const transformedCords = ProjUtil.transformFrom4326(latlng.lat, latlng.lng);
        updateFormValues(parseInt(transformedCords.x), parseInt(transformedCords.y));
    };

    const stableHandleClick = useMutableCallback(handleMapChangeLocation);

    return (
        <MapConfigProvider>
            <MapProvider config={mapConfig} geom={geom} onMapClick={stableHandleClick}>
                <AlertError error={error?.message} sx={{mb: 2, width: "100%"}} />
                <SisbagalMap
                    legend={<SisbagalMapMenu />}
                    mapOptions={{minZoom: 5}}
                    height="800px"
                />
            </MapProvider>
        </MapConfigProvider>
    );
};

export default FormMapGeojsonPoint;
