import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {DepositoCreationForm, DepositoModificationForm, DepositoGeoForm} from ".";
import {FormUtil} from "base/utilities";

const DepositoForm = ({
    deposito = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(deposito?.id),
        codigo: FormUtil.getFormValue(deposito?.codigo),
        titular: FormUtil.getFormValue(deposito?.titular),
        xestion: FormUtil.getFormValue(deposito?.xestion),
        estado: FormUtil.getFormValue(deposito?.estado),
        descripcion: FormUtil.getFormValue(deposito?.descripcion),
        posicion: FormUtil.getFormValue(deposito?.posicion),
        material: FormUtil.getFormValue(deposito?.material),
        planta: FormUtil.getFormValue(deposito?.planta),
        cloracion: FormUtil.getFormValue(deposito?.cloracion),
        diametro: FormUtil.getFormValue(deposito?.diametro),
        largo: FormUtil.getFormValue(deposito?.largo),
        ancho: FormUtil.getFormValue(deposito?.ancho),
        alto: FormUtil.getFormValue(deposito?.alto),
        capacidade: FormUtil.getFormValue(deposito?.capacidade),
        proteccion: FormUtil.getFormValue(deposito?.proteccion),
        suministro_electrico: FormUtil.getFormValue(deposito?.suministro_electrico),
        telexestion: FormUtil.getFormValue(deposito?.telexestion),
        caudalimetro: FormUtil.getFormValue(deposito?.caudalimetro),
        notas: FormUtil.getFormValue(deposito?.notas),
        // we must create an object with the same representation as expected search results
        sistema: deposito?.sistema
            ? {id: deposito.sistema, nome: deposito?.sistema_label}
            : null,
        geom: FormUtil.getFormValue(deposito?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            titular: FormUtil.getDataValue(data.titular),
            xestion: FormUtil.getDataValue(data.xestion),
            estado: FormUtil.getDataValue(data.estado),
            descripcion: FormUtil.getDataValue(data.descripcion),
            posicion: FormUtil.getDataValue(data.posicion),
            material: FormUtil.getDataValue(data.material),
            cloracion: FormUtil.getDataValue(data.cloracion),
            planta: FormUtil.getDataValue(data.planta),
            diametro: FormUtil.getDataValue(data.diametro),
            largo: FormUtil.getDataValue(data.largo),
            ancho: FormUtil.getDataValue(data.ancho),
            alto: FormUtil.getDataValue(data.alto),
            capacidade: FormUtil.getDataValue(data.capacidade),
            proteccion: FormUtil.getDataValue(data.proteccion),
            suministro_electrico: FormUtil.getDataValue(data.suministro_electrico),
            telexestion: FormUtil.getDataValue(data.telexestion),
            caudalimetro: FormUtil.getDataValue(data.caudalimetro),
            notas: FormUtil.getDataValue(data.notas),
            sistema: FormUtil.getDataValue(data.sistema?.id),
            concello: deposito?.concello || concello,
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <DepositoGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <DepositoModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <DepositoCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default DepositoForm;
