import {useDomain} from "domain/provider";
import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";
import {ConcelloFormSearch} from "concello/presentational/form";
import {AglomeracionFormSearch} from "saneamiento/aglomeracion/presentational/form";

const ColectorFormGeneralDataFields = ({creation = false, layout = "row"}) => {
    const {dominiocolectororixedato} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <ConcelloHiddenAuth>
                    {!creation && (
                        <Grid item xs={12}>
                            <ConcelloFormSearch
                                name="concello"
                                rules={{required: "O campo é obrigatorio"}}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AglomeracionFormSearch name="aglomeracion" />
                    </Grid>
                </ConcelloHiddenAuth>
            </Grid>
        </Grid>
    );
};

export default ColectorFormGeneralDataFields;
