import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {SistemaService} from "abastecemento/service";

const ViewSistemaChangesSubPage = () => {
    let sistema;
    [sistema] = useOutletContext();

    return (
        sistema && (
            <ViewEntityChangesSubPage
                entityService={SistemaService}
                entityType="sistema"
                entityId={sistema.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewSistemaChangesSubPage;
