import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const TanqueSubPageMenu = ({tanque}) => {
    return (
        <ChangesSubPageMenu
            pending_status={tanque.pending_status}
            headingPrimaryText={tanque.nome || tanque.codigo || tanque.id}
            headingSecondaryText="Tanque"
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Ficha"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="detail"
                text="Datos"
                icon={<ViewListOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={tanque} />
        </ChangesSubPageMenu>
    );
};

export default TanqueSubPageMenu;
