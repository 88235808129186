import {FormInputDecimal, FormInputInteger, FormInputText} from "base/component/form";
import Grid from "@mui/material/Grid";
import {AugasHiddenAuth} from "concello/auth";

const VertidoFormCNVFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <AugasHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputText name="codigo_cnae" label="Código CNAE" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="caracteristicas" label="Características" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="volume_autorizado"
                        label="Volume autorizado"
                        placeholder="0,00"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText
                        name="autorizacion_titular"
                        label="Titular autorización"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="cnae" label="Actividade" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputInteger name="x_cnv" label="X (CNV)" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputInteger name="y_cnv" label="Y (CNV)" />
                </Grid>
            </AugasHiddenAuth>
        </Grid>
    );
};

export default VertidoFormCNVFields;
