import {useDomain} from "domain/provider";
import {FormSelect, FormSelectMultiple} from "base/component/form";
import Grid from "@mui/material/Grid";

const BombeoFormElementosFields = ({layout = "row"}) => {
    const {
        dominiobombeopretratamento,
        dominiobombeoproteccion,
        dominiobombeotelecontrol,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telecontrol"
                    label="Telecontrol"
                    options={dominiobombeotelecontrol}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="proteccion"
                    label="Sistema de protección"
                    options={dominiobombeoproteccion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="pretratamiento"
                    label="Pretratamento"
                    options={dominiobombeopretratamento}
                />
            </Grid>
        </Grid>
    );
};

export default BombeoFormElementosFields;
