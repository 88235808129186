import {PageLayout} from "base/layout";
import {useAppConfig, useConfigModule} from "base/provider";
import {useEffect} from "react";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMap} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";

const ViewConcellosPage = () => {
    const {setPagePath} = useAppConfig();
    const {moduleFilter, setModuleFilter} = useConfigModule();

    useEffect(() => {
        setPagePath([{name: "Visor", path: `/concellos`}]);
        setModuleFilter({
            ...moduleFilter,
            concello: null,
            aglomeracion: null,
        });
    }, []);

    // TODO: Improve to include layers in provider without config
    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    concello: {fitBounds: true},
                    subaglomeracion: {
                        visible: false,
                    },
                    edar: {
                        visible: false,
                    },
                    sistema: {
                        visible: false,
                    },
                    etap: {
                        visible: false,
                    },
                    masaAuga: {
                        visible: false,
                    },
                    rios: {
                        visible: false,
                    },
                    zonasCaptacion: {
                        visible: false,
                    },
                }}
            >
                <PageLayout>
                    <SisbagalMap legend={<SisbagalMapMenu />} />
                </PageLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewConcellosPage;
