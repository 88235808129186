import {useAuth} from "base/auth";
import {NumberUtil} from "base/utilities";

import {EntityGeneralDataSection} from "base/entity";

const PoligonoGeneralDataSection = ({poligono}) => {
    const {ROLES} = useAuth();

    const sections = [
        {
            label: "Concello",
            value: poligono.concello_label,
            linkPath: `/concellos/${poligono.concello}`,
        },
        {
            label: "Aglomeración",
            value: poligono.aglomeracion_label,
            linkPath: `/aglomeracions/${poligono.aglomeracion}/summary`,
        },
        {
            label: "EDAR",
            value: poligono.edar_label,
            linkPath: `/concellos/${poligono.concello}/edars/${poligono.edar}/summary`,
            tooltipText: "EDAR a que verte as augas residuais",
        },
    ];

    return (
        <EntityGeneralDataSection
            featured_image={poligono.featured_image}
            featured_document={poligono.featured_document}
            name={poligono.nome}
            sections={sections}
        />
    );
};

export default PoligonoGeneralDataSection;
