import {Route} from "react-router-dom";
import {
    CreateDepositoPage,
    ListDepositoPage,
    ViewDepositoPanel,
    ViewDepositoPage,
    ViewDepositoLocationSubPage,
    ViewDepositoDocumentsSubPage,
    ViewDepositoSummarySubPage,
    ViewDepositoDetailSubPage,
    UpdateDepositoPanel,
    ManageDepositoPage,
    ViewDepositoDocumentPanel,
    ViewDepositoChangesSubPage,
} from "abastecemento/deposito/container";

const depositoRoutes = [
    <Route key="deposito-new" path="depositos/new" element={<CreateDepositoPage />} />,
    <Route key="deposito-manage" path="depositos" element={<ManageDepositoPage />}>
        <Route key="deposito-list" path="" element={<ListDepositoPage />}>
            <Route
                key="deposito-info"
                path="info/:idDeposito"
                element={<ViewDepositoPanel />}
            />
        </Route>
        <Route key="deposito-detail" path=":idDeposito" element={<ViewDepositoPage />}>
            <Route
                key="deposito-location"
                path="location"
                element={<ViewDepositoLocationSubPage />}
            >
                <Route
                    key="deposito-location-edit"
                    path=":section/:action"
                    element={<UpdateDepositoPanel />}
                />
            </Route>
            <Route
                key="deposito-documents"
                path="documents/*"
                element={<ViewDepositoDocumentsSubPage />}
            >
                <Route
                    key="deposito-documents-view"
                    path="detail/*"
                    element={<ViewDepositoDocumentPanel />}
                />
            </Route>
            <Route
                key="deposito-summary"
                path="summary"
                element={<ViewDepositoSummarySubPage />}
            >
                <Route
                    key="deposito-general-edit"
                    path=":section/:action"
                    element={<UpdateDepositoPanel />}
                />
            </Route>
            <Route
                key="deposito-detail"
                path="detail"
                element={<ViewDepositoDetailSubPage />}
            >
                <Route
                    key="deposito-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateDepositoPanel />}
                />
            </Route>
            <Route
                key="deposito-changes"
                path="changes"
                element={<ViewDepositoChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default depositoRoutes;
