export function useDepositoMap(concello = null) {
    const concelloConfig = concello
        ? {
              customLoader: () => {
                  return () => Promise.resolve(concello.geom);
              },
              detail: true,
          }
        : {customClickHandler: () => {}};

    const mapConfig = {
        concello: {...concelloConfig},
        etap: {customClickHandler: () => {}},
        deposito: {customClickHandler: () => {}},
        captacion: {customClickHandler: () => {}},
        tuberia: {
            visible: false,
            customClickHandler: () => {},
        },
        nucleo: {customClickHandler: () => {}},
    };

    return {mapConfig};
}
