import {createEntityService} from "base/entity/service";
import {
    createPoligono,
    createPoligonos,
    poligono_api_adapter,
    poligonos_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/poligonosindustriais",
    createPoligono,
    createPoligonos,
    poligono_api_adapter,
    poligonos_api_adapter
);

const PoligonoService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(poligono) {
        return entityService.create(poligono);
    },

    update(poligono) {
        return entityService.update(poligono);
    },

    updateWithPatch(poligono) {
        return entityService.updateWithPatch(poligono);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default PoligonoService;
