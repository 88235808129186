import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SistemaCaracteristicasSection,
    SistemaGeneralDataSection,
} from "../presentational/section";

const ViewSistemaDetailSubPage = () => {
    let sistema;
    [sistema] = useOutletContext();

    const sections = [
        <SistemaGeneralDataSection sistema={sistema} />,
        <SistemaCaracteristicasSection sistema={sistema} />,
    ];

    return sistema && <EntityViewSubPage sections={sections} />;
};

export default ViewSistemaDetailSubPage;
