import {SubPageMenu} from "base/layout";
import ChangesAlertBox from "./ChangesAlertBox";

const ChangesSubPageMenu = ({
    pending_status,
    headingPrimaryText,
    headingSecondaryText = null,
    headingInfo = null,
    children,
}) => {
    const getHeadingInfo = () => {
        return (
            <>
                {headingInfo}
                <ChangesAlertBox pending_status={pending_status} />
            </>
        );
    };

    return (
        <SubPageMenu
            headingPrimaryText={headingPrimaryText}
            headingSecondaryText={headingSecondaryText}
            headingInfo={getHeadingInfo()}
        >
            {children}
        </SubPageMenu>
    );
};

export default ChangesSubPageMenu;
