import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionCargasDispersasNonConectadaSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`cargasdispersasnonconectada/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Non conectada" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Saneamento autónomo:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.saneamento_autonomo
                        )}
                        unit="Heq"
                        tooltipText="Poboación con saneamento autónomo dentro da aglomeración"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sen depuración:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.domestica_sen_tramento
                        )}
                        unit="Heq"
                        tooltipText="Poboación dentro da aglomeración conectada a rede de saneamento que non recibe tratamento de depuración"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionCargasDispersasNonConectadaSection;
