import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewDepositoLocationSubPage = () => {
    let deposito;
    [deposito] = useOutletContext();

    const sections = [<EntityLocationSection geom={deposito.geom} />];

    return deposito && <EntityViewSubPage sections={sections} />;
};

export default ViewDepositoLocationSubPage;
