import {FormProvider, useForm} from "react-hook-form";
import {FormUtil} from "base/utilities";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {PoligonoCreationForm, PoligonoModificationForm} from ".";

const PoligonoForm = ({
    poligono = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(poligono?.id, null),
        nome: FormUtil.getFormValue(poligono?.nome),
        notas: FormUtil.getFormValue(poligono?.notas),
        sector: FormUtil.getFormValue(poligono?.sector),
        superficie: FormUtil.getFormValue(poligono?.superficie),
        grado_ocupacion: FormUtil.getFormValue(poligono?.grado_ocupacion),
        caudal_saneamento: FormUtil.getFormValue(poligono?.caudal_saneamento),
        caudal_abastecemento: FormUtil.getFormValue(poligono?.caudal_abastecemento),
        habitantes_equivalentes: FormUtil.getFormValue(
            poligono?.habitantes_equivalentes
        ),
        carga: FormUtil.getFormValue(poligono?.carga),
        saneamento_propio: FormUtil.getFormValue(poligono?.saneamento_propio),
        estado: FormUtil.getFormValue(poligono?.estado),
        xestor: FormUtil.getFormValue(poligono?.xestor),
        promotor: FormUtil.getFormValue(poligono?.promotor),
        dotacion: FormUtil.getFormValue(poligono?.dotacion),
        // we must create an object with the same representation as expected search results
        aglomeracion: poligono?.aglomeracion
            ? {id: poligono.aglomeracion, nome: poligono?.aglomeracion_label}
            : null,
        concello: poligono?.concello
            ? {codigo: poligono.concello, nome: poligono?.concello_label}
            : {codigo: concello, nome: ""},
        edar: poligono?.edar ? {id: poligono.edar, nome: poligono?.edar_label} : null,
        geom: FormUtil.getFormValue(poligono?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            nome: FormUtil.getDataValue(data.nome),
            notas: FormUtil.getDataValue(data.notas),
            sector: FormUtil.getDataValue(data.sector),
            superficie: FormUtil.getDataValue(data.superficie),
            grado_ocupacion: FormUtil.getDataValue(data.grado_ocupacion),
            caudal_saneamento: FormUtil.getDataValue(data.caudal_saneamento),
            caudal_abastecemento: FormUtil.getDataValue(data.caudal_abastecemento),
            habitantes_equivalentes: FormUtil.getDataValue(
                data.habitantes_equivalentes
            ),
            carga: FormUtil.getDataValue(data.carga),
            saneamento_propio: FormUtil.getDataValue(data.saneamento_propio),
            estado: FormUtil.getDataValue(data.estado),
            xestor: FormUtil.getDataValue(data.xestor),
            promotor: FormUtil.getDataValue(data.promotor),
            dotacion: FormUtil.getDataValue(data.dotacion),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            edar: FormUtil.getDataValue(data.edar?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                {updatedSection ? (
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    >
                        <PoligonoModificationForm section={updatedSection} />
                    </EntityForm>
                ) : (
                    <PoligonoCreationForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    />
                )}
            </FormProvider>
        </DomainProvider>
    );
};

export default PoligonoForm;
