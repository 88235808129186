import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {vertido_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {VertidoForm} from "../presentational/form";

const CreateVertidoPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = vertido => {
        VertidoService.create(vertido_view_adapter({...vertido}))
            .then(createdVertido => {
                navigate(`${basePath}/${createdVertido.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <VertidoForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de Vertido"
            error={error}
        />
    );
};

export default CreateVertidoPage;
