import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    ConcelloInventarioBombeosSection,
    ConcelloInventarioEDARSection,
    ConcelloInventarioRexistroConDatosSection,
    ConcelloInventarioTanquesSection,
    ConcelloInventarioTotalSection,
} from "../presentational/section";

const ViewConcelloInventarioSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    const sections = [
        <ConcelloInventarioTotalSection concello={concello} />,
        <ConcelloInventarioBombeosSection concello={concello} />,
        <ConcelloInventarioTanquesSection concello={concello} />,
        <ConcelloInventarioEDARSection concello={concello} />,
        <ConcelloInventarioRexistroConDatosSection concello={concello} />,
    ];

    return concello && <EntityViewSubPage sections={sections} />;
};

export default ViewConcelloInventarioSubPage;
