import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";

import {useNavigateWithReload} from "base/hooks";
import EditIcon from "@mui/icons-material/Edit";
import {ETAPForm} from "../presentational/form";
import {SectionCardHeaderAction} from "base/component/presentational";
import {ETAPService} from "abastecemento/service";
import {etap_view_adapter} from "abastecemento/model";
import ETAPLocationSection from "../presentational/section/ETAPLocationSection";
import {SisbagalSectionCard} from "sisbagal/auth";

const UpdateETAPGeoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [mode, setMode] = useState("view");
    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let etap;
    [etap] = useOutletContext();

    const handleSubmit = etap => {
        ETAPService.update(etap_view_adapter({...etap}))
            .then(() => {
                navigate(location.pathname, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        setMode("view");
    };

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Ubicación" secondaryActions={secondaryActions}>
            {mode === "edit" ? (
                <ETAPForm
                    etap={etap}
                    updatedSection="geo"
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            ) : (
                <ETAPLocationSection etap={etap} />
            )}
        </SisbagalSectionCard>
    );
};

export default UpdateETAPGeoPanel;
