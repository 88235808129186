import NumberUtil from "base/utilities/NumberUtil";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";
import "leaflet-arrowheads";
import {useNavigate} from "react-router-dom";

const markerBaseOptions = {
    marker: "l",
    fontSize: 35,
    addStroke: false,
    weight: 2,
    color: "#000000",
    opacity: 0.8,
    lineJoin: "round",
    pane: mapOverlayPanes[0],
};

const discriminators = Object.freeze({
    PORCENTAXE: "porcentaxe",
});

export const LayerLegend = createLayerLegend({
    layerName: "Conexións",
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.PORCENTAXE,
            text: "Porcentaxe de conexión",
            defaultIconOptions: markerBaseOptions,
            getValueFn: feature =>
                parseFloat(feature.properties[discriminators.PORCENTAXE]),
            entries: [
                {
                    text: "100%",
                    filterFn: val => val === 100,
                    markerOptions: {...markerBaseOptions, color: "#00FF1E"},
                },
                {
                    text: "> 66%, < 100%",
                    filterFn: val => val > 66 && val < 100,
                    markerOptions: {...markerBaseOptions, color: "#F5F503"},
                },
                {
                    text: "> 33%, ≤ 66%",
                    filterFn: val => val > 33 && val <= 66,
                    markerOptions: {...markerBaseOptions, color: "#FF9500"},
                },
                {
                    text: "> 0%, ≤ 33%",
                    filterFn: val => val !== null && val <= 33,
                    markerOptions: {...markerBaseOptions, color: "#F400A1"},
                },
                {
                    text: "0%",
                    filterFn: val => val === null,
                    markerOptions: {...markerBaseOptions, color: "#000000"},
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            return `<span>Conexión: ${
                feature.properties.porcentaxe
                    ? NumberUtil.formatDecimal2(feature.properties.porcentaxe) + "%"
                    : "---"
            }</span>`;
        },
        addCustomLayerOptions: options => {
            return {
                ...options,
                arrowheads: {
                    frequency: "75px",
                    size: "6px",
                },
            };
        },
        defaultOnClick: ({feature}) => navigate(`info/conexions/${feature.id}`),
    });
}
