import {AuthApiService} from "base/service";
import {createEntityService} from "base/entity/service";
import {
    createConcello,
    createConcellos,
    concello_api_adapter,
    concellos_api_adapter,
} from "concello/model";

const entityService = createEntityService(
    "/api/infrastructures/concellos",
    createConcello,
    createConcellos,
    concello_api_adapter,
    concellos_api_adapter
);

const basePathForStats = "/api/infrastructures/stats/concello";

const ConcelloService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(tanque) {
        return entityService.create(tanque);
    },

    update(tanque) {
        return entityService.update(tanque);
    },

    updateWithPatch(tanque) {
        return entityService.updateWithPatch(tanque);
    },
};

export default ConcelloService;
