import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const EDARGeneralDataSection = ({edar}) => {
    const {ROLES} = useAuth();

    const sections = [
        {label: "Código", value: edar.codigo},
        {label: "Código interno", value: edar.codigo_interno},
        {
            label: "Concello",
            value: edar.concello_label,
            linkPath: `/concellos/${edar.concello}`,
        },
        {
            label: "Aglomeración",
            value: edar.aglomeracion_label,
            linkPath: `/aglomeracions/${edar.aglomeracion}/summary`,
        },
        {
            label: "Punto vertido",
            value: edar.punto_vertido_label,
            linkPath: `/concellos/${edar.concello}/vertidos/${edar.punto_vertido}/summary`,
        },
        {
            label: "Bacía",
            value: edar.masa_auga_label,
            linkPath: `/medioreceptor/masaauga/${edar.masa_auga}/principal`,
        },
        {
            label: "Sistema de explotación",
            value: edar.sistema_explotacion_label,
            linkPath: `/medioreceptor/sistemaexplotacion/${edar.sistema_explotacion}/`,
        },
        {label: "Tipo de carga", value: edar.tipo_carga_label},
        {label: "Notas", value: edar.notas},
    ];

    return (
        <EntityGeneralDataSection
            featured_image={edar.featured_image}
            featured_document={edar.featured_document}
            name={edar.nome || `EDAR ${edar.codigo}`}
            sections={sections}
        />
    );
};

export default EDARGeneralDataSection;
