import {FormSection} from "base/component/form";
import {BombeoFormCaracteristicasFields, BombeoFormElementosFields} from ".";

const BombeoFormCaracteristicasSection = () => {
    return (
        <>
            <FormSection title="Características">
                <BombeoFormCaracteristicasFields />
            </FormSection>
            <FormSection title="Elementos">
                <BombeoFormElementosFields />
            </FormSection>
        </>
    );
};

export default BombeoFormCaracteristicasSection;
