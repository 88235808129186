import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";

const SectionHeading = ({children, label = true}) => {
    return (
        <Grid
            container
            sx={{display: "flex", flexDirection: "row", flexWrap: "noWrap"}}
        >
            {label && (
                <CircleIcon
                    sx={{color: "grey.300", mr: 1, mt: 1, fontSize: "0.7rem"}}
                />
            )}
            <Typography variant="h6">{children}</Typography>
        </Grid>
    );
};

export default SectionHeading;
