import {DialogLayout} from "base/component/presentational";

const ApproveChangeDialog = ({isDialogOpen, setIsDialogOpen, onApproveChange}) => {
    const handleDialog = isOpen => {
        setIsDialogOpen(isOpen);
    };

    const handleConfirmApproveChange = () => {
        setIsDialogOpen(false);
        onApproveChange();
    };

    return (
        <DialogLayout
            dialogId={`approve`}
            dialogLabelledBy={`aprobar`}
            dialogTitle={`¿Desea incoporar este cambio a los datos ya consolidados en el sistema?`}
            dialogContentText={`Si hace clic en Aprobar, el elemento se actualizará y los datos reemplazarán a los anteriores.`}
            mainActionClick={handleConfirmApproveChange}
            mainActionText="Aprobar"
            mainActionColor="success"
            handleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
        />
    );
};

export default ApproveChangeDialog;
