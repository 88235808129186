import {useEffect} from "react";

export function useEditLayerProvider(layer) {
    return [
        {
            included: true,
            layer,
            useProvider: function() {},
        },
    ];
}
