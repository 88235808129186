const findLegendColor = (legendItems, label) => {
    return legendItems.find(legendItem => legendItem.label === label).color;
};

const infrastructureTypesConfig = {
    edars: {
        title: "Nº total de EDAR",
        legendItems: [
            {
                color: "#F3D6F6",
                label: "0",
            },
            {
                color: "#EBAEE0",
                label: "1 a 3",
            },
            {
                color: "#DF88B7",
                label: "4 a 10",
            },
            {
                color: "#D2637F",
                label: "11 a 20",
            },
            {
                color: "#c3423f",
                label: "> 20",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total == 0:
                    return findLegendColor(
                        infrastructureTypesConfig.edars.legendItems,
                        "0"
                    );
                case total <= 3:
                    return findLegendColor(
                        infrastructureTypesConfig.edars.legendItems,
                        "1 a 3"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.edars.legendItems,
                        "4 a 10"
                    );
                case total <= 20:
                    return findLegendColor(
                        infrastructureTypesConfig.edars.legendItems,
                        "11 a 20"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.edars.legendItems,
                        "> 20"
                    );
            }
        },
    },
    tanques: {
        title: "Nº total de Tanques",
        legendItems: [
            {
                color: "#F9DBF7",
                label: "0",
            },
            {
                color: "#EFACD2",
                label: "1",
            },
            {
                color: "#E38092",
                label: "2 a 5",
            },
            {
                color: "#D56E56",
                label: "> 5",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total == 0:
                    return findLegendColor(
                        infrastructureTypesConfig.tanques.legendItems,
                        "0"
                    );
                case total <= 3:
                    return findLegendColor(
                        infrastructureTypesConfig.tanques.legendItems,
                        "1"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.tanques.legendItems,
                        "2 a 5"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.tanques.legendItems,
                        "> 5"
                    );
            }
        },
    },
    bombeos: {
        title: "Nº total de Bombeos",
        legendItems: [
            {
                color: "#FDDFF5",
                label: "0",
            },
            {
                color: "#F9C1DB",
                label: "1",
            },
            {
                color: "#F4A4B2",
                label: "2 a 5",
            },
            {
                color: "#EE9288",
                label: "6 a 10",
            },
            {
                color: "#E79B6D",
                label: "> 10",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total === 0:
                    return findLegendColor(
                        infrastructureTypesConfig.bombeos.legendItems,
                        "0"
                    );
                case total === 1:
                    return findLegendColor(
                        infrastructureTypesConfig.bombeos.legendItems,
                        "1"
                    );
                case total <= 5:
                    return findLegendColor(
                        infrastructureTypesConfig.bombeos.legendItems,
                        "2 a 5"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.bombeos.legendItems,
                        "6 a 10"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.bombeos.legendItems,
                        "> 10"
                    );
            }
        },
    },
    sistemas: {
        title: "Nº total de Sistemas",
        legendItems: [
            {
                color: "#F6FFF2",
                label: "0",
            },
            {
                color: "#E6FFE5",
                label: "1 a 3",
            },
            {
                color: "#D9FFE3",
                label: "4 a 10",
            },
            {
                color: "#CEFFE9",
                label: "11 a 20",
            },
            {
                color: "#C3FFF4",
                label: "> 20",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total == 0:
                    return findLegendColor(
                        infrastructureTypesConfig.sistemas.legendItems,
                        "0"
                    );
                case total <= 3:
                    return findLegendColor(
                        infrastructureTypesConfig.sistemas.legendItems,
                        "1 a 3"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.sistemas.legendItems,
                        "4 a 10"
                    );
                case total <= 20:
                    return findLegendColor(
                        infrastructureTypesConfig.sistemas.legendItems,
                        "11 a 20"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.sistemas.legendItems,
                        "> 20"
                    );
            }
        },
    },
    etaps: {
        title: "Nº total de ETAP",
        legendItems: [
            {
                color: "#E0F4D7",
                label: "0",
            },
            {
                color: "#B2E7B1",
                label: "1 a 3",
            },
            {
                color: "#8BDA9F",
                label: "4 a 10",
            },
            {
                color: "#67CB9C",
                label: "11 a 20",
            },
            {
                color: "#44BBA4",
                label: "> 20",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total == 0:
                    return findLegendColor(
                        infrastructureTypesConfig.etaps.legendItems,
                        "0"
                    );
                case total <= 3:
                    return findLegendColor(
                        infrastructureTypesConfig.etaps.legendItems,
                        "1 a 3"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.etaps.legendItems,
                        "4 a 10"
                    );
                case total <= 20:
                    return findLegendColor(
                        infrastructureTypesConfig.etaps.legendItems,
                        "11 a 20"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.etaps.legendItems,
                        "> 20"
                    );
            }
        },
    },
    depositos: {
        title: "Nº total de Depósitos",
        legendItems: [
            {
                color: "#E8F8E2",
                label: "0",
            },
            {
                color: "#BCEDC0",
                label: "1",
            },
            {
                color: "#98E1B9",
                label: "2 a 5",
            },
            {
                color: "#76D3C2",
                label: "> 5",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total == 0:
                    return findLegendColor(
                        infrastructureTypesConfig.depositos.legendItems,
                        "0"
                    );
                case total <= 3:
                    return findLegendColor(
                        infrastructureTypesConfig.depositos.legendItems,
                        "1"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.depositos.legendItems,
                        "2 a 5"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.depositos.legendItems,
                        "> 5"
                    );
            }
        },
    },
    captacions: {
        title: "Nº total de Captacións",
        legendItems: [
            {
                color: "#F0FCEC",
                label: "0",
            },
            {
                color: "#DAF9DA",
                label: "1",
            },
            {
                color: "#C8F4D5",
                label: "2 a 5",
            },
            {
                color: "#B7F0D7",
                label: "6 a 10",
            },
            {
                color: "#A7EAE0",
                label: "> 10",
            },
        ],
        fillColor: item => {
            const total = item.total;
            switch (true) {
                case total === 0:
                    return findLegendColor(
                        infrastructureTypesConfig.captacions.legendItems,
                        "0"
                    );
                case total === 1:
                    return findLegendColor(
                        infrastructureTypesConfig.captacions.legendItems,
                        "1"
                    );
                case total <= 5:
                    return findLegendColor(
                        infrastructureTypesConfig.captacions.legendItems,
                        "2 a 5"
                    );
                case total <= 10:
                    return findLegendColor(
                        infrastructureTypesConfig.captacions.legendItems,
                        "6 a 10"
                    );
                default:
                    return findLegendColor(
                        infrastructureTypesConfig.captacions.legendItems,
                        "> 10"
                    );
            }
        },
    },
};

const useInfrastructureStatsCountConfig = () => {
    const getInfrastructureTypeConfig = infrastructureType => {
        return infrastructureTypesConfig[infrastructureType];
    };

    return {getInfrastructureTypeConfig};
};

export default useInfrastructureStatsCountConfig;
