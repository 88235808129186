import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const MasaAugaDatosDescargaDomesticaSection = ({masaAuga}) => {
    return (
        <SectionCard title="Doméstica">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Rede de saneamento:"
                        value={NumberUtil.formatDecimalAsInteger(
                            masaAuga.stats.saneamentodomesticatotal
                        )}
                        unit="Heq."
                        tooltipText="Carga doméstica descargada procedente dos puntos de vertido das EDAR dos sistemas de saneamento"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outros:"
                        value={NumberUtil.formatDecimalAsInteger(
                            masaAuga.stats.nonsaneamentourbanodomestica
                        )}
                        unit="Heq."
                        tooltipText="Carga doméstica da poboación non conectada á rede de saneamento dos nucleos."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default MasaAugaDatosDescargaDomesticaSection;
