import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {EDAROutrasInstalacionsSection} from "../presentational/section";

const ViewEDAROutrasInstalacionsSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [<EDAROutrasInstalacionsSection edar={edar} />];

    return edar && <EntityViewSubPage sections={sections} />;
};

export default ViewEDAROutrasInstalacionsSubPage;
