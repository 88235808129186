import {PoligonoService} from "saneamiento/service";
import {usePoligonoTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListPoligonoPage = () => {
    const {tableColumns} = usePoligonoTable();

    return (
        <ListInfraestructuraPage
            entityName="Polígonos"
            entityPath="poligonos"
            service={PoligonoService}
            tableColumns={tableColumns}
            createButton={false}
        />
    );
};
export default ListPoligonoPage;
