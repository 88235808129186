import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const StatsFilterLayout = ({filterAttributes}) => {
    return (
        <Stack sx={{width: "100%", bgcolor: "#ffff", p: 2}}>
            <Typography variant="h6">Filtro</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
                {filterAttributes.map((filterAttribute, index) => (
                    <Box key={index} sx={{width: "25%", p: 1}}>
                        {filterAttribute}
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
};

export default StatsFilterLayout;
