import {Link, useLocation, useNavigate, useResolvedPath} from "react-router-dom";

import useTheme from "@mui/material/styles/useTheme";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import {usePageMenu} from ".";

const PageMenuListItemButton = ({to, text, icon = null, ...props}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {clearSelected} = usePageMenu();

    let resolved = useResolvedPath(to);
    let location = useLocation();

    const selected = location.pathname === resolved.pathname;

    const handleClick = () => {
        clearSelected();
        navigate(to);
    };

    return (
        <>
            <ListItemButton
                {...props}
                selected={selected}
                sx={{
                    bgcolor: theme.palette.menu.primary.header.background,
                    borderLeft: selected
                        ? `5px solid ${theme.palette.menu.primary.header.text}`
                        : null,
                }}
                onClick={handleClick}
            >
                {icon && (
                    <Tooltip title={text} placement="bottom-end">
                        <ListItemIcon
                            style={{
                                minWidth: "35px",
                                color: selected
                                    ? theme.palette.menu.primary.header.text
                                    : theme.palette.menu.primary.header.icon,
                            }}
                        >
                            {icon}
                        </ListItemIcon>
                    </Tooltip>
                )}
                <ListItemText
                    primaryTypographyProps={{
                        color: theme.palette.menu.primary.header.text,
                        fontWeight: selected ? "700" : "100",
                    }}
                    primary={text}
                />
            </ListItemButton>
            <Divider />
        </>
    );
};

export default PageMenuListItemButton;
