import {NumberUtil} from "base/utilities";

export function usePoligonoTable() {
    const tableColumns = [
        {
            id: "nome",
            label: "Nome",
            width: 15,
        },
        {
            id: "concello_label",
            label: "Concello",
            width: 15,
        },
        {
            id: "aglomeracion_label",
            label: "Aglomeración",
            width: 15,
        },
        {
            id: "edar_label",
            label: "EDAR",
            width: 15,
        },
        {
            id: "carga",
            label: "Carga (mg/l)",
            formatFunction: element => NumberUtil.formatDecimal(element.carga),
            width: 15,
        },
        {
            id: "habitantes_equivalentes",
            label: "Hab. Equivalentes",
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.habitantes_equivalentes),
            width: 15,
        },
        {
            id: "superficie",
            label: "Superficie (ha)",
            formatFunction: element => NumberUtil.formatDecimal(element.superficie),
            width: 10,
        },
    ];

    return {tableColumns};
}
