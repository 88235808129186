import {FieldUtil, NumberUtil} from "base/utilities";

export function usePoligonoData(poligono) {
    const getEntityData = poligono => {
        return {
            image: poligono?.featured_image,
            name: `${poligono?.nome || poligono?.codigo}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(poligono?.codigo)],
                        ["Concello:", FieldUtil.getValue(poligono?.concello_label)],
                        [
                            "Aglomeración:",
                            FieldUtil.getValue(poligono?.aglomeracion_label),
                        ],
                        [
                            "Grado de ocupación:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal2(poligono?.grado_ocupacion)
                            )} %`,
                        ],
                    ],
                },
                {
                    head: "Saneamento",
                    content: [
                        [
                            "Grado de ocupación:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(poligono?.grado_ocupacion)
                            ),
                        ],
                        [
                            "Superficie:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(poligono?.superficie)
                            ),
                        ],
                        [
                            "Habitantes equivalentes:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(
                                    poligono?.habitantes_equivalentes
                                )
                            ),
                        ],
                        [
                            "Caudal de saneamento:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(poligono?.caudal_saneamento)
                            ),
                        ],
                        ["Carga:", FieldUtil.getValue(poligono?.carga)],
                        [
                            "Dotación:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(poligono?.dotacion)
                            ),
                        ],
                        [
                            "Caudal de abastecemento:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(
                                    poligono?.caudal_abastecemento
                                )
                            ),
                        ],
                    ],
                },
                {
                    head: "Inventario",
                    content: [
                        ["Estado:", FieldUtil.getValue(poligono?.estado_label)],
                        ["Xestor:", FieldUtil.getValue(poligono?.xestor)],
                        ["Promotor:", FieldUtil.getValue(poligono?.promotor)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
