import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {CaptacionCreationForm, CaptacionGeoForm, CaptacionModificationForm} from ".";
import {FormUtil} from "base/utilities";

const CaptacionForm = ({
    captacion = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(captacion?.id),
        codigo: FormUtil.getFormValue(captacion?.codigo),
        titular: FormUtil.getFormValue(captacion?.titular),
        xestion: FormUtil.getFormValue(captacion?.xestion),
        estado: FormUtil.getFormValue(captacion?.estado),
        bacia: FormUtil.getFormValue(captacion?.bacia),
        descripcion: FormUtil.getFormValue(captacion?.descripcion),
        orixe: FormUtil.getFormValue(captacion?.orixe),
        tipo: FormUtil.getFormValue(captacion?.tipo),
        uso: FormUtil.getFormValue(captacion?.uso),
        legalizada: FormUtil.getFormValue(captacion?.legalizada),
        bombeo: FormUtil.getFormValue(captacion?.bombeo),
        proteccion: FormUtil.getFormValue(captacion?.proteccion),
        suministro_electrico: FormUtil.getFormValue(captacion?.suministro_electrico),
        telexestion: FormUtil.getFormValue(captacion?.telexestion),
        caudalimetro: FormUtil.getFormValue(captacion?.caudalimetro),
        notas: FormUtil.getFormValue(captacion?.notas),
        // we must create an object with the same representation as expected search results
        sistema: captacion?.sistema
            ? {id: captacion.sistema, nome: captacion?.sistema_label}
            : null,
        geom: FormUtil.getFormValue(captacion?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            titular: FormUtil.getDataValue(data.titular),
            xestion: FormUtil.getDataValue(data.xestion),
            estado: FormUtil.getDataValue(data.estado),
            bacia: FormUtil.getDataValue(data.bacia),
            descripcion: FormUtil.getDataValue(data.descripcion),
            orixe: FormUtil.getDataValue(data.orixe),
            tipo: FormUtil.getDataValue(data.tipo),
            uso: FormUtil.getDataValue(data.uso),
            legalizada: FormUtil.getDataValue(data.legalizada),
            bombeo: FormUtil.getDataValue(data.bombeo),
            proteccion: FormUtil.getDataValue(data.proteccion),
            suministro_electrico: FormUtil.getDataValue(data.suministro_electrico),
            telexestion: FormUtil.getDataValue(data.telexestion),
            caudalimetro: FormUtil.getDataValue(data.caudalimetro),
            notas: FormUtil.getDataValue(data.notas),
            sistema: FormUtil.getDataValue(data.sistema?.id),
            concello: captacion?.concello || concello,
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <CaptacionGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <CaptacionModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <CaptacionCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default CaptacionForm;
