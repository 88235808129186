import {useOutletContext} from "react-router-dom";
import {TanqueService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {TanqueGeneralDataSection} from "../presentational/section";
import {UpdateTanqueGeoPanel} from ".";

const ViewTanqueSummarySubPage = () => {
    let tanque;
    [tanque] = useOutletContext();

    const sections = [
        <TanqueGeneralDataSection tanque={tanque} />,
        <UpdateTanqueGeoPanel />,
        <EntityAuditSection entity={tanque} />,
    ];

    return tanque && <EntityViewSubPage sections={sections} service={TanqueService} />;
};

export default ViewTanqueSummarySubPage;
