import {useNavigate} from "react-router-dom";
import {useAuth} from "base/auth";
import {NumberUtil} from "base/utilities";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const BombeoCaracteristicasSection = ({bombeo}) => {
    const navigate = useNavigate();
    const {ROLES} = useAuth();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Caudal:"
                        value={NumberUtil.formatDecimal2(bombeo.caudal_bombeo)}
                        unit="l/s"
                        tooltipText="Caudal de deseño ou caudal no punto de funcionamento"
                    />
                    <SectionField
                        label="Altura bombeo:"
                        value={NumberUtil.formatDecimal2(bombeo.altura_bombeo)}
                        unit="m"
                        tooltipText="Altura de deseño ou altura no punto de funcionamento"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Bombas activas:"
                        value={bombeo.bombas_activas}
                        tooltipText="Número de bombas activas no punto de funcionamento"
                    />
                    <SectionField
                        label="Bombas reserva:"
                        value={bombeo.bombas_reserva}
                        tooltipText="Número de bombas de reserva"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default BombeoCaracteristicasSection;
