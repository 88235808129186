import useTheme from "@mui/material/styles/useTheme";
import Stack from "@mui/material/Stack";
import ModuleBreadcrumb from "./ModuleBreadcrumb";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import {useAppConfig} from "base/provider";
import {Fragment} from "react";

const ModuleHeader = () => {
    const theme = useTheme();
    const {breadcrumbPath, moduleActions} = useAppConfig();

    return (
        <Stack
            direction="row"
            sx={{
                backgroundColor: theme.palette.primary.light,
                borderBottom: `1px solid ${theme.palette.secondary.light}`,
            }}
            justifyContent="space-between"
            alignItems="center"
        >
            <ModuleBreadcrumb breadcrumbPath={breadcrumbPath} />
            <ButtonGroup variant="outlined" aria-label="map button group">
                {moduleActions.map((moduleAction, index) => {
                    return (
                        <Fragment key={index}>
                            <Divider orientation="vertical" flexItem />
                            {moduleAction}
                        </Fragment>
                    );
                })}
            </ButtonGroup>
        </Stack>
    );
};
export default ModuleHeader;
