import {SubPageMenuListItemButton, TextLink} from "base/component/presentational";
import Box from "@mui/material/Box";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const SistemaSubPageMenu = ({sistema}) => {
    return (
        <ChangesSubPageMenu
            pending_status={sistema.pending_status}
            headingPrimaryText={sistema.nome || sistema.codigo || sistema.id}
            headingSecondaryText="Sistema"
            headingInfo={
                <Box sx={{p: 1}}>
                    Concello:{" "}
                    <TextLink
                        text={sistema.concello_label}
                        to={`/concellos/${sistema.concello}`}
                    />
                </Box>
            }
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Mapa do sistema"
                icon={<MapOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="detail"
                text="Datos"
                icon={<ViewListOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={sistema} />
        </ChangesSubPageMenu>
    );
};

export default SistemaSubPageMenu;
