import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {ConcelloHiddenAuth} from "concello/auth";
import {SisbagalSectionCard} from "sisbagal/auth";

const VertidoCaracteristicasSection = ({vertido}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <ConcelloHiddenAuth>
            <SectionCardHeaderAction
                key="edit"
                name="edit"
                text="Modificar"
                icon={<EditIcon />}
                onClick={() => {
                    navigate(`caracteristicas/edit`);
                }}
                roles={[]}
            />
        </ConcelloHiddenAuth>,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tipo de punto de vertido:"
                        value={vertido.tipo_punto_vertido_label}
                        tooltipText="Tipoloxía de punto de vertido en función da orixe das augas."
                    />
                    <SectionField
                        label="Tipo de vertido:"
                        value={vertido.tipo_label}
                        tooltipText="Tipo de obra de vertido"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tipo de titular:"
                        value={vertido.tipo_titular_label}
                        tooltipText="Tipo de titular / responsable do punto de vertido."
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default VertidoCaracteristicasSection;
