import {useAuth} from "base/auth";

export function useConcelloAuth() {
    const {user, hasRole, ROLES} = useAuth();

    const checkAllowed = codigoConcello => {
        if (hasRole(ROLES.CONCELLO) && user.codigo_concello && codigoConcello) {
            return user.codigo_concello === parseInt(codigoConcello);
        }
        return true;
    };

    const isConcelloUser = () => {
        return hasRole(ROLES.CONCELLO);
    };

    return {checkAllowed, isConcelloUser};
}
