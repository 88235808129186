import {Route} from "react-router-dom";
import {ViewBombeoPanel} from "saneamiento/bombeo/container";
import {ViewColectorPanel} from "saneamiento/colector/container";
import {ViewEDARPanel} from "saneamiento/edar/container";
import {ViewPoligonoPanel} from "saneamiento/poligono/container";
import {
    CreateSubaglomeracionPage,
    ListSubaglomeracionPage,
    ViewSubaglomeracionPanel,
    ViewSubaglomeracionPage,
    ViewSubaglomeracionDocumentsSubPage,
    ViewSubaglomeracionSummarySubPage,
    UpdateSubaglomeracionPanel,
    ManageSubaglomeracionPage,
    ViewSubaglomeracionDocumentPanel,
    ViewSubaglomeracionMapSubPage,
    ViewSubaglomeracionPoboacionSubPage,
    ViewSubaglomeracionInventarioSubPage,
    ViewSubaglomeracionCargasDispersasSubPage,
    ViewSubaglomeracionRedeSubPage,
    ViewSubaglomeracionExplotacionSubPage,
    ViewSubaglomeracionProblemasSubPage,
    ViewSubaglomeracionChangesSubPage,
} from "saneamiento/subaglomeracion/container";
import {ViewTanquePanel} from "saneamiento/tanque/container";
import {ViewVertidoPanel} from "saneamiento/vertido/container";

const subaglomeracionRoutes = [
    <Route
        key="subaglomeracion-new"
        path="subaglomeracions/new"
        element={<CreateSubaglomeracionPage />}
    />,
    <Route
        key="subaglomeracion-manage"
        path="subaglomeracions"
        element={<ManageSubaglomeracionPage />}
    >
        <Route key="subaglomeracion-list" path="" element={<ListSubaglomeracionPage />}>
            <Route
                key="subaglomeracion-info"
                path="info/:idSubaglomeracion"
                element={<ViewSubaglomeracionPanel />}
            />
        </Route>
        <Route
            key="subaglomeracion-detail"
            path=":idSubaglomeracion"
            element={<ViewSubaglomeracionPage />}
        >
            <Route
                key="subaglomeracion-documents"
                path="documents/*"
                element={<ViewSubaglomeracionDocumentsSubPage />}
            >
                <Route
                    key="subaglomeracion-documents-view"
                    path="detail/*"
                    element={<ViewSubaglomeracionDocumentPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-map"
                path="map"
                element={<ViewSubaglomeracionMapSubPage />}
            >
                <Route
                    key="subaglomeracion-edar-info"
                    path="info/edars/:idEdar"
                    element={<ViewEDARPanel />}
                />
                <Route
                    key="subaglomeracion-bombeo-info"
                    path="info/bombeos/:idBombeo"
                    element={<ViewBombeoPanel />}
                />
                <Route
                    key="subaglomeracion-tanque-info"
                    path="info/tanques/:idTanque"
                    element={<ViewTanquePanel />}
                />
                <Route
                    key="subaglomeracion-colector-info"
                    path="info/colectores/:idColector"
                    element={<ViewColectorPanel />}
                />
                <Route
                    key="subaglomeracion-vertido-info"
                    path="info/vertidos/:idVertido"
                    element={<ViewVertidoPanel />}
                />
                <Route
                    key="subaglomeracion-poligono-info"
                    path="info/poligonos/:idPoligono"
                    element={<ViewPoligonoPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-summary"
                path="summary"
                element={<ViewSubaglomeracionSummarySubPage />}
            />
            <Route
                key="subaglomeracion-poboacion"
                path="poboacion"
                element={<ViewSubaglomeracionPoboacionSubPage />}
            />
            <Route
                key="subaglomeracion-inventario"
                path="inventario"
                element={<ViewSubaglomeracionInventarioSubPage />}
            />
            <Route
                key="subaglomeracion-cargas-dispersas"
                path="cargasdispersas"
                element={<ViewSubaglomeracionCargasDispersasSubPage />}
            >
                <Route
                    key="subaglomeracion-cargas-dispersas-edit"
                    path=":section/:action"
                    element={<UpdateSubaglomeracionPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-explotacion"
                path="explotacion"
                element={<ViewSubaglomeracionExplotacionSubPage />}
            >
                <Route
                    key="subaglomeracion-cargas-dispersas-edit"
                    path=":section/:action"
                    element={<UpdateSubaglomeracionPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-rede"
                path="rede"
                element={<ViewSubaglomeracionRedeSubPage />}
            >
                <Route
                    key="subaglomeracion-rede-edit"
                    path=":section/:action"
                    element={<UpdateSubaglomeracionPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-problemas"
                path="problemas"
                element={<ViewSubaglomeracionProblemasSubPage />}
            >
                <Route
                    key="subaglomeracion-problemas-edit"
                    path=":section/:action"
                    element={<UpdateSubaglomeracionPanel />}
                />
            </Route>
            <Route
                key="subaglomeracion-changes"
                path="changes"
                element={<ViewSubaglomeracionChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default subaglomeracionRoutes;
