import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "H",
    fillColor: "#fc9900",
    radius: 10,
    color: "white",
    weight: 2,
    opacity: 1,
    fillOpacity: 1,
    pane: mapOverlayPanes[5],
};

export const LayerLegend = createLayerLegend({
    layerName: "Tanques",
    menuIconShape: markerBaseOptions.marker,
    defaultMarkerOptions: markerBaseOptions,
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Tanque: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Código</i>: ${
                data["codigo"] ? data["codigo"] : "---"
            }</li>`;
            tooltip += `<li><i>Concello</i>: ${
                data["concello_label"] ? data["concello_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Aglomeración</i>: ${
                data["aglomeracion_label"] ? data["aglomeracion_label"] : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/tanques/${feature.id}`),
    });
}
