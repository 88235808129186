import {useNavigate, useParams} from "react-router-dom";
import {AglomeracionService} from "../service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";

const ViewAglomeracionPanel = () => {
    const {idAglomeracion} = useParams();
    const navigate = useNavigate();

    const handleClickDetail = aglomeracion => {
        navigate(`/aglomeracions/${aglomeracion.id}/summary`);
    };

    const getSectionData = aglomeracion => {
        return (
            <>
                <SectionField label="Código:" value={aglomeracion.codigo} />
                <SectionField
                    label="Urbana:"
                    value={NumberUtil.formatDecimal(aglomeracion.stats.cargaurbana, 0)}
                    unit="Heq"
                    tooltipText="Poboación urbana total da aglomeración (domestica e non doméstica)"
                />
                <SectionField
                    label="Doméstica:"
                    value={NumberUtil.formatDecimal(
                        aglomeracion.stats.cargadomestica,
                        0
                    )}
                    unit="Heq"
                    tooltipText="Poboación de orixe doméstica (Fixa e estacional)"
                />
                <SectionField
                    label="Non doméstica:"
                    value={NumberUtil.formatDecimal(
                        aglomeracion.stats.carganondomestica,
                        0
                    )}
                    unit="Heq"
                    tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                />
                <SectionField
                    label="Tratamento:"
                    value={aglomeracion.stats.tratamento_label}
                    tooltipText="Nivel de tratamento"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={AglomeracionService}
            id={idAglomeracion}
            title="Resumo de aglomeración"
            getSectionTitle={entity => entity?.nome}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
        />
    );
};

export default ViewAglomeracionPanel;
