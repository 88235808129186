import {useEffect, useState} from "react";

import {StatsService} from "stats/service";
import {MapStats} from "base/component/geo";

import CircularProgress from "@mui/material/CircularProgress";
import {NumberUtil, ServiceRequestFormat} from "base/utilities";

const findLegendColor = (legendItems, label) => {
    return legendItems.find(legendItem => legendItem.label === label).color;
};

const ViewStatsConcellosCargasMap = ({statsFilter}) => {
    const mapStatsConfig = {
        title: "% carga conectada vs. total",
        legendItems: [
            {
                color: "#F3D6F6",
                label: "-",
            },
            {
                color: "#EBAEE0",
                label: "0-0.25",
            },
            {
                color: "#DF88B7",
                label: "0.25-0.5",
            },
            {
                color: "#D2637F",
                label: "0.5-0.75",
            },
            {
                color: "#c3423f",
                label: "0.75-1",
            },
        ],
        fillColor: item => {
            const carga_conectada_vs_domestica = parseFloat(
                NumberUtil.parseDecimal(item.carga_conectada_vs_domestica)
            );
            switch (true) {
                case carga_conectada_vs_domestica >= 0 &&
                    carga_conectada_vs_domestica <= 0.25:
                    return findLegendColor(mapStatsConfig.legendItems, "0-0.25");
                case carga_conectada_vs_domestica >= 0.25 &&
                    carga_conectada_vs_domestica <= 0.5:
                    return findLegendColor(mapStatsConfig.legendItems, "0.25-0.5");
                case carga_conectada_vs_domestica >= 0.5 &&
                    carga_conectada_vs_domestica <= 0.75:
                    return findLegendColor(mapStatsConfig.legendItems, "0.5-0.75");
                case carga_conectada_vs_domestica >= 0.75 &&
                    carga_conectada_vs_domestica <= 1:
                    return findLegendColor(mapStatsConfig.legendItems, "0.75-1");
                default:
                    return findLegendColor(mapStatsConfig.legendItems, "-");
            }
        },
    };

    const [loading, setLoading] = useState(false);
    const [geoData, setGeoData] = useState(null);

    useEffect(() => {
        setLoading(true);
        StatsService.getConcelloCargasData(
            statsFilter,
            ServiceRequestFormat.GEOJSON
        ).then(data => {
            setLoading(false);
            setGeoData(data);
        });
    }, [statsFilter]);

    return !mapStatsConfig ? null : loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        geoData && (
            <MapStats
                geojson={geoData}
                attribute="carga_conectada_vs_domestica"
                attributeLabel={mapStatsConfig.title}
                legendItems={mapStatsConfig.legendItems}
                fillColor={mapStatsConfig.fillColor}
            />
        )
    );
};
export default ViewStatsConcellosCargasMap;
