const TextUtil = {
    capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatBoolean(value) {
        return value ? "Si" : "Non";
    },
};

export default TextUtil;
