import {createEntityService} from "base/entity/service";
import {
    createVertido,
    createVertidos,
    vertido_api_adapter,
    vertidos_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/vertidos",
    createVertido,
    createVertidos,
    vertido_api_adapter,
    vertidos_api_adapter
);

const VertidoService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(vertido) {
        return entityService.create(vertido);
    },

    update(vertido) {
        return entityService.update(vertido);
    },

    updateWithPatch(vertido) {
        return entityService.updateWithPatch(vertido);
    },

    delete(vertidoId) {
        return entityService.delete(vertidoId);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default VertidoService;
