import {ModuleLayout} from "base/layout";
import {ModuleConfigProvider} from "base/provider";

const MedioReceptorModule = () => {
    return (
        <ModuleConfigProvider name="Medio" path="medioreceptor">
            <ModuleLayout title="Medio" />
        </ModuleConfigProvider>
    );
};

export default MedioReceptorModule;
