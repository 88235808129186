import {useAuth} from "base/auth";
import {useNavigate} from "react-router-dom";

import {
    SectionCard,
    SectionCardHeaderAction,
    SectionField,
} from "base/component/presentational";
import {FeaturedDocumentDownload} from "document/component/presentational";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";

const SistemaGeneralDataSection = ({sistema}) => {
    const {ROLES, hasRole} = useAuth();
    const navigate = useNavigate();

    const sections = [
        {label: "Código", value: sistema.codigo},
        {label: "Titular", value: sistema.titular_label},
        {label: "Xestión", value: sistema.xestion_label},
        {label: "Descripción", value: sistema.descripcion},
    ];

    const sectionFields =
        sections.length !== 0
            ? sections.map((section, index) => (
                  <SectionField
                      key={index}
                      label={`${section.label}:`}
                      value={section.value}
                      unit={section.unit}
                      editButton={section.edit_button}
                      linkPath={section.linkPath}
                  />
              ))
            : null;

    return (
        <SectionCard
            headingLabel={false}
            secondaryActions={
                !hasRole(ROLES.AUDITOR)
                    ? [
                          <SectionCardHeaderAction
                              key="edit"
                              name="edit"
                              text="Modificar"
                              icon={<EditIcon />}
                              onClick={() => {
                                  navigate(`generaldata/edit`);
                              }}
                              roles={[]}
                          />,
                      ]
                    : []
            }
        >
            <Grid container columnSpacing={2}>
                <Grid item container xs={12} alignContent="flex-start">
                    <Grid item xs={6}>
                        <Stack alignItems="flex-start">
                            <Stack direction="row" alignItems="flex-start" spacing={2}>
                                <Typography
                                    variant="h4"
                                    color="grey.700"
                                    pb={2}
                                    sx={{fontWeight: "bold"}}
                                >
                                    {sistema.nome}
                                </Typography>
                            </Stack>
                            {sistema.featured_document && (
                                <FeaturedDocumentDownload
                                    featuredDocument={sistema.featured_document}
                                />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        {sectionFields}
                    </Grid>
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SistemaGeneralDataSection;
