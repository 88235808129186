import {VertidoFormLocationFields} from ".";
import {EntityGeoForm} from "base/entity";

const VertidoGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <VertidoFormLocationFields />
        </EntityGeoForm>
    );
};

export default VertidoGeoForm;
