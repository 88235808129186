import {useDomain} from "domain/provider";
import {FormSelect} from "base/component/form";

import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";

const EDARFormTratamentoRendementoFields = ({layout = "row"}) => {
    const {dominioedarobxetivovertido, dominioedardesinfeccion} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="rendemento_tratamento"
                        label="Tratamento"
                        options={dominioedarobxetivovertido}
                        tooltipText="Tipo de tratamento da instalación"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="rendemento_desinfeccion"
                        label="Tratamento avanzado"
                        options={dominioedardesinfeccion}
                        tooltipText="Rendemento de depuración da instalación segundo"
                    />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default EDARFormTratamentoRendementoFields;
