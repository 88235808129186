import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    AglomeracionPoboacionConectadaSection,
    AglomeracionPoboacionNonConectadaSection,
    AglomeracionPoboacionTotalSection,
    AglomeracionPoboacionVariabilidadeSection,
} from "../presentational/section";

const ViewAglomeracionPoboacionSubPage = () => {
    let aglomeracion;
    [aglomeracion] = useOutletContext();

    const sections = [
        <AglomeracionPoboacionVariabilidadeSection aglomeracion={aglomeracion} />,
        <AglomeracionPoboacionTotalSection aglomeracion={aglomeracion} />,
        <AglomeracionPoboacionConectadaSection aglomeracion={aglomeracion} />,
        <AglomeracionPoboacionNonConectadaSection aglomeracion={aglomeracion} />,
    ];

    return aglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewAglomeracionPoboacionSubPage;
