import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    VertidoCaracteristicasSection,
    VertidoCNVSection,
    VertidoOutrasReferenciasSection,
} from "../presentational/section";

const ViewVertidoDetailSubPage = () => {
    let vertido;
    [vertido] = useOutletContext();

    const sections = [
        <VertidoCaracteristicasSection vertido={vertido} />,
        <VertidoCNVSection vertido={vertido} />,
        <VertidoOutrasReferenciasSection vertido={vertido} />,
    ];

    return vertido && <EntityViewSubPage sections={sections} />;
};

export default ViewVertidoDetailSubPage;
