import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/service";
import {ServiceUtil} from "base/utilities";
import {
    createEDAR,
    createEDARs,
    edar_api_adapter,
    edars_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/edars",
    createEDAR,
    createEDARs,
    edar_api_adapter,
    edars_api_adapter
);

const EDARService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(edar) {
        return entityService.create(edar);
    },

    update(edar) {
        return entityService.update(edar);
    },

    updateWithPatch(edar) {
        return entityService.updateWithPatch(edar);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },

    getStatsList(filter, page = null, sort = null, order = null) {
        return AuthApiService.get(
            "/api/infrastructures/edars/stats/list" +
                `?${ServiceUtil.getQueryString(page, filter, sort, order)}`
        );
    },
};

export default EDARService;
