import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "^",
    fillColor: "#fc0",
    radius: 9,
    color: "white",
    weight: 2,
    opacity: 1,
    fillOpacity: 1,
    pane: mapOverlayPanes[4],
};

export const LayerLegend = createLayerLegend({
    layerName: "Bombeos",
    menuIconShape: markerBaseOptions.marker,
    defaultMarkerOptions: markerBaseOptions,
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Bombeo: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Código</i>: ${
                data["codigo"] ? data["codigo"] : "---"
            }</li>`;
            tooltip += `<li><i>Concello</i>: ${
                data["concello_label"] ? data["concello_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Aglomeración</i>: ${data["aglomeracion_label"]}</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/bombeos/${feature.id}`),
    });
}
