import {FormInputText, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";

const SubaglomeracionFormGeneralDataFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome" label="Nome" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText name="codigo" label="Código" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormGeneralDataFields;
