import {FormInputDecimal, FormInputInteger, FormSelect} from "base/component/form";

import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const EDARFormDesenoFields = ({layout = "row"}) => {
    const {dominioedarantiguedad} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputInteger
                    name="ano_posta_marcha"
                    label="Ano posta en marcha"
                    tooltipText="Ano de posta en marcha das instalacions"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="antiguidade"
                    label="Antigüidade"
                    options={dominioedarantiguedad}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputInteger
                    name="habitantes_equivalentes_desenho"
                    label="Habitantes equivalentes"
                    endAdornment="heq"
                    tooltipText="Habitantes equivalentes de deseño"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="caudal_medio_desenho"
                    label="Caudal medio"
                    endAdornment="l/s"
                    tooltipText="Caudal medio de deseño da planta"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="qp_pretrat_primario"
                    label="Caudal tratamento primario"
                    endAdornment="l/s"
                    tooltipText="Caudal punta de deseño do tratamento primario"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="qp_secundario"
                    label="Caudal tratamento secundario"
                    endAdornment="l/s"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormDesenoFields;
