import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const BombeoElementosSection = ({bombeo}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`elementos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Elementos" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Telecontrol:"
                        value={bombeo.telecontrol_label}
                    />
                    <SectionField
                        label="Sistema de protección:"
                        value={bombeo.proteccion_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Pretratamento:"
                        value={bombeo.pretratamiento_label}
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default BombeoElementosSection;
