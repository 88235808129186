import {useContext} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {FormInputDecimal} from "base/component/form";
import {conexionElementTypesConstants} from "conexion/model";
import {ConexionesFormContext} from "./ConexionesForm";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";

function formatTipo(tipo) {
    switch (tipo) {
        case conexionElementTypesConstants.EDAR:
            return "EDAR";
        case conexionElementTypesConstants.NUCLEO:
            return "Núcleo";
        case conexionElementTypesConstants.DEPOSITO:
            return "Depósito";
        case conexionElementTypesConstants.CAPTACION:
            return "Captación";
        case conexionElementTypesConstants.ETAP:
            return "ETAP";
        default:
            return tipo;
    }
}

const ConexionFormFields = ({
    idx,
    conexionPath,
    showOrixe,
    isSaneamiento,
    onDelete = null,
    onFocus = null,
    onBlur = null,
    layout = "row",
}) => {
    const conexionesCtx = useContext(ConexionesFormContext);

    const {control, register} = useFormContext();

    const conexionEnd = showOrixe ? "orixe" : "destino";

    const conexionCodigo = useWatch({
        name: conexionPath + `.codigo_` + conexionEnd,
        control,
    });

    const conexionNome = useWatch({
        name: conexionPath + `.nome_` + conexionEnd,
        control,
    });

    const conexionId = useWatch({
        name: conexionPath + `.id_` + conexionEnd,
        control,
    });

    const conexionTipo = useWatch({
        name: conexionPath + `.tipo_` + conexionEnd,
        control,
    });

    const deleteHandler = () => {
        if (onDelete) {
            onDelete(idx);
        }
        conexionesCtx.remove(idx);
    };

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            direction={layout}
            // Hide conexiones to EDARS when not showing sanitation data
            // (whole connections data must still be sent back to the server, so the fields must exist)
            sx={{
                display:
                    !isSaneamiento &&
                    conexionTipo === conexionElementTypesConstants.EDAR
                        ? "none"
                        : "default",
            }}
        >
            <Grid item xs={12} md={2}>
                <FormLabel>{formatTipo(conexionTipo)}:</FormLabel>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormLabel>
                    <b>{conexionNome || conexionCodigo || conexionId}</b>
                </FormLabel>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormInputDecimal
                    name="porcentaxe"
                    label="% conexión"
                    onFocusHandler={onFocus}
                    onBlurHandler={onBlur}
                    small={true}
                    {...register(conexionPath + `.porcentaxe`)}
                    rules={{
                        validate: {
                            validateRange: value => {
                                if (!value) {
                                    return "Obrigatorio";
                                }
                                const intValue = parseInt(value);
                                return (intValue >= 0 && intValue <= 100) || "0 - 100";
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} md={1}>
                <Tooltip title="Eliminar conexión">
                    <IconButton aria-label="eliminar" onClick={deleteHandler}>
                        <DeleteOutlinedIcon sx={{fontSize: 20, color: "grey.400"}} />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default ConexionFormFields;
