import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {ETAPCaracteristicasSection} from "../presentational/section";

const ViewETAPDetailSubPage = () => {
    let etap;
    [etap] = useOutletContext();

    const sections = [<ETAPCaracteristicasSection etap={etap} />];

    return etap && <EntityViewSubPage sections={sections} />;
};

export default ViewETAPDetailSubPage;
