import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/service";
import {
    createChange,
    createChanges,
    change_api_adapter,
    changes_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/changes",
    createChange,
    createChanges,
    change_api_adapter,
    changes_api_adapter
);

const ChangeService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    get(id) {
        return entityService.get(id);
    },
};

export default ChangeService;
