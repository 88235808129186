import {
    FormInputInteger,
    FormInputText,
    FormSelect,
    FormSelectMultiple,
} from "base/component/form";
import {useDomain} from "domain/provider";

import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";

const EDARFormTratamentoPretratamentoPrimarioFields = ({layout = "row"}) => {
    const {dominiosino, dominioedarpretratamento, dominioedarprimario} = useDomain();
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="vertido_directo"
                        label="Vertido directo"
                        options={dominiosino}
                        tooltipText="Planta bypaseada ou un punto de vertido directo de ARU"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormSelectMultiple
                        name="pretratamento"
                        label="Pretratamento"
                        options={dominioedarpretratamento}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormInputInteger
                        name="trat_primario_lineas"
                        label="Liñas primario"
                        tooltipText="Número de liñas de tratamento primario"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelectMultiple
                        name="trat_primario"
                        label="Tratamento primario"
                        options={dominioedarprimario}
                        tooltipText="Tipoloxía de tratamento primario"
                    />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormInputText
                    name="trat_primario_outro"
                    label="Tratamento primario (outro)"
                    tooltipText="Outro tratamento non especificado"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormTratamentoPretratamentoPrimarioFields;
