import {AppLayout} from "base/layout";
import {AppConfigProvider} from "base/provider";
import {SisbagalFooter, SisbagalHeaderHero} from "sisbagal/layout";
import {SisbagalMenu} from "sisbagal/menu";
import {SisbagalAppProvider} from "sisbagal/provider";

const SisbagalApp = () => {
    return (
        <AppConfigProvider>
            <SisbagalAppProvider>
                <AppLayout
                    hero={<SisbagalHeaderHero />}
                    menu={<SisbagalMenu />}
                    footer={<SisbagalFooter />}
                />
            </SisbagalAppProvider>
        </AppConfigProvider>
    );
};

export default SisbagalApp;
