import {ColectorService} from "saneamiento/service";
import {useColectorTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListColectorPage = () => {
    const {tableColumns} = useColectorTable();

    return (
        <ListInfraestructuraPage
            entityName="Colectores"
            entityPath="colectores"
            service={ColectorService}
            tableColumns={tableColumns}
            createButton={false}
        />
    );
};
export default ListColectorPage;
