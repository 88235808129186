import {useParams} from "react-router-dom";
import {useConcelloAuth} from ".";

const ConcelloAuthRequired = ({children}) => {
    const {checkAllowed, isConcelloUser} = useConcelloAuth();
    const {codigo} = useParams();

    return (isConcelloUser() && checkAllowed(codigo)) || !isConcelloUser() ? (
        <>{children}</>
    ) : null;
};

export default ConcelloAuthRequired;
