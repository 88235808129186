import {useParams} from "react-router-dom";
import {EDARService} from "saneamiento/service";

import {useEDARTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListEDARPage = () => {
    const {codigo: codigoConcello} = useParams();
    const {tableColumns} = useEDARTable();

    return (
        <ListInfraestructuraPage
            entityName="EDAR"
            entityPath="edars"
            service={EDARService}
            tableColumns={tableColumns}
            createButton={!!codigoConcello}
        />
    );
};
export default ListEDARPage;
