import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    EDARTratamentoSecundarioSection,
    EDARTratamentoTerciarioSection,
    EDARTratamentoLodosSection,
    EDARTratamentoRendementoSection,
    EDARTratamentoConservacionSection,
    EDARTratamentoPretratamentoPrimarioSection,
    EDARTratamentoProblemasSection,
} from "../presentational/section";

const ViewEDARTratamentoSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [
        <EDARTratamentoRendementoSection edar={edar} />,
        <EDARTratamentoConservacionSection edar={edar} />,
        <EDARTratamentoPretratamentoPrimarioSection edar={edar} />,
        <EDARTratamentoSecundarioSection edar={edar} />,
        <EDARTratamentoTerciarioSection edar={edar} />,
        <EDARTratamentoProblemasSection edar={edar} />,
        <EDARTratamentoLodosSection edar={edar} />,
    ];

    return edar && <EntityViewSubPage sections={sections} />;
};

export default ViewEDARTratamentoSubPage;
