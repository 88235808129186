import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormMarcosRegulatoriosFields = ({layout = "row"}) => {
    const {dominiogobernanzasinosimple} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="regulamento_servizos_adaptado_decreto"
                    label="O regulamento dos servizos está adaptado ao Decreto 141/2012"
                    options={dominiogobernanzasinosimple}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="expidense_permisos_decreto"
                    label="Expídense permisos de vertido conforme ao Decreto 141/2012"
                    options={dominiogobernanzasinosimple}
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormMarcosRegulatoriosFields;
