import {SubPageMenuListItemButton} from "base/component/presentational";
import {SubPageMenu} from "base/layout";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import BarChartIcon from "@mui/icons-material/BarChart";

const ConcelloStatsSubPageMenu = ({concello}) => {
    return (
        <SubPageMenu headingPrimaryText="Estatísticas abastecemento">
            <SubPageMenuListItemButton
                to="abastecemento_volume"
                text="Capacidade"
                icon={<QueryStatsIcon />}
            />
            <SubPageMenuListItemButton
                to="abastecemento_infrastructures_type"
                text="Por atributo"
                icon={<BarChartIcon />}
            />
        </SubPageMenu>
    );
};

export default ConcelloStatsSubPageMenu;
