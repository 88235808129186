import {FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const SubaglomeracionFormCargasDispersasNonConectadaFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="saneamento_autonomo"
                    label="Saneamento autónomo"
                    endAdornment="Heq"
                    tooltipText="Poboación con saneamento autónomo dentro da aglomeración"
                />
                <FormInputDecimal
                    name="domestica_sen_tramento"
                    label="Sen depuración"
                    endAdornment="Heq"
                    tooltipText="Poboación dentro da aglomeración conectada a rede de saneamento que non recibe tratamento de depuración"
                />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormCargasDispersasNonConectadaFields;
