import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DepositoService} from "abastecemento/service";
import {deposito_view_adapter} from "abastecemento/model";
import {EntityCreatePage} from "base/entity";
import {DepositoForm} from "../presentational/form";

const CreateDepositoPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = deposito => {
        DepositoService.create(deposito_view_adapter({...deposito}))
            .then(createdDeposito => {
                navigate(`${basePath}/${createdDeposito.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <DepositoForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de Depósito"
            error={error}
        />
    );
};

export default CreateDepositoPage;
