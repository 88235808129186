import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDAROutrasInstalacionsSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`outrasinstalacionsdatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Servizos e instalacións aux."
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Suministro eléctrico:"
                        value={edar.suministro_electrico_label}
                        tooltipText="Existencia de acometida eléctrica"
                    />
                    <SectionField
                        label="Arqueta tomamostras:"
                        value={edar.arqueta_mostras_label}
                        tooltipText="Existencia de arqueta para a toma de mostras de análise na planta"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Medición caudais saída:"
                        value={edar.caudal_saida_label}
                        tooltipText="Existencia de sistema de medición de caudais na saída da planta"
                    />
                    <SectionField
                        label="Medición caudais entrada:"
                        value={edar.caudal_entrada_label}
                        tooltipText="Existencia de sistema de medición de caudais na entrada da planta"
                    />
                    <SectionField label="Telecontrol:" value={edar.telecontrol_label} />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDAROutrasInstalacionsSection;
