import {FooterTextLink, LogoLink} from "base/component/presentational";
import {Footer} from "base/layout";
import {CUSTOM_FONT_FAMILY} from "Theme";

const SisbagalFooter = ({isFixed = false}) => {
    const logoHeight = "30px";
    const logoHeightMobile = "28px";

    const sisbagalFooterLinks = [
        <FooterTextLink
            to="https://www.xunta.gal/aviso-legal-do-portal-da-xunta"
            title="Páxina do Aviso Legal do Portal da Xunta"
            text="Aviso legal"
        />,
    ];

    const sisbagalFooterLogo = (
        <LogoLink
            href="https://www.xunta.gal/"
            title="Navega á web da Xunta de Galicia"
            src="/images/footer_logo.png"
            alt="Logo da Xunta de Galicia"
            logoHeight={logoHeight}
            logoHeightMobile={logoHeightMobile}
            style={{marginBottom: -1}}
        />
    );

    return (
        <Footer
            text="© Augas de Galicia. Información mantida e publicada por Augas de Galicia."
            links={sisbagalFooterLinks}
            logo={sisbagalFooterLogo}
            fontFamily={CUSTOM_FONT_FAMILY}
            position={isFixed ? "fixed" : "relative"}
        />
    );
};

export default SisbagalFooter;
