import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SubaglomeracionInventarioBombeosSection = ({subaglomeracion}) => {
    return (
        <SectionCard title="Bombeos">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Totais:"
                        value={NumberUtil.formatInteger(subaglomeracion.stats.bombeos)}
                        tooltipText="Bombeos totais da rede"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            subaglomeracion.stats.potenciabombeo
                        )}
                        unit="Kw"
                        tooltipText="Potencia total instalada"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            subaglomeracion.stats.consumoelectricobombeo
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico medio anual nos últimos cinco anos."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SubaglomeracionInventarioBombeosSection;
