import {SectionCard} from "base/component/presentational";
import {useAuth} from "base/auth";

const SisbagalSectionCard = ({title, secondaryActions, children}) => {
    const {ROLES, hasRole} = useAuth();

    return (
        <SectionCard
            title={title}
            secondaryActions={!hasRole(ROLES.AUDITOR) ? secondaryActions : []}
        >
            {children}
        </SectionCard>
    );
};

export default SisbagalSectionCard;
