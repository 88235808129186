import {useState} from "react";
import {AuthAction, useAuth} from "base/auth";
import {ChangeService} from "saneamiento/service";
import {ChangeActionState} from "saneamiento/model/Change";
import {ApproveChangeDialog, ChangesTable, RejectChangeDialog} from "../presentational";

import {PageLayout} from "base/layout";
import {AlertError, PaperContainer} from "base/component/presentational";
import {useNavigateWithReload} from "base/hooks";

import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const ViewEntityChangesSubPage = ({entityService, entityType, entityId}) => {
    const navigate = useNavigateWithReload();
    const {ROLES} = useAuth();

    const [error, setError] = useState(null);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    const [disabledActions, setDisabledActions] = useState(true);

    const handleSearch = (filter, page, sort, order) => {
        return ChangeService.getList(
            {
                ...filter,
                entity_type: entityType,
                entity_id: entityId,
            },
            page
        );
    };

    const handleOpenApproveDialog = element => {
        setIsApproveDialogOpen(true);
    };
    const handleOpenRejectDialog = element => {
        setIsRejectDialogOpen(true);
    };

    const handleApproveChange = () => {
        entityService
            .approveChanges(entityId)
            .then(change => {
                navigate("", true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleRejectChange = comments => {
        entityService
            .rejectChanges(entityId, comments)
            .then(change => {
                navigate("", true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleChangesLoaded = changes => {
        setDisabledActions(
            !changes.some(change => change.action_state === ChangeActionState.PENDING)
        );
    };

    return (
        <PageLayout subPage={true}>
            <PaperContainer justifyContent="space-between" alignItems="center">
                <AlertError error={error} />
                <AuthAction roles={[ROLES.XESTOR, ROLES.ADMINISTRADOR]}>
                    <Grid container justifyContent="center" spacing={2} sx={{mb: 2}}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleOpenApproveDialog}
                                endIcon={<ThumbUpAltOutlinedIcon />}
                                disabled={disabledActions}
                            >
                                Aprobar últimos cambios
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleOpenRejectDialog}
                                endIcon={<ThumbDownAltOutlinedIcon />}
                                disabled={disabledActions}
                            >
                                Rechazar últimos cambios
                            </Button>
                        </Grid>
                    </Grid>
                </AuthAction>
                <ChangesTable
                    service={handleSearch}
                    onChangesLoaded={handleChangesLoaded}
                ></ChangesTable>
                <ApproveChangeDialog
                    isDialogOpen={isApproveDialogOpen}
                    setIsDialogOpen={setIsApproveDialogOpen}
                    onApproveChange={handleApproveChange}
                />
                <RejectChangeDialog
                    isDialogOpen={isRejectDialogOpen}
                    setIsDialogOpen={setIsRejectDialogOpen}
                    onRejectChange={handleRejectChange}
                />
            </PaperContainer>
        </PageLayout>
    );
};

export default ViewEntityChangesSubPage;
