const infrastructureTypesAttributeConfig = {
    gestion: {
        title: "Nº de infraestructuras por tipo de xestión",
        legendItems: [
            "Municipal",
            "Veciñal",
            "Augas de Galicia",
            "Autonómica",
            "Mancomunidade",
            "Privada",
            "Empresa municipal",
            "Provincial",
            "Empresa pública",
            "Consorcio",
            "(Sen datos)",
        ],
    },
    titular: {
        title: "Nº de infraestructuras por tipo de titular",
        legendItems: [
            "Municipal",
            "Veciñal",
            "Augas de Galicia",
            "Autonómica",
            "Mancomunidade",
            "Privada",
            "Empresa municipal",
            "Provincial",
            "Empresa pública",
            "Consorcio",
            "(Sen datos)",
        ],
    },
    estado: {
        title: "Nº de infraestructuras por estado",
        legendItems: ["Bo", "Regular", "Malo", "(Sen datos)"],
    },
    proteccion: {
        title: "Nº de infraestructuras por estado de protección",
        legendItems: ["Deficiente", "Correcto", "(Sen datos)"],
    },
    telegestion: {
        title: "Nº de infraestructuras por presencia de telexestión",
        legendItems: ["Non", "Si", "(Sen datos)"],
    },
    electricidad: {
        title: "Nº de infraestructuras por presencia de suministro eléctrico",
        legendItems: ["Non", "Si", "(Sen datos)"],
    },
    caudalimetro: {
        title: "Nº de infraestructuras por presencia de caudalímetro",
        legendItems: ["Non", "Si", "(Sen datos)"],
    },
};

const infrastructureTypesTypeConfig = {
    etaps: {
        fillColor: "#44BBA4",
    },
    depositos: {
        fillColor: "#76D3C2",
    },
    captacions: {
        fillColor: "#A7EAE0",
    },
};

const useInfrastructureStatsTypeConfig = () => {
    const getInfrastructureTypeConfig = infrastructureType => {
        return infrastructureTypesTypeConfig[infrastructureType];
    };

    const getInfrastructureTypeAttributeConfig = type => {
        return infrastructureTypesAttributeConfig[type];
    };

    return {getInfrastructureTypeConfig, getInfrastructureTypeAttributeConfig};
};

export default useInfrastructureStatsTypeConfig;
