import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const DepositoCaracteristicasSection = ({deposito}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Posición:" value={deposito.posicion_label} />
                    <SectionField label="Material:" value={deposito.material_label} />
                    <SectionField label="Planta:" value={deposito.planta_label} />
                    <SectionField label="Cloración:" value={deposito.cloracion_label} />
                    <SectionField label="Notas:" value={deposito.notas} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Protección:"
                        value={deposito.proteccion_label}
                    />
                    <SectionField
                        label="Suministro eléctrico:"
                        value={deposito.suministro_electrico_label}
                        tooltipText="Existencia de acometida eléctrica"
                    />
                    <SectionField
                        label="Telexestión:"
                        value={deposito.telexestion_label}
                    />
                    <SectionField
                        label="Caudalímetro:"
                        value={deposito.caudalimetro_label}
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default DepositoCaracteristicasSection;
