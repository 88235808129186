import {useOutletContext} from "react-router-dom";
import {PoligonoService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {PoligonoGeneralDataSection} from "../presentational/section";
import PoligonoLocationSection from "../presentational/section/PoligonoLocationSection";
import {SectionCard} from "base/component/presentational";

const ViewPoligonoSummarySubPage = () => {
    let poligono;
    [poligono] = useOutletContext();

    const sections = [
        <PoligonoGeneralDataSection poligono={poligono} />,
        <SectionCard title="Ubicación">
            <PoligonoLocationSection poligono={poligono} />
        </SectionCard>,
        <EntityAuditSection entity={poligono} />,
    ];

    return (
        poligono && <EntityViewSubPage sections={sections} service={PoligonoService} />
    );
};

export default ViewPoligonoSummarySubPage;
