class Captacions extends Array {}

const captacion_api_adapter = captacion => {
    // abastecemento autonomo if titular veciñal (2) or privada (6)
    captacion["abastecemento_autonomo"] = [2, 6].includes(captacion["titular"]);

    if (captacion["geom"]) {
        captacion["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    captacion["created_at"] = new Date(captacion["created_at"]);
    captacion["updated_at"] = new Date(captacion["updated_at"]);
    return captacion;
};

const captacions_api_adapter = captacions => captacions.map(captacion_api_adapter);

const createCaptacions = (data = []) => {
    const captacions = Captacions.from(data, captacion => createCaptacion(captacion));
    return captacions;
};

const captacion_view_adapter = captacion => {
    delete captacion["folder"];
    delete captacion["featured_image"];
    delete captacion["featured_document"];
    delete captacion["created_at"];
    delete captacion["created_by"];
    delete captacion["updated_at"];
    delete captacion["updated_by"];
    return captacion;
};

const createCaptacion = ({
    id = null,
    codigo = null,
    abastecemento_autonomo = false,
    titular = null,
    titular_label = null,
    xestion = null,
    xestion_label = null,
    estado = null,
    estado_label = null,
    bacia = null,
    descripcion = null,
    orixe = null,
    orixe_label = null,
    tipo = null,
    tipo_label = null,
    uso = null,
    uso_label = null,
    legalizada = null,
    legalizada_label = null,
    bombeo = null,
    bombeo_label = null,
    proteccion = null,
    proteccion_label = null,
    suministro_electrico = null,
    suministro_electrico_label = null,
    telexestion = null,
    telexestion_label = null,
    caudalimetro = null,
    caudalimetro_label = null,
    notas = "",
    pending_status = null,
    concello = null,
    concello_label = null,
    sistema = null,
    sistema_label = null,
    geom = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        abastecemento_autonomo,
        titular,
        titular_label,
        xestion,
        xestion_label,
        estado,
        estado_label,
        bacia,
        descripcion,
        orixe,
        orixe_label,
        tipo,
        tipo_label,
        uso,
        uso_label,
        legalizada,
        legalizada_label,
        bombeo,
        bombeo_label,
        proteccion,
        proteccion_label,
        suministro_electrico,
        suministro_electrico_label,
        telexestion,
        telexestion_label,
        caudalimetro,
        caudalimetro_label,
        notas,
        pending_status,
        concello,
        concello_label,
        sistema,
        sistema_label,
        geom,
        folder,
        featured_image,
        featured_document,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createCaptacion as default,
    createCaptacions,
    captacion_api_adapter,
    captacions_api_adapter,
    captacion_view_adapter,
};
