import {useDownloadFile} from "base/hooks";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";

const FeaturedDocumentDownload = ({featuredDocument}) => {
    const downloadFile = useDownloadFile();
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        downloadFile(featuredDocument, null, () => {
            setLoading(false);
        });
    };

    return loading ? (
        <CircularProgress size={22} sx={{ml: 1, mt: 1}} />
    ) : (
        <Tooltip title="Descargar ficha" placement="bottom-end">
            <Button
                aria-label="download-document"
                onClick={() => handleClick()}
                startIcon={<InsertDriveFileOutlinedIcon />}
                variant="outlined"
            >
                Ficha
            </Button>
        </Tooltip>
    );
};

export default FeaturedDocumentDownload;
