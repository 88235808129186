import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaMarcosRegulatoriosSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`marcos_regulatorios/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "O regulamento dos servizos está adaptado ao Decreto 141/2012:",
            value: gobernanza.regulamento_servizos_adaptado_decreto_label,
        },
        {
            label: "Expídense permisos de vertido conforme ao Decreto 141/2012:",
            value: gobernanza.expidense_permisos_decreto_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Marcos regulatorios"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_marcos_regulatorios}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_marcos_regulatorios,
                    "low"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaMarcosRegulatoriosSection;
