import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SubaglomeracionService} from "saneamiento/service";
import {subaglomeracion_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {SubaglomeracionForm} from "../presentational/form";

const CreateSubaglomeracionPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = subaglomeracion => {
        SubaglomeracionService.create(
            subaglomeracion_view_adapter({...subaglomeracion})
        )
            .then(createdSubaglomeracion => {
                navigate(`/subaglomeracions/${createdSubaglomeracion.id}/map`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <SubaglomeracionForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Rexistro de Subaglomeracion"
            error={error}
        ></EntityCreatePage>
    );
};

export default CreateSubaglomeracionPage;
