class ETAPs extends Array {}

const etap_api_adapter = etap => {
    // abastecemento autonomo if titular veciñal (2) or privada (6)
    etap["abastecemento_autonomo"] = [2, 6].includes(etap["titular"]);

    if (etap["geom"]) {
        etap["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    etap["created_at"] = new Date(etap["created_at"]);
    etap["updated_at"] = new Date(etap["updated_at"]);
    return etap;
};

const etaps_api_adapter = etaps => etaps.map(etap_api_adapter);

const createETAPs = (data = []) => {
    const etaps = ETAPs.from(data, etap => createETAP(etap));
    return etaps;
};

const etap_view_adapter = etap => {
    delete etap["lodos_destino_label"];

    delete etap["folder"];
    delete etap["featured_image"];
    delete etap["featured_document"];
    delete etap["created_at"];
    delete etap["created_by"];
    delete etap["updated_at"];
    delete etap["updated_by"];
    return etap;
};

const createETAP = ({
    id = null,
    codigo = null,
    abastecemento_autonomo = false,
    titular = null,
    titular_label = null,
    xestion = null,
    xestion_label = null,
    estado = null,
    estado_label = null,
    descripcion = null,
    pretratamento = null,
    pretratamento_label = null,
    fisicoquimico = null,
    fisicoquimico_label = null,
    filtracion = null,
    filtracion_label = null,
    desinfeccion = null,
    desinfeccion_label = null,
    proteccion = null,
    proteccion_label = null,
    suministro_electrico = null,
    suministro_electrico_label = null,
    telexestion = null,
    telexestion_label = null,
    caudalimetro = null,
    caudalimetro_label = null,
    notas = "",
    pending_status = null,
    concello = null,
    concello_label = null,
    sistema = null,
    sistema_label = null,
    geom = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        abastecemento_autonomo,
        titular,
        titular_label,
        xestion,
        xestion_label,
        estado,
        estado_label,
        descripcion,
        pretratamento,
        pretratamento_label,
        fisicoquimico,
        fisicoquimico_label,
        filtracion,
        filtracion_label,
        desinfeccion,
        desinfeccion_label,
        proteccion,
        proteccion_label,
        suministro_electrico,
        suministro_electrico_label,
        telexestion,
        telexestion_label,
        caudalimetro,
        caudalimetro_label,
        notas,
        pending_status,
        concello,
        concello_label,
        sistema,
        sistema_label,
        geom,
        folder,
        featured_image,
        featured_document,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createETAP as default,
    createETAPs,
    etap_api_adapter,
    etaps_api_adapter,
    etap_view_adapter,
};
