import {PageMenuListGroupItemButton, usePageMenu} from "base/component/presentational";
import useTheme from "@mui/material/styles/useTheme";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import {useEffect, useState} from "react";

/**
 * This component accepts an array of items (`menuListSubItems`). These items can be of 2 types:
 * * React components (e.g. `<MenuListDetailButton />`)
 * * Objects with these attributes:
 *     * to
 *     * text
 *     * icon
 *
 * If `menuListSubItems` is composed of objects, then the `createButtons` prop must be set to `true`, so that the objects can be used to create their corresponding button.
 */

const PageMenuListGroup = ({
    id = "",
    headerText = "",
    headerIcon = null,
    items = [],
}) => {
    const theme = useTheme();
    const {opened, setOpened, selectedGroup} = usePageMenu();
    const [showChildren, setShowChildren] = useState(false);

    const menuItems = items.map((menuListSubItem, index) => (
        <PageMenuListGroupItemButton
            parentId={id}
            key={index}
            text={menuListSubItem.text}
            to={menuListSubItem.to}
            pathForSelected={menuListSubItem.pathForSelected}
        />
    ));

    const onClickHeader = () => {
        setOpened(id);
    };

    useEffect(() => {
        setShowChildren(opened === id);
    }, [opened]);

    return (
        <>
            {headerText ? (
                <>
                    <ListItemButton
                        sx={{
                            bgcolor: theme.palette.menu.primary.header.background,
                        }}
                        onClick={onClickHeader}
                    >
                        {headerIcon && (
                            <ListItemIcon
                                style={{
                                    minWidth: "35px",
                                    color:
                                        selectedGroup === id
                                            ? theme.palette.menu.primary.header.text
                                            : theme.palette.menu.primary.header.icon,
                                }}
                            >
                                {headerIcon}
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primaryTypographyProps={{
                                color: theme.palette.menu.primary.header.text,
                                fontWeight: selectedGroup === id ? "700" : "100",
                            }}
                        >
                            {headerText}
                        </ListItemText>
                        {opened === id ? (
                            <ArrowDropUpIcon
                                sx={{
                                    color: theme.palette.menu.primary.header.text,
                                }}
                            />
                        ) : (
                            <ArrowDropDownIcon
                                sx={{
                                    color: "grey",
                                }}
                            />
                        )}
                    </ListItemButton>
                    <Divider />
                </>
            ) : null}

            <Box
                sx={{
                    bgcolor: theme.palette.menu.primary.options.background,
                    transition: "all 0.5s ease-in-out",
                    display: showChildren ? "inherit" : "none",
                    overflow: "hidden",
                }}
            >
                {menuItems.length !== 0 ? (
                    <List dense disablePadding>
                        {menuItems}
                    </List>
                ) : null}
            </Box>
        </>
    );
};

export default PageMenuListGroup;
