import {FormInputText, FormSelect, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const SistemaFormGeneralDataFields = ({layout = "row"}) => {
    const {dominioabastecementoxestion} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome" label="Nome" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText name="codigo" label="Código" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="titular"
                    label="Titular"
                    options={dominioabastecementoxestion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="xestion"
                    label="Xestión"
                    options={dominioabastecementoxestion}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name="descripcion" label="Descripción" />
            </Grid>
        </Grid>
    );
};

export default SistemaFormGeneralDataFields;
