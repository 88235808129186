class Tanques extends Array {}

const tanque_api_adapter = tanque => {
    if (tanque["geom"]) {
        tanque["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    tanque["created_at"] = new Date(tanque["created_at"]);
    tanque["updated_at"] = new Date(tanque["updated_at"]);
    return tanque;
};

const tanques_api_adapter = tanques => tanques.map(tanque_api_adapter);

const createTanques = (data = []) => {
    const tanques = Tanques.from(data, tanques => createTanque(tanques));
    return tanques;
};

const tanque_view_adapter = tanque => {
    delete tanque["problemas_label"];
    delete tanque["pretratamiento_label"];
    delete tanque["regulacion_label"];
    delete tanque["tratamento_aliviadoiro_label"];
    delete tanque["sistema_limpeza_label"];
    delete tanque["telecontrol_label"];

    delete tanque["concello_label"];
    delete tanque["aglomeracion_label"];

    delete tanque["folder"];
    delete tanque["featured_image"];
    delete tanque["featured_document"];
    delete tanque["created_at"];
    delete tanque["created_by"];
    delete tanque["updated_at"];
    delete tanque["updated_by"];
    return tanque;
};

const createTanque = ({
    id = null,
    codigo = "",
    nome = "",
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    notas = null,
    potencia = null,
    consumo_electrico = null,
    problemas = [],
    problemas_label = null,
    capacidade = null,
    pretratamiento = [],
    pretratamiento_label = null,
    bombeo = null,
    bombeo_label = null,
    regulacion = [],
    regulacion_label = null,
    tratamento_aliviadoiro = [],
    tratamento_aliviadoiro_label = null,
    sistema_limpeza = [],
    sistema_limpeza_label = null,
    telecontrol = null,
    telecontrol_label = null,
    subaglomeracion = null,
    pending_status = null,
    concello = null,
    concello_label = "",
    aglomeracion = null,
    aglomeracion_label = "",
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        nome,
        folder,
        featured_image,
        featured_document,
        geom,
        notas,
        potencia,
        consumo_electrico,
        problemas,
        problemas_label,
        capacidade,
        pretratamiento,
        pretratamiento_label,
        bombeo,
        bombeo_label,
        regulacion,
        regulacion_label,
        tratamento_aliviadoiro,
        tratamento_aliviadoiro_label,
        sistema_limpeza,
        sistema_limpeza_label,
        telecontrol,
        telecontrol_label,
        subaglomeracion,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createTanque as default,
    createTanques,
    tanque_api_adapter,
    tanques_api_adapter,
    tanque_view_adapter,
};
