import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloResumoSistemasPrincipalSection = ({concello}) => {
    return (
        <SectionCard title="Sistemas">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sistemas <250 Heq:"
                        value={NumberUtil.formatInteger(
                            concello.stats.edarrangopequenas
                        )}
                        tooltipText="Número de  sistemas menores a 250 habitantes equivalentes"
                    />
                    <SectionField
                        label="Sistemas 250<Heq<2000:"
                        value={NumberUtil.formatInteger(
                            concello.stats.edarrangomedianas
                        )}
                        tooltipText="Numero de sistemas entre 250 e 2.000  habitantes equivalentes"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sistemas Heq>2000:"
                        value={NumberUtil.formatInteger(
                            concello.stats.edarrangograndes
                        )}
                        tooltipText="Número de sistemas maiores a 2.000 habitantes equivalentes"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloResumoSistemasPrincipalSection;
