import {Navigate, Route} from "react-router-dom";
import {
    ViewAglomeracionExplotacionSubPage,
    ViewAglomeracionInventarioSubPage,
    ViewAglomeracionPage,
    ViewAglomeracionPanel,
    ViewAglomeracionPoboacionSubPage,
    ViewAglomeracionSummarySubPage,
    ViewAglomeracionViewerSubPage,
    ViewAglomeracionsPage,
    ViewAglomeracionDocumentsSubPage,
    ViewAglomeracionDocumentPanel,
} from "saneamiento/aglomeracion/container";
import {ViewSubaglomeracionPanel} from "saneamiento/subaglomeracion/container";
import {ViewEDARPanel} from "saneamiento/edar/container";
import {ViewBombeoPanel} from "saneamiento/bombeo/container";
import {ViewTanquePanel} from "saneamiento/tanque/container";
import {ViewColectorPanel} from "saneamiento/colector/container";
import {subaglomeracionRoutes} from "saneamiento/subaglomeracion/routes";
import {edarRoutes} from "saneamiento/edar/routes";
import {bombeoRoutes} from "saneamiento/bombeo/routes";
import {tanqueRoutes} from "saneamiento/tanque/routes";
import {colectorRoutes} from "saneamiento/colector/routes";
import {ViewSistemaExplotacionPanel} from "medioreceptor/sistemaexplotacion/container";
import {ViewMasaAugaPanel} from "medioreceptor/masaauga/container";
import {ViewVertidoPanel} from "saneamiento/vertido/container";
import {vertidoRoutes} from "saneamiento/vertido/routes";
import {poligonoRoutes} from "saneamiento/poligono/routes";
import {ViewPoligonoPanel} from "saneamiento/poligono/container";

const aglomeracionRoutes = [
    <Route key="aglomeracion-page" path=":id" element={<ViewAglomeracionPage />}>
        <Route
            key="aglomeracion-subpage-viewer"
            path="map"
            element={<ViewAglomeracionViewerSubPage />}
        >
            <Route
                key="aglomeracions-page-subaglomeracion-info"
                path="info/subaglomeracions/:idSubaglomeracion"
                element={<ViewSubaglomeracionPanel />}
            />
            <Route
                key="aglomeracions-page-edar-info"
                path="info/edars/:idEdar"
                element={<ViewEDARPanel />}
            />
            <Route
                key="aglomeracions-page-bombeo-info"
                path="info/bombeos/:idBombeo"
                element={<ViewBombeoPanel />}
            />
            <Route
                key="aglomeracions-page-tanque-info"
                path="info/tanques/:idTanque"
                element={<ViewTanquePanel />}
            />
            <Route
                key="concellos-page-colector-info"
                path="info/colectores/:idColector"
                element={<ViewColectorPanel />}
            />
            <Route
                key="concellos-page-vertido-info"
                path="info/vertidos/:idVertido"
                element={<ViewVertidoPanel />}
            />
            <Route
                key="concellos-page-poligono-info"
                path="info/poligonos/:idPoligono"
                element={<ViewPoligonoPanel />}
            />
        </Route>
        <Route
            key="aglomeracion-summary"
            path="summary"
            element={<ViewAglomeracionSummarySubPage />}
        />
        <Route
            key="aglomeracion-poboacion"
            path="poboacion"
            element={<ViewAglomeracionPoboacionSubPage />}
        />
        <Route
            key="aglomeracion-inventario"
            path="inventario"
            element={<ViewAglomeracionInventarioSubPage />}
        />
        <Route
            key="aglomeracion-explotacion"
            path="explotacion"
            element={<ViewAglomeracionExplotacionSubPage />}
        />
        <Route
            key="aglomeracion-documents"
            path="documents/*"
            element={<ViewAglomeracionDocumentsSubPage />}
        >
            <Route
                key="aglomeracion-documents-view"
                path="detail/*"
                element={<ViewAglomeracionDocumentPanel />}
            />
        </Route>
        {subaglomeracionRoutes}
        {edarRoutes}
        {bombeoRoutes}
        {tanqueRoutes}
        {colectorRoutes}
        {vertidoRoutes}
        {poligonoRoutes}
    </Route>,
    <Route key="aglomeracions-page" path="" element={<ViewAglomeracionsPage />}>
        <Route
            key="concellos-page-aglomeracion-info"
            path="info/aglomeracions/:idAglomeracion"
            element={<ViewAglomeracionPanel />}
        />
        <Route
            key="concellos-page-subaglomeracion-info"
            path="info/subaglomeracions/:idSubaglomeracion"
            element={<ViewSubaglomeracionPanel />}
        />
        <Route
            key="concellos-page-edar-info"
            path="info/edars/:idEdar"
            element={<ViewEDARPanel />}
        />
        <Route
            key="concellos-page-sistema-explotacion-info"
            path="info/sistemasexplotacion/:idSistemaExplotacion"
            element={<ViewSistemaExplotacionPanel />}
        />
        <Route
            key="concellos-page-masa-auga-info"
            path="info/masasauga/:idMasaAuga"
            element={<ViewMasaAugaPanel />}
        />
    </Route>,
];

export default aglomeracionRoutes;
