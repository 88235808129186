import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {AugasHiddenAuth} from "concello/auth";
import {SisbagalSectionCard} from "sisbagal/auth";

const VertidoOutrasReferenciasSection = ({vertido}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <AugasHiddenAuth>
            <SectionCardHeaderAction
                key="edit"
                name="edit"
                text="Modificar"
                icon={<EditIcon />}
                onClick={() => {
                    navigate(`outrasreferencias/edit`);
                }}
                roles={[]}
            />
        </AugasHiddenAuth>,
    ];

    return (
        <SisbagalSectionCard
            title="Outras referencias"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Código UE:" value={vertido.codigo_ue} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Nome UE:" value={vertido.nome_ue} />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default VertidoOutrasReferenciasSection;
