import {NumberUtil} from "base/utilities";

export function useEDARTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 13,
        },
        {
            id: "nome",
            label: "Nome",
            width: 13,
        },
        {
            id: "tipotratamento_label",
            label: "Tipo de tratamento",
            width: 10,
            ordering: false,
        },
        {
            id: "rendemento_desinfeccion_label",
            label: "Trat. avanzado",
            width: 7,
            ordering: false,
        },
        {
            id: "habitantes_equivalentes_desenho",
            label: "Habit. equival. (heq)",
            width: 7,
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(
                    element.habitantes_equivalentes_desenho
                ),
        },
        {
            id: "urbanatotal",
            label: "Carga urbana (heq)",
            width: 7,
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.urbanatotal),
            ordering: false,
        },
        {
            id: "cargadomestica",
            label: "Carga doméstica (heq)",
            width: 7,
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.cargadomestica),
            ordering: false,
        },
        {
            id: "carganondomestica",
            label: "Carga non doméstica (heq)",
            width: 7,
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.carganondomestica),
            ordering: false,
        },
        {
            id: "codigo_interno",
            label: "Código interno",
            width: 7,
        },
        {
            id: "concello_label",
            label: "Concello",
            width: 11,
        },
        {
            id: "aglomeracion_label",
            label: "Aglomeración",
            width: 11,
        },
    ];

    return {tableColumns};
}

export function useEDARConcelloTable() {
    const tableColumns = [
        {
            id: "codigoedar",
            label: "Código EDAR",
            width: 25,
        },
        {
            id: "nomeedar",
            label: "Nome EDAR",
            width: 25,
        },
        {
            id: "tratamento",
            label: "Tipo de tratamento",
            width: 25,
        },
        {
            id: "porcentaxe",
            label: "Porcentaxe",
            width: 25,
        },
    ];

    return {tableColumns};
}
