import {FormInputText} from "base/component/form";
import Grid from "@mui/material/Grid";

const VertidoFormOutrasReferenciasFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="codigo_ue" label="Código UE" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome_ue" label="Nome UE" />
            </Grid>
        </Grid>
    );
};

export default VertidoFormOutrasReferenciasFields;
