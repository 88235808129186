import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {ColectorCaracteristicasSection} from "../presentational/section";

const ViewColectorDetailSubPage = () => {
    let colector;
    [colector] = useOutletContext();

    const sections = [<ColectorCaracteristicasSection colector={colector} />];

    return colector && <EntityViewSubPage sections={sections} />;
};

export default ViewColectorDetailSubPage;
