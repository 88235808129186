class Poligonos extends Array {}

const poligono_api_adapter = poligono => {
    if (poligono["geom"]) {
        poligono["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    poligono["created_at"] = new Date(poligono["created_at"]);
    poligono["updated_at"] = new Date(poligono["updated_at"]);
    return poligono;
};

const poligonos_api_adapter = poligonos => poligonos.map(poligono_api_adapter);

const createPoligonos = (data = []) => {
    const poligonos = Poligonos.from(data, poligonos => createPoligono(poligonos));
    return poligonos;
};

const poligono_view_adapter = poligono => {
    delete poligono["concello_label"];
    delete poligono["edar_label"];
    delete poligono["estado_label"];

    delete poligono["folder"];
    delete poligono["featured_image"];
    delete poligono["featured_document"];
    delete poligono["created_at"];
    delete poligono["created_by"];
    delete poligono["updated_at"];
    delete poligono["updated_by"];
    return poligono;
};

const createPoligono = ({
    id = null,
    nome = "",
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    notas = null,
    sector = null,
    superficie = null,
    grado_ocupacion = null,
    caudal_saneamento = null,
    caudal_abastecemento = null,
    habitantes_equivalentes = null,
    carga = null,
    saneamento_propio = null,
    saneamento_propio_label = null,
    estado = null,
    estado_label = null,
    xestor = null,
    promotor = null,
    dotacion = null,
    pending_status = null,
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    edar = null,
    edar_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        nome,
        folder,
        featured_image,
        featured_document,
        geom,
        notas,
        sector,
        superficie,
        grado_ocupacion,
        caudal_saneamento,
        caudal_abastecemento,
        habitantes_equivalentes,
        carga,
        saneamento_propio,
        saneamento_propio_label,
        estado,
        estado_label,
        xestor,
        promotor,
        dotacion,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        edar,
        edar_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createPoligono as default,
    createPoligonos,
    poligono_api_adapter,
    poligonos_api_adapter,
    poligono_view_adapter,
};
