import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {createElement} from "react";
import {BREADCRUMB_HEIGHT} from "base/config/measurements";

const ModuleBreadcrumb = ({breadcrumbPath}) => {
    const theme = useTheme();

    const getBreadcrumItemUrl = item => {
        const index = breadcrumbPath.findIndex(
            breadcrumbItem => breadcrumbItem.path === item.path
        );
        const parentPath = breadcrumbPath
            .slice(0, index)
            .map(breadcrumbItem => breadcrumbItem.path)
            .join("/");
        return parentPath ? `/${parentPath}/${item.path}` : `/${item.path}`;
    };

    const getBreadcrumItemComponent = (breadcrumbItem, isLast) => {
        const breadcrumItemUrl = getBreadcrumItemUrl(breadcrumbItem);

        return (
            <Stack
                direction="row"
                key={breadcrumItemUrl}
                alignItems="center"
                sx={{color: "white", height: `${BREADCRUMB_HEIGHT}px`}}
            >
                {breadcrumbItem.icon &&
                    createElement(breadcrumbItem.icon, {
                        fontSize: "small",
                        sx: {color: theme.palette.secondary.light},
                    })}
                {breadcrumItemUrl ? (
                    <Button
                        to={breadcrumItemUrl}
                        component={Link}
                        sx={{
                            color: isLast ? "white" : theme.palette.secondary.light,
                            fontWeight: isLast ? "bold" : 100,
                        }}
                    >
                        {breadcrumbItem.name}
                    </Button>
                ) : (
                    <Typography sx={{fontSize: "0.9em"}}>
                        {breadcrumbItem.name}
                    </Typography>
                )}
                {!isLast ? <ArrowRightIcon sx={{color: "#5cabdd"}} /> : null}
            </Stack>
        );
    };

    return (
        <Stack direction="row" sx={{pl: 1, pr: 3}}>
            {breadcrumbPath.map((breadcrumbItem, index) => {
                return getBreadcrumItemComponent(
                    breadcrumbItem,
                    index === breadcrumbPath.length - 1
                );
            })}
        </Stack>
    );
};

export default ModuleBreadcrumb;
