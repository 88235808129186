import {createEntityService} from "base/entity/service";
import {createPerdidas, perdidas_api_adapter} from "abastecemento/model";

const entityService = createEntityService(
    "/api/infrastructures/perdidas",
    createPerdidas,
    null,
    perdidas_api_adapter,
    null
);

const PerdidasService = {
    get(id) {
        return entityService.get(id);
    },

    update(perdidas) {
        return entityService.update(perdidas);
    },
};

export default PerdidasService;
