import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {PageLayout} from "base/layout";
import {BombeoSubPageMenu} from "../menu";
import {useBombeoData} from "../hooks/BombeoData";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewBombeoPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();

    const [bombeo, setBombeo] = useState(null);
    const location = useLocation();
    const {idBombeo} = useParams();
    const {getEntityData} = useBombeoData();

    useEffect(() => {
        BombeoService.get(idBombeo).then(data => {
            setBombeo(data);
            setSubpagePath([
                {name: "Listado de Bombeos", path: "bombeos"},
                {
                    name: data.nome || data.codigo || "-",
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setBombeo(null);
            setSubpagePath([]);
            setModuleActions([]);
        };
    }, [idBombeo, location.state?.lastRefreshDate]);

    return (
        bombeo && (
            <PageLayout
                menu={<BombeoSubPageMenu bombeo={bombeo} />}
                context={[bombeo]}
                subPage={true}
            />
        )
    );
};

export default ViewBombeoPage;
