import {NumberUtil} from "base/utilities";

export function useNucleoAglomeracionTable() {
    const tableColumns = [
        {
            id: "concello",
            label: "Concello",
            width: 10,
        },
        {
            id: "parroquia",
            label: "Parroquia",
            width: 20,
        },
        {
            id: "codigonucleo",
            label: "Código",
            width: 10,
        },
        {
            id: "nucleo",
            label: "Núcleo",
            width: 20,
        },
        {
            id: "domesticafixatotalnucleo",
            label: "Doméstica fixa",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.domesticafixatotalnucleo, 0),
        },
        {
            id: "domesticaestacionaltotalnucleo",
            label: "Doméstica estacional",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.domesticaestacionaltotalnucleo, 0),
        },
        {
            id: "domesticatotalnucleo",
            label: "Doméstica total",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.domesticatotalnucleo, 0),
        },
        {
            id: "porcentaxe",
            label: "Porcentaxe conectado",
            width: 10,
            formatFunction: element => NumberUtil.formatDecimal(element.porcentaxe, 1),
        },
    ];

    return {tableColumns};
}

export function useNucleoConcelloTable() {
    const tableColumns = [
        {
            id: "codigoparroquia",
            label: "Código parroquia",
            width: 8,
        },
        {
            id: "nomeparroquia",
            label: "Parroquia",
            width: 14,
        },
        {
            id: "codigonucleo",
            label: "Código núcleo",
            width: 10,
        },
        {
            id: "nucleo",
            label: "Nome núcleo",
            width: 10,
        },
        {
            id: "domesticafixa",
            label: "Domést. fixa",
            width: 6,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.domesticafixa, 0),
        },
        {
            id: "domesticatotal",
            label: "Domést. total",
            width: 6,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.domesticatotal, 0),
        },
        {
            id: "porcentaxeconexion",
            label: "Porcent. conexión",
            width: 7,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.porcentaxeconexion, 1),
        },
        {
            id: "codigoedar",
            label: "Código EDAR",
            width: 13,
        },
        {
            id: "nomeedar",
            label: "Nome EDAR",
            width: 10,
        },
        {
            id: "tratamento",
            label: "Tipo de tratamento",
            width: 10,
        },
        {
            id: "porcentaxe",
            label: "Porcent.",
            width: 6,
            formatFunction: element => NumberUtil.formatDecimal(element.porcentaxe, 1),
        },
    ];

    return {tableColumns};
}
