import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {PageLayout} from "base/layout";
import {VertidoSubPageMenu} from "../menu";
import {useVertidoData} from "../hooks/VertidoData";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewVertidoPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();

    const {idVertido} = useParams();
    const [vertido, setVertido] = useState(null);
    const location = useLocation();
    const {getEntityData} = useVertidoData();

    useEffect(() => {
        VertidoService.get(idVertido).then(data => {
            setVertido(data);
            setSubpagePath([
                {name: "Listado de Vertidos", path: "vertidos"},
                {
                    name: data.nome || data.codigo || data.id,
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setVertido(null);
            setSubpagePath([]);
            setModuleActions([]);
        };
    }, [idVertido, location.state?.lastRefreshDate]);

    return (
        vertido && (
            <PageLayout
                menu={<VertidoSubPageMenu vertido={vertido} />}
                context={[vertido]}
                subPage={true}
            />
        )
    );
};

export default ViewVertidoPage;
