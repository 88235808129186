import {useDomain} from "domain/provider";
import {FormSelect, FormTextArea} from "base/component/form";

import Grid from "@mui/material/Grid";

const DepositoFormCaracteristicasFields = ({layout = "row"}) => {
    const {
        dominiosino,
        dominioabastecementoproteccion,
        dominioabastecementoubicacion,
        dominioabastecementomaterial,
        dominioabastecementoplanta,
    } = useDomain();

    // TO-DO: Check dominioabastecementoproteccion -- returning undefined
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="posicion"
                    label="Posición"
                    options={dominioabastecementoubicacion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="material"
                    label="Material"
                    options={dominioabastecementomaterial}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="planta"
                    label="Planta"
                    options={dominioabastecementoplanta}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect name="cloracion" label="Cloración" options={dominiosino} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="proteccion"
                    label="Protección"
                    options={dominioabastecementoproteccion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="suministro_electrico"
                    label="Suministro eléctrico"
                    options={dominiosino}
                    tooltipText="Existencia de acometida eléctrica"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telexestion"
                    label="Telexestión"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="caudalimetro"
                    label="Caudalímetro"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default DepositoFormCaracteristicasFields;
