import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {EDARCreationForm, EDARModificationForm, EDARGeoForm} from ".";
import {FormUtil} from "base/utilities";

const EDARForm = ({
    edar = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    // For si/non fields, 0 should not evaluate as falsy, since it is the value for the "Non" option.

    const defaultFormValues = {
        id: FormUtil.getFormValue(edar?.id),
        codigo: FormUtil.getFormValue(edar?.codigo),
        codigo_interno: FormUtil.getFormValue(edar?.codigo_interno),
        nome: FormUtil.getFormValue(edar?.nome),
        notas: FormUtil.getFormValue(edar?.notas),
        orixe_datos: FormUtil.getFormValue(edar?.orixe_datos),
        vertido_directo: FormUtil.getFormValue(edar?.vertido_directo),
        habitantes_equivalentes_desenho: FormUtil.getFormValue(
            edar?.habitantes_equivalentes_desenho
        ),
        tipo_carga: FormUtil.getFormValue(edar?.tipo_carga),
        problemas: FormUtil.getFormValue(edar?.problemas, []),
        problemas_outro: FormUtil.getFormValue(edar?.problemas_outro),
        conservacion_urbanizacion: FormUtil.getFormValue(
            edar?.conservacion_urbanizacion
        ),
        conservacion_instalacions: FormUtil.getFormValue(
            edar?.conservacion_instalacions
        ),
        arqueta_mostras: FormUtil.getFormValue(edar?.arqueta_mostras),
        suministro_electrico: FormUtil.getFormValue(edar?.suministro_electrico),
        caudal_entrada: FormUtil.getFormValue(edar?.caudal_entrada),
        caudal_saida: FormUtil.getFormValue(edar?.caudal_saida),
        instalacion_compacta: FormUtil.getFormValue(edar?.instalacion_compacta),
        pretratamento: FormUtil.getFormValue(edar?.pretratamento, []),
        trat_primario: FormUtil.getFormValue(edar?.trat_primario, []),
        trat_primario_lineas: FormUtil.getFormValue(edar?.trat_primario_lineas),
        trat_primario_outro: FormUtil.getFormValue(edar?.trat_primario_outro),
        trat_secundario_extensivos: FormUtil.getFormValue(
            edar?.trat_secundario_extensivos,
            []
        ),
        trat_secundario_pequenas: FormUtil.getFormValue(
            edar?.trat_secundario_pequenas,
            []
        ),
        trat_secundario_outros: FormUtil.getFormValue(edar?.trat_secundario_outros, []),
        trat_secundario_lineas: FormUtil.getFormValue(edar?.trat_secundario_lineas),
        trat_secundario_outrost: FormUtil.getFormValue(edar?.trat_secundario_outrost),
        trat_terciario: FormUtil.getFormValue(edar?.trat_terciario),
        trat_avanzado: FormUtil.getFormValue(edar?.trat_avanzado, []),
        dixestion_anaerobia: FormUtil.getFormValue(edar?.dixestion_anaerobia),
        lodos_tipo_tratamiento: FormUtil.getFormValue(edar?.lodos_tipo_tratamiento, []),
        lodos_orixe: FormUtil.getFormValue(edar?.lodos_orixe),
        lodos_saida: FormUtil.getFormValue(edar?.lodos_saida),
        lodos_edar_destino: FormUtil.getFormValue(edar?.lodos_edar_destino),
        lodos_destino: FormUtil.getFormValue(edar?.lodos_destino),
        lodos_xestor_destino: FormUtil.getFormValue(edar?.lodos_xestor_destino),
        lodos_distancia_tratamiento: FormUtil.getFormValue(
            edar?.lodos_distancia_tratamiento
        ),
        lodos_cantidade_alleo: FormUtil.getFormValue(edar?.lodos_cantidade_alleo),
        caudal_medio_desenho: FormUtil.getFormValue(edar?.caudal_medio_desenho),
        qp_pretrat_primario: FormUtil.getFormValue(edar?.qp_pretrat_primario),
        qp_secundario: FormUtil.getFormValue(edar?.qp_secundario),
        potencia: FormUtil.getFormValue(edar?.potencia),
        consumo_electrico: FormUtil.getFormValue(edar?.consumo_electrico),
        reactivos: FormUtil.getFormValue(edar?.reactivos),
        residuos: FormUtil.getFormValue(edar?.residuos),
        rendemento_tratamento: FormUtil.getFormValue(edar?.rendemento_tratamento),
        rendemento_desinfeccion: FormUtil.getFormValue(edar?.rendemento_desinfeccion),
        telecontrol: FormUtil.getFormValue(edar?.telecontrol),
        ano_posta_marcha: FormUtil.getFormValue(edar?.ano_posta_marcha),
        antiguidade: FormUtil.getFormValue(edar?.antiguidade),
        reutilizacion: FormUtil.getFormValue(edar?.reutilizacion),
        // we must create an object with the same representation as expected search results
        punto_vertido: edar?.punto_vertido
            ? {id: edar.punto_vertido, codigo: edar?.punto_vertido_label}
            : null,
        aglomeracion: edar?.aglomeracion
            ? {id: edar.aglomeracion, nome: edar?.aglomeracion_label}
            : null,
        masa_auga: edar?.masa_auga
            ? {id: edar.masa_auga, nome: edar?.masa_auga_label}
            : null,
        concello: edar?.concello
            ? {codigo: edar.concello, nome: edar?.concello_label}
            : {codigo: concello, nome: ""},
        geom: FormUtil.getFormValue(edar?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            codigo_interno: FormUtil.getDataValue(data.codigo_interno),
            nome: FormUtil.getDataValue(data.nome),
            notas: FormUtil.getDataValue(data.notas),
            orixe_datos: FormUtil.getDataValue(data.orixe_datos),
            vertido_directo: FormUtil.getDataValue(data.vertido_directo),
            habitantes_equivalentes_desenho: FormUtil.getDataValue(
                data.habitantes_equivalentes_desenho
            ),
            tipo_carga: FormUtil.getDataValue(data.tipo_carga),
            problemas: FormUtil.getDataValue(data.problemas),
            problemas_outro: FormUtil.getDataValue(data.problemas_outro),
            conservacion_urbanizacion: FormUtil.getDataValue(
                data.conservacion_urbanizacion
            ),
            conservacion_instalacions: FormUtil.getDataValue(
                data.conservacion_instalacions
            ),
            arqueta_mostras: FormUtil.getDataValue(data.arqueta_mostras),
            suministro_electrico: FormUtil.getDataValue(data.suministro_electrico),
            caudal_entrada: FormUtil.getDataValue(data.caudal_entrada),
            caudal_saida: FormUtil.getDataValue(data.caudal_saida),
            instalacion_compacta: FormUtil.getDataValue(data.instalacion_compacta),
            pretratamento: FormUtil.getDataValue(data.pretratamento),
            trat_primario: FormUtil.getDataValue(data.trat_primario),
            trat_primario_lineas: FormUtil.getDataValue(data.trat_primario_lineas),
            trat_primario_outro: FormUtil.getDataValue(data.trat_primario_outro),
            trat_secundario_extensivos: FormUtil.getDataValue(
                data.trat_secundario_extensivos
            ),
            trat_secundario_pequenas: FormUtil.getDataValue(
                data.trat_secundario_pequenas
            ),
            trat_secundario_outros: FormUtil.getDataValue(data.trat_secundario_outros),
            trat_secundario_lineas: FormUtil.getDataValue(data.trat_secundario_lineas),
            trat_secundario_outrost: FormUtil.getDataValue(
                data.trat_secundario_outrost
            ),
            trat_terciario: FormUtil.getDataValue(data.trat_terciario),
            trat_avanzado: FormUtil.getDataValue(data.trat_avanzado),
            dixestion_anaerobia: FormUtil.getDataValue(data.dixestion_anaerobia),
            lodos_tipo_tratamiento: FormUtil.getDataValue(data.lodos_tipo_tratamiento),
            lodos_orixe: FormUtil.getDataValue(data.lodos_orixe),
            lodos_saida: FormUtil.getDataValue(data.lodos_saida),
            lodos_edar_destino: FormUtil.getDataValue(data.lodos_edar_destino),
            lodos_destino: FormUtil.getDataValue(data.lodos_destino),
            lodos_xestor_destino: FormUtil.getDataValue(data.lodos_xestor_destino),
            lodos_distancia_tratamiento: FormUtil.getDataValue(
                data.lodos_distancia_tratamiento
            ),
            lodos_cantidade_alleo: FormUtil.getDataValue(data.lodos_cantidade_alleo),
            caudal_medio_desenho: FormUtil.getDataValue(data.caudal_medio_desenho),
            qp_pretrat_primario: FormUtil.getDataValue(data.qp_pretrat_primario),
            qp_secundario: FormUtil.getDataValue(data.qp_secundario),
            potencia: FormUtil.getDataValue(data.potencia),
            consumo_electrico: FormUtil.getDataValue(data.consumo_electrico),
            reactivos: FormUtil.getDataValue(data.reactivos),
            residuos: FormUtil.getDataValue(data.residuos),
            rendemento_tratamento: FormUtil.getDataValue(data.rendemento_tratamento),
            rendemento_desinfeccion: FormUtil.getDataValue(
                data.rendemento_desinfeccion
            ),
            telecontrol: FormUtil.getDataValue(data.telecontrol),
            ano_posta_marcha: FormUtil.getDataValue(data.ano_posta_marcha),
            antiguidade: FormUtil.getDataValue(data.antiguidade),
            reutilizacion: FormUtil.getDataValue(data.reutilizacion),
            punto_vertido: FormUtil.getDataValue(data.punto_vertido?.id),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            masa_auga: FormUtil.getDataValue(data.masa_auga?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <EDARGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <EDARModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <EDARCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default EDARForm;
