import {useEffect, useState} from "react";

import {StatsService} from "stats/service";
import {BarChart} from "base/component/chart";

import CircularProgress from "@mui/material/CircularProgress";
import useInfrastructureStatsTypeConfig from "./InfrastructuresStatsTypeConfig";

const ViewStatsInfrastructuresTypeChart = ({statsFilter}) => {
    const {
        getInfrastructureTypeAttributeConfig,
        getInfrastructureTypeConfig,
    } = useInfrastructureStatsTypeConfig();
    const attributeStatsConfig = getInfrastructureTypeAttributeConfig(
        statsFilter.attribute
    );
    const typeStatsConfig = getInfrastructureTypeConfig(
        statsFilter.infrastructure_type
    );

    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (statsFilter) {
            setLoading(true);
            setChartData(null);
            StatsService.getInfrastructuresTypeData(statsFilter).then(data => {
                setLoading(false);
                const orderedData = attributeStatsConfig.legendItems
                    .map(legendItem =>
                        data.find(labelResult => labelResult.label === legendItem)
                    )
                    .filter(n => n); // Remove nulls
                setChartData(orderedData);
            });
        }
    }, [statsFilter]);

    const getChartLabels = chartData => {
        return chartData.map(item => item.label);
    };

    const getChartDataset = chartData => {
        const data = chartData.map(item => item.value);
        return [
            {
                label: attributeStatsConfig.title,
                data,
                backgroundColor: typeStatsConfig.fillColor,
                borderWidth: 1,
            },
        ];
    };

    return loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        chartData && (
            <BarChart
                labels={getChartLabels(chartData)}
                datasets={getChartDataset(chartData)}
                title={attributeStatsConfig.title}
            />
        )
    );
};
export default ViewStatsInfrastructuresTypeChart;
