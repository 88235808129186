import {useOutletContext} from "react-router-dom";
import {DepositoService} from "abastecemento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {DepositoGeneralDataSection} from "../presentational/section";
import {UpdateDepositoGeoPanel} from ".";

const ViewDepositoSummarySubPage = () => {
    let deposito;
    [deposito] = useOutletContext();

    const sections = [
        <DepositoGeneralDataSection deposito={deposito} />,
        <UpdateDepositoGeoPanel />,
        <EntityAuditSection entity={deposito} />,
    ];

    return (
        deposito && <EntityViewSubPage sections={sections} service={DepositoService} />
    );
};

export default ViewDepositoSummarySubPage;
