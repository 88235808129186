import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {CaptacionCaracteristicasSection} from "../presentational/section";

const ViewCaptacionDetailSubPage = () => {
    let captacion;
    [captacion] = useOutletContext();

    const sections = [<CaptacionCaracteristicasSection captacion={captacion} />];

    return captacion && <EntityViewSubPage sections={sections} />;
};

export default ViewCaptacionDetailSubPage;
