import {HeaderHero} from "base/layout";
import {FULL_APP_NAME} from "base/config/appInfo";
import {LogoLink} from "base/component/presentational";

const SisbagalHeaderHero = () => {
    const logoHeight = "48px";

    const sisbagalLeftLogos = [
        <LogoLink
            href="/"
            textLogo={FULL_APP_NAME}
            title="Navega á páxina de inicio de Sisbagal"
            targetBlank={false}
            style={{fontSize: 18, maxWidth: "400px", color: "#fff"}}
        />,
    ];

    const sisbagalRightLogo = [
        <LogoLink
            href="https://augasdegalicia.xunta.gal/"
            title="Navega á web de Augas de Galicia"
            src="/images/logo_augas_blanco.png"
            alt="Logos de Augas de Galicia e a Xunta de Galicia"
            logoHeight={logoHeight}
        />,
    ];

    return (
        <>
            <HeaderHero left={sisbagalLeftLogos} right={sisbagalRightLogo} />
        </>
    );
};

export default SisbagalHeaderHero;
