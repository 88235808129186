import {useLocation, useNavigate} from "react-router-dom";
import {IdleTimerProvider} from "react-idle-timer";

const IdleLogoutTimer = ({children}) => {
    let navigate = useNavigate();
    let location = useLocation();

    const onIdle = () => {
        navigate("/logout", {state: {error: "IDLE_LOGOUT", from: location}});
    };

    const onAction = event => {
        // Do something when a user triggers a watched event
    };

    return (
        <IdleTimerProvider
            timeout={1000 * parseInt(process.env.REACT_APP_IDLE_SECONDS_LOGOUT)}
            crossTab={true}
            name="sisbagal"
            syncTimers={250}
            onIdle={onIdle}
            onAction={onAction}
        >
            {children}
        </IdleTimerProvider>
    );
};

export default IdleLogoutTimer;
