import {useState, useEffect, createContext, useContext} from "react";
import {DomainService} from "domain/service";

let DomainContext = createContext(null);

export default function DomainProvider({children}) {
    const [dominiosino, setDominiosino] = useState([]);
    const [dominiobombeopretratamento, setDominiobombeopretratamento] = useState([]);
    const [dominiobombeoproteccion, setDominiobombeoproteccion] = useState([]);
    const [dominiobombeotelecontrol, setDominiobombeotelecontrol] = useState([]);
    const [dominiobombeoproblema, setDominiobombeoproblema] = useState([]);
    const [dominiotanquepretratamento, setDominiotanquepretratamento] = useState([]);
    const [dominiotanqueregulacion, setDominiotanqueregulacion] = useState([]);
    const [
        dominiotanquetratamentoaliviadero,
        setDominiotanquetratamentoaliviadero,
    ] = useState([]);
    const [dominiotanquelimpeza, setDominiotanquelimpeza] = useState([]);
    const [dominiotanquetelecontrol, setDominiotanquetelecontrol] = useState([]);
    const [dominiotanqueproblemas, setDominiotanqueproblemas] = useState([]);
    const [dominioedarorixedatos, setDominioedarorixedatos] = useState([]);
    const [dominioedarproblemas, setDominioedarproblemas] = useState([]);
    const [dominioedartipocarga, setDominioedartipocarga] = useState([]);
    const [dominioedarurbanizacion, setDominioedarurbanizacion] = useState([]);
    const [dominioedarestadoinstalacions, setDominioedarestadoinstalacions] = useState(
        []
    );
    const [dominioedartomamostras, setDominioedartomamostras] = useState([]);
    const [dominioedarmedidacaudal, setDominioedarmedidacaudal] = useState([]);
    const [dominioedarpretratamento, setDominioedarpretratamento] = useState([]);
    const [dominioedarprimario, setDominioedarprimario] = useState([]);
    const [
        dominioedarsecundarioextensivos,
        setDominioedarsecundarioextensivos,
    ] = useState([]);
    const [dominioedarsecundariopequenas, setDominioedarsecundariopequenas] = useState(
        []
    );
    const [dominioedarsecundariooutros, setDominioedarsecundariooutros] = useState([]);
    const [dominioedartratnutrientes, setDominioedartratnutrientes] = useState([]);
    const [dominioedarterciario, setDominioedarterciario] = useState([]);
    const [dominioedarlodostratamento, setDominioedarlodostratamento] = useState([]);
    const [dominioedarlodosorixe, setDominioedarlodosorixe] = useState([]);
    const [dominioedarlodosdestino, setDominioedarlodosdestino] = useState([]);
    const [dominioedarobxetivovertido, setDominioedarobxetivovertido] = useState([]);
    const [dominioedardesinfeccion, setDominioedardesinfeccion] = useState([]);
    const [dominioedarantiguedad, setDominioedarantiguedad] = useState([]);
    const [dominioedarreutilizacion, setDominioedarreutilizacion] = useState([]);
    const [
        dominiocolectortipocanalizacion,
        setDominiocolectortipocanalizacion,
    ] = useState([]);
    const [dominiocolectormaterial, setDominiocolectormaterial] = useState([]);
    const [dominiocolectorfluxo, setDominiocolectorfluxo] = useState([]);
    const [dominiocolectortipo, setDominiocolectortipo] = useState([]);
    const [dominiocolectororixedato, setDominiocolectororixedato] = useState([]);
    const [dominioaglomeracionantiguedad, setDominioaglomeracionantiguedad] = useState(
        []
    );
    const [
        dominioaglomeracionmedicionalivios,
        setDominioaglomeracionmedicionalivios,
    ] = useState([]);
    const [
        dominioaglomeraciontelecontrol,
        setDominioaglomeraciontelecontrol,
    ] = useState([]);
    const [
        dominioaglomeracionreutilizacion,
        setDominioaglomeracionreutilizacion,
    ] = useState([]);
    const [dominioaglomeracionfrecuencia, setDominioaglomeracionfrecuencia] = useState(
        []
    );
    const [dominioaglomeracionalcance, setDominioaglomeracionalcance] = useState([]);
    const [
        dominionaglomeracionexplotacionmantemento,
        setDominionaglomeracionexplotacionmantemento,
    ] = useState([]);
    const [
        dominioaglomeracionlimpezacolectores,
        setDominioaglomeracionlimpezacolectores,
    ] = useState([]);
    const [
        dominioaglomeraciondesbordamento,
        setDominioaglomeraciondesbordamento,
    ] = useState([]);
    const [dominioabastecementoxestion, setDominioabastecementoxestion] = useState([]);
    const [dominioabastecementodestino, setDominioabastecementodestino] = useState([]);
    const [dominioabastecementoestado, setDominioabastecementoestado] = useState([]);
    const [dominioabastecementoubicacion, setDominioabastecementoubicacion] = useState(
        []
    );
    const [dominioabastecementomaterial, setDominioabastecementomaterial] = useState(
        []
    );
    const [dominioabastecementoplanta, setDominioabastecementoplanta] = useState([]);
    const [dominioabastecementouso, setDominioabastecementouso] = useState([]);
    const [dominioabastecementoorixe, setDominioabastecementoorixe] = useState([]);
    const [dominioabastecementotipo, setDominioabastecementotipo] = useState([]);
    const [
        dominioabastecementoproteccion,
        setDominioabastecementoproteccion,
    ] = useState([]);

    const [dominiovertidotipo, setDominiovertidotipo] = useState([]);
    const [dominiovertidotipopunto, setDominiovertidotipopunto] = useState([]);
    const [dominiovertidofontedatos, setDominiovertidofontedatos] = useState([]);
    const [dominiovertidotipotitular, setDominiovertidotipotitular] = useState([]);
    const [dominiopoligonoestado, setDominiopoligonoestado] = useState([]);

    const [dominiogobernanzasino, setDominiogobernanzasino] = useState([]);
    const [dominiogobernanzasinosimple, setDominiogobernanzasinosimple] = useState([]);
    const [
        dominiogobernanzanumeropersoas,
        setDominiogobernanzanumeropersoas,
    ] = useState([]);
    const [dominiogobernanzaporcentaxe, setDominiogobernanzaporcentaxe] = useState([]);

    useEffect(() => {
        Promise.all([DomainService.getDomain()]).then(([domain]) => {
            const {
                dominiosino,
                dominiobombeopretratamento,
                dominiobombeoproteccion,
                dominiobombeotelecontrol,
                dominiobombeoproblema,
                dominiotanquepretratamento,
                dominiotanqueregulacion,
                dominiotanquetratamentoaliviadero,
                dominiotanquelimpeza,
                dominioedarorixedatos,
                dominioedarproblemas,
                dominioedartipocarga,
                dominioedarurbanizacion,
                dominioedarestadoinstalacions,
                dominioedartomamostras,
                dominioedarmedidacaudal,
                dominioedarpretratamento,
                dominioedarprimario,
                dominioedarsecundarioextensivos,
                dominioedarsecundariopequenas,
                dominioedarsecundariooutros,
                dominioedartratnutrientes,
                dominioedarterciario,
                dominioedarlodostratamento,
                dominioedarlodosorixe,
                dominioedarlodosdestino,
                dominioedarobxetivovertido,
                dominioedardesinfeccion,
                dominioedarantiguedad,
                dominioedarreutilizacion,
                dominiotanquetelecontrol,
                dominiotanqueproblemas,
                dominiocolectortipocanalizacion,
                dominiocolectormaterial,
                dominiocolectorfluxo,
                dominiocolectortipo,
                dominiocolectororixedato,
                dominioaglomeracionantiguedad,
                dominioaglomeraciontelecontrol,
                dominioaglomeracionmedicionalivios,
                dominioaglomeracionreutilizacion,
                dominioaglomeracionfrecuencia,
                dominioaglomeracionalcance,
                dominionaglomeracionexplotacionmantemento,
                dominioaglomeraciondesbordamento,
                dominioaglomeracionlimpezacolectores,
                dominioabastecementoxestion,
                dominioabastecementodestino,
                dominioabastecementoestado,
                dominioabastecementoubicacion,
                dominioabastecementomaterial,
                dominioabastecementoplanta,
                dominioabastecementouso,
                dominioabastecementoorixe,
                dominioabastecementotipo,
                dominioabastecementoproteccion,
                dominiovertidotipo,
                dominiovertidotipopunto,
                dominiovertidofontedatos,
                dominiovertidotipotitular,
                dominiopoligonoestado,
                dominiogobernanzasino,
                dominiogobernanzasinosimple,
                dominiogobernanzanumeropersoas,
                dominiogobernanzaporcentaxe,
            } = domain;
            setDominiosino(dominiosino);
            setDominiobombeopretratamento(dominiobombeopretratamento);
            setDominiobombeoproteccion(dominiobombeoproteccion);
            setDominiobombeotelecontrol(dominiobombeotelecontrol);
            setDominiobombeoproblema(dominiobombeoproblema);
            setDominiotanquepretratamento(dominiotanquepretratamento);
            setDominiotanqueregulacion(dominiotanqueregulacion);
            setDominiotanquetratamentoaliviadero(dominiotanquetratamentoaliviadero);
            setDominiotanquelimpeza(dominiotanquelimpeza);
            setDominioedarorixedatos(dominioedarorixedatos);
            setDominioedarproblemas(dominioedarproblemas);
            setDominioedartipocarga(dominioedartipocarga);
            setDominioedarurbanizacion(dominioedarurbanizacion);
            setDominioedarestadoinstalacions(dominioedarestadoinstalacions);
            setDominioedartomamostras(dominioedartomamostras);
            setDominioedarmedidacaudal(dominioedarmedidacaudal);
            setDominioedarpretratamento(dominioedarpretratamento);
            setDominioedarprimario(dominioedarprimario);
            setDominioedarsecundarioextensivos(dominioedarsecundarioextensivos);
            setDominioedarsecundariopequenas(dominioedarsecundariopequenas);
            setDominioedarsecundariooutros(dominioedarsecundariooutros);
            setDominioedartratnutrientes(dominioedartratnutrientes);
            setDominioedarterciario(dominioedarterciario);
            setDominioedarlodostratamento(dominioedarlodostratamento);
            setDominioedarlodosorixe(dominioedarlodosorixe);
            setDominioedarlodosdestino(dominioedarlodosdestino);
            setDominioedarobxetivovertido(dominioedarobxetivovertido);
            setDominioedardesinfeccion(dominioedardesinfeccion);
            setDominioedarantiguedad(dominioedarantiguedad);
            setDominioedarreutilizacion(dominioedarreutilizacion);
            setDominiotanquetelecontrol(dominiotanquetelecontrol);
            setDominiotanqueproblemas(dominiotanqueproblemas);
            setDominiocolectortipocanalizacion(dominiocolectortipocanalizacion);
            setDominiocolectormaterial(dominiocolectormaterial);
            setDominiocolectorfluxo(dominiocolectorfluxo);
            setDominiocolectortipo(dominiocolectortipo);
            setDominiocolectororixedato(dominiocolectororixedato);
            setDominioaglomeracionantiguedad(dominioaglomeracionantiguedad);
            setDominioaglomeraciontelecontrol(dominioaglomeraciontelecontrol);
            setDominioaglomeracionmedicionalivios(dominioaglomeracionmedicionalivios);
            setDominioaglomeracionreutilizacion(dominioaglomeracionreutilizacion);
            setDominioaglomeracionfrecuencia(dominioaglomeracionfrecuencia);
            setDominioaglomeracionalcance(dominioaglomeracionalcance);
            setDominionaglomeracionexplotacionmantemento(
                dominionaglomeracionexplotacionmantemento
            );
            setDominioaglomeracionlimpezacolectores(
                dominioaglomeracionlimpezacolectores
            );
            setDominioaglomeraciondesbordamento(dominioaglomeraciondesbordamento);
            setDominioabastecementoxestion(dominioabastecementoxestion);
            setDominioabastecementodestino(dominioabastecementodestino);
            setDominioabastecementoestado(dominioabastecementoestado);
            setDominioabastecementoubicacion(dominioabastecementoubicacion);
            setDominioabastecementomaterial(dominioabastecementomaterial);
            setDominioabastecementoplanta(dominioabastecementoplanta);
            setDominioabastecementouso(dominioabastecementouso);
            setDominioabastecementoorixe(dominioabastecementoorixe);
            setDominioabastecementotipo(dominioabastecementotipo);
            setDominioabastecementoproteccion(dominioabastecementoproteccion);
            setDominiovertidotipo(dominiovertidotipo);
            setDominiovertidotipopunto(dominiovertidotipopunto);
            setDominiovertidofontedatos(dominiovertidofontedatos);
            setDominiovertidotipotitular(dominiovertidotipotitular);
            setDominiopoligonoestado(dominiopoligonoestado);
            setDominiogobernanzasino(dominiogobernanzasino);
            setDominiogobernanzasinosimple(dominiogobernanzasinosimple);
            setDominiogobernanzanumeropersoas(dominiogobernanzanumeropersoas);
            setDominiogobernanzaporcentaxe(dominiogobernanzaporcentaxe);
        });
    }, []);

    let value = {
        dominiosino,
        dominiobombeopretratamento,
        dominiobombeoproteccion,
        dominiobombeotelecontrol,
        dominiobombeoproblema,
        dominiotanquepretratamento,
        dominiotanqueregulacion,
        dominiotanquetratamentoaliviadero,
        dominiotanquelimpeza,
        dominioedarorixedatos,
        dominioedartipocarga,
        dominioedarproblemas,
        dominioedarurbanizacion,
        dominioedarestadoinstalacions,
        dominioedartomamostras,
        dominioedarmedidacaudal,
        dominioedarpretratamento,
        dominioedarprimario,
        dominioedarsecundarioextensivos,
        dominioedarsecundariopequenas,
        dominioedarsecundariooutros,
        dominioedartratnutrientes,
        dominioedarterciario,
        dominioedarlodostratamento,
        dominioedarlodosorixe,
        dominioedarlodosdestino,
        dominioedarobxetivovertido,
        dominioedardesinfeccion,
        dominioedarantiguedad,
        dominioedarreutilizacion,
        dominiotanquetelecontrol,
        dominiotanqueproblemas,
        dominiocolectortipocanalizacion,
        dominiocolectormaterial,
        dominiocolectorfluxo,
        dominiocolectortipo,
        dominiocolectororixedato,
        dominioaglomeracionantiguedad,
        dominioaglomeraciontelecontrol,
        dominioaglomeracionmedicionalivios,
        dominioaglomeracionreutilizacion,
        dominioaglomeracionfrecuencia,
        dominioaglomeracionalcance,
        dominionaglomeracionexplotacionmantemento,
        dominioaglomeracionlimpezacolectores,
        dominioaglomeraciondesbordamento,
        dominioabastecementoxestion,
        dominioabastecementodestino,
        dominioabastecementoestado,
        dominioabastecementoubicacion,
        dominioabastecementomaterial,
        dominioabastecementoplanta,
        dominioabastecementouso,
        dominioabastecementoorixe,
        dominioabastecementotipo,
        dominioabastecementoproteccion,
        dominiovertidotipo,
        dominiovertidotipopunto,
        dominiovertidofontedatos,
        dominiovertidotipotitular,
        dominiopoligonoestado,
        dominiogobernanzasino,
        dominiogobernanzasinosimple,
        dominiogobernanzanumeropersoas,
        dominiogobernanzaporcentaxe,
    };

    return <DomainContext.Provider value={value}>{children}</DomainContext.Provider>;
}

function useDomain() {
    return useContext(DomainContext);
}

export {useDomain};
