import {ContentLayout} from "base/layout";

import {useOutletContext} from "react-router-dom";
import {ViewStatsAbastecementoVolumePanel} from "stats/container";
import {SectionCard} from "base/component/presentational";
import {useEffect} from "react";
import {useAppConfig} from "base/provider";

const ViewConcelloStatsAbastecementoVolumeSubPage = () => {
    let concello;
    [concello] = useOutletContext();
    const {setSubpagePath} = useAppConfig();

    useEffect(() => {
        setSubpagePath([
            {name: "Estatísticas abastecemento", path: "stats"},
            {name: "Capacidade dos depósitos", path: "abastecemento_volume"},
        ]);
        return () => {
            setSubpagePath([]);
        };
    }, []);

    return (
        <ContentLayout>
            <SectionCard title="Estadísticas de capacidade dos depósitos">
                <ViewStatsAbastecementoVolumePanel
                    statsFilter={{concello: concello.codigo}}
                />
            </SectionCard>
        </ContentLayout>
    );
};
export default ViewConcelloStatsAbastecementoVolumeSubPage;
