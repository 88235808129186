import {SubPageMenuListItemButton} from "base/component/presentational";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";

const ChangesMenuListItemButtom = ({entity}) => {
    return (
        <SubPageMenuListItemButton
            to="changes"
            text="Cambios"
            icon={
                <HistoryOutlinedIcon
                    color={entity.pending_status ? "error" : "inherit"}
                />
            }
        />
    );
};

export default ChangesMenuListItemButtom;
