import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    AglomeracionDatosPrincipaisSection,
    AglomeracionTerritorioSection,
} from "../presentational/section";

const ViewAglomeracionSummarySubPage = () => {
    let aglomeracion;
    [aglomeracion] = useOutletContext();

    const sections = [
        <AglomeracionDatosPrincipaisSection aglomeracion={aglomeracion} />,
        <AglomeracionTerritorioSection aglomeracion={aglomeracion} />,
    ];

    return aglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewAglomeracionSummarySubPage;
