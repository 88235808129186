import {FieldUtil, NumberUtil} from "base/utilities";

export function useEDARData() {
    const getEntityData = edar => {
        return {
            image: edar?.featured_image,
            name: `EDAR ${edar?.nome || edar?.codigo}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(edar?.codigo)],
                        ["Código interno:", FieldUtil.getValue(edar?.codigo_interno)],
                        ["Concello:", FieldUtil.getValue(edar?.concello_label)],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                edar?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(edar?.geom.coordinates[1])}`,
                        ],
                    ],
                },
                /*{
                head: "Carga",
                content: [
                    ["Tipo de carga:", FieldUtil.getValue(edar?.tipo_carga_label)],
                    [
                        "Urbanos fixos:",
                        FieldUtil.getValue(
                            NumberUtil.formatDecimal(edar?.poboacion_fixa_conectada, 0)
                        ),
                    ],
                    [
                        "Urbanos estacionais:",
                        FieldUtil.getValue(
                            NumberUtil.formatDecimal(
                                edar?.poboacion_estacional_conectada,
                                0
                            )
                        ),
                    ],
                    [
                        "Urbanos totais:",
                        FieldUtil.getValue(
                            NumberUtil.formatDecimal(
                                edar?.habitantes_equivalentes_urbanos,
                                0
                            )
                        ),
                    ],
                    [
                        "Industriais:",
                        FieldUtil.getValue(
                            NumberUtil.formatDecimal(
                                edar?.habitantes_equivalentes_industriais,
                                0
                            )
                        ),
                    ],
                    [
                        "Totais:",
                        FieldUtil.getValue(
                            NumberUtil.formatDecimal(
                                edar?.habitantes_equivalentes_totais,
                                0
                            )
                        ),
                    ],
                ],
            },*/
                {
                    head: "Conservación",
                    content: [
                        [
                            "Urbanización:",
                            FieldUtil.getValue(edar?.conservacion_urbanizacion_label),
                        ],
                        [
                            "Instalacións:",
                            FieldUtil.getValue(edar?.conservacion_instalacions_label),
                        ],
                        ["Problemas:", FieldUtil.getValue(edar?.problemas_label)],
                    ],
                },
                {
                    head: "Vertido e pretatamento",
                    content: [
                        [
                            "Vertido directo:",
                            FieldUtil.getValue(edar?.vertido_directo_label),
                        ],
                        [
                            "Pretratamento:",
                            FieldUtil.getValue(edar?.pretratamento_label),
                        ],
                    ],
                },
                {
                    head: "Tratamento primario",
                    content: [
                        [
                            "Número de liñas:",
                            FieldUtil.getValue(edar?.trat_primario_lineas),
                        ],
                        [
                            "Tipo de tratamento:",
                            FieldUtil.getValue(edar?.trat_primario_label),
                        ],
                        ["Outro:", FieldUtil.getValue(edar?.trat_primario_outro)],
                    ],
                },
                {
                    head: "Tratamento secundario",
                    content: [
                        [
                            "Compacta:",
                            FieldUtil.getValue(edar?.instalacion_compacta_label),
                        ],
                        [
                            "Número de liñas:",
                            FieldUtil.getValue(edar?.trat_primario_lineas),
                        ],
                        [
                            "Tratementos intensivos:",
                            FieldUtil.getValue(edar?.trat_secundario_pequenas_label),
                        ],
                        [
                            "Tratamentos extensivos:",
                            FieldUtil.getValue(edar?.trat_secundario_extensivos_label),
                        ],
                        [
                            "Tratamentos grandes plantas:",
                            FieldUtil.getValue(edar?.trat_secundario_outros_label),
                        ],
                        ["Outro:", FieldUtil.getValue(edar?.trat_secundario_outrost)],
                    ],
                },
                {
                    head: "Tratamento terciario e avanzado",
                    content: [
                        [
                            "Tipo de tratamento terciario:",
                            FieldUtil.getValue(edar?.trat_terciario_label),
                        ],
                        [
                            "Tipo de tratamento avanzado:",
                            FieldUtil.getValue(edar?.trat_avanzado_label),
                        ],
                    ],
                },
                {
                    head: "Tratamento lodos",
                    content: [
                        [
                            "Dixestión anaerobia:",
                            FieldUtil.getValue(edar?.trat_avanzado_label),
                        ],
                        [
                            "Tratamento lodos:",
                            FieldUtil.getValue(edar?.lodos_tipo_tratamiento_label),
                        ],
                    ],
                },
                {
                    head: "Servizos e instalacións aux.",
                    content: [
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(edar?.suministro_electrico_label),
                        ],
                        [
                            "Arqueta tomamostras:",
                            FieldUtil.getValue(edar?.arqueta_mostras_label),
                        ],
                        [
                            "Medición caudais saída:",
                            FieldUtil.getValue(edar?.caudal_saida_label),
                        ],
                        [
                            "Medición caudais entrada:",
                            FieldUtil.getValue(edar?.caudal_entrada_label),
                        ],
                    ],
                },
                {
                    head: "Servizos e instalacións aux.",
                    content: [
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(edar?.suministro_electrico_label),
                        ],
                        [
                            "Arqueta tomamostras:",
                            FieldUtil.getValue(edar?.arqueta_mostras_label),
                        ],
                        [
                            "Medición caudais saída:",
                            FieldUtil.getValue(edar?.caudal_saida_label),
                        ],
                        [
                            "Medición caudais entrada:",
                            FieldUtil.getValue(edar?.caudal_entrada_label),
                        ],
                    ],
                },
                {
                    head: "Xestión lodos",
                    content: [
                        [
                            "Tratamento de lodos alleos:",
                            FieldUtil.getValue(edar?.lodos_orixe_label),
                        ],
                        [
                            "Cantidade lodos alleos:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.lodos_cantidade_alleo)
                            ),
                        ],
                        [
                            "Cantidade lodos:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.lodos_saida)
                            ),
                        ],
                        [
                            "Destino lodos:",
                            FieldUtil.getValue(edar?.lodos_destino_label),
                        ],
                        [
                            "Xestor destino:",
                            FieldUtil.getValue(edar?.lodos_xestor_destino),
                        ],
                        ["EDAR destino:", FieldUtil.getValue(edar?.lodos_edar_destino)],
                        [
                            "Distancia destino:",
                            FieldUtil.getValue(edar?.lodos_distancia_tratamiento),
                        ],
                    ],
                },
                {
                    head: "Deseño",
                    content: [
                        [
                            "Potencia instalada:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.potencia)
                            ),
                        ],
                        [
                            "HEQ deseño:",
                            FieldUtil.getValue(edar?.habitantes_equivalentes_desenho),
                        ],
                        [
                            "Caudal medio:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.caudal_medio_desenho)
                            ),
                        ],
                        [
                            "Punta primario:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.qp_pretrat_primario)
                            ),
                        ],
                        [
                            "Punta secundario:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.qp_secundario)
                            ),
                        ],
                    ],
                },
                {
                    head: "Explotación",
                    content: [
                        [
                            "Consumo eléctrico:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.consumo_electrico)
                            ),
                        ],
                        [
                            "Producción de residuos:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.residuos)
                            ),
                        ],
                        [
                            "Consumo reactivos:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(edar?.reactivos)
                            ),
                        ],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
