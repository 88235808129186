import {createEntityService} from "base/entity/service";
import {
    createColector,
    createColectores,
    colector_api_adapter,
    colectores_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/colectores",
    createColector,
    createColectores,
    colector_api_adapter,
    colectores_api_adapter
);

const ColectorService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(colector) {
        return entityService.create(colector);
    },

    update(colector) {
        return entityService.update(colector);
    },

    updateWithPatch(colector) {
        return entityService.updateWithPatch(colector);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default ColectorService;
