import {useDomain} from "domain/provider";
import {FormSelect, FormTextArea} from "base/component/form";

import Grid from "@mui/material/Grid";

const ETAPFormCaracteristicasFields = ({layout = "row"}) => {
    const {dominiosino, dominioabastecementoproteccion} = useDomain();

    // TO-DO: Check dominioabastecementoproteccion (it returns undefined)

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="pretratamento"
                    label="Pretratamento"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="fisicoquimico"
                    label="Físico-químico"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="filtracion"
                    label="Filtración"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="desinfeccion"
                    label="Desinfección"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="proteccion"
                    label="Protección"
                    options={dominioabastecementoproteccion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="suministro_electrico"
                    label="Suministro eléctrico"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telexestion"
                    label="Telexestión"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="caudalimetro"
                    label="Caudalímetro"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default ETAPFormCaracteristicasFields;
