import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewColectorLocationSubPage = () => {
    let colector;
    [colector] = useOutletContext();

    const sections = [<EntityLocationSection geom={colector.geom} />];

    return colector && <EntityViewSubPage sections={sections} />;
};

export default ViewColectorLocationSubPage;
