import {NumberUtil} from "base/utilities";

export function useBombeoTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 18,
        },
        {
            id: "nome",
            label: "Nome",
            width: 14,
        },
        {
            id: "caudal_bombeo",
            label: "Caudal (l/s)",
            formatFunction: element => NumberUtil.formatDecimal(element.caudal_bombeo),
            width: 7,
        },
        {
            id: "altura_bombeo",
            label: "Altura (m.c.a)",
            formatFunction: element => NumberUtil.formatDecimal(element.altura_bombeo),
            width: 7,
        },
        {
            id: "potencia",
            label: "Potencia (kW)",
            formatFunction: element => NumberUtil.formatDecimal(element.potencia),
            width: 7,
        },
        {
            id: "consumo_electrico",
            label: "Consumo eléctrico (kWh/ano)",
            formatFunction: element =>
                NumberUtil.formatDecimal(element.consumo_electrico),
            width: 7,
        },
        {
            id: "bombas_activas",
            label: "Bombas activas",
            width: 7,
        },
        {
            id: "bombas_reserva",
            label: "Bombas reserva",
            width: 7,
        },
        {
            id: "concello_label",
            label: "Concello",
            width: 13,
        },
        {
            id: "aglomeracion_label",
            label: "Aglomeración",
            width: 13,
        },
    ];

    return {tableColumns};
}
