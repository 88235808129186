import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewETAPDocumentsSubPage = () => {
    let etap;
    [etap] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/etaps/")[0];

    return (
        etap && <ViewDocumentsSubPage entity={etap} basePath={`${basePath}/etaps`} />
    );
};

export default ViewETAPDocumentsSubPage;
