import {useEffect} from "react";
import {PageLayout} from "base/layout";
import {useStatsConfig} from "stats/provider";
import {StatsProvinceFilter} from "stats/presentational";

import Stack from "@mui/material/Stack";
import {SectionCard} from "base/component/presentational";
import ViewStatsAbastecementoVolumePanel from "./ViewStatsAbastecementoVolumePanel";
import {useAppConfig} from "base/provider";

const ViewStatsAbastecementoVolumeSubPage = () => {
    const {setPagePath} = useAppConfig();
    const {statsFilter, setStatsFilter} = useStatsConfig();

    useEffect(() => {
        setPagePath([
            {
                name: "Capacidade dos depósitos (Abastecemento)",
                path: "abastecemento_volume",
            },
        ]);
        return () => {
            setPagePath([]);
        };
    }, []);

    const handleFilterChange = filter => {
        setStatsFilter(filter);
    };

    return (
        <PageLayout subPage={true}>
            <Stack spacing={2} sx={{width: "100%"}}>
                <StatsProvinceFilter
                    filter={statsFilter}
                    onChange={handleFilterChange}
                />
                <SectionCard title="Estadísticas de capacidade dos depósitos">
                    <ViewStatsAbastecementoVolumePanel statsFilter={statsFilter} />
                </SectionCard>
            </Stack>
        </PageLayout>
    );
};
export default ViewStatsAbastecementoVolumeSubPage;
