import {useOutletContext} from "react-router-dom";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {
    GobernanzaSummaryEficaciaSection,
    GobernanzaSummaryEficienciaSection,
    GobernanzaSummaryConfianzaSection,
} from "../presentational/section";

const ViewGobernanzaSummarySubPage = () => {
    let gobernanza;
    [gobernanza] = useOutletContext();

    const sections = [
        <GobernanzaSummaryEficaciaSection gobernanza={gobernanza} />,
        <GobernanzaSummaryEficienciaSection gobernanza={gobernanza} />,
        <GobernanzaSummaryConfianzaSection gobernanza={gobernanza} />,
        <EntityAuditSection entity={gobernanza} />,
    ];

    return gobernanza && <EntityViewSubPage sections={sections} />;
};

export default ViewGobernanzaSummarySubPage;
