import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloInventarioEDARSection = ({concello}) => {
    return (
        <SectionCard title="EDAR">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.potenciainstaladaedar
                        )}
                        unit="Kw"
                        tooltipText="Potencia instalada"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.consumoelectricoedar
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico medio anual nos últimos cinco anos."
                    />
                    <SectionField
                        label="Suministro eléctrico:"
                        value={NumberUtil.formatInteger(
                            concello.stats.suministroelectricoedar
                        )}
                        tooltipText="Número total de instalación de depuración con suministro eléctrico."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tratamento deficiente:"
                        value={NumberUtil.formatInteger(concello.stats.edartratamento0)}
                        tooltipText="Número total de instalación de depuración tratamento deficiente, ben por ausencia de instalacions de depuración sen polo menos un tratamento primario, abandono, etc."
                    />
                    <SectionField
                        label="Tratamento primario:"
                        value={NumberUtil.formatInteger(concello.stats.edartratamento1)}
                        tooltipText="Número total de instalación de depuración con tipo de tratamento primario"
                    />
                    <SectionField
                        label="Tratamento secundario:"
                        value={NumberUtil.formatInteger(concello.stats.edartratamento2)}
                        tooltipText="Número total de instalación de depuración con tipo de tratamento secundario"
                    />
                    <SectionField
                        label="Tratamento terciario:"
                        value={NumberUtil.formatInteger(concello.stats.edartratamento3)}
                        tooltipText="Número total de instalación de depuración con tipo de tratamento terciario"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloInventarioEDARSection;
