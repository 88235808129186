import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaIntegridadeSection = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_consulta"
                    label="Existen mecanismos de consulta dos custos e investimentos"
                    options={dominiogobernanzasino}
                    tooltipText="A posibilidade de consultar os custos e investimentos en relación cos servizos xera confianza nos usuarios."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="contas_separadas"
                    label="Están separadas as contas dos servizos das do resto"
                    options={dominiogobernanzasino}
                    tooltipText="A separación das contas dos servizos de saneamento e depuración do resto de contas e básica para garantir a trazabilidade do diñeiro nos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="procedemento_reclamacion_regulado"
                    label="Está regulado o procedemento de reclamación"
                    options={dominiogobernanzasino}
                    tooltipText="O dereito dos usuarios a facer reclamacións en relación cos servizos debe estar regulado."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="xestions_web"
                    label="Xestións na web"
                    options={dominiogobernanzasino}
                    tooltipText="A posibilidade de facer a través da web do concello ou do xestor xestións relacionadas coa contratación do servizo (altas, modificacións...) mellora a confianza e a imaxe dos servizos por parte dos usuarios."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="facilitase_info_xeral"
                    label="Facilítase información xeral dos servizos na web"
                    options={dominiogobernanzasino}
                    tooltipText="Debería publicarse a seguinte información de acceso público na web: responsabilidades e estrutura da xestión dos servizos; carga total de augas residuais urbanas xeradas en cada aglomeración; calidade das augas residuais urbanas vertidas; custos anuais totais de operación e investimentos, medios por fogar e medios por m3 de auga tratada; cómo se recuperan os custos, investimentos previstos en cada aglomeración, enerxía consumida/producida e cantidades de residuos xerados nas instalacións, pegada de carbono dos servizos, resumo das reclamacións presentadas."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="facilitase_info_especifica"
                    label="Facilítase información específica dos servizos aos abonados"
                    options={dominiogobernanzasino}
                    tooltipText="Debería facilitarse a seguinte información específica a cada abonado: grao de cumprimento da autorización de vertido do sistema ao que está conectado, volume de auga residual recollida e tratada do abonado, comparativa deste volume co do resto de abonados da aglomeración, ligazón á información xeral dispoñible na web."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaIntegridadeSection;
