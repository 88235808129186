import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const SectionSubheading = ({text}) => {
    return (
        <Grid item xs={12} sx={{borderBottom: "1px solid lightgrey", mt: 1, mb: 2}}>
            <Typography sx={{pl: 1, fontWeight: "bold"}}>{text}</Typography>
        </Grid>
    );
};

export default SectionSubheading;
