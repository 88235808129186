import {useOutletContext} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {BombeoGeneralDataSection} from "../presentational/section";
import {UpdateBombeoGeoPanel} from ".";

const ViewBombeoSummarySubPage = () => {
    let bombeo;
    [bombeo] = useOutletContext();

    const sections = [
        <BombeoGeneralDataSection bombeo={bombeo} />,
        <UpdateBombeoGeoPanel />,
        <EntityAuditSection entity={bombeo} />,
    ];

    return bombeo && <EntityViewSubPage sections={sections} service={BombeoService} />;
};

export default ViewBombeoSummarySubPage;
