import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {PageLayout} from "base/layout";
import {useStatsConfig} from "stats/provider";
import {StatsProvinceFilter} from "stats/presentational";
import {ViewStatsConcellosCargasChart, ViewStatsConcellosCargasMap} from ".";
import {SectionCard} from "base/component/presentational";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {useAppConfig} from "base/provider";

const ViewStatsConcellosCargasSubPage = () => {
    const {setPagePath} = useAppConfig();
    const {statsFilter, setStatsFilter} = useStatsConfig();
    const location = useLocation();
    const path = location.pathname
        .split("/")
        .slice(-1)
        .pop();

    useEffect(() => {
        setPagePath([
            {
                name: "Cargas",
                path,
            },
        ]);
        return () => {
            setPagePath([]);
        };
    }, []);

    const handleFilterChange = filter => {
        setStatsFilter(filter);
    };

    return (
        <PageLayout subPage={true}>
            <Stack spacing={2} sx={{width: "100%"}}>
                <StatsProvinceFilter
                    filter={statsFilter}
                    onChange={handleFilterChange}
                />
                <SectionCard title="Estadísticas de infraestructuras por totales">
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} md={8}>
                            <ViewStatsConcellosCargasMap statsFilter={statsFilter} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ViewStatsConcellosCargasChart statsFilter={statsFilter} />
                        </Grid>
                    </Grid>
                </SectionCard>
            </Stack>
        </PageLayout>
    );
};
export default ViewStatsConcellosCargasSubPage;
