class EDARs extends Array {}

const edar_api_adapter = edar => {
    if (edar["geom"]) {
        edar["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    edar["created_at"] = new Date(edar["created_at"]);
    edar["updated_at"] = new Date(edar["updated_at"]);
    return edar;
};

const edars_api_adapter = edars => edars.map(edar_api_adapter);

const createEDARs = (data = []) => {
    const edars = EDARs.from(data, edar => createEDAR(edar));
    return edars;
};

const edar_view_adapter = edar => {
    delete edar["aglomeracion_label"];
    delete edar["tipo_carga_label"];
    delete edar["orixe_datos_label"];
    delete edar["vertido_directo_label"];
    delete edar["problemas_label"];
    delete edar["conservacion_urbanizacion_label"];
    delete edar["conservacion_instalacions_label"];
    delete edar["arqueta_mostras_label"];
    delete edar["suministro_electrico_label"];
    delete edar["caudal_entrada_label"];
    delete edar["caudal_saida_label"];
    delete edar["instalacion_compacta_label"];
    delete edar["pretratamento_label"];
    delete edar["trat_primario_label"];
    delete edar["trat_terciario_label"];
    delete edar["trat_avanzado_label"];
    delete edar["lodos_tipo_tratamiento_label"];
    delete edar["lodos_orixe_label"];
    delete edar["lodos_destino_label"];

    delete edar["folder"];
    delete edar["featured_image"];
    delete edar["featured_document"];
    delete edar["created_at"];
    delete edar["created_by"];
    delete edar["updated_at"];
    delete edar["updated_by"];
    return edar;
};

const createEDAR = ({
    id = null,
    codigo = "",
    codigo_interno = null,
    nome = "",
    orixe_datos = null,
    orixe_datos_label = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    notas = null,
    vertido_directo = null,
    vertido_directo_label = null,
    habitantes_equivalentes_desenho = null,
    tipo_carga = null,
    tipo_carga_label = null,
    problemas = [],
    problemas_label = [],
    problemas_outro = null,
    punto_vertido = null,
    punto_vertido_label = null,
    conservacion_urbanizacion = null,
    conservacion_urbanizacion_label = null,
    conservacion_instalacions = null,
    conservacion_instalacions_label = null,
    arqueta_mostras = null,
    arqueta_mostras_label = null,
    suministro_electrico = null,
    suministro_electrico_label = null,
    caudal_entrada = null,
    caudal_entrada_label = null,
    caudal_saida = null,
    caudal_saida_label = null,
    instalacion_compacta = null,
    instalacion_compacta_label = null,
    pretratamento = [],
    pretratamento_label = [],
    trat_primario = [],
    trat_primario_label = [],
    trat_primario_lineas = null,
    trat_primario_outro = null,
    trat_secundario_extensivos = null,
    trat_secundario_extensivos_label = null,
    trat_secundario_pequenas = null,
    trat_secundario_pequenas_label = null,
    trat_secundario_outros = null,
    trat_secundario_outros_label = null,
    trat_secundario_lineas = null,
    trat_secundario_outrost = null,
    trat_terciario = null,
    trat_terciario_label = null,
    trat_avanzado = [],
    trat_avanzado_label = [],
    dixestion_anaerobia = null,
    dixestion_anaerobia_label = null,
    lodos_tipo_tratamiento = [],
    lodos_tipo_tratamiento_label = [],
    lodos_orixe = null,
    lodos_orixe_label = null,
    lodos_saida = null,
    lodos_edar_destino = null,
    lodos_destino = null,
    lodos_destino_label = null,
    lodos_xestor_destino = "",
    lodos_distancia_tratamiento = null,
    lodos_cantidade_alleo = null,
    caudal_medio_desenho = null,
    qp_pretrat_primario = null,
    qp_secundario = null,
    potencia = null,
    consumo_electrico = null,
    reactivos = null,
    residuos = null,
    rendemento_tratamento = null,
    rendemento_tratamento_label = null,
    rendemento_desinfeccion = null,
    rendemento_desinfeccion_label = null,
    telecontrol = null,
    telecontrol_label = null,
    ano_posta_marcha = null,
    antiguidade = null,
    antiguidade_label = null,
    reutilizacion = null,
    reutilizacion_label = null,
    pending_status = null,
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    masa_auga = null,
    masa_auga_label = null,
    sistema_explotacion = null,
    sistema_explotacion_label = null,
    stats = {},
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
    // list attributes
    tipotratamento = null,
    tipotratamento_label = null,
    urbanatotal = null,
    cargadomestica = null,
    carganondomestica = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        codigo_interno,
        nome,
        orixe_datos,
        orixe_datos_label,
        folder,
        featured_image,
        featured_document,
        geom,
        notas,
        vertido_directo,
        vertido_directo_label,
        habitantes_equivalentes_desenho,
        tipo_carga,
        tipo_carga_label,
        problemas,
        problemas_label,
        problemas_outro,
        punto_vertido,
        punto_vertido_label,
        conservacion_urbanizacion,
        conservacion_urbanizacion_label,
        conservacion_instalacions,
        conservacion_instalacions_label,
        instalacion_compacta_label,
        arqueta_mostras,
        arqueta_mostras_label,
        suministro_electrico,
        suministro_electrico_label,
        caudal_entrada,
        caudal_entrada_label,
        caudal_saida,
        caudal_saida_label,
        instalacion_compacta,
        pretratamento,
        pretratamento_label,
        trat_primario,
        trat_primario_label,
        trat_primario_lineas,
        trat_primario_outro,
        trat_secundario_extensivos,
        trat_secundario_extensivos_label,
        trat_secundario_pequenas,
        trat_secundario_pequenas_label,
        trat_secundario_outros,
        trat_secundario_outros_label,
        trat_secundario_lineas,
        trat_secundario_outrost,
        trat_terciario,
        trat_terciario_label,
        trat_avanzado,
        trat_avanzado_label,
        dixestion_anaerobia,
        dixestion_anaerobia_label,
        lodos_tipo_tratamiento,
        lodos_tipo_tratamiento_label,
        lodos_orixe,
        lodos_orixe_label,
        lodos_saida,
        lodos_edar_destino,
        lodos_destino,
        lodos_destino_label,
        lodos_xestor_destino,
        lodos_distancia_tratamiento,
        lodos_cantidade_alleo,
        caudal_medio_desenho,
        qp_pretrat_primario,
        qp_secundario,
        potencia,
        consumo_electrico,
        reactivos,
        residuos,
        rendemento_tratamento,
        rendemento_tratamento_label,
        rendemento_desinfeccion,
        rendemento_desinfeccion_label,
        telecontrol,
        telecontrol_label,
        ano_posta_marcha,
        antiguidade,
        antiguidade_label,
        reutilizacion,
        reutilizacion_label,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        masa_auga,
        masa_auga_label,
        sistema_explotacion,
        sistema_explotacion_label,
        stats,
        created_by,
        created_at,
        updated_by,
        updated_at,
        tipotratamento,
        tipotratamento_label,
        urbanatotal,
        cargadomestica,
        carganondomestica,
    };

    return Object.freeze(publicApi);
};

export {
    createEDAR as default,
    createEDARs,
    edar_api_adapter,
    edars_api_adapter,
    edar_view_adapter,
};
