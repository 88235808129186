import {
    BombeoFormCaracteristicasFields,
    BombeoFormGeneralDataFields,
    BombeoFormElementosFields,
    BombeoFormExplotacionFields,
} from ".";

const BombeoModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <BombeoFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <BombeoFormCaracteristicasFields layout="column" />;
    }
    if (section === "elementos") {
        return <BombeoFormElementosFields layout="column" />;
    }
    if (section === "explotacion") {
        return <BombeoFormExplotacionFields layout="column" />;
    }
    return null;
};

export default BombeoModificationForm;
