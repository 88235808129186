import {FieldUtil} from "base/utilities";

export function useVertidoData(vertido) {
    const getEntityData = vertido => {
        return {
            image: vertido?.featured_image,
            name: `Vertido ${vertido?.codigo || vertido?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(vertido?.codigo)],
                        [
                            "Código externo:",
                            FieldUtil.getValue(vertido?.codigo_externo),
                        ],
                        ["Concello:", FieldUtil.getValue(vertido?.concello_label)],
                        [
                            "Aglomeración:",
                            FieldUtil.getValue(vertido?.aglomeracion_label),
                        ],
                        ["Bacía:", FieldUtil.getValue(vertido?.masa_auga_label)],
                        [
                            "Sistema de explotación:",
                            FieldUtil.getValue(vertido?.sistema_explotacion_label),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                vertido?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(vertido?.geom.coordinates[1])}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Tipo de punto de vertido:",
                            FieldUtil.getValue(vertido?.tipo_punto_vertido_label),
                        ],
                        ["Tipo de vertido:", FieldUtil.getValue(vertido?.tipo_label)],
                        [
                            "Tipo de titular:",
                            FieldUtil.getValue(vertido?.tipo_titular_label),
                        ],
                    ],
                },
                {
                    head: "Censo nacional de vertidos",
                    content: [
                        [
                            "Características:",
                            FieldUtil.getValue(vertido?.caracteristicas),
                        ],
                        [
                            "Titular da autorización:",
                            FieldUtil.getValue(vertido?.autorizacion_titular),
                        ],
                        [
                            "X (CNV):",
                            FieldUtil.getValue(vertido?.x_cnv),
                            ["Y (CNV):", FieldUtil.getValue(vertido?.y_cnv)],
                        ],
                        ["Código CNAE:", FieldUtil.getValue(vertido?.codigo_cnae)],
                    ],
                },
                {
                    head: "Outras referencias",
                    content: [
                        ["Código UE:", FieldUtil.getValue(vertido?.codigo_ue)],
                        ["Nome UE:", FieldUtil.getValue(vertido?.nome_ue)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
