import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    MasaAugaDatosDescargaDomesticaSection,
    MasaAugaDatosDescargaUrbanaSection,
    MasaAugaDatosNonDescargaDomesticaSection,
} from "../presentational/section";

const ViewMasaAugaDatosDescargaSubPage = () => {
    let masaAuga;
    [masaAuga] = useOutletContext();

    const sections = [
        <MasaAugaDatosDescargaDomesticaSection masaAuga={masaAuga} />,
        <MasaAugaDatosNonDescargaDomesticaSection masaAuga={masaAuga} />,
        <MasaAugaDatosDescargaUrbanaSection masaAuga={masaAuga} />,
    ];

    return masaAuga && <EntityViewSubPage sections={sections} />;
};

export default ViewMasaAugaDatosDescargaSubPage;
