import {ViewConexionsSubPage} from "conexion/container";
import {useOutletContext} from "react-router-dom";

const ViewAbastecementoConexionsSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    return (
        <ViewConexionsSubPage
            concello={concello}
            rede="abastecemento"
            title="Conexions de abastecemento"
            layersConfig={{
                sistema: {},
                etap: {},
                deposito: {},
                captacion: {},
                tuberia: {visible: false},
            }}
        />
    );
};
export default ViewAbastecementoConexionsSubPage;
