import {AglomeracionFormSearch} from "../../../aglomeracion/presentational/form";
import {MasaAugaFormSearch} from "medioreceptor/masaauga/presentational/form";
import {FormInputText, FormSelect, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";
import {VertidoFormSearch} from "saneamiento/vertido/presentational/form";
import {ConcelloHiddenAuth} from "concello/auth";
import {ConcelloFormSearch} from "concello/presentational/form";
import {useDomain} from "domain/provider";

const EDARFormGeneralDataFields = ({creation = false, layout = "row"}) => {
    const {dominioedartipocarga} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome" label="Nome" />
            </Grid>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputText name="codigo" label="Código" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="codigo_interno" label="Código interno" />
                </Grid>
                {!creation && (
                    <Grid item xs={12}>
                        <ConcelloFormSearch
                            name="concello"
                            rules={{required: "O campo é obrigatorio"}}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <AglomeracionFormSearch name="aglomeracion" />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6}>
                <VertidoFormSearch name="punto_vertido" />
            </Grid>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <MasaAugaFormSearch name="masa_auga" />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="tipo_carga"
                    label="Tipo de carga"
                    options={dominioedartipocarga}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default EDARFormGeneralDataFields;
