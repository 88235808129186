import {useEDARMap} from "saneamiento/edar/hooks";
import {FormMapGeojsonPoint} from "base/component/form";
import {useOutletContext} from "react-router-dom";

const EDARFormLocationFields = ({isCreationForm = false}) => {
    let concello;
    [concello] = useOutletContext();

    const {mapConfig} = useEDARMap(isCreationForm ? concello : null);

    return (
        <FormMapGeojsonPoint
            name="geom"
            mapConfig={mapConfig}
            rules={{required: "O campo é obrigatorio"}}
        />
    );
};

export default EDARFormLocationFields;
