import {
    DepositoFormCaracteristicasFields,
    DepositoFormGeneralDataFields,
    DepositoFormMedidasFields,
} from ".";

const DepositoModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <DepositoFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <DepositoFormCaracteristicasFields layout="column" />;
    }
    if (section === "medidas") {
        return <DepositoFormMedidasFields layout="column" />;
    }
    return null;
};

export default DepositoModificationForm;
