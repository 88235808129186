import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    GobernanzaIntegridadeSection,
    GobernanzaCompromisoSection,
    GobernanzaEquidadeSection,
    GobernanzaMonitoreoAvaliacionSection,
} from "../presentational/section";

const ViewGobernanzaEficienciaSubPage = () => {
    let gobernanza;
    [gobernanza] = useOutletContext();

    const sections = [
        <GobernanzaIntegridadeSection gobernanza={gobernanza} />,
        <GobernanzaCompromisoSection gobernanza={gobernanza} />,
        <GobernanzaEquidadeSection gobernanza={gobernanza} />,
        <GobernanzaMonitoreoAvaliacionSection gobernanza={gobernanza} />,
    ];

    return gobernanza && <EntityViewSubPage sections={sections} />;
};

export default ViewGobernanzaEficienciaSubPage;
