import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloInventarioRexistroConDatosSection = ({concello}) => {
    return (
        <SectionCard title="Rexistros con datos">
            <Grid container>
                <SectionSubheading text="EDAR" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatInteger(
                            concello.stats.consumorexistrosdatosedar
                        )}
                        tooltipText="Número de edar con suministro electrico e rexistros con datos de consumo eléctrico."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatInteger(
                            concello.stats.potenciarexistrosdatosedar
                        )}
                        tooltipText="Número de edar con suministro electrico e rexistros con datos de potencia instalada"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Bombeos" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatInteger(
                            concello.stats.consumorexistrosdatosbombeo
                        )}
                        tooltipText="Número de bombeos con datos de consumo electrico."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatInteger(
                            concello.stats.potenciarexistrosdatosbombeo
                        )}
                        tooltipText="Numero de bombeos con datos de potencia"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Tanques" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatInteger(
                            concello.stats.consumorexistrosdatostanques
                        )}
                        tooltipText="Número de tanques con datos de consumo electrico"
                    />
                    <SectionField
                        label="Capacidade:"
                        value={NumberUtil.formatInteger(
                            concello.stats.potenciarexistrosdatostanques
                        )}
                        tooltipText="Número de tanques con datos de capacidade"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatInteger(
                            concello.stats.capacidadetanque
                        )}
                        tooltipText="Número de tanques con datos de potencia"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloInventarioRexistroConDatosSection;
