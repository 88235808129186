import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {EntityTable} from "base/entity";
import {ConexionService} from "conexion/service";
import {ListProvider} from "base/provider";
import {conexionElementTypesConstants} from "conexion/model";
import {ChangesAlert} from "changes/presentational";
import {useSisbagalAppConfig} from "sisbagal/provider";

function formatTipo(tipo) {
    switch (tipo) {
        case conexionElementTypesConstants.EDAR:
            return "EDAR";
        case conexionElementTypesConstants.NUCLEO:
            return "Núcleo";
        case conexionElementTypesConstants.DEPOSITO:
            return "Depósito";
        case conexionElementTypesConstants.CAPTACION:
            return "Captación";
        case conexionElementTypesConstants.ETAP:
            return "ETAP";
        default:
            return tipo;
    }
}

const tableColumns = [
    {
        id: "tipo_orixe",
        label: "Tipo orixe",
        width: 20,
        formatFunction: conexion => formatTipo(conexion.tipo_orixe),
    },
    {
        id: "nome_orixe",
        label: "Orixe",
        width: 20,
        formatFunction: conexion => conexion.nome_orixe || conexion.codigo_orixe,
    },
    {
        id: "tipo_destino",
        label: "Tipo destino",
        width: 20,
        formatFunction: conexion => formatTipo(conexion.tipo_destino),
    },
    {
        id: "nome_destino",
        label: "Destino",
        width: 20,
        formatFunction: conexion => conexion.nome_destino || conexion.codigo_destino,
    },
    {
        id: "porcentaxe",
        label: "Porcentaxe",
        width: 20,
    },
];

const ViewConexionsTable = ({concello, rede}) => {
    const navigate = useNavigate();
    const {consolidated} = useSisbagalAppConfig();

    const [selectedElement, setSelectedElement] = useState(null);

    const handleSelectElement = elementId => {
        setSelectedElement(elementId);
        navigate(`info/conexions/${elementId}`);
    };

    if (!consolidated) {
        const existControlChangesColumn = tableColumns.some(
            column => column.id === "pending_status"
        );
        if (!existControlChangesColumn) {
            tableColumns.push({
                id: "pending_status",
                label: "Control cambios",
                width: 5,
                formatFunction: element => (
                    <ChangesAlert status={element.pending_status} />
                ),
            });
        }
    } else {
        const controlChangesColumnIndex = tableColumns.findIndex(
            column => column.id === "pending_status"
        );
        if (controlChangesColumnIndex >= 0) {
            tableColumns.splice(controlChangesColumnIndex, 1);
        }
    }

    return (
        <ListProvider>
            <EntityTable
                columns={tableColumns}
                service={ConexionService}
                customPaginatedService={(filter, page, sort, order) =>
                    ConexionService.getPaginatedList(
                        {rede, concello: concello.codigo, ...filter},
                        page,
                        sort,
                        order
                    )
                }
                selectedElement={selectedElement}
                onSelectElement={handleSelectElement}
            />
        </ListProvider>
    );
};
export default ViewConexionsTable;
