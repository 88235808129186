import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SistemaExplotacionDatosDescargaUrbanaSection = ({sistemaExplotacion}) => {
    return (
        <SectionCard title="Urbana">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana rede saneamento:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanatotal
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR."
                    />
                    <SectionField
                        label="Urbana tratamento primario:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanatratamento1
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR con tratamento primario."
                    />
                    <SectionField
                        label="Urbana tratamento terciario:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanatratamento3
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR con tratamento terciario."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana tratamento deficiente:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanatratamento0
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR con tratamento non axeitado."
                    />
                    <SectionField
                        label="Urbana tratamento secundario:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanatratamento2
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR con tratamento secundario."
                    />
                    <SectionField
                        label="Urbana tratamento avanzado:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentourbanadesinfeccion
                        )}
                        unit="Heq."
                        tooltipText="Total de carga urbana descargada dende os puntos de vertido das EDAR con tratamento de avanzado."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SistemaExplotacionDatosDescargaUrbanaSection;
