import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloResumoSistemasImpactoSection = ({concello}) => {
    return (
        <SectionCard title="Impacto">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tratamento deficiente:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.conexiontratamento0,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana da rede de saneamento que recibe un tratamento deficiente"
                    />
                    <SectionField
                        label="Tratamento primario:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.conexiontratamento1,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana da rede de saneamento que recibe un tratamento primario"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tratamento secundario:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.conexiontratamento2,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana da rede de saneamento que recibe un tratamento secundario"
                    />
                    <SectionField
                        label="Tratamento terciario:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.conexiontratamento3,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana da rede de saneamento que recibe un tratamento terciario"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloResumoSistemasImpactoSection;
