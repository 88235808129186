import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {ColectorService} from "saneamiento/service";

const ViewColectorChangesSubPage = () => {
    let colector;
    [colector] = useOutletContext();

    return (
        colector && (
            <ViewEntityChangesSubPage
                entityService={ColectorService}
                entityType="colector"
                entityId={colector.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewColectorChangesSubPage;
