import {useDomain} from "domain/provider";
import {FormInputDecimal, FormInputInteger, FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";

const PoligonoFormSaneamentoFields = ({layout = "row"}) => {
    const {dominiosino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="grado_ocupacion"
                        label="Grado de ocupación"
                        endAdornment="%"
                        placeholder="0,00"
                        tooltipText="Porcentaxe de ocupación da área industrial"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="superficie"
                        label="Superficie"
                        endAdornment="ha"
                        placeholder="0,00"
                        tooltipText="Superficie da área industrial"
                    />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="saneamento_propio"
                    label="Saneamiento propio"
                    options={dominiosino}
                />
            </Grid>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="habitantes_equivalentes"
                        label="Habitantes equivalentes"
                        placeholder="0,00"
                        tooltipText="Carga xerada na área industrial"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="caudal_saneamento"
                        label="Caudal de saneamento"
                        endAdornment="l/s"
                        placeholder="0,00"
                        tooltipText="Caudal vertido a rede de saneamento na área industrial"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputInteger
                        name="carga"
                        label="Carga"
                        endAdornment="mg/l"
                        placeholder="0,00"
                        tooltipText="Carga de materia orgánica das augas residuais xeradas na área industrial"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="dotacion"
                        label="Dotación"
                        endAdornment="l/s / ha"
                        placeholder="0,00"
                        tooltipText="Dotación estimada na área industrial"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="caudal_abastecemento"
                        label="Caudal de abastecemento"
                        endAdornment="l/s"
                        placeholder="0,00"
                        tooltipText="Consumo de auga na área industrial"
                    />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default PoligonoFormSaneamentoFields;
