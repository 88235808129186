import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const MasaAugaPrincipalSection = ({masaAuga}) => {
    return (
        <SectionCard title="Datos principales">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Código:" value={masaAuga.codigo} />
                    <SectionField
                        label="Código europeo:"
                        value={masaAuga.codigo_europeo}
                    />
                    <SectionField
                        label="Categoría:"
                        value={masaAuga.categoria_label}
                        tooltipText="Categoria de bacía segundo plans hidrolóxicos."
                    />
                    <SectionField
                        label="Caudal medio anual:"
                        value={NumberUtil.formatDecimal(masaAuga.caudal_medio_anual)}
                        unit="l/s"
                        tooltipText="Caudal medio anual no punto de peche"
                    />
                    <SectionField
                        label="Caudal medio mínimo:"
                        value={NumberUtil.formatDecimal(masaAuga.caudal_medio_minimo)}
                        unit="l/s"
                        tooltipText="Caudal medio minimo anual no punto de peche"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estado global:"
                        value={masaAuga.estado_global_label}
                        tooltipText="Estado global da bacía segundo plans hidrolóxicos"
                    />
                    <SectionField
                        label="Estado ecolóxico:"
                        value={masaAuga.estado_ecoloxico_label}
                        tooltipText="Estado ecolóxico da bacía segundo plans hidrolóxicos"
                    />
                    <SectionField
                        label="Estado químico:"
                        value={masaAuga.estado_quimico_label}
                        tooltipText="Estado químico da bacía segundo plans hidrolóxicos"
                    />
                    <SectionField
                        label="Sistema explotación:"
                        value={masaAuga.sistema_explotacion_label}
                        linkPath={`/medioreceptor/sistemaexplotacion/${masaAuga.sistema_explotacion}`}
                        tooltipText="Sistema de explotación da bacía segundo plans hidrolóxicos"
                    />
                    <SectionField
                        label="Demarcación:"
                        value={masaAuga.demarcacion_label}
                        tooltipText="Demarcación hidrográfica da bacía"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default MasaAugaPrincipalSection;
