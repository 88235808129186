import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewCaptacionLocationSubPage = () => {
    let captacion;
    [captacion] = useOutletContext();

    const sections = [<EntityLocationSection geom={captacion.geom} />];

    return captacion && <EntityViewSubPage sections={sections} />;
};

export default ViewCaptacionLocationSubPage;
