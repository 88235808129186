import {useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import WaterIcon from "@mui/icons-material/Water";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import HideSourceIcon from "@mui/icons-material/HideSource";
import {useSisbagalAppConfig} from "sisbagal/provider";

const SisbagalSectorSelector = () => {
    const {
        consolidated,
        toggleConsolidated,
        toggleSaneamento,
        toggleAbastecemento,
        isSaneamentoVisible,
        isAbastecementoVisible,
    } = useSisbagalAppConfig();

    const [anchorEl, setAnchorEl] = useState(null);
    const openSectorMenu = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getTooltip = () => {
        let text = "";
        if (isSaneamentoVisible() && isAbastecementoVisible()) {
            text = "Saneamento + Abastecemento";
        } else if (isSaneamentoVisible()) {
            text = "Saneamento";
        } else if (isAbastecementoVisible()) {
            text = "Abastecemento";
        }
        text += !consolidated ? " (No consolidado)" : "";
        return text;
    };

    const getIcon = () => {
        if (isSaneamentoVisible() && isAbastecementoVisible()) {
            return (
                <>
                    <WaterIcon color={getConsolidatedColor()} />
                    <Typography style={{padding: 2, color: "lightgray"}}>+</Typography>
                    <InvertColorsIcon color={getConsolidatedColor()} />
                </>
            );
        } else if (isSaneamentoVisible()) {
            return <WaterIcon color={getConsolidatedColor()} />;
        } else if (isAbastecementoVisible()) {
            return <InvertColorsIcon color={getConsolidatedColor()} />;
        } else {
            return <HideSourceIcon color={getConsolidatedColor()} />;
        }
    };

    const getConsolidatedColor = () => {
        return consolidated ? "primary" : "error";
    };

    return (
        <div>
            <Tooltip title={getTooltip()}>
                <IconButton
                    onClick={handleClick}
                    sx={{
                        height: 40,
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: "primary.main",
                        ml: 2,
                        bgcolor: "white",
                        ":hover": {
                            bgcolor: "white", // theme.palette.primary.main
                            color: "primary.main",
                        },
                    }}
                    aria-controls={openSectorMenu ? "profile-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSectorMenu ? "true" : undefined}
                >
                    {getIcon()}
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openSectorMenu}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isSaneamentoVisible()}
                                onClick={() => {
                                    toggleSaneamento();
                                }}
                            />
                        }
                        label="Saneamento"
                    />
                </MenuItem>
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isAbastecementoVisible()}
                                onClick={() => {
                                    toggleAbastecemento();
                                }}
                            />
                        }
                        label="Abastecemento"
                    />
                </MenuItem>
                <Divider />
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={consolidated}
                                onClick={() => {
                                    toggleConsolidated();
                                }}
                            />
                        }
                        label={
                            <Typography color={getConsolidatedColor()}>
                                Consolidado
                            </Typography>
                        }
                    />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default SisbagalSectorSelector;
