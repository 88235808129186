import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionCargasDispersasSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`cargasdispersasdatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Cargas dispersas"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industria dispersa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.heq_industria_dispersa
                        )}
                        unit="Heq"
                        tooltipText="Carga industrial non localizada en áreas industriais"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.heq_outros_usos_non_domesticos
                        )}
                        unit="Heq"
                        tooltipText="Outras cargas non domésticas, tales como a xerada pola poboación flotante, dotacional etc."
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionCargasDispersasSection;
