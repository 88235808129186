import {FormInputDecimal, FormInputInteger} from "base/component/form";
import Grid from "@mui/material/Grid";

const BombeoFormCaracteristicasFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item container xs={12} md={6}>
                <FormInputDecimal
                    name="caudal_bombeo"
                    label="Caudal"
                    endAdornment="l/s."
                    placeholder="0,00"
                    tooltipText="Caudal de deseño ou caudal no punto de funcionamento"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormInputDecimal
                    name="altura_bombeo"
                    label="Altura Bombeo"
                    endAdornment="m.c.a"
                    placeholder="0,00"
                    tooltipText="Altura de deseño ou altura no punto de funcionamento"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormInputInteger
                    name="bombas_activas"
                    label="Bombas activas"
                    maxLength={3}
                    endAdornment="uds."
                    tooltipText="Número de bombas activas no punto de funcionamento"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormInputInteger
                    name="bombas_reserva"
                    label="Bombas reserva"
                    maxLength={3}
                    endAdornment="uds."
                    tooltipText="Número de bombas de reserva"
                />
            </Grid>
        </Grid>
    );
};

export default BombeoFormCaracteristicasFields;
