import {
    CaptacionService,
    DepositoService,
    ETAPService,
    SistemaService,
    TuberiaService,
} from "abastecemento/service";
import {
    useEditLayerProvider,
    useMapLayerProvider,
    useWMSMapLayerProvider,
} from "base/component/geo";
import {ConcelloService} from "concello/service";
import {SistemaExplotacionService} from "medioreceptor/service";
import MasaAugaService from "medioreceptor/service/MasaAugaService";
import {createContext, useContext, useEffect} from "react";
import {NucleoService} from "nucleo/service";
import {AglomeracionService} from "saneamiento/aglomeracion/service";
import {
    BombeoService,
    ColectorService,
    EDARService,
    PoligonoService,
    SubaglomeracionService,
    TanqueService,
    VertidoService,
} from "saneamiento/service";
import {ConexionService} from "conexion/service";
import {useLayer as useMasasAugaLayer} from "medioreceptor/masaauga/map";
import {useLayer as useSistemasExplotacionLayer} from "medioreceptor/sistemaexplotacion/map";
import {useLayer as useColectoresLayer} from "saneamiento/colector/map";
import {useLayer as useEDARsLayer} from "saneamiento/edar/map";
import {useLayer as useBombeosLayer} from "saneamiento/bombeo/map";
import {useLayer as useTanquesLayer} from "saneamiento/tanque/map";
import {useLayer as usePoligonosLayer} from "saneamiento/poligono/map";
import {useLayer as useVertidosLayer} from "saneamiento/vertido/map";
import {useLayer as useAglomeracionsLayer} from "saneamiento/aglomeracion/map";
import {useLayer as useSubaglomeracionsLayer} from "saneamiento/subaglomeracion/map";
import {useLayer as useSistemasLayer} from "abastecemento/sistema/map";
import {useLayer as useDepositosLayer} from "abastecemento/deposito/map";
import {useLayer as useCaptacionsLayer} from "abastecemento/captacion/map";
import {useLayer as useTuberiasLayer} from "abastecemento/tuberia/map";
import {useLayer as useETAPsLayer} from "abastecemento/etap/map";
import {useLayer as useNucleosLayer} from "nucleo/map";
import {useLayer as useConcellosLayer} from "concello/map";
import {useLayer as useConexionesLayer} from "conexion/map";
import {createTileLayerLegend, useEditLayerObject, useTileLayerObject} from "base/map";

let ListContext = createContext(null);

export default function MapProvider({
    config = null,
    geom = null,
    onMapClick = null,
    children,
}) {
    config = config === null ? {} : config;

    const concellosLayer = useConcellosLayer(
        config?.concello?.detail,
        config?.concello?.fitBounds
    );
    const [concelloMapProvider] = useMapLayerProvider(ConcelloService, concellosLayer, {
        ...config.concello,
        included: config.concello != null,
    });

    const nucleosLayer = useNucleosLayer(config?.nucleo?.detail);
    const [nucleoMapProvider] = useMapLayerProvider(NucleoService, nucleosLayer, {
        ...config.nucleo,
        included: config.nucleo != null,
    });

    const aglomeracionsLayer = useAglomeracionsLayer(
        config?.aglomeracion?.detail,
        config?.aglomeracion?.fitBounds
    );
    const [aglomeracionMapProvider] = useMapLayerProvider(
        AglomeracionService,
        aglomeracionsLayer,
        {
            ...config.aglomeracion,
            included: config.aglomeracion != null,
            sector: "saneamento",
        }
    );

    const subaglomeracionsLayer = useSubaglomeracionsLayer(
        config?.subaglomeracion?.detail
    );
    const [subaglomeracionMapProvider] = useMapLayerProvider(
        SubaglomeracionService,
        subaglomeracionsLayer,
        {
            ...config.subaglomeracion,
            included: config.subaglomeracion != null,
            sector: "saneamento",
        }
    );

    const conexionesLayer = useConexionesLayer(config?.conexion?.detail);
    const [conexionMapProvider] = useMapLayerProvider(
        ConexionService,
        conexionesLayer,
        {
            ...config.conexion,
            discriminator: conexionesLayer.legend.discriminators.PORCENTAXE,
            included: config.conexion != null,
        }
    );

    const edarsLayer = useEDARsLayer(config?.edar?.detail);
    const [edarMapProvider] = useMapLayerProvider(EDARService, edarsLayer, {
        discriminator: edarsLayer.legend.discriminators.NINGUNO,
        ...config.edar,
        included: config.edar != null,
        sector: "saneamento",
    });

    const tanquesLayer = useTanquesLayer(config?.tanque?.detail);
    const [tanqueMapProvider] = useMapLayerProvider(TanqueService, tanquesLayer, {
        ...config.tanque,
        included: config.tanque != null,
        sector: "saneamento",
    });

    const bombeosLayer = useBombeosLayer(config?.bombeo?.detail);
    const [bombeoMapProvider] = useMapLayerProvider(BombeoService, bombeosLayer, {
        ...config.bombeo,
        included: config.bombeo != null,
        sector: "saneamento",
    });

    const colectoresLayer = useColectoresLayer(config?.colector?.detail);
    const [colectorMapProvider] = useMapLayerProvider(
        ColectorService,
        colectoresLayer,
        {
            discriminator: colectoresLayer.legend.discriminators.NINGUNO,
            ...config.colector,
            included: config.colector != null,
            sector: "saneamento",
        }
    );

    const vertidosLayer = useVertidosLayer(config?.vertido?.detail);
    const [vertidoMapProvider] = useMapLayerProvider(VertidoService, vertidosLayer, {
        discriminator: vertidosLayer.legend.discriminators.NINGUNO,
        ...config.vertido,
        included: config.vertido != null,
        sector: "saneamento",
    });

    const poligonosLayer = usePoligonosLayer(config?.poligono?.detail);
    const [poligonoMapProvider] = useMapLayerProvider(PoligonoService, poligonosLayer, {
        ...config.poligono,
        included: config.poligono != null,
        sector: "saneamento",
    });

    const sistemasLayer = useSistemasLayer(config?.sistema?.detail);
    const [sistemaMapProvider] = useMapLayerProvider(SistemaService, sistemasLayer, {
        ...config.sistema,
        included: config.sistema != null,
        sector: "abastecemento",
    });

    const etapsLayer = useETAPsLayer(config?.etap?.detail);
    const [etapMapProvider] = useMapLayerProvider(ETAPService, etapsLayer, {
        discriminator: etapsLayer.legend.discriminators.NINGUNO,
        ...config.etap,
        included: config.etap != null,
        sector: "abastecemento",
    });

    const depositosLayer = useDepositosLayer(config?.deposito?.detail);
    const [depositoMapProvider] = useMapLayerProvider(DepositoService, depositosLayer, {
        discriminator: depositosLayer.legend.discriminators.NINGUNO,
        ...config.deposito,
        included: config.deposito != null,
        sector: "abastecemento",
    });

    const captacionsLayer = useCaptacionsLayer(config?.captacion?.detail);
    const [captacionMapProvider] = useMapLayerProvider(
        CaptacionService,
        captacionsLayer,
        {
            discriminator: captacionsLayer.legend.discriminators.NINGUNO,
            ...config.captacion,
            included: config.captacion != null,
            sector: "abastecemento",
        }
    );

    const tuberiasLayer = useTuberiasLayer(config?.tuberia?.detail);
    const [tuberiaMapProvider] = useMapLayerProvider(TuberiaService, tuberiasLayer, {
        ...config.tuberia,
        included: config.tuberia != null,
        sector: "abastecemento",
    });

    const sistemaExplotacionLayer = useSistemasExplotacionLayer(
        config?.sistemaExplotacion?.detail
    );
    const [sistemaExplotacionMapProvider] = useMapLayerProvider(
        SistemaExplotacionService,
        sistemaExplotacionLayer,
        {
            ...config.sistemaExplotacion,
            included: config.sistemaExplotacion != null,
        }
    );

    const masaAugaLayer = useMasasAugaLayer(config?.masaAuga?.detail);
    const [masaAugaMapProvider] = useMapLayerProvider(MasaAugaService, masaAugaLayer, {
        ...config.masaAuga,
        included: config.masaAuga != null,
    });

    /* WMS LAYERS */
    const riosLayer = useTileLayerObject({
        urlTemplate: "https://wms.mapama.gob.es/sig/Agua/RedHidrograficaMDT/wms.aspx",
        layers: "HY.PhysicalWaters.Waterbodies",
        opacity: 1,
        format: "image/png",
        otherLayerOptions: {
            transparent: "true",
        },
        legend: createTileLayerLegend({layerName: "Ríos"}),
    });
    const [riosLayerProvider] = useWMSMapLayerProvider(riosLayer, {
        ...config.rios,
        included: config.rios != null,
    });

    const zonasCaptacionLayer = useTileLayerObject({
        urlTemplate: "https://wms.mapama.gob.es/sig/agua/ZonasCaptacion/2019/wms.aspx",
        layers: "AM.SensitiveArea",
        opacity: 0.5,
        format: "image/png",
        otherLayerOptions: {
            transparent: "true",
        },
        legend: createTileLayerLegend({
            layerName: "Zonas de captación de zona sensible",
        }),
    });
    const [zonasCaptacionLayerProvider] = useWMSMapLayerProvider(zonasCaptacionLayer, {
        ...config.zonasCaptacion,
        included: config.zonasCaptacion != null,
    });

    const editLayer = useEditLayerObject({geom, onClick: onMapClick});
    const [editLayerProvider] = useEditLayerProvider(editLayer);

    useEffect(() => {
        // update edit layer when geom changed
        editLayer.updateLayer();
    }, [geom]);

    const layerProviders = {
        editLayerProvider,
        concelloMapProvider,
        nucleoMapProvider,
        aglomeracionMapProvider,
        subaglomeracionMapProvider,
        conexionMapProvider,
        edarMapProvider,
        tanqueMapProvider,
        bombeoMapProvider,
        colectorMapProvider,
        vertidoMapProvider,
        poligonoMapProvider,
        sistemaMapProvider,
        etapMapProvider,
        depositoMapProvider,
        captacionMapProvider,
        tuberiaMapProvider,
        sistemaExplotacionMapProvider,
        masaAugaMapProvider,
        riosLayerProvider,
        zonasCaptacionLayerProvider,
    };

    const otherLayers = {
        riosLayerProvider,
        zonasCaptacionLayer,
    };

    const getAllLayerProviders = () => {
        const includedLayers = Object.entries(layerProviders).map(
            ([k, layerProvider]) => layerProvider
        );
        return includedLayers;
    };

    const getIncludedLayerProviders = () => {
        return getAllLayerProviders().filter(
            layerProvider => layerProvider.included === true
        );
    };

    const getMenuSections = () => {
        return [
            {
                name: "Divisións administrativas",
                providers: [concelloMapProvider, nucleoMapProvider],
            },
            {
                name: "Saneamento",
                providers: [
                    aglomeracionMapProvider,
                    subaglomeracionMapProvider,
                    conexionMapProvider,
                    edarMapProvider,
                    tanqueMapProvider,
                    bombeoMapProvider,
                    colectorMapProvider,
                    vertidoMapProvider,
                    poligonoMapProvider,
                ],
            },
            {
                name: "Abastecemento",
                providers: [
                    sistemaMapProvider,
                    etapMapProvider,
                    depositoMapProvider,
                    captacionMapProvider,
                    tuberiaMapProvider,
                ],
            },
            {
                name: "Outras capas",
                providers: [
                    sistemaExplotacionMapProvider,
                    masaAugaMapProvider,
                    riosLayerProvider,
                    zonasCaptacionLayerProvider,
                ],
            },
        ];
    };

    return (
        <ListContext.Provider
            value={{
                ...layerProviders,
                getAllLayerProviders,
                getIncludedLayerProviders,
                getMenuSections,
            }}
        >
            {children}
        </ListContext.Provider>
    );
}

function useMapProvider() {
    return useContext(ListContext);
}

export {useMapProvider};
