import {createEntityService} from "base/entity/service";
import {
    createSistema,
    createSistemas,
    sistema_api_adapter,
    sistemas_api_adapter,
} from "abastecemento/model";

const entityService = createEntityService(
    "/api/infrastructures/sistemas",
    createSistema,
    createSistemas,
    sistema_api_adapter,
    sistemas_api_adapter
);

const SistemaService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(sistema) {
        return entityService.create(sistema);
    },

    update(sistema) {
        return entityService.update(sistema);
    },

    updateWithPatch(sistema) {
        return entityService.updateWithPatch(sistema);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default SistemaService;
