import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {PageLayout} from "base/layout";
import {PerdidasSubPageMenu} from "../menu";
import {PerdidasService} from "abastecemento/service";
import {useAppConfig} from "base/provider";

const ViewPoligonoPage = () => {
    const {setSubpagePath} = useAppConfig();

    const {codigo} = useParams();
    const [perdidas, setPerdidas] = useState(null);
    const location = useLocation();

    useEffect(() => {
        PerdidasService.get(codigo).then(data => {
            setPerdidas(data);
            setSubpagePath([
                {
                    name: "Datos de indicadores",
                    path: "datos/perdidas",
                },
            ]);
        });
        return () => {
            setPerdidas(null);
            setSubpagePath([]);
        };
    }, [codigo, location.state?.lastRefreshDate]);

    return (
        perdidas && (
            <PageLayout
                menu={<PerdidasSubPageMenu perdidas={perdidas} />}
                context={[perdidas]}
                subPage={true}
            />
        )
    );
};

export default ViewPoligonoPage;
