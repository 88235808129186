import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";
import {ResultsSummaryList} from "base/component/presentational";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaSummaryEficaciaSection = ({gobernanza}) => {
    const {
        getDimensionEvaluationLabel,
        getDimensionEvaluationColor,
        getPrincipiosEvaluationColor,
    } = useGobernanzaEvaluation();

    const basePath = `/concellos/${gobernanza.concello}/gobernanza`;

    const listItems = [
        {
            label: "Roles e responsabilidades claros:",
            to: `${basePath}/eficacia`,
            total: gobernanza.total_roles_responsibilidades,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_roles_responsibilidades,
                "medium"
            ),
        },
        {
            label: "Consideración dos sistemas de conca:",
            to: `${basePath}/eficacia#2`,
            total: gobernanza.total_consideracion_sistemas,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_consideracion_sistemas,
                "high"
            ),
        },
        {
            label: "Coherencia de políticas:",
            to: `${basePath}/eficacia#3`,
            total: gobernanza.total_coherencia_politicas,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_coherencia_politicas,
                "low"
            ),
        },
        {
            label: "Capacitación e medios:",
            to: `${basePath}/eficacia#4`,
            total: gobernanza.total_capacitacion_medios,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_capacitacion_medios,
                "medium"
            ),
        },
    ];

    return (
        <SisbagalSectionCard title="Eficacia">
            <ResultsSummaryList
                listItems={listItems}
                totalValue={gobernanza.total_eficacia}
                totalLabel={getDimensionEvaluationLabel(gobernanza.total_eficacia)}
                getChipColor={getDimensionEvaluationColor}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaSummaryEficaciaSection;
