import {NO_CONTENT_HEIGHT, SUBPAGE_MENU_DRAWER_WIDTH} from "base/config/measurements";

import {SubPagePageMenuHeading} from "base/component/presentational";

import useTheme from "@mui/material/styles/useTheme";

import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";

const SubPageMenu = ({
    headingPrimaryText = "",
    headingSecondaryText = "",
    headingInfo = null,
    children,
}) => {
    const theme = useTheme();

    const menuStyles = {
        zIndex: 1,
        width: `${SUBPAGE_MENU_DRAWER_WIDTH}px`,
        minHeight: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
        bgcolor: theme.palette.menu.secondary.background,
    };

    return (
        <Box component="nav" sx={menuStyles}>
            <MenuList sx={{color: "white"}} dense disablePadding>
                <SubPagePageMenuHeading
                    headingPrimaryText={headingPrimaryText || "-"}
                    headingSecondaryText={headingSecondaryText}
                    isSubMenu={true}
                >
                    {headingInfo}
                </SubPagePageMenuHeading>

                {children}
            </MenuList>
        </Box>
    );
};

export default SubPageMenu;
