import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SubaglomeracionPoboacionConectadaSection = ({subaglomeracion}) => {
    return (
        <SectionCard title="Conectada">
            <Grid container>
                <SectionSubheading text="Total" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.cargadomestica
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe doméstica a partires de datos do estudo de segundas vivendas e prazas turísticas."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Non doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.carganondomestica
                        )}
                        unit="Heq"
                        tooltipText="Outras cargas de orixe non doméstico"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Doméstica" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fixa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.cargadomesticafixa
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe doméstica a partires de datos do padrón continuo."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacional:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.cargadomesticaestacional
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe doméstica a partires de datos do estudo de segundas vivendas e prazas turísticas."
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Non doméstica" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.carganondomesticaindustrial
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe industrial"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimalAsInteger(
                            subaglomeracion.stats.carganondomesticaoutros
                        )}
                        unit="Heq"
                        tooltipText="Outras cargas de orixe non doméstico"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SubaglomeracionPoboacionConectadaSection;
