import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";
import {NumberUtil} from "base/utilities";

const markerBaseOptions = {
    marker: "l",
    fontSize: 35,
    addStroke: false,
    weight: 2,
    color: "#ffd67e",
    opacity: 1,
    lineJoin: "round",
    pane: mapOverlayPanes[3],
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    FLUXO: "fluxo",
    TIPO: "tipo",
});

export const LayerLegend = createLayerLegend({
    layerName: "Colectores",
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.FLUXO,
            text: "Fluxo",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "Gravedad",
                    filterFn: val => val === 1,
                    markerOptions: markerBaseOptions,
                },
                {
                    text: "Impulsión",
                    filterFn: val => val === 2,
                    markerOptions: {
                        ...markerBaseOptions,
                        color: "#ffb296",
                        dashArray: "4,4",
                    },
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val !== 1 && val !== 2,
                    markerOptions: {...markerBaseOptions, color: "#ffdfd3"},
                },
            ],
        },
        {
            field: discriminators.TIPO,
            text: "Natureza",
            defaultIconOptions: markerBaseOptions,
            // 1 Unitario
            // 2 Pluviales
            // 3 Fecales
            entries: [
                {
                    text: "Unitario",
                    filterFn: val => val === 1,
                    markerOptions: {
                        ...markerBaseOptions,
                        color: "#FFC547",
                    },
                },
                {
                    text: "Pluviales",
                    filterFn: val => val === 2,
                    markerOptions: {
                        ...markerBaseOptions,
                        color: "#FFBE33",
                        dashArray: "4,4",
                    },
                },
                {
                    text: "Fecales",
                    filterFn: val => val === 3,
                    markerOptions: {
                        ...markerBaseOptions,
                        color: "#FFD885",
                        dashArray: "8,8",
                    },
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val !== 1 && val !== 2,
                    markerOptions: {...markerBaseOptions, color: "#ffdfd3"},
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Colector: ${
                feature.id ? feature.id : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Aglomeración</i>: ${
                data["aglomeracion_label"] ? data["aglomeracion_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Fluxo</i>: ${
                data["fluxo_label"] ? data["fluxo_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Diámetro</i>: ${
                "diametro" in data && data["diametro"] !== null
                    ? NumberUtil.formatDecimal(data["diametro"])
                    : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/colectores/${feature.id}`),
    });
}
