import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewAglomeracionDocumentsSubPage = () => {
    let aglomeracion;
    [aglomeracion] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/aglomeracions/")[0];

    return (
        aglomeracion && (
            <ViewDocumentsSubPage
                entity={aglomeracion}
                basePath={`${basePath}/aglomeracions`}
            />
        )
    );
};

export default ViewAglomeracionDocumentsSubPage;
