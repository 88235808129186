import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormSelect} from "base/component/form";
import {StatsFilterLayout} from "stats/menu";
import {ProvinciaService} from "concello/service";

const StatsFilter = ({sector, filter, onChange = null, showProvincia = true}) => {
    const [provincias, setProvincias] = useState(null);

    const formMethods = useForm({
        defaultValues: {
            infrastructure_type: filter?.infrastructure_type || "",
            provincia: filter?.provincia || "",
            concello: filter?.concello || "",
        },
    });

    useEffect(() => {
        formMethods.reset();
    }, [sector]);

    useEffect(() => {
        Promise.all([ProvinciaService.getList()]).then(([provincias]) => {
            setProvincias(
                provincias.map(provincia => {
                    return {value: provincia.id, label: provincia.nome};
                })
            );
        });
    }, []);

    const handleChange = value => {
        onChange(formMethods.getValues());
    };

    const getInfrastructureTypeOptions = sector => {
        if (sector === "abastecemento") {
            return [
                {value: "sistemas", label: "Sistemas PMAA"},
                {value: "etaps", label: "ETAP"},
                {value: "depositos", label: "Depósitos"},
                {value: "captacions", label: "Captacións"},
            ];
        }
        if (sector === "saneamento") {
            return [
                {value: "edars", label: "EDAR"},
                {value: "tanques", label: "Tanques"},
                {value: "bombeos", label: "Bombeos"},
            ];
        }
        return [];
    };

    let filterAttributes = [
        <FormSelect
            name="infrastructure_type"
            label="Tipo de infraestructura"
            options={getInfrastructureTypeOptions(sector)}
            onChangeHandler={handleChange}
            showEmptyOption={false}
        />,
    ];

    if (showProvincia) {
        filterAttributes.push(
            <FormSelect
                name="provincia"
                label="Provincia"
                options={provincias}
                onChangeHandler={handleChange}
                showEmptyOption={true}
            />
        );
    }

    return (
        <FormProvider {...formMethods}>
            <StatsFilterLayout filterAttributes={filterAttributes}></StatsFilterLayout>
        </FormProvider>
    );
};

export default StatsFilter;
