import {createEntityService} from "base/entity/service";
import {AuthApiService} from "base/service";
import {ServiceUtil} from "base/utilities";
import {createNucleo, nucleo_api_adapter} from "nucleo/model";

const entityService = createEntityService(
    "/api/infrastructures/nucleos",
    createNucleo,
    null,
    nucleo_api_adapter,
    null
);

const NucleoService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    get(id) {
        return entityService.get(id);
    },

    getStatsList(filter, page = null, sort = null, order = null) {
        return AuthApiService.get(
            "/api/infrastructures/nucleos/stats/list" +
                `?${ServiceUtil.getQueryString(page, filter, sort, order)}`
        ).then(response => {
            return response;
        });
    },
};

export default NucleoService;
