import {createEntityService} from "base/entity/service";

const entityService = createEntityService("/api/infrastructures/provincias");

const ProvinciaService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },
};

export default ProvinciaService;
