import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {FormUtil} from "base/utilities";

import {EntityForm} from "base/component/form";
import {TanqueCreationForm, TanqueModificationForm, TanqueGeoForm} from ".";

const TanqueForm = ({
    tanque = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(tanque?.id),
        codigo: FormUtil.getFormValue(tanque?.codigo),
        nome: FormUtil.getFormValue(tanque?.nome),
        notas: FormUtil.getFormValue(tanque?.notas),
        potencia: FormUtil.getFormValue(tanque?.potencia),
        consumo_electrico: FormUtil.getFormValue(tanque?.consumo_electrico),
        problemas: FormUtil.getFormValue(tanque?.problemas, []),
        capacidade: FormUtil.getFormValue(tanque?.capacidade),
        pretratamiento: FormUtil.getFormValue(tanque?.pretratamiento, []),
        regulacion: FormUtil.getFormValue(tanque?.regulacion, []),
        tratamento_aliviadoiro: FormUtil.getFormValue(
            tanque?.tratamento_aliviadoiro,
            []
        ),
        sistema_limpeza: FormUtil.getFormValue(tanque?.sistema_limpeza, []),
        telecontrol: FormUtil.getFormValue(tanque?.telecontrol),
        // we must create an object with the same representation as expected search results
        bombeo: tanque?.bombeo ? {id: tanque.bombeo, nome: tanque?.bombeo_label} : null,
        aglomeracion: tanque?.aglomeracion
            ? {id: tanque.aglomeracion, nome: tanque?.aglomeracion_label}
            : null,
        concello: tanque?.concello
            ? {codigo: tanque.concello, nome: tanque?.concello_label}
            : {codigo: concello, nome: ""},
        geom: FormUtil.getFormValue(tanque?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            nome: FormUtil.getDataValue(data.nome),
            notas: FormUtil.getDataValue(data.notas),
            potencia: FormUtil.getDataValue(data.potencia),
            consumo_electrico: FormUtil.getDataValue(data.consumo_electrico),
            problemas: FormUtil.getDataValue(data.problemas),
            capacidade: FormUtil.getDataValue(data.capacidade),
            altura_tanque: FormUtil.getDataValue(data.altura_tanque),
            pretratamiento: FormUtil.getDataValue(data.pretratamiento),
            regulacion: FormUtil.getDataValue(data.regulacion),
            tratamento_aliviadoiro: FormUtil.getDataValue(data.tratamento_aliviadoiro),
            sistema_limpeza: FormUtil.getDataValue(data.sistema_limpeza),
            telecontrol: FormUtil.getDataValue(data.telecontrol),
            bombeo: FormUtil.getDataValue(data.bombeo?.id),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <TanqueGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <TanqueModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <TanqueCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default TanqueForm;
