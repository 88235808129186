import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SubaglomeracionInventarioBombeosSection,
    SubaglomeracionInventarioRexistroConDatosSection,
    SubaglomeracionInventarioTanquesSection,
    SubaglomeracionInventarioTotalSection,
} from "../presentational/section";

const ViewSubaglomeracionInventarioSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionInventarioTotalSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionInventarioBombeosSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionInventarioTanquesSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionInventarioRexistroConDatosSection
            subaglomeracion={subaglomeracion}
        />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionInventarioSubPage;
