import {
    SubaglomeracionFormGeneralDataFields,
    SubaglomeracionFormProblemasFields,
    SubaglomeracionFormRedeDatosFields,
    SubaglomeracionFormRedeMateriaisFields,
    SubaglomeracionFormRedeNaturezaFields,
    SubaglomeracionFormExplotacionFields,
    SubaglomeracionFormCargasDispersasFields,
    SubaglomeracionFormCargasDispersasNonConectadaFields,
} from ".";

const SubaglomeracionModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <SubaglomeracionFormGeneralDataFields layout="column" />;
    }
    if (section === "rededatos") {
        return <SubaglomeracionFormRedeDatosFields layout="column" />;
    }
    if (section === "redenatureza") {
        return <SubaglomeracionFormRedeNaturezaFields layout="column" />;
    }
    if (section === "redemateriais") {
        return <SubaglomeracionFormRedeMateriaisFields layout="column" />;
    }
    if (section === "explotaciondatos") {
        return <SubaglomeracionFormExplotacionFields layout="column" />;
    }
    if (section === "problemasdatos") {
        return <SubaglomeracionFormProblemasFields layout="column" />;
    }
    if (section === "cargasdispersasdatos") {
        return <SubaglomeracionFormCargasDispersasFields layout="column" />;
    }
    if (section === "cargasdispersasnonconectada") {
        return <SubaglomeracionFormCargasDispersasNonConectadaFields layout="column" />;
    }
    return null;
};

export default SubaglomeracionModificationForm;
