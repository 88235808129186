import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    ConcelloResumoSistemasCargasSection,
    ConcelloResumoSistemasImpactoSection,
    ConcelloResumoSistemasPrincipalSection,
} from "../presentational/section";

const ViewConcelloResumoSistemasSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    const sections = [
        <ConcelloResumoSistemasPrincipalSection concello={concello} />,
        <ConcelloResumoSistemasCargasSection concello={concello} />,
        <ConcelloResumoSistemasImpactoSection concello={concello} />,
    ];

    return concello && <EntityViewSubPage sections={sections} />;
};

export default ViewConcelloResumoSistemasSubPage;
