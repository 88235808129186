import {Route} from "react-router-dom";
import {
    CreateTanquePage,
    ListTanquePage,
    ViewTanquePanel,
    ViewTanquePage,
    ViewTanqueLocationSubPage,
    ViewTanqueDocumentsSubPage,
    ViewTanqueSummarySubPage,
    ViewTanqueDetailSubPage,
    UpdateTanquePanel,
    ManageTanquePage,
    ViewTanqueDocumentPanel,
    ViewTanqueChangesSubPage,
} from "saneamiento/tanque/container";

const tanqueRoutes = [
    <Route key="tanque-new" path="tanques/new" element={<CreateTanquePage />} />,
    <Route key="tanque-manage" path="tanques" element={<ManageTanquePage />}>
        <Route key="tanque-list" path="" element={<ListTanquePage />}>
            <Route
                key="tanque-info"
                path="info/:idTanque"
                element={<ViewTanquePanel />}
            />
        </Route>
        <Route key="tanque-detail" path=":idTanque" element={<ViewTanquePage />}>
            <Route
                key="tanque-location"
                path="location"
                element={<ViewTanqueLocationSubPage />}
            >
                <Route
                    key="tanque-location-edit"
                    path=":section/:action"
                    element={<UpdateTanquePanel />}
                />
            </Route>
            <Route
                key="tanque-documents"
                path="documents/*"
                element={<ViewTanqueDocumentsSubPage />}
            >
                <Route
                    key="tanque-documents-view"
                    path="detail/*"
                    element={<ViewTanqueDocumentPanel />}
                />
            </Route>
            <Route
                key="tanque-summary"
                path="summary"
                element={<ViewTanqueSummarySubPage />}
            >
                <Route
                    key="tanque-general-edit"
                    path=":section/:action"
                    element={<UpdateTanquePanel />}
                />
            </Route>
            <Route
                key="tanque-detail"
                path="detail"
                element={<ViewTanqueDetailSubPage />}
            >
                <Route
                    key="tanque-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateTanquePanel />}
                />
            </Route>
            <Route
                key="tanque-changes"
                path="changes"
                element={<ViewTanqueChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default tanqueRoutes;
