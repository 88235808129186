import {PageLayout} from "base/layout";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMap} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";

const ViewMedioReceptorPage = () => {
    // TODO: Improve to include layers in provider without config
    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    sistemaExplotacion: {},
                    masaAuga: {
                        visible: false,
                    },
                    rios: {
                        visible: false,
                    },
                    zonasCaptacion: {
                        visible: false,
                    },
                }}
            >
                <PageLayout>
                    <SisbagalMap legend={<SisbagalMapMenu />} />
                </PageLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewMedioReceptorPage;
