import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {DepositoService} from "abastecemento/service";
import {useDepositoData} from "../hooks/DepositoData";
import {PageLayout} from "base/layout";
import {DepositoSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewDepositoPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();

    const {idDeposito} = useParams();
    const [deposito, setDeposito] = useState(null);
    const location = useLocation();
    const {getEntityData} = useDepositoData();

    useEffect(() => {
        DepositoService.get(idDeposito).then(data => {
            setDeposito(data);
            setSubpagePath([
                {name: "Listado de Depósitos", path: "depositos"},
                {
                    name: data.nome || data.codigo || data.id,
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setDeposito(null);
            setSubpagePath([]);
            setModuleActions([]);
        };
    }, [idDeposito, location.state?.lastRefreshDate]);

    return (
        deposito && (
            <PageLayout
                menu={<DepositoSubPageMenu deposito={deposito} />}
                context={[deposito]}
                subPage={true}
            />
        )
    );
};

export default ViewDepositoPage;
