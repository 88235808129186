import {useNavigate} from "react-router-dom";
import {NumberUtil} from "base/utilities";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const BombeoExplotacionSection = ({bombeo}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`explotacion/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Explotación" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Horas de funcionamento:"
                        value={NumberUtil.formatDecimal2(
                            bombeo.horas_funcionamento_diarias
                        )}
                        unit="h"
                        tooltipText="Horas de funcionamento diarias"
                    />
                    <SectionField
                        label="Caudal medio anual:"
                        value={NumberUtil.formatDecimal2(bombeo.caudal_medio_anual)}
                        unit="m³/ano"
                        tooltipText="Caudal anual bombeado"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal2(bombeo.consumo_electrico)}
                        unit="kWh/ano"
                        tooltipText="Consumo eléctrico anual"
                    />
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatDecimal2(bombeo.potencia)}
                        unit="kW"
                        tooltipText="Potencia eléctrica instalada"
                    />
                    <SectionField label="Problemas:" value={bombeo.problemas_label} />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default BombeoExplotacionSection;
