import {useNavigate} from "react-router-dom";

import {
    SectionCard,
    SectionCardHeaderAction,
    SectionField,
} from "base/component/presentational";
import {
    FeaturedDocumentDownload,
    ImagePreview,
} from "document/component/presentational";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import {useAuth} from "base/auth";

const EntityGeneralDataSection = ({
    featured_image = null,
    featured_document = null,
    name,
    sections = [],
}) => {
    const navigate = useNavigate();
    const {ROLES, hasRole} = useAuth();

    const sectionFields =
        sections.length !== 0
            ? sections.map((section, index) => (
                  <SectionField
                      key={index}
                      label={`${section.label}:`}
                      value={section.value}
                      unit={section.unit}
                      editButton={section.edit_button}
                      linkPath={section.linkPath}
                      tooltipText={section.tooltipText}
                  />
              ))
            : null;

    return (
        <SectionCard
            headingLabel={false}
            secondaryActions={
                !hasRole(ROLES.AUDITOR)
                    ? [
                          <SectionCardHeaderAction
                              key="edit"
                              name="edit"
                              text="Modificar"
                              icon={<EditIcon />}
                              onClick={() => {
                                  navigate(`generaldata/edit`);
                              }}
                              roles={[]}
                          />,
                      ]
                    : []
            }
        >
            <Grid container columnSpacing={2}>
                <Grid item container md={4} xl={3}>
                    <Grid item>
                        <Stack spacing={2} alignItems="flex-start">
                            <div style={{position: "relative"}}>
                                <ImagePreview
                                    path={featured_image}
                                    alt={name}
                                    sx={{
                                        borderRadius: 1,
                                    }}
                                />
                            </div>
                            {featured_document && (
                                <FeaturedDocumentDownload
                                    featuredDocument={featured_document}
                                />
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container md={8} xl={9} alignContent="flex-start">
                    <Typography
                        variant="h4"
                        color="grey.700"
                        pb={2}
                        sx={{fontWeight: "bold"}}
                    >
                        {name}
                    </Typography>
                    <Grid item xs={12}>
                        {sectionFields}
                    </Grid>
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default EntityGeneralDataSection;
