import {FormSelect, FormSelectMultiple} from "base/component/form";

import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const EDARFormTratamentoTerciarioFields = ({layout = "row"}) => {
    const {dominioedartratnutrientes, dominioedarterciario} = useDomain();
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelect
                    name="trat_terciario"
                    label="Tipo de tratamento terciario"
                    options={dominioedartratnutrientes}
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelectMultiple
                    name="trat_avanzado"
                    label="Tipo de tratamento avanzado"
                    options={dominioedarterciario}
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormTratamentoTerciarioFields;
