import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

export const LayerLegend = createLayerLegend({
    layerName: "Sistemas colectores",
    menuIconShape: "s",
    defaultMarkerOptions: {
        marker: "l",
        fontSize: 35,
        addStroke: true,
        weight: 2,
        color: "#bd0012",
        fillOpacity: 0.3,
        opacity: 1,
        lineJoin: "round",
        pane: mapOverlayPanes[2],
    },
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Sistema colector</b><ul class="attributes">`;
            tooltip += `<li><i>Concello</i>: ${
                data["concello_label"] ? data["concello_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Aglomeración</i>: ${
                data["aglomeracion_label"] ? data["aglomeracion_label"] : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        getCustomMarkerOptions: ({options}) =>
            detail ? {...options, weight: 3, opacity: 1} : options,
        defaultOnClick: ({feature}) => navigate(`info/subaglomeracions/${feature.id}`),
        onMouseOver: ({layer}) =>
            layer.setStyle({
                weight: 4,
                fillOpacity: 0.6,
            }),
        onMouseOut: ({layer}) =>
            layer.setStyle({
                weight: 2,
                fillOpacity: 0.3,
            }),
    });
}
