import {createEntityService} from "base/entity/service";

const entityService = createEntityService("/api/infrastructures/sistemasexplotacion");

const SistemaExplotacionService = {
    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },
};

export default SistemaExplotacionService;
