import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {createSubaglomeracion} from "saneamiento/model";
import {EntityForm} from "base/component/form";

import {SubaglomeracionCreationForm, SubaglomeracionModificationForm} from ".";
import {FormUtil} from "base/utilities";

const SubaglomeracionForm = ({
    subaglomeracion = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(subaglomeracion?.id),
        nome: FormUtil.getFormValue(subaglomeracion?.nome),
        notas: FormUtil.getFormValue(subaglomeracion?.notas),
        poboacion_saneada_abastecida: FormUtil.getFormValue(
            subaglomeracion?.poboacion_saneada_abastecida
        ),
        caudal_abastecemento: FormUtil.getFormValue(
            subaglomeracion?.caudal_abastecemento
        ),
        antiguidade_colectores: FormUtil.getFormValue(
            subaglomeracion?.antiguidade_colectores
        ),
        antiguidade_sumidoiros: FormUtil.getFormValue(
            subaglomeracion?.antiguidade_sumidoiros
        ),
        telecontrol: FormUtil.getFormValue(subaglomeracion?.telecontrol),
        plan_mantemento: FormUtil.getFormValue(subaglomeracion?.plan_mantemento),
        manual_explotacion: FormUtil.getFormValue(subaglomeracion?.manual_explotacion),
        limpeza_colectores: FormUtil.getFormValue(subaglomeracion?.limpeza_colectores),
        control_alivios: FormUtil.getFormValue(subaglomeracion?.control_alivios),
        desbordamento: FormUtil.getFormValue(subaglomeracion?.desbordamento),
        augas_brancas: FormUtil.getFormValue(subaglomeracion?.augas_brancas),
        reutilizacion_outras_instalacions: FormUtil.getFormValue(
            subaglomeracion?.reutilizacion_outras_instalacions
        ),
        material_plastico: FormUtil.getFormValue(subaglomeracion?.material_plastico),
        material_formigon: FormUtil.getFormValue(subaglomeracion?.material_formigon),
        material_fibrocemento: FormUtil.getFormValue(
            subaglomeracion?.material_fibrocemento
        ),
        natureza_pluviais: FormUtil.getFormValue(subaglomeracion?.natureza_pluviais),
        natureza_fecais: FormUtil.getFormValue(subaglomeracion?.natureza_fecais),
        natureza_unitarios: FormUtil.getFormValue(subaglomeracion?.natureza_unitarios),
        problema_sen_datos: FormUtil.getFormValue(subaglomeracion?.problema_sen_datos),
        problema_sen_problema: FormUtil.getFormValue(
            subaglomeracion?.problema_sen_problema
        ),
        problema_infiltracion_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_infiltracion_frecuencia
        ),
        problema_infiltracion_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_infiltracion_alcance
        ),
        problema_augamar_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_augamar_frecuencia
        ),
        problema_augamar_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_augamar_alcance
        ),
        problema_augablanca_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_augablanca_frecuencia
        ),
        problema_augablanca_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_augablanca_alcance
        ),
        problema_contrapendente_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_contrapendente_frecuencia
        ),
        problema_contrapendente_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_contrapendente_alcance
        ),
        problema_rotura_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_rotura_frecuencia
        ),
        problema_rotura_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_rotura_alcance
        ),
        problema_atasco_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_atasco_frecuencia
        ),
        problema_atasco_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_atasco_alcance
        ),
        problema_outro: FormUtil.getFormValue(subaglomeracion?.problema_outro),
        problema_outro_frecuencia: FormUtil.getFormValue(
            subaglomeracion?.problema_outro_frecuencia
        ),
        problema_outro_alcance: FormUtil.getFormValue(
            subaglomeracion?.problema_outro_alcance
        ),
        heq_industria_dispersa: FormUtil.getFormValue(
            subaglomeracion?.heq_industria_dispersa
        ),
        heq_outros_usos_non_domesticos: FormUtil.getFormValue(
            subaglomeracion?.heq_outros_usos_non_domesticos
        ),
        saneamento_autonomo: FormUtil.getFormValue(
            subaglomeracion?.saneamento_autonomo
        ),
        domestica_sen_tramento: FormUtil.getFormValue(
            subaglomeracion?.domestica_sen_tramento
        ),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        const subaglomeracionToSave = createSubaglomeracion({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            nome: FormUtil.getDataValue(data.nome),
            notas: FormUtil.getDataValue(data.notas),
            poboacion_saneada_abastecida: FormUtil.getDataValue(
                data.poboacion_saneada_abastecida
            ),
            caudal_abastecemento: FormUtil.getDataValue(data.caudal_abastecemento),
            antiguidade_colectores: FormUtil.getDataValue(data.antiguidade_colectores),
            antiguidade_sumidoiros: FormUtil.getDataValue(data.antiguidade_sumidoiros),
            telecontrol: FormUtil.getDataValue(data.telecontrol),
            plan_mantemento: FormUtil.getDataValue(data.plan_mantemento),
            manual_explotacion: FormUtil.getDataValue(data.manual_explotacion),
            limpeza_colectores: FormUtil.getDataValue(data.limpeza_colectores),
            control_alivios: FormUtil.getDataValue(data.control_alivios),
            desbordamento: FormUtil.getDataValue(data.desbordamento),
            augas_brancas: FormUtil.getDataValue(data.augas_brancas),
            reutilizacion_outras_instalacions: FormUtil.getDataValue(
                data.reutilizacion_outras_instalacions
            ),
            material_plastico: FormUtil.getDataValue(data.material_plastico),
            material_formigon: FormUtil.getDataValue(data.material_formigon),
            material_fibrocemento: FormUtil.getDataValue(data.material_fibrocemento),
            natureza_pluviais: FormUtil.getDataValue(data.natureza_pluviais),
            natureza_fecais: FormUtil.getDataValue(data.natureza_fecais),
            natureza_unitarios: FormUtil.getDataValue(data.natureza_unitarios),
            problema_sen_datos: FormUtil.getDataValue(data.problema_sen_datos),
            problema_sen_problema: FormUtil.getDataValue(data.problema_sen_problema),
            problema_infiltracion_frecuencia: FormUtil.getDataValue(
                data.problema_infiltracion_frecuencia
            ),
            problema_infiltracion_alcance: FormUtil.getDataValue(
                data.problema_infiltracion_alcance
            ),
            problema_augamar_frecuencia: FormUtil.getDataValue(
                data.problema_augamar_frecuencia
            ),
            problema_augamar_alcance: FormUtil.getDataValue(
                data.problema_augamar_alcance
            ),
            problema_augablanca_frecuencia: FormUtil.getDataValue(
                data.problema_augablanca_frecuencia
            ),
            problema_augablanca_alcance: FormUtil.getDataValue(
                data.problema_augablanca_alcance
            ),
            problema_contrapendente_frecuencia: FormUtil.getDataValue(
                data.problema_contrapendente_frecuencia
            ),
            problema_contrapendente_alcance: FormUtil.getDataValue(
                data.problema_contrapendente_alcance
            ),
            problema_rotura_frecuencia: FormUtil.getDataValue(
                data.problema_rotura_frecuencia
            ),
            problema_rotura_alcance: FormUtil.getDataValue(
                data.problema_rotura_alcance
            ),
            problema_atasco_frecuencia: FormUtil.getDataValue(
                data.problema_atasco_frecuencia
            ),
            problema_atasco_alcance: FormUtil.getDataValue(
                data.problema_atasco_alcance
            ),
            problema_outro: FormUtil.getDataValue(data.problema_outro),
            problema_outro_frecuencia: FormUtil.getDataValue(
                data.problema_outro_frecuencia
            ),
            problema_outro_alcance: FormUtil.getDataValue(data.problema_outro_alcance),
            heq_industria_dispersa: FormUtil.getDataValue(data.heq_industria_dispersa),
            heq_outros_usos_non_domesticos: FormUtil.getDataValue(
                data.heq_outros_usos_non_domesticos
            ),
            saneamento_autonomo: FormUtil.getDataValue(data.saneamento_autonomo),
            domestica_sen_tramento: FormUtil.getDataValue(data.domestica_sen_tramento),
            concello: subaglomeracion?.concello,
            aglomeracion: subaglomeracion?.aglomeracion,
            geom: subaglomeracion?.geom,
        });
        onSubmit(subaglomeracionToSave);
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    {updatedSection ? (
                        <SubaglomeracionModificationForm section={updatedSection} />
                    ) : (
                        <SubaglomeracionCreationForm />
                    )}
                </EntityForm>
            </FormProvider>
        </DomainProvider>
    );
};

export default SubaglomeracionForm;
