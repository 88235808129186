import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaRolesResponsabilidadesSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`roles_responsabilidades/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label:
                "Existe un regulamento dos servizos indicando roles e responsabilidades:",
            value: gobernanza.existe_regulamento_servizo_label,
        },
        {
            label: "Está regulada a xestión do baleirado de fosas:",
            value: gobernanza.regulada_xestion_baleirado_label,
        },
        {
            label: "Existen mecanismos de coordinación coa área de urbanismo:",
            value: gobernanza.existe_mecanismos_coordinacion_areas_label,
        },
        {
            label: "Existen mecanismos de coordinación con outros concellos:",
            value: gobernanza.existe_mecanismos_coordinacion_outras_label,
        },
        {
            label: "Existen mecanismos de coordinación con entidades supramunicipais:",
            value: gobernanza.existe_mecanismos_coordinacion_supra_label,
        },
        {
            label: "Existe unha planificación das tarefas a realizar nos servizos:",
            value: gobernanza.existe_planificacion_tarefas_label,
        },
        {
            label: "Existe unha planificación das novas actuacións a executar:",
            value: gobernanza.existe_planificacion_actuacions_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Roles e responsabilidades claros"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_roles_responsibilidades}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_roles_responsibilidades,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaRolesResponsabilidadesSection;
