import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SubaglomeracionPoboacionConectadaSection,
    SubaglomeracionPoboacionTotalSection,
    SubaglomeracionPoboacionVariabilidadeSection,
} from "../presentational/section";

const ViewSubaglomeracionPoboacionSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionPoboacionVariabilidadeSection
            subaglomeracion={subaglomeracion}
        />,
        <SubaglomeracionPoboacionTotalSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionPoboacionConectadaSection subaglomeracion={subaglomeracion} />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionPoboacionSubPage;
