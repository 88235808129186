import {FormProvider, useForm} from "react-hook-form";
import {FormUtil} from "base/utilities";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {PerdidasModificationForm} from ".";

const PerdidasForm = ({
    perdidas = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(perdidas?.id, null),
        porcentaxe_volume_perdidas: FormUtil.getFormValue(
            perdidas?.porcentaxe_volume_perdidas
        ),
        cantidade_volume_perdidas: FormUtil.getFormValue(
            perdidas?.cantidade_volume_perdidas
        ),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            porcentaxe_volume_perdidas: FormUtil.getDataValue(
                data.porcentaxe_volume_perdidas
            ),
            cantidade_volume_perdidas: FormUtil.getDataValue(
                data.cantidade_volume_perdidas
            ),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                {updatedSection ? (
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    >
                        <PerdidasModificationForm section={updatedSection} />
                    </EntityForm>
                ) : null}
            </FormProvider>
        </DomainProvider>
    );
};

export default PerdidasForm;
