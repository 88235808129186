import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "base/auth";
import {useEffect} from "react";

export default function AuthRequired({children, roles = []}) {
    let auth = useAuth();
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.user) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            navigate("/logout", {state: {from: location}});
        }

        if (roles.length !== 0) {
            const hasPermission = roles.some(role => auth.hasRole(role));
            if (!hasPermission) {
                navigate("/");
            }
        }

        if (auth.hasRole(auth.ROLES.CONCELLO)) {
            const concelloBasePath = `/concellos/${auth.user.codigo_concello}`;
            if (
                !location.pathname.startsWith(concelloBasePath) &&
                !location.pathname.startsWith("/aglomeracions")
            ) {
                navigate(concelloBasePath);
            }
        }
    }, [location, auth.user]);

    return children;
}
