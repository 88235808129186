import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {TanqueCaracteristicasSection} from "../presentational/section";

const ViewTanqueDetailSubPage = () => {
    let tanque;
    [tanque] = useOutletContext();

    const sections = [<TanqueCaracteristicasSection tanque={tanque} />];

    return tanque && <EntityViewSubPage sections={sections} />;
};

export default ViewTanqueDetailSubPage;
