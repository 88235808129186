import {SectionField} from "base/component/presentational";
import Grid from "@mui/material/Grid";

const CoordinatesGeojsonSection = ({geom}) => {
    if (geom && geom.type === "Point")
        return (
            <Grid container>
                <Grid item xs={6}>
                    <SectionField
                        label="Coordenadas UTM:"
                        value={`x: ${geom.coordinates[0]}
                            y: ${geom.coordinates[1]}`}
                    />
                </Grid>
            </Grid>
        );
    return null;
};

export default CoordinatesGeojsonSection;
