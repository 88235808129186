import {useNavigate} from "react-router-dom";
import {createLayerLegend, useLayerObject} from "base/map";
import {mapOverlayPanes} from "base/component/geo";

const markerBaseOptions = {
    marker: "s",
    fillColor: "#014868",
    radius: 12,
    color: "white",
    weight: 2,
    opacity: 1,
    fillOpacity: 1,
    pane: mapOverlayPanes[9],
};

const markerSelectedOptions = {
    weight: 2,
    opacity: 1,
    color: "#ff0",
};

const markerHighlightOptions = {
    weight: 2,
    opacity: 1,
    color: "#fff",
};

const markerSuperHighlightOptions = {
    weight: 4,
    opacity: 1,
    fillOpacity: 1,
    color: "#fff",
    radius: 15,
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    ESTADO: "estado",
    PRETRATAMENTO: "pretratamento",
    ABASTECEMENTO_AUTONOMO: "titular",
});

export const LayerLegend = createLayerLegend({
    layerName: "ETAPs",
    menuIconShape: markerBaseOptions.marker,
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.ESTADO,
            text: "Estado",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "Bo",
                    filterFn: val => val === 1,
                    markerOptions: {...markerBaseOptions, radius: 18},
                },
                {
                    text: "Regular",
                    filterFn: val => val === 2,
                    markerOptions: {...markerBaseOptions, radius: 12},
                },
                {
                    text: "Malo",
                    filterFn: val => val === 3,
                    markerOptions: {...markerBaseOptions, radius: 8},
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
        {
            field: discriminators.PRETRATAMENTO,
            text: "Pretratamento",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "Si",
                    filterFn: val => val === 1,
                    markerOptions: {...markerBaseOptions, radius: 16},
                },
                {
                    text: "Non",
                    filterFn: val => val === 0,
                    markerOptions: {...markerBaseOptions, radius: 12},
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
        {
            field: discriminators.ABASTECEMENTO_AUTONOMO,
            text: "Abastecemento",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "Municipal",
                    filterFn: val => val !== 2 && val !== 6,
                    markerOptions: {...markerBaseOptions, radius: 14, dashArray: null},
                },
                {
                    text: "Autónomo",
                    filterFn: val => val === 2 || val === 6,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 10,
                        dashArray: "1,3",
                        color: markerBaseOptions.fillColor,
                        fillColor: "#69a8c5",
                    },
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        selectedMarkerOptions: markerSelectedOptions,
        highlightMarkerOptions: markerHighlightOptions,
        superHighlightMarkerOptions: markerSuperHighlightOptions,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>ETAP: ${
                feature.id ? feature.id : "---"
            }</b><ul class="attributes">`;
            if (data["sistema"]) {
                tooltip += `<li><i>Sistema</i>: ${data["sistema_label"]}</li>`;
            }
            tooltip += `<li><i>Código</i>: ${
                data["codigo"] ? data["codigo"] : "---"
            }</li>`;
            tooltip += `<li><i>Titular</i>: ${
                data["titular_label"] ? data["titular_label"] : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/etaps/${feature.id}`),
    });
}
