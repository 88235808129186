import {PerdidasFormDatosFields} from ".";

const PerdidasModificationForm = ({section}) => {
    if (section === "indicadores") {
        return <PerdidasFormDatosFields layout="column" />;
    }
    return null;
};

export default PerdidasModificationForm;
