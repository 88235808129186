import {useParams} from "react-router-dom";
import {NumberUtil} from "base/utilities";
import {NucleoService} from "nucleo/service";

import {ConexionEntitySummaryPanel} from "conexion/container";
import {conexionElementTypesConstants} from "conexion/model";
import {SectionField} from "base/component/presentational";

const ViewNucleoPanel = ({allowConnectionManagement = false}) => {
    const {idNucleo} = useParams();

    const getSectionData = nucleo => {
        return (
            <>
                <SectionField label="Código IET:" value={nucleo.codigo_iet} />
                <SectionField
                    label="Poboación fixa:"
                    value={NumberUtil.formatDecimalAsInteger(nucleo.poboacion_fixa)}
                    unit="heq"
                />
                <SectionField
                    label="Poboación estacional:"
                    value={NumberUtil.formatDecimalAsInteger(
                        nucleo.poboacion_estacional
                    )}
                    unit="heq"
                />
                <SectionField label="Parroquia:" value={nucleo.parroquia_label} />
                <SectionField
                    label="Concello:"
                    value={nucleo.concello_label}
                    linkPath={`/concellos/${nucleo.concello}`}
                />
            </>
        );
    };

    return (
        <ConexionEntitySummaryPanel
            service={NucleoService}
            id={idNucleo}
            title="Resumo de núcleo"
            getSectionTitle={entity => entity?.nome}
            getSectionData={getSectionData}
            allowConnectionsAsOrigen={allowConnectionManagement}
            allowConnectionsAsDestino={allowConnectionManagement}
            conexionElementType={conexionElementTypesConstants.NUCLEO}
        />
    );
};

export default ViewNucleoPanel;
