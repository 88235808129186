import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {AugasHiddenAuth, ConcelloHiddenAuth} from "concello/auth";
import {SisbagalSectionCard} from "sisbagal/auth";

const PoligonoInventarioSection = ({poligono}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <AugasHiddenAuth>
            <SectionCardHeaderAction
                key="edit"
                name="edit"
                text="Modificar"
                icon={<EditIcon />}
                onClick={() => {
                    navigate(`inventario/edit`);
                }}
                roles={[]}
            />
        </AugasHiddenAuth>,
    ];

    return (
        <ConcelloHiddenAuth>
            <SisbagalSectionCard title="Inventario" secondaryActions={secondaryActions}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <SectionField label="Estado:" value={poligono.estado_label} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <SectionField label="Xestor:" value={poligono.xestor} />
                        <SectionField label="Promotor:" value={poligono.promotor} />
                    </Grid>
                </Grid>
            </SisbagalSectionCard>
        </ConcelloHiddenAuth>
    );
};

export default PoligonoInventarioSection;
