import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const ColectorGeneralDataSection = ({colector}) => {
    const {ROLES} = useAuth();

    const sections = [
        {
            label: "Concello",
            value: colector.concello_label,
            linkPath: `/concellos/${colector.concello}`,
        },
        {
            label: "Aglomeración",
            value: colector.aglomeracion_label,
            linkPath: `/aglomeracions/${colector.aglomeracion}/summary`,
        },
    ];

    return (
        <>
            <EntityGeneralDataSection
                featured_image={colector.featured_image}
                featured_document={colector.featured_document}
                name={`Colector ${colector.id}`}
                sections={sections}
            />
        </>
    );
};

export default ColectorGeneralDataSection;
