export const USER = "sisbagal_user";
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const ROLES = {
    CONCELLO: "Concello",
    EDITOR: "Editor",
    XESTOR: "Xestor",
    ADMINISTRADOR: "Administrador",
    AUDITOR: "Auditor",
};
