import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewSistemaLocationSubPage = () => {
    let sistema;
    [sistema] = useOutletContext();

    const sections = [<EntityLocationSection geom={sistema.geom} />];

    return sistema && <EntityViewSubPage sections={sections} />;
};

export default ViewSistemaLocationSubPage;
