import {CaptacionService} from "abastecemento/service";
import {useCaptacionTable} from "../hooks";
import {EntityWithSistemaFilterForm} from "abastecemento/sistema/presentational";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListCaptacionPage = () => {
    const {tableColumns} = useCaptacionTable();

    return (
        <ListInfraestructuraPage
            entityName="Captacións"
            entityPath="captacions"
            service={CaptacionService}
            tableColumns={tableColumns}
            filterForm={<EntityWithSistemaFilterForm entityName="Captacións" />}
        />
    );
};
export default ListCaptacionPage;
