import {DepositoFormLocationFields} from ".";
import {EntityGeoForm} from "base/entity";

const DepositoGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <DepositoFormLocationFields />
        </EntityGeoForm>
    );
};

export default DepositoGeoForm;
