import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionTerritorioSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Territorio">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Superficie:"
                        value={NumberUtil.formatDecimal(aglomeracion.stats.superficie)}
                        unit="Ha."
                        tooltipText="Superficie delimitada"
                    />
                    <SectionField
                        label="Núcleos:"
                        value={NumberUtil.formatInteger(
                            aglomeracion.stats.nucleosconectados
                        )}
                        tooltipText="Núcleos conectados"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Superficie industrial:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.superficieindustrial
                        )}
                        unit="Ha."
                        tooltipText="Superficie industrial conectada"
                    />
                    <SectionField
                        label="Densidade:"
                        value={NumberUtil.formatDecimal(aglomeracion.stats.densidade)}
                        unit="Heq/Ha"
                        tooltipText="Densidade de poboación"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionTerritorioSection;
