import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionRedeMateriaisSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`redemateriais/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Materiais da rede"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Material plástico:"
                        value={subaglomeracion.material_plastico}
                        unit="%"
                        tooltipText="Porcentaxe da rede construída en materiais plásticos (PVC, PE…)"
                    />
                    <SectionField
                        label="Material formigón:"
                        value={subaglomeracion.material_formigon}
                        unit="%"
                        tooltipText="Porcentaxe da rede construída en formigón"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Material fibrocemento:"
                        value={subaglomeracion.material_fibrocemento}
                        unit="%"
                        tooltipText="Porcentaxe da rede construída en fibrocemento"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionRedeMateriaisSection;
