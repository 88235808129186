import {FormSection} from "base/component/form";
import {PoligonoFormSaneamentoFields, PoligonoFormInventarioFields} from ".";

const PoligonoFormCaracteristicasSection = () => {
    return (
        <>
            <FormSection title="Saneamiento">
                <PoligonoFormSaneamentoFields />
            </FormSection>
            <FormSection title="Inventario">
                <PoligonoFormInventarioFields />
            </FormSection>
        </>
    );
};

export default PoligonoFormCaracteristicasSection;
