import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormRolesResponsabilidadesFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_regulamento_servizo"
                    label="Existe un regulamento dos servizos indicando roles e responsabilidades"
                    options={dominiogobernanzasino}
                    tooltipText="O regulamento dos servizos ten por obxecto determinar o alcance das prestacións dos servizos a favor dos usuarios, regular as relacións entre o xestor, os abonados e o titular dos servizos e determinar os dereitos e obrigas das partes"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="regulada_xestion_baleirado"
                    label="Está regulada a xestión do baleirado de fosas"
                    options={dominiogobernanzasino}
                    tooltipText="Está regulada a xestión do baleirado de fosas mediante norma u ordenanza, ben dentro da ordenanza de saneamento e depuración, ou ben mediante norma ou ordenanza específica"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_coordinacion_areas"
                    label="Existen mecanismos de coordinación coa área de urbanismo"
                    options={dominiogobernanzasino}
                    tooltipText="Deberían establecerse mecanismos de coordinación coa área de urbanismo no que respecta ás acometidas a novas construcións, á capacidade das infraestruturas para admitir vertidos de novos desenvolvementos urbanísticos, á necesidade de implantar técnicas que reduzan o aporte de augas pluviais ás redes (TDUs) nos proxectos de urbanización, etc."
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_coordinacion_outras"
                    label="Existen mecanismos de coordinación con outros concellos"
                    options={dominiogobernanzasino}
                    tooltipText="No caso de servizos compartidos, cando un concello verte á rede doutro concello deberían existir mecanismos de coordinación para avisar de posibles incidencias ou problemas que podan ter repercusión augas abaixo, en instalacións que non son competencia do concello localizado augas arriba. Por exemplo, vertidos que exceden os parámetros límite poden provocar problemas na EDAR augas abaixo, e caudais desproporcionados poden provocar alivios incontrolados en bombeos e rede augas abaixo. Doutra banda, as labores de mantemento ou incidencias nas instalacións augas abaixo poden provocar problemas nos concellos localizados augas arriba. Por exemplo, un atasco augas abaixo pode provocar un alivio no concello localizado augas arriba, ou pode requirir do paro dun bombeo localizado no concello augas arriba"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_coordinacion_supra"
                    label="Existen mecanismos de coordinación con entidades supramunicipais"
                    options={dominiogobernanzasino}
                    tooltipText="Cando un concello verte a unha EDAR ou a unha rede de carácter supramunicipal, deberían existir mecanismos de coordinación entre ambos, polas mesmas razóns sinaladas no indicador anterior"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_planificacion_tarefas"
                    label="Existe unha planificación das tarefas a realizar nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="A existencia dun manual de explotación, tanto en operación normal como en emerxencia, e dun plan de mantemento, é básico para definir as responsabilidades e roles na explotación das infraestruturas"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_planificacion_actuacions"
                    label="Existe unha planificación das novas actuacións a executar"
                    options={dominiogobernanzasino}
                    tooltipText="As necesidade de actuación sobre os servizos debería estar planificada, definindo os responsables da súa execución e do seu financiamento"
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormRolesResponsabilidadesFields;
