import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import {TextUtil} from "base/utilities";

const formatAttribute = attribute => {
    if (attribute instanceof Array) {
        if (
            attribute.length === 3 &&
            attribute[0] === "geom" &&
            attribute[1] === "coordinates"
        ) {
            switch (attribute[2]) {
                case 0:
                    return "Coordenada X";
                case 1:
                    return "Coordenada Y";
            }
        }
        return "";
    }
    return TextUtil.capitalize(attribute.replace("_label", "").replaceAll("_", " "));
};

const hasValue = value => {
    if (value instanceof Array) {
        return value.length !== 0;
    }
    return value != null && value !== "undefined" && value !== "";
};

const checkIfRealChange = change => {
    const values = change[2];
    return hasValue(values[0]) || hasValue(values[1]);
};

const DataChangesCell = ({change}) => {
    if (change && checkIfRealChange(change)) {
        const changeType = change[0];
        // TODO: Review all other type of changes
        if (changeType === "change") {
            const attribute = change[1];
            const values = change[2];
            return (
                <Stack direction="row" spacing={2}>
                    <Typography component="span" sx={{fontWeight: "bold"}}>
                        {formatAttribute(attribute)}:
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography component="span">{values[0]}</Typography>
                        <ArrowRightAltOutlinedIcon fontSize="small" />
                        <Typography component="span">{values[1]}</Typography>
                    </Stack>
                </Stack>
            );
        }
    }
    return null;
};

export default DataChangesCell;
