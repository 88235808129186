const ChangeActionTypes = Object.freeze({
    INSERT: "INSERT",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
});

const ChangeActionState = Object.freeze({
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
});

class Changes extends Array {}

const change_api_adapter = change => {
    change["action_date"] = new Date(change["action_date"]);
    change["resolution_date"] = change["resolution_date"]
        ? new Date(change["resolution_date"])
        : null;
    return change;
};

const changes_api_adapter = changes => changes.map(change_api_adapter);

const createChanges = (data = []) => {
    const changes = Changes.from(data, changes => createChange(changes));
    return changes;
};

const change_view_adapter = change => {
    return change;
};

const createChange = ({
    id = null,
    entity_id = null,
    entity_type = null,
    data_changes = null,
    data_geom = null,
    action_type = null,
    action_date = null,
    action_state = null,
    action_comments = null,
    action_user = null,
    action_user_label = null,
    resolution_date = null,
    resolution_user = null,
    resolution_user_label = null,
} = {}) => {
    const publicApi = {
        id,
        entity_id,
        entity_type,
        data_changes,
        data_geom,
        action_type,
        action_date,
        action_state,
        action_comments,
        action_user,
        action_user_label,
        resolution_date,
        resolution_user,
        resolution_user_label,
    };

    return Object.freeze(publicApi);
};

export {
    createChange as default,
    createChanges,
    change_api_adapter,
    changes_api_adapter,
    change_view_adapter,
    ChangeActionTypes,
    ChangeActionState,
};
