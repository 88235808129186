import {useNavigate, useParams} from "react-router-dom";
import {SistemaService} from "abastecemento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConcelloAuth} from "concello/auth";

const ViewSistemaPanel = () => {
    const {idSistema} = useParams();
    const navigate = useNavigate();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = sistema => {
        navigate(`/concellos/${sistema.concello}/sistemas/${sistema.id}/summary`);
    };

    const getSectionData = sistema => {
        return (
            <>
                <SectionField label="Código:" value={sistema.codigo} />
                <SectionField label="Titular:" value={sistema.titular_label} />
                <SectionField label="Xestión:" value={sistema.xestion_label} />
                <SectionField label="Expediente:" value={sistema.expediente} />
                <SectionField
                    label="% conexión:"
                    value={NumberUtil.formatDecimal(sistema.porcentaxe_conexion)}
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={SistemaService}
            id={idSistema}
            title="Resumo de sistema"
            getSectionTitle={entity => entity?.nome || entity?.codigo}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewSistemaPanel;
