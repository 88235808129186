import {useOutletContext} from "react-router-dom";
import {useSubaglomeracionData} from "../hooks";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {useConfigModule} from "base/provider";
import {SisbagalMapMenu} from "sisbagal/menu";
import {SisbagalMap} from "sisbagal/map";
import {ContentLayout} from "base/layout";
import {useEffect} from "react";

const ViewSubaglomeracionMapSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const {moduleFilter, setModuleFilter} = useConfigModule();
    const {getEntityData} = useSubaglomeracionData();

    useEffect(() => {
        setModuleFilter({...moduleFilter, subaglomeracion: subaglomeracion.id});
    }, []);

    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    concello: {
                        visible: false,
                        customClickHandler: () => {}, // Empty click handler because concellos features are not clickable at this map
                    },
                    subaglomeracion: {
                        customLoader: () => {
                            return () => Promise.resolve(subaglomeracion.geom);
                        },
                        detail: true,
                    },
                    edar: {},
                    tanque: {},
                    bombeo: {},
                    colector: {
                        visible: false,
                    },
                    vertido: {},
                    poligono: {},
                }}
            >
                <ContentLayout>
                    <SisbagalMap
                        mapOptions={{minZoom: 5}}
                        legend={<SisbagalMapMenu />}
                        showDownloadPdfAction={true}
                        entityData={getEntityData(subaglomeracion)}
                    />
                </ContentLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewSubaglomeracionMapSubPage;
