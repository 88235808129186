import {useTanqueMap} from "saneamiento/tanque/hooks";
import {EntityLocationSection} from "base/entity";

const TanqueLocationSection = ({tanque}) => {
    const {mapConfig} = useTanqueMap();

    return <EntityLocationSection entity={tanque} mapConfig={mapConfig} />;
};

export default TanqueLocationSection;
