import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARExplotacionSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`explotaciondatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Explotación" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo eléctrico:"
                        value={NumberUtil.formatDecimal(edar.consumo_electrico)}
                        unit="kwh/ano"
                        tooltipText="Media do consumo eléctrico anual nos últimos 5 anos"
                    />
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(edar.potencia)}
                        unit="kW"
                        tooltipText="Total de potencia eléctrica instalada na planta"
                    />
                    <SectionField
                        label="Reutilización:"
                        value={edar.reutilizacion_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Producción de residuos:"
                        value={NumberUtil.formatDecimal(edar.residuos)}
                        unit="t/ano"
                        tooltipText="Cantidade de residuos (distintos dos lodos) producida na planta"
                    />
                    <SectionField
                        label="Consumo de reactivos:"
                        value={NumberUtil.formatDecimal(edar.reactivos)}
                        unit="t/ano"
                        tooltipText="Cantidade de reactivos consumidos"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARExplotacionSection;
