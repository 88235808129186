import {createEntityService} from "base/entity/service";

import {
    createCaptacion,
    createCaptacions,
    captacion_api_adapter,
    captacions_api_adapter,
} from "abastecemento/model";

const entityService = createEntityService(
    "/api/infrastructures/captacions",
    createCaptacion,
    createCaptacions,
    captacion_api_adapter,
    captacions_api_adapter
);

const CaptacionService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(captacion) {
        return entityService.create(captacion);
    },

    update(captacion) {
        return entityService.update(captacion);
    },

    updateWithPatch(captacion) {
        return entityService.updateWithPatch(captacion);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default CaptacionService;
