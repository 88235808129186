import {NumberUtil} from "base/utilities";

class Colectores extends Array {}

const colector_api_adapter = colector => {
    if (colector["geom"]) {
        colector["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    colector["created_at"] = new Date(colector["created_at"]);
    colector["updated_at"] = new Date(colector["updated_at"]);
    return colector;
};

const colectores_api_adapter = colectores => colectores.map(colector_api_adapter);

const createColectores = (data = []) => {
    const colectores = Colectores.from(data, colectores => createColector(colectores));
    return colectores;
};

const colector_view_adapter = colector => {
    delete colector["orixe_datos_label"];
    delete colector["tipo_conduccion_label"];
    delete colector["material_label"];
    delete colector["fluxo_label"];
    delete colector["tipo_label"];

    delete colector["folder"];
    delete colector["featured_image"];
    delete colector["featured_document"];
    delete colector["created_at"];
    delete colector["created_by"];
    delete colector["updated_at"];
    delete colector["updated_by"];
    return colector;
};

const createColector = ({
    id = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    orixe_datos = null,
    orixe_datos_label = null,
    tipo_conduccion = null,
    tipo_conduccion_label = null,
    diametro = null,
    material = null,
    material_label = null,
    fluxo = null,
    fluxo_label = null,
    tipo = null,
    tipo_label = null,
    pending_status = null,
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        folder,
        featured_image,
        featured_document,
        geom,
        orixe_datos,
        orixe_datos_label,
        tipo_conduccion,
        tipo_conduccion_label,
        diametro,
        material,
        material_label,
        fluxo,
        fluxo_label,
        tipo,
        tipo_label,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createColector as default,
    createColectores,
    colector_api_adapter,
    colectores_api_adapter,
    colector_view_adapter,
};
