import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    AglomeracionInventarioBombeosSection,
    AglomeracionInventarioColectoresSection,
    AglomeracionInventarioEDARSection,
    AglomeracionInventarioRexistroConDatosSection,
    AglomeracionInventarioTanquesSection,
    AglomeracionInventarioTotalSection,
} from "../presentational/section";

const ViewAglomeracionInventarioSubPage = () => {
    let aglomeracion;
    [aglomeracion] = useOutletContext();

    const sections = [
        <AglomeracionInventarioTotalSection aglomeracion={aglomeracion} />,
        <AglomeracionInventarioBombeosSection aglomeracion={aglomeracion} />,
        <AglomeracionInventarioTanquesSection aglomeracion={aglomeracion} />,
        <AglomeracionInventarioEDARSection aglomeracion={aglomeracion} />,
        <AglomeracionInventarioColectoresSection aglomeracion={aglomeracion} />,
        <AglomeracionInventarioRexistroConDatosSection aglomeracion={aglomeracion} />,
    ];

    return aglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewAglomeracionInventarioSubPage;
