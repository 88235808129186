import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const ETAPGeneralDataSection = ({etap}) => {
    const {ROLES} = useAuth();

    let sections = [
        {label: "Código", value: etap.codigo},
        {label: "Titular", value: etap.titular_label},
        {label: "Xestión", value: etap.xestion_label},
        {label: "Estado", value: etap.estado_label},
        {label: "Descripción", value: etap.descripcion},
    ];

    if (etap.sistema) {
        sections.push({
            label: "Sistema PMAA",
            value: etap.sistema_label || etap.sistema,
            linkPath: `/concellos/${etap.concello}/sistemas/${etap.sistema}/summary`,
        });
    }

    return (
        <EntityGeneralDataSection
            featured_image={etap.featured_image}
            featured_document={etap.featured_document}
            name={`ETAP: ${etap.codigo || etap.id}`}
            sections={sections}
        />
    );
};

export default ETAPGeneralDataSection;
