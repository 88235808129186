import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import {NumberUtil} from "base/utilities";
import Typography from "@mui/material/Typography";
import {SisbagalSectionCard} from "sisbagal/auth";

const PerdidasDatosSection = ({perdidas}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`indicadores/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Indicadores de pérdidas"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} sx={{mb: 3}}>
                    <Typography variant="subtitle2">
                        As pérdidas de auga nas redes de abastecemento segundo a Ley
                        1/2022, do 12 de xullo, de mellora da xestión do ciclo integral
                        da auga, son as seguintes:
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Porcentaxe de volumen de pérdidas:"
                        value={NumberUtil.formatDecimal(
                            perdidas.porcentaxe_volume_perdidas
                        )}
                        unit="%"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Cantidade de volumen de pérdidas:"
                        value={NumberUtil.formatDecimal(
                            perdidas.cantidade_volume_perdidas
                        )}
                        unit="m³"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default PerdidasDatosSection;
