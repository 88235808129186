import {PageLayout} from "base/layout";
import {StatsPageMenu} from "stats/menu";
import {StatsConfigProvider} from "stats/provider";

const ViewStatsPage = () => {
    return (
        <StatsConfigProvider>
            <PageLayout menu={<StatsPageMenu />} />
        </StatsConfigProvider>
    );
};
export default ViewStatsPage;
