import {PageLayout} from "base/layout";
import {SectionHeading} from "base/component/presentational";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {ViewConexionsTable, ViewConexionsViewer} from ".";
import {ListProvider} from "base/provider";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {ConexionService} from "conexion/service";
import {NucleoService} from "nucleo/service";

const ViewConexionsSubPage = ({concello, rede, title, layersConfig}) => {
    const [view, setView] = useState("map");

    const handleChange = (event, selectedOption) => {
        setView(selectedOption);
    };

    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    concello: {
                        customLoader: () => {
                            // is stored in state, so we have to return the function we want
                            // https://stackoverflow.com/a/55621325
                            return () => Promise.resolve(concello.geom);
                        },
                        detail: true,
                    },
                    nucleo: {
                        customLoader: () => {
                            return mapFilter =>
                                NucleoService.getFeatures({
                                    ...mapFilter,
                                    rede,
                                });
                        },
                    },
                    conexion: {
                        customLoader: () => {
                            return mapFilter =>
                                ConexionService.getAllDetailed({
                                    ...mapFilter,
                                    rede,
                                });
                        },
                    },
                    ...layersConfig,
                }}
            >
                <PageLayout subPage={true}>
                    <Paper sx={{p: 2}}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{mb: 2}}
                        >
                            <SectionHeading>{title}</SectionHeading>
                            <ToggleButtonGroup
                                color="primary"
                                value={view}
                                exclusive
                                onChange={handleChange}
                            >
                                <ToggleButton value="map">
                                    <MapOutlinedIcon fontSize="small" />
                                </ToggleButton>
                                <ToggleButton value="table">
                                    <FormatListBulletedOutlinedIcon fontSize="small" />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        {view === "map" && (
                            <ViewConexionsViewer concello={concello} rede={rede} />
                        )}
                        {view === "table" && (
                            <ListProvider>
                                <ViewConexionsTable concello={concello} rede={rede} />
                            </ListProvider>
                        )}
                    </Paper>
                </PageLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewConexionsSubPage;
