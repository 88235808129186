import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {SistemaService} from "abastecemento/service";
import {PageLayout} from "base/layout";
import {SistemaSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";

const ViewSistemaPage = () => {
    const {setSubpagePath} = useAppConfig();

    const {idSistema} = useParams();
    const [sistema, setSistema] = useState(null);
    const location = useLocation();

    useEffect(() => {
        SistemaService.get(idSistema).then(data => {
            setSistema(data);
            setSubpagePath([
                {name: "Listado de Sistemas PMAA", path: "sistemas"},
                {
                    name: data.nome || data.codigo || data.id,
                    path: `${data.id}/summary`,
                },
            ]);
        });
        return () => {
            setSistema(null);
            setSubpagePath([]);
        };
    }, [idSistema, location.state?.lastRefreshDate]);

    return (
        sistema && (
            <PageLayout
                menu={<SistemaSubPageMenu sistema={sistema} />}
                context={[sistema]}
                subPage={true}
            />
        )
    );
};

export default ViewSistemaPage;
