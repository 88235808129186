import {useDomain} from "domain/provider";
import {
    FormSelect,
    FormInputInteger,
    FormSelectMultiple,
    FormTextArea,
    FormInputDecimal,
} from "base/component/form";

import Grid from "@mui/material/Grid";

const DepositoFormMedidasFields = ({layout = "row"}) => {
    const {
        dominiosino,
        dominioabastecementoproteccion,
        dominioabastecementoubicacion,
        dominioabastecementomaterial,
        dominioabastecementoplanta,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="diametro"
                    label="Diámetro"
                    endAdornment="m."
                    placeholder="0,00"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="largo"
                    label="Largo"
                    endAdornment="m."
                    placeholder="0,00"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="ancho"
                    label="Ancho"
                    endAdornment="m."
                    placeholder="0,00"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="alto"
                    label="Alto"
                    endAdornment="m."
                    placeholder="0,00"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="capacidade"
                    label="Capacidade"
                    endAdornment="m³"
                    placeholder="0,00"
                />
            </Grid>
        </Grid>
    );
};

export default DepositoFormMedidasFields;
