import {useOutletContext} from "react-router-dom";

import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewTanqueLocationSubPage = () => {
    let tanque;
    [tanque] = useOutletContext();

    const sections = [<EntityLocationSection geom={tanque.geom} />];

    return tanque && <EntityViewSubPage sections={sections} />;
};

export default ViewTanqueLocationSubPage;
