import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionExplotacionSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`explotaciondatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Explotación" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Telecontrol:"
                        value={subaglomeracion.telecontrol_label}
                        tooltipText="Grado de telecontrol do sistema"
                    />
                    <SectionField
                        label="Plan de mantemento:"
                        value={subaglomeracion.plan_mantemento_label}
                        tooltipText="Existencia dun plan de mantemento do sistema"
                    />
                    <SectionField
                        label="Manual de explotación:"
                        value={subaglomeracion.manual_explotacion_label}
                        tooltipText="Existencia de manual de explotación do sistema"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Limpeza de colectores:"
                        value={subaglomeracion.limpeza_colectores_label}
                        tooltipText="Frecuencia de limpeza dos colectores"
                    />
                    <SectionField
                        label="Control de alivios:"
                        value={subaglomeracion.control_alivios_label}
                        tooltipText="Control dos alivios producidos no sistema"
                    />
                    <SectionField
                        label="Reutilización outras instalacións:"
                        value={subaglomeracion.reutilizacion_outras_instalacions_label}
                        tooltipText="Reutilización da auga en outras instalacións"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionExplotacionSection;
