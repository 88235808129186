import {Route} from "react-router-dom";
import {
    CreateBombeoPage,
    ListBombeoPage,
    ViewBombeoPanel,
    ViewBombeoPage,
    ViewBombeoLocationSubPage,
    ViewBombeoDocumentsSubPage,
    ViewBombeoSummarySubPage,
    ViewBombeoDetailSubPage,
    UpdateBombeoPanel,
    ManageBombeoPage,
    ViewBombeoDocumentPanel,
    ViewBombeoChangesSubPage,
} from "saneamiento/bombeo/container";
import {ViewChangePanel} from "changes/container";

const bombeoRoutes = [
    <Route key="bombeo-new" path="bombeos/new" element={<CreateBombeoPage />} />,
    <Route key="bombeo-manage" path="bombeos" element={<ManageBombeoPage />}>
        <Route key="bombeo-list" path="" element={<ListBombeoPage />}>
            <Route
                key="bombeo-info"
                path="info/:idBombeo"
                element={<ViewBombeoPanel />}
            />
        </Route>
        <Route key="bombeo-detail" path=":idBombeo" element={<ViewBombeoPage />}>
            <Route
                key="bombeo-location"
                path="location"
                element={<ViewBombeoLocationSubPage />}
            >
                {" "}
                <Route
                    key="bombeo-location-edit"
                    path=":section/:action"
                    element={<UpdateBombeoPanel />}
                />
            </Route>
            <Route
                key="bombeo-documents"
                path="documents/*"
                element={<ViewBombeoDocumentsSubPage />}
            >
                <Route
                    key="bombeo-documents-view"
                    path="detail/*"
                    element={<ViewBombeoDocumentPanel />}
                />
            </Route>
            <Route
                key="bombeo-changes"
                path="changes"
                element={<ViewBombeoChangesSubPage />}
            />
            <Route
                key="bombeo-summary"
                path="summary"
                element={<ViewBombeoSummarySubPage />}
            >
                <Route
                    key="bombeo-general-edit"
                    path=":section/:action"
                    element={<UpdateBombeoPanel />}
                />
            </Route>
            <Route
                key="bombeo-detail"
                path="detail"
                element={<ViewBombeoDetailSubPage />}
            >
                <Route
                    key="bombeo-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateBombeoPanel />}
                />
            </Route>
        </Route>
    </Route>,
];

export default bombeoRoutes;
