import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {TanqueService} from "saneamiento/service";
import {tanque_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {TanqueForm} from "../presentational/form";

const CreateTanquePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = tanque => {
        TanqueService.create(tanque_view_adapter({...tanque}))
            .then(createdTanque => {
                navigate(`${basePath}/${createdTanque.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <TanqueForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de Tanque"
            error={error}
        />
    );
};

export default CreateTanquePage;
