import {useRef} from "react";
import L from "leaflet";
import {useCRS} from "./CRSHook";

export const availableBaseLayers = [
    {
        code: "callejero_ign",
        name: "Rueiro IGN",
        url: "https://www.ign.es/wms-inspire/ign-base?SERVICE=WMS&",
        wmsOptions: {
            layers: "IGNBaseTodo-gris",
            format: "image/png",
            transparent: true,
            attribution: "© Instituto Geográfico Nacional de España",
            opacity: 0.8,
        },
    },
    {
        code: "ortofoto",
        name: "Ortofoto",
        url: "https://www.ign.es/wms-inspire/pnoa-ma?SERVICE=WMS&",
        wmsOptions: {
            layers: "OI.OrthoimageCoverage",
            format: "image/png",
            transparent: true,
            attribution: "© Instituto Geográfico Nacional de España",
            opacity: 0.8,
        },
    },
];

export function useBaseLayer() {
    let layerRef = useRef(null);
    const {crs} = useCRS();

    const setBaseLayer = (map, baseLayer) => {
        if (map) {
            if (layerRef.current) {
                map.removeLayer(layerRef.current);
            }
            layerRef.current = L.tileLayer
                .wms(baseLayer.url, {
                    ...baseLayer.wmsOptions,
                    crs: crs,
                })
                .addTo(map);
        }
    };

    return {layerRef, setBaseLayer};
}
