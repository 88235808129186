import {useEffect, useState} from "react";

import {StatsService} from "stats/service";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {NumberUtil} from "base/utilities";

const ViewStatsAbastecementoVolumePanel = ({statsFilter}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        setData(null);
        StatsService.getAbastecementoVolumeData(statsFilter).then(data => {
            setLoading(false);
            setData(data);
        });
    }, [statsFilter]);

    const getDataPanel = (label, value, unit) => {
        return (
            <Paper elevation={3}>
                <Stack alignItems="center" sx={{p: 4, bgcolor: "grey.100"}}>
                    <Typography variant="body1" sx={{mb: 3}}>
                        {label}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="h4">{value}</Typography>
                        <Typography variant="overline">{unit}</Typography>
                    </Stack>
                </Stack>
            </Paper>
        );
    };

    return loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        data && (
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    {getDataPanel(
                        "Habitantes conectados abastecemento",
                        NumberUtil.formatInteger(data["sum_poboacion_conectada"]),
                        "habs."
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {getDataPanel(
                        "Capacidade dos depósitos",
                        NumberUtil.formatDecimal(data["capacidade_depositos"]),
                        "m³"
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {getDataPanel(
                        "Capacidade por habitante conectado",
                        NumberUtil.formatDecimal(data["capacidade_habitante"]),
                        "habs. / m³"
                    )}
                </Grid>
                <Grid item xs={12} md={3}>
                    {getDataPanel(
                        "Porcentaxe de pérdidas",
                        data["porcentaxe_perdidas"]
                            ? NumberUtil.formatInteger(data["porcentaxe_perdidas"])
                            : "-",
                        "%"
                    )}
                </Grid>
            </Grid>
        )
    );
};
export default ViewStatsAbastecementoVolumePanel;
