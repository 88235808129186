import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewBombeoLocationSubPage = () => {
    let bombeo;
    [bombeo] = useOutletContext();

    const sections = [<EntityLocationSection geom={bombeo.geom} />];

    return bombeo && <EntityViewSubPage sections={sections} />;
};

export default ViewBombeoLocationSubPage;
