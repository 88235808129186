import {useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AuthService, useAuth} from "base/auth";

import {SisbagalFooter, SisbagalHeaderHero} from "sisbagal/layout";
import {Header} from "base/layout";
import {APP_NAME, APP_VERSION} from "base/config/appInfo";

import {AlertError, SpinnerFullHeight} from "base/component/presentational";
import {LoginForm} from "../presentational";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {NO_CONTENT_HEIGHT} from "base/config/measurements";
import DataProtectionDialog from "./DataProtectionDialog";

const ALLOW_USER_PASSWORD_LOGIN = process.env.REACT_APP_ALLOW_USER_PASSWORD_LOGIN;
const CONTACT_MAIL = process.env.REACT_APP_CONTACT_MAIL;

const casTokenParam = "CAS_TOKEN";
const casUserMissingParam = "CAS_USER_MISSING";

const LoginPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    let from = location.state?.from?.pathname;

    const [open, setOpen] = useState(false);
    const [forceLogout, setForceLogout] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    function validateLogin(username, password) {
        auth.login(username, password, () => {})
            .then(() => {
                // Send them back to the page they tried to visit when they were
                // redirected to the login page. Use { replace: true } so we don't create
                // another entry in the history stack for the login page.  This means that
                // when they get to the protected page and click the back button, they
                // won't end up back on the login page, which is also really nice for the
                // user experience.
                if (!from || from === "/") {
                    // to concellos page by default after login
                    from = "/concellos";
                }
                navigate(from, {replace: true});
            })
            .catch(error => {
                setError(error);
            });
    }

    useEffect(() => {
        // We manually keep control of whether the CAS login has already been checked
        // during current page loading or not, in order to prevent processing it twice in
        // development environments due to StrictMode. The original GET request cannot be
        // prevented to be made twice, though.
        //
        // See: https://github.com/facebook/react/issues/24502
        // See: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data

        async function checkIdleLogout() {
            if (location.state?.error === "IDLE_LOGOUT") {
                setError(
                    "A súa sesión foi pechada por inactividade. Por favor, autentíquese de novo."
                );
                auth.logout();
            }
        }

        async function checkCASLogin() {
            if (
                new URLSearchParams(window.location.search).get(casTokenParam) ===
                "true"
            ) {
                await auth
                    .getTokenFromCAS()
                    .then(() => {
                        // Send them back to the page they tried to visit when they were
                        // redirected to the login page. Use { replace: true } so we don't create
                        // another entry in the history stack for the login page.  This means that
                        // when they get to the protected page and click the back button, they
                        // won't end up back on the login page, which is also really nice for the
                        // user experience.
                        let casFrom = new URLSearchParams(window.location.search).get(
                            "from"
                        );
                        if (!casFrom || casFrom === "/") {
                            // to concellos page by default after login
                            casFrom = "/concellos";
                        }
                        setLoading(false);
                        navigate(casFrom, {replace: true});
                    })
                    .catch(error => {
                        setError(error);
                        setLoading(false);
                    });
            } else if (
                new URLSearchParams(window.location.search).get(casUserMissingParam) ===
                "true"
            ) {
                setError(
                    "Non existe ningún usuario na aplicación asociado á súa conta do CAS da Xunta"
                );
                setForceLogout(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }

        checkIdleLogout();
        checkCASLogin();
    }, []);

    const loginUrlReturn = encodeURIComponent(
        window.location.href.split(/[?#]/)[0] +
            `?${casTokenParam}=true&from=${from ? from : ""}`
    );

    return loading ? (
        <SpinnerFullHeight />
    ) : (
        <Box
            role="wrapper"
            sx={{display: "flex", flexDirection: "column", minHeight: "100vh"}}
        >
            <Header hero={<SisbagalHeaderHero />} toolbarSx={{minHeight: "112px"}} />
            <Container
                component="main"
                sx={{
                    minHeight: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Grid container columns={{xs: 1, sm: 6, md: 7}}>
                    <Grid item xs={0} sm={1} md={2} />
                    <Grid
                        item
                        component={Paper}
                        xs={1}
                        sm={4}
                        md={3}
                        sx={{padding: "20px"}}
                    >
                        <Box
                            component="header"
                            sx={{
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="h2"
                                sx={{
                                    color: "grey.300",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                }}
                            >
                                {APP_NAME}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: "grey.300",
                                    fontWeight: "bold",
                                    marginTop: "-15px",
                                }}
                            >
                                v{APP_VERSION}
                            </Typography>
                            <Typography variant="body1" sx={{mt: 3}}>
                                Benvido/a a SISBAGAL
                            </Typography>
                        </Box>
                        <AlertError error={error} />
                        {ALLOW_USER_PASSWORD_LOGIN === "true" && (
                            <>
                                <LoginForm handleValidation={validateLogin} />
                                <hr />
                            </>
                        )}
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            {forceLogout ? (
                                <Button
                                    onClick={() => AuthService.logoutCAS()}
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3}}
                                >
                                    Pechar sesión
                                </Button>
                            ) : (
                                <Button
                                    href={
                                        process.env.REACT_APP_API_BASE_URL +
                                        process.env.REACT_APP_API_CAS_PATH +
                                        `?next=${loginUrlReturn}`
                                    }
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3}}
                                >
                                    Autenticarse
                                </Button>
                            )}
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: "grey.700",
                                        marginTop: "10px",
                                    }}
                                >
                                    Esta aplicación almacena no navegador do usuario
                                    únicamente a información para xestionar a sesión.
                                    Recoméndase o uso dun navegador actualizado como
                                    Firefox, Chrome, Opera, Brave, Edge ou Safari.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: "grey.700",
                                        marginTop: "10px",
                                    }}
                                >
                                    Pode contactar cos responsables da aplicación
                                    escribindo a{" "}
                                    <Link
                                        href={`mailto:${CONTACT_MAIL}`}
                                        underline="none"
                                    >
                                        {CONTACT_MAIL}
                                    </Link>
                                </Typography>
                            </Box>
                            <Link
                                component="button"
                                onClick={() => setOpen(true)}
                                variant="subtitle2"
                                underline="hover"
                                sx={{mt: 3}}
                            >
                                Consulte a política de privacidade
                            </Link>
                            <DataProtectionDialog open={open} onClose={handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <SisbagalFooter isFixed={true} />
        </Box>
    );
};

export default LoginPage;
