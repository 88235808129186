import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {AuthProvider, AuthRequired, useAuth} from "base/auth";

import {LoginPage, LogoutPage} from "user/component/container";
import {ViewHomePage} from "home/container";
import {SisbagalApp} from "sisbagal/container";
import {concelloRoutes, ConcellosModule} from "concello/module";
import {aglomeracionRoutes, AglomeracionsModule} from "saneamiento/aglomeracion/module";
import {statsRoutes, StatsModule} from "stats/module";
import {medioReceptorRoutes, MedioReceptorModule} from "medioreceptor/module";
import {ROLES} from "base/config";

export default function SisbagalRoutes() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="" element={<SisbagalApp />}>
                        <Route
                            path="concellos"
                            element={
                                <AuthRequired>
                                    <ConcellosModule />
                                </AuthRequired>
                            }
                        >
                            {concelloRoutes}
                        </Route>
                        <Route
                            path="aglomeracions"
                            element={
                                <AuthRequired>
                                    <AglomeracionsModule />
                                </AuthRequired>
                            }
                        >
                            {aglomeracionRoutes}
                        </Route>
                        <Route
                            path="medioreceptor"
                            element={
                                <AuthRequired
                                    roles={[
                                        ROLES.ADMINISTRADOR,
                                        ROLES.XESTOR,
                                        ROLES.EDITOR,
                                        ROLES.AUDITOR,
                                    ]}
                                >
                                    <MedioReceptorModule />
                                </AuthRequired>
                            }
                        >
                            {medioReceptorRoutes}
                        </Route>
                        <Route
                            path="stats"
                            element={
                                <AuthRequired
                                    roles={[
                                        ROLES.ADMINISTRADOR,
                                        ROLES.XESTOR,
                                        ROLES.EDITOR,
                                        ROLES.AUDITOR,
                                    ]}
                                >
                                    <StatsModule />
                                </AuthRequired>
                            }
                        >
                            {statsRoutes}
                        </Route>
                        <Route
                            path=""
                            element={
                                <AuthRequired>
                                    <ViewHomePage />
                                </AuthRequired>
                            }
                        />
                    </Route>
                </Routes>
            </AuthProvider>
        </Router>
    );
}
