import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SubaglomeracionInventarioTanquesSection = ({subaglomeracion}) => {
    return (
        <SectionCard title="Tanques">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Totais:"
                        value={NumberUtil.formatInteger(subaglomeracion.stats.tanques)}
                        tooltipText="Tanques totais da rede"
                    />
                    <SectionField
                        label="Volume:"
                        value={NumberUtil.formatDecimal(
                            subaglomeracion.stats.capacidadetanque
                        )}
                        unit="m³"
                        tooltipText="Volume total de retención"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            subaglomeracion.stats.potenciainstaladatanque
                        )}
                        unit="Kw"
                        tooltipText="Potencia total instalada"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            subaglomeracion.stats.consumoelectricotanque
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico medio anual nos últimos cinco anos."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SubaglomeracionInventarioTanquesSection;
