import {NumberUtil} from "base/utilities";

export function useColectorTable() {
    const tableColumns = [
        {
            id: "tipo_conduccion_label",
            label: "Tipo",
            width: 20,
        },
        {
            id: "tipo_label",
            label: "Natureza",
            width: 20,
        },
        {
            id: "diametro",
            label: "Diámetro (mm)",
            width: 20,
            formatFunction: element => NumberUtil.formatDecimal(element.diametro),
        },
        {
            id: "material_label",
            label: "Material",
            width: 20,
        },
        {
            id: "fluxo_label",
            label: "Fluxo",
            width: 20,
        },
    ];

    return {tableColumns};
}
