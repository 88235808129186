import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {createSistema} from "abastecemento/model";
import {EntityForm} from "base/component/form";

import {SistemaCreationForm, SistemaModificationForm} from ".";
import {FormUtil} from "base/utilities";

const SistemaForm = ({
    sistema = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(sistema?.id),
        nome: FormUtil.getFormValue(sistema?.nome),
        codigo: FormUtil.getFormValue(sistema?.codigo),
        titular: FormUtil.getFormValue(sistema?.titular),
        xestion: FormUtil.getFormValue(sistema?.xestion),
        descripcion: FormUtil.getFormValue(sistema?.descripcion),
        porcentaxe_conexion: FormUtil.getFormValue(sistema?.porcentaxe_conexion),
        rexistrado: FormUtil.getFormValue(sistema?.rexistrado),
        expediente: FormUtil.getFormValue(sistema?.expediente),
        num_tratamentos_potabilizacion: FormUtil.getFormValue(
            sistema?.num_tratamentos_potabilizacion
        ),
        notas: FormUtil.getFormValue(sistema?.notas),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        const sistemaToSave = createSistema({
            id: FormUtil.getDataValue(data.id),
            nome: FormUtil.getDataValue(data.nome),
            codigo: FormUtil.getDataValue(data.codigo),
            titular: FormUtil.getDataValue(data.titular),
            xestion: FormUtil.getDataValue(data.xestion),
            descripcion: FormUtil.getDataValue(data.descripcion),
            porcentaxe_conexion: FormUtil.getDataValue(data.porcentaxe_conexion),
            rexistrado: FormUtil.getDataValue(data.rexistrado),
            expediente: FormUtil.getDataValue(data.expediente),
            num_tratamentos_potabilizacion: FormUtil.getDataValue(
                data.num_tratamentos_potabilizacion
            ),
            notas: FormUtil.getDataValue(data.notas),
            concello: sistema?.concello,
            geom: sistema?.geom,
        });
        onSubmit(sistemaToSave);
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    {updatedSection ? (
                        <SistemaModificationForm section={updatedSection} />
                    ) : (
                        <SistemaCreationForm />
                    )}
                </EntityForm>
            </FormProvider>
        </DomainProvider>
    );
};

export default SistemaForm;
