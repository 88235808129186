import {useNavigate, useParams} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {useConfigModule} from "base/provider";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {useConcelloAuth} from "concello/auth";

const ViewVertidoPanel = () => {
    const {idVertido} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = vertido => {
        const basePath =
            path === "concellos"
                ? `/concellos/${vertido.concello}`
                : `/aglomeracions/${vertido.aglomeracion}`;
        navigate(`${basePath}/vertidos/${vertido.id}/summary`);
    };

    const getSectionData = vertido => {
        return (
            <>
                <SectionField
                    label="Tipo punto de vertido:"
                    value={vertido.tipo_punto_vertido_label}
                />
                <SectionField label="Tip de vertido:" value={vertido.tipo_label} />
                <SectionField
                    label="Tipo titular:"
                    value={vertido.tipo_titular_label}
                />
                <SectionField label="Código CNV:" value={vertido.codigo_externo} />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={VertidoService}
            id={idVertido}
            title="Resumo de vertido"
            getSectionTitle={entity => entity?.codigo || entity?.id}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewVertidoPanel;
