import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormSelect} from "base/component/form";
import {StatsFilterLayout} from "stats/menu";
import {ProvinciaService} from "concello/service";

const StatsProvinceFilter = ({filter, onChange = null}) => {
    const [provincias, setProvincias] = useState(null);

    const formMethods = useForm({
        defaultValues: {
            provincia: filter?.provincia || "",
            concello: filter?.concello || "",
        },
    });

    useEffect(() => {
        Promise.all([ProvinciaService.getList()]).then(([provincias]) => {
            setProvincias(
                provincias.map(provincia => {
                    return {value: provincia.id, label: provincia.nome};
                })
            );
        });
    }, []);

    const handleChange = value => {
        onChange(formMethods.getValues());
    };

    let filterAttributes = [
        <FormSelect
            name="provincia"
            label="Provincia"
            options={provincias}
            onChangeHandler={handleChange}
            showEmptyOption={true}
        />,
    ];

    return (
        <FormProvider {...formMethods}>
            <StatsFilterLayout filterAttributes={filterAttributes}></StatsFilterLayout>
        </FormProvider>
    );
};

export default StatsProvinceFilter;
