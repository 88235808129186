import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {ETAPService} from "abastecemento/service";
import {etap_view_adapter} from "abastecemento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {ETAPForm} from "../presentational/form";

const UpdateETAPPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let etap;
    [etap] = useOutletContext();

    const handleSubmit = etap => {
        ETAPService.update(etap_view_adapter({...etap}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <ETAPForm
                    etap={etap}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar ETAP"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateETAPPanel;
