import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewColectorDocumentsSubPage = () => {
    let colector;
    [colector] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/colectores/")[0];

    return (
        colector && (
            <ViewDocumentsSubPage
                entity={colector}
                basePath={`${basePath}/colectores`}
            />
        )
    );
};

export default ViewColectorDocumentsSubPage;
