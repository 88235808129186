import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {MasaAugaPrincipalSection} from "../presentational/section";

const ViewMasaAugaPrincipalSubPage = () => {
    let masaAuga;
    [masaAuga] = useOutletContext();

    const sections = [<MasaAugaPrincipalSection masaAuga={masaAuga} />];

    return masaAuga && <EntityViewSubPage sections={sections} />;
};

export default ViewMasaAugaPrincipalSubPage;
