import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {SubaglomeracionExplotacionSection} from "../presentational/section";

const ViewSubaglomeracionExplotacionSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionExplotacionSection subaglomeracion={subaglomeracion} />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionExplotacionSubPage;
