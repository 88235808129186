import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SistemaService} from "abastecemento/service";
import {sistema_view_adapter} from "abastecemento/model";
import {EntityCreatePage} from "base/entity";
import {SistemaForm} from "../presentational/form";

const CreateSistemaPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];

    const [error, setError] = useState("");

    const handleFormSubmit = sistema => {
        SistemaService.create(sistema_view_adapter({...sistema}))
            .then(createdSistema => {
                navigate(`/sistemas/${createdSistema.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <SistemaForm onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
            }
            title="Rexistro de Sistema"
            error={error}
        ></EntityCreatePage>
    );
};

export default CreateSistemaPage;
