import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {ETAPService} from "abastecemento/service";

const ViewETAPChangesSubPage = () => {
    let etap;
    [etap] = useOutletContext();

    return (
        etap && (
            <ViewEntityChangesSubPage
                entityService={ETAPService}
                entityType="etap"
                entityId={etap.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewETAPChangesSubPage;
