import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewCaptacionDocumentsSubPage = () => {
    let captacion;
    [captacion] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/captacions/")[0];

    return (
        captacion && (
            <ViewDocumentsSubPage
                entity={captacion}
                basePath={`${basePath}/captacions`}
            />
        )
    );
};

export default ViewCaptacionDocumentsSubPage;
