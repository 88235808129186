import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SubaglomeracionDatosPrincipaisSection = ({subaglomeracion}) => {
    return (
        <SectionCard title="Datos principais">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Código aglomeración:"
                        value={subaglomeracion.stats.codigoaglomeracion}
                    />
                    <SectionField
                        label="Nome aglomeración:"
                        value={subaglomeracion.stats.nomeaglomeracion}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Código concello:"
                        value={subaglomeracion.stats.codigoconcello}
                        linkPath={`/concellos/${subaglomeracion.stats.codigoconcello}`}
                    />
                    <SectionField
                        label="Nome concello:"
                        value={subaglomeracion.stats.nomeconcello}
                        linkPath={`/concellos/${subaglomeracion.stats.codigoconcello}`}
                    />
                    <SectionField
                        label="Código EDAR:"
                        value={subaglomeracion.stats.codigoedar}
                        linkPath={`/aglomeracions/${subaglomeracion.id}/edars/${subaglomeracion.stats.idedar}/summary`}
                        tooltipText="EDAR da aglomeración"
                    />
                    <SectionField
                        label="Nome EDAR:"
                        value={subaglomeracion.stats.nomeedar}
                        linkPath={`/aglomeracions/${subaglomeracion.id}/edars/${subaglomeracion.stats.idedar}/summary`}
                        tooltipText="EDAR da aglomeración"
                    />
                    <SectionField
                        label="Tratamento:"
                        value={subaglomeracion.stats.tratamento}
                        tooltipText="Nivel de tratamento"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SubaglomeracionDatosPrincipaisSection;
