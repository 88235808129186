import {useNavigate} from "react-router-dom";

import {SectionFieldHelpText, TextLink} from ".";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const SectionField = ({
    label = null,
    value = "",
    unit = "",
    labelIcon = null,
    containerWidth = "",
    valueFontStyle = "inherit",
    linkPath = null,
    editButton = false,
    helperText = null,
    tooltipText = null,
}) => {
    const navigate = useNavigate();
    let labelWidth;
    let valueWidth;

    switch (`${containerWidth}`) {
        case "long":
            labelWidth = editButton ? 9 : 9;
            valueWidth = editButton ? 2 : 3;
            break;
        case "short":
            labelWidth = editButton ? 6 : 7;
            valueWidth = editButton ? 5 : 5;
            break;
        default:
            labelWidth = editButton ? 6 : 6;
            valueWidth = editButton ? 5 : 6;
            break;
    }

    const LabelIcon = labelIcon;

    return (
        <Grid container columnSpacing={containerWidth === "long" ? 2 : 1}>
            <Grid
                item
                xs="auto"
                sm={5}
                lg={labelWidth}
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                }}
            >
                {tooltipText && (
                    <Tooltip title={tooltipText} arrow enterDelay={500}>
                        <InfoOutlinedIcon
                            sx={{mr: 1, color: "grey.500", fontSize: "0.9rem"}}
                        />
                    </Tooltip>
                )}
                <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{
                        lineHeight: {xs: 1.5, sm: 1.25},
                        mb: {xs: 0, sm: 1.5},
                        hyphens: "auto",
                    }}
                >
                    {label}
                </Typography>
                {labelIcon && (
                    <LabelIcon fontSize="small" sx={{ml: 1, color: "text.secondary"}} />
                )}
            </Grid>
            <Grid item container xs="auto" sm={6} lg={valueWidth}>
                <Grid item>
                    {value != null && value !== "undefined" && value !== "" ? (
                        !linkPath ? (
                            <Stack direction="row">
                                <Typography
                                    variant="subtitle1"
                                    component="p"
                                    sx={{
                                        lineHeight: {xs: 1.5, sm: 1.25},
                                        mb: {xs: 0, sm: 1.5},
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-line;",
                                        fontStyle: valueFontStyle,
                                    }}
                                >
                                    {value}
                                </Typography>
                                {helperText && (
                                    <SectionFieldHelpText text={helperText} />
                                )}
                                {unit && (
                                    <Typography
                                        variant="subtitle1"
                                        component="p"
                                        sx={{
                                            lineHeight: {xs: 1.85, sm: 1.65},
                                            ml: 1,
                                            overflowWrap: "break-word",
                                            whiteSpace: "pre-line;",
                                            fontSize: "0.8em",
                                            color: "grey",
                                        }}
                                    >
                                        {unit}
                                    </Typography>
                                )}
                            </Stack>
                        ) : (
                            <TextLink text={value} to={linkPath} />
                        )
                    ) : (
                        "—"
                    )}
                </Grid>
                {editButton ? (
                    <Grid item xs={1}>
                        <Tooltip title="Editar campo">
                            <IconButton
                                sx={{
                                    mt: "-12px",
                                    ml: 4,
                                }}
                                onClick={() => {
                                    navigate(`generaldata/edit`);
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default SectionField;
