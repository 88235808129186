import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloMedioFisicoSection = ({concello}) => {
    return (
        <SectionCard title="Medio físico">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Superficie:"
                        value={NumberUtil.formatDecimal(concello.stats.superficietotal)}
                        unit="Ha."
                        tooltipText="Superficie total do concello"
                    />
                    <SectionField
                        label="Núcleos:"
                        value={NumberUtil.formatInteger(concello.stats.nucleos)}
                        tooltipText="Nucleos totais do concello"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Superficie industrial:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.totalareaindustrial
                        )}
                        unit="Ha."
                        tooltipText="Superficie industrial total do concello"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloMedioFisicoSection;
