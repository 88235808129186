import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";

import {CUSTOM_COLORS} from "Theme";
import {callAddBoldFont} from "fonts/XuntaSans-Bold-normal";
import {callAddRegularFont} from "fonts/XuntaSans-Regular-normal";
import PDFElements from "base/pdf/PDFElements";

export function useDownloadMapWithTablesAsPDF() {
    const download = async (
        entityTables = [],
        mapImgData = null,
        mapDimensions = null,
        entityData = {},
        callback = null
    ) => {
        // See solution to use custom fonts: https://stackoverflow.com/questions/63378821/how-to-add-custom-font-in-jspdf-with-react
        jsPDF.API.events.push(
            ["addFonts", callAddBoldFont],
            ["addFonts", callAddRegularFont]
        );

        let doc = new jsPDF({orientation: "portrait"});

        const dimensions = PDFElements.getPDFDimensions(doc, mapDimensions);

        let summaryPositionTop = dimensions.canvasHeight + PDFElements.pagePaddingTop;

        const isPreviousTableLastInPage = previousTable => {
            return (
                previousTable.finalY > dimensions.pageHeight - PDFElements.pageMargin
            );
        };

        PDFElements.drawHeader(doc, entityData);

        // Draw map image
        doc.addImage(
            mapImgData,
            "PNG",
            dimensions.imagePositionLeft,
            dimensions.imagePositionTop,
            dimensions.imageWidth,
            dimensions.canvasHeight
        );
        // Draw summary table
        entityData.sections.map((section, index) => {
            PDFElements.drawSummaryTable(doc, entityData, section, summaryPositionTop);
        });

        doc.addPage("a4", "landscape");

        // Draw entity tables
        entityTables
            .filter(entityTable => {
                return entityTable.data?.length > 0;
            })
            .forEach((entityTable, index) => {
                // For first table & tables after page break, we need to set title position at the top of the page; the rest will be placed after previous table.
                // @ts-ignore
                const previousTable = doc.lastAutoTable;

                let titlePositionTop = previousTable.finalY + 10;
                let tablePositionTop = previousTable.finalY + PDFElements.pageMargin;
                if (index === 0 || isPreviousTableLastInPage(previousTable)) {
                    titlePositionTop = PDFElements.pagePaddingTop - 5;
                    tablePositionTop = PDFElements.pagePaddingTop;
                }
                if (isPreviousTableLastInPage(previousTable)) {
                    // Remove blank page added when previous content ends at the bottom of the page.
                    // @ts-ignore
                    const previousPage = doc.internal.getNumberOfPages();
                    if (index === 0) doc.deletePage(previousPage);

                    // Avoid table titles to get placed on the page bottom margin.
                    doc.addPage();
                }

                const tableTitle = `${entityTable.title} (${entityTable.data?.length})`;
                const tableHead = entityTable.columns.map(
                    tableColumn => tableColumn.label
                );
                const tableBody = entityTable.data?.map(item => {
                    const row = [];
                    entityTable.columns.forEach(column => {
                        row.push(
                            column.formatFunction
                                ? column.formatFunction(item)
                                : item[column.id]
                        );
                    });
                    return row;
                });

                const tableColumnStyles = {};
                entityTable.columns.forEach((tableColumn, index) => {
                    tableColumnStyles[index] = {
                        cellWidth:
                            (dimensions.pageHeight - PDFElements.pageMargin) *
                            (tableColumn.width / 100),
                        fontSize: 9,
                    };
                });

                doc.setFont("XuntaSans-Bold", "normal");
                doc.setFontSize(12);
                doc.setTextColor(CUSTOM_COLORS.text.primary);
                doc.text(tableTitle, PDFElements.pageMargin, titlePositionTop);

                autoTable(doc, {
                    startY: tablePositionTop,
                    margin: {top: 35},
                    head: [tableHead],
                    headStyles: {
                        valign: "middle",
                        fillColor: CUSTOM_COLORS.primary.main,
                        textColor: CUSTOM_COLORS.white,
                        fontSize: 9,
                    },
                    body: tableBody,
                    bodyStyles: {
                        textColor: CUSTOM_COLORS.text.primary,
                    },
                    columnStyles: {...tableColumnStyles},
                    // @ts-ignore
                    didDrawPage: function(data) {
                        PDFElements.drawHeader(doc, entityData);
                        PDFElements.drawFooter(doc);
                    },
                });
            });

        doc.save(`download_${entityData.name}.pdf`);

        if (callback) {
            callback();
        }
    };
    return {download};
}
