import {FormInputDecimal, FormSelect} from "base/component/form";

import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const EDARFormExplotacionFields = ({layout = "row"}) => {
    const {dominioedarreutilizacion} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="consumo_electrico"
                    label="Consumo eléctrico"
                    endAdornment="kwh/ano"
                    tooltipText="Media do consumo eléctrico anual nos últimos 5 anos"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="potencia"
                    label="Potencia instalada"
                    endAdornment="kW"
                    tooltipText="Total de potencia eléctrica instalada na planta"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="reutilizacion"
                    label="Reutilización"
                    options={dominioedarreutilizacion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="residuos"
                    label="Producción de residuos"
                    endAdornment="t/ano"
                    tooltipText="Cantidade de residuos (distintos dos lodos) producida na planta"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="reactivos"
                    label="Consumo de reactivos"
                    endAdornment="t/ano"
                    tooltipText="Cantidade de reactivos consumidos"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormExplotacionFields;
