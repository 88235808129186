import {Route} from "react-router-dom";
import {
    CreateColectorPage,
    ListColectorPage,
    ViewColectorPanel,
    ViewColectorPage,
    ViewColectorLocationSubPage,
    ViewColectorDocumentsSubPage,
    ViewColectorSummarySubPage,
    ViewColectorDetailSubPage,
    UpdateColectorPanel,
    ManageColectorPage,
    ViewColectorDocumentPanel,
    ViewColectorChangesSubPage,
} from "saneamiento/colector/container";

const colectorRoutes = [
    <Route key="colector-new" path="colectores/new" element={<CreateColectorPage />} />,
    <Route key="colector-manage" path="colectores" element={<ManageColectorPage />}>
        <Route key="colector-list" path="" element={<ListColectorPage />}>
            <Route
                key="colector-info"
                path="info/:idColector"
                element={<ViewColectorPanel />}
            />
        </Route>
        <Route key="colector-detail" path=":idColector" element={<ViewColectorPage />}>
            <Route
                key="colector-location"
                path="location"
                element={<ViewColectorLocationSubPage />}
            >
                {" "}
                <Route
                    key="colector-location-edit"
                    path=":section/:action"
                    element={<UpdateColectorPanel />}
                />
            </Route>
            <Route
                key="colector-documents"
                path="documents/*"
                element={<ViewColectorDocumentsSubPage />}
            >
                <Route
                    key="colector-documents-view"
                    path="detail/*"
                    element={<ViewColectorDocumentPanel />}
                />
            </Route>
            <Route
                key="colector-summary"
                path="summary"
                element={<ViewColectorSummarySubPage />}
            >
                <Route
                    key="colector-general-edit"
                    path=":section/:action"
                    element={<UpdateColectorPanel />}
                />
            </Route>
            <Route
                key="colector-detail"
                path="detail"
                element={<ViewColectorDetailSubPage />}
            >
                <Route
                    key="colector-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateColectorPanel />}
                />
            </Route>
            <Route
                key="colector-changes"
                path="changes"
                element={<ViewColectorChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default colectorRoutes;
