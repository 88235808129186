import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {FormSelect} from "base/component/form";
import {StatsFilterLayout} from "stats/menu";
import {ProvinciaService} from "concello/service";

const StatsInfrastructureTypeFilter = ({
    filter,
    onChange = null,
    showProvincia = true,
}) => {
    const [provincias, setProvincias] = useState(null);

    const formMethods = useForm({
        defaultValues: {
            infrastructure_type: filter?.infrastructure_type || "",
            attribute: filter?.attribute || "",
            provincia: filter?.provincia || "",
            concello: filter?.concello || "",
        },
    });

    useEffect(() => {
        Promise.all([ProvinciaService.getList()]).then(([provincias]) => {
            setProvincias(
                provincias.map(provincia => {
                    return {value: provincia.id, label: provincia.nome};
                })
            );
        });
    }, []);

    const handleChange = value => {
        onChange(formMethods.getValues());
    };

    let filterAttributes = [
        <FormSelect
            name="attribute"
            label="Atributo"
            options={[
                {value: "gestion", label: "Xestión"},
                {value: "titular", label: "Titular"},
                {value: "estado", label: "Estado"},
                {value: "proteccion", label: "Protección"},
                {value: "telegestion", label: "Telexestión"},
                {value: "electricidad", label: "Suministro eléctrico"},
                {value: "caudalimetro", label: "Cuadalímetro"},
            ]}
            onChangeHandler={handleChange}
            showEmptyOption={false}
        />,
        <FormSelect
            name="infrastructure_type"
            label="Tipo de infraestructura"
            options={[
                {value: "etaps", label: "ETAP"},
                {value: "depositos", label: "Depósitos"},
                {value: "captacions", label: "Captacións"},
            ]}
            onChangeHandler={handleChange}
            showEmptyOption={false}
        />,
    ];

    if (showProvincia) {
        filterAttributes.push(
            <FormSelect
                name="provincia"
                label="Provincia"
                options={provincias}
                onChangeHandler={handleChange}
                showEmptyOption={true}
            />
        );
    }

    return (
        <FormProvider {...formMethods}>
            <StatsFilterLayout filterAttributes={filterAttributes}></StatsFilterLayout>
        </FormProvider>
    );
};

export default StatsInfrastructureTypeFilter;
