import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {PageLayout} from "base/layout";
import {AglomeracionPageMenu} from "saneamiento/aglomeracion/menu";
import {AglomeracionService} from "saneamiento/aglomeracion/service";
import {useConfigModule} from "base/provider";
import {AlertError, SpinnerFullHeight} from "base/component/presentational";
import {useAppConfig} from "base/provider";

const ViewAglomeracionPage = () => {
    const {id} = useParams();

    const {setPagePath} = useAppConfig();
    const {moduleFilter, setModuleFilter} = useConfigModule();

    const [aglomeracion, setAglomeracion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        AglomeracionService.get(id)
            .then(data => {
                setPagePath([{name: data.nome, path: `${data.id}/summary`}]);
                setModuleFilter({...moduleFilter, aglomeracion: id, concello: null});
                setAglomeracion(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [id, location.state?.lastRefreshDate]);

    return loading ? (
        <SpinnerFullHeight />
    ) : error ? (
        <AlertError error={error} />
    ) : (
        aglomeracion && (
            <PageLayout
                menu={<AglomeracionPageMenu aglomeracion={aglomeracion} />}
                context={[aglomeracion]}
            />
        )
    );
};
export default ViewAglomeracionPage;
