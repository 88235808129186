import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloResumoSistemasCargasSection = ({concello}) => {
    return (
        <SectionCard title="Cargas sistemas">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sistemas <250 Heq:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.rangopequenos,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana en sistemas menores a 250 habitantes equivalentes"
                    />
                    <SectionField
                        label="Sistemas 250<Heq<2000:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.rangomedianos,
                            0
                        )}
                        unit="Heq."
                        tooltipText="Carga urbana en sistemas de entre 250 e 2.000  habitantes equivalentes"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sistemas Heq>2000:"
                        value={NumberUtil.formatDecimal(concello.stats.rangograndes, 0)}
                        unit="Heq."
                        tooltipText="Carga urbana en sistemas maiores a 2.000 habitantes equivalentes"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloResumoSistemasCargasSection;
