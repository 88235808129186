import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARTratamentoProblemasSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`problemas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Problemas" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Problemas:" value={edar.problemas_label} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outros problemas:"
                        value={edar.problemas_outro}
                        tooltipText="Outro tipo de problemas"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARTratamentoProblemasSection;
