import {
    PoligonoFormInventarioFields,
    PoligonoFormGeneralDataFields,
    PoligonoFormSaneamentoFields,
} from ".";

const PoligonoModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <PoligonoFormGeneralDataFields layout="column" />;
    }
    if (section === "inventario") {
        return <PoligonoFormInventarioFields layout="column" />;
    }
    if (section === "saneamento") {
        return <PoligonoFormSaneamentoFields layout="column" />;
    }
    return null;
};

export default PoligonoModificationForm;
