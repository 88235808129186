import {NumberUtil} from "base/utilities";

class Vertidos extends Array {}

const vertido_api_adapter = vertido => {
    if (vertido["geom"]) {
        vertido["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    vertido["created_at"] = new Date(vertido["created_at"]);
    vertido["updated_at"] = new Date(vertido["updated_at"]);
    return vertido;
};

const vertidos_api_adapter = vertidos => vertidos.map(vertido_api_adapter);

const createVertidos = (data = []) => {
    const vertidos = Vertidos.from(data, vertidos => createVertido(vertidos));
    return vertidos;
};

const vertido_view_adapter = vertido => {
    delete vertido["tipo_label"];
    delete vertido["orixe_datos_label"];
    delete vertido["tipo_titular_label"];
    delete vertido["tipo_punto_vertido_label"];
    delete vertido["masa_auga_label"];
    delete vertido["sistema_explotacion_label"];

    delete vertido["folder"];
    delete vertido["featured_image"];
    delete vertido["featured_document"];
    delete vertido["created_at"];
    delete vertido["created_by"];
    delete vertido["updated_at"];
    delete vertido["updated_by"];
    return vertido;
};

const createVertido = ({
    id = null,
    codigo = "",
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    notas = null,
    medio_receptor = null,
    orixe_datos = null,
    orixe_datos_label = null,
    tipo_punto_vertido = null,
    tipo_punto_vertido_label = null,
    tipo = null,
    tipo_label = null,
    tipo_titular = null,
    tipo_titular_label = null,
    codigo_externo = null,
    caracteristicas = null,
    volume_autorizado = null,
    autorizacion_titular = null,
    codigo_cnae = null,
    cnae = null,
    x_cnv = null,
    y_cnv = null,
    codigo_ue = null,
    nome_ue = null,
    masa_auga = null,
    masa_auga_label = null,
    sistema_explotacion = null,
    sistema_explotacion_label = null,
    demarcacion_label = null,
    pending_status = null,
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        folder,
        featured_image,
        featured_document,
        geom,
        notas,
        medio_receptor,
        orixe_datos,
        orixe_datos_label,
        tipo_punto_vertido,
        tipo_punto_vertido_label,
        tipo,
        tipo_label,
        tipo_titular,
        tipo_titular_label,
        codigo_externo,
        caracteristicas,
        volume_autorizado,
        autorizacion_titular,
        codigo_cnae,
        cnae,
        x_cnv,
        y_cnv,
        codigo_ue,
        nome_ue,
        masa_auga,
        masa_auga_label,
        sistema_explotacion,
        sistema_explotacion_label,
        demarcacion_label,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createVertido as default,
    createVertidos,
    vertido_api_adapter,
    vertidos_api_adapter,
    vertido_view_adapter,
};
