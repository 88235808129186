import {EntityFormLocationFields, FormSection} from "base/component/form";

const EntityFormGeneralDataSection = ({fields = null, location = null}) => {
    return (
        <>
            {fields ? (
                <FormSection title="Información general">{fields}</FormSection>
            ) : null}
            {location ? <FormSection title="Ubicación">{location}</FormSection> : null}
        </>
    );
};

export default EntityFormGeneralDataSection;
