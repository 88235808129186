import {FormInputText, FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";
import {AugasHiddenAuth} from "concello/auth";

const PoligonoFormInventarioFields = ({layout = "row"}) => {
    const {dominiopoligonoestado} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <AugasHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="estado"
                        label="Estado"
                        options={dominiopoligonoestado}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="xestor" label="Xestor" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="promotor" label="Promotor" />
                </Grid>
            </AugasHiddenAuth>
        </Grid>
    );
};

export default PoligonoFormInventarioFields;
