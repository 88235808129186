import {FormSelect, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const SubaglomeracionFormProblemasFields = ({layout = "row"}) => {
    const {
        dominiosino,
        dominioaglomeracionfrecuencia,
        dominioaglomeracionalcance,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_sen_datos"
                    label="Sen datos"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_sen_problema"
                    label="Sen problemas"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_infiltracion_frecuencia"
                    label="Infiltración (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_infiltracion_alcance"
                    label="Infiltración (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_augamar_frecuencia"
                    label="Auga de mar (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_augamar_alcance"
                    label="Auga de mar (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_augablanca_frecuencia"
                    label="Auga blanca (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_augablanca_alcance"
                    label="Auga blanca (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_contrapendente_frecuencia"
                    label="Contrapendente (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_contrapendente_alcance"
                    label="Contrapendente (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_rotura_frecuencia"
                    label="Rotura (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_rotura_alcance"
                    label="Rotura (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_atasco_frecuencia"
                    label="Atasco (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_atasco_alcance"
                    label="Atasco (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormTextArea name="problema_outro" label="Outro problema" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_outro_frecuencia"
                    label="Outro (frecuencia)"
                    options={dominioaglomeracionfrecuencia}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="problema_outro_alcance"
                    label="Outro (alcance)"
                    options={dominioaglomeracionalcance}
                />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormProblemasFields;
