import {FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const SubaglomeracionFormRedeMateriaisFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal name="material_plastico" label="Material plástico" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal name="material_formigon" label="Material formigón" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="material_fibrocemento"
                    label="Material fibrocemento"
                />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormRedeMateriaisFields;
