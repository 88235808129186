import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const EDARCargasNonDomesticaSection = ({edar}) => {
    return (
        <SectionCard title="Non doméstica">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.nondomesticaindustrial
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe industrial"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.nondomesticaoutros
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non domestica ( dotacional,flotante...)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default EDARCargasNonDomesticaSection;
