import useTheme from "@mui/material/styles/useTheme";

import {NO_CONTENT_HEIGHT, SIDEBAR_PANEL_DRAWER_WIDTH} from "base/config/measurements";

const SidebarPanelDrawer = ({children, isSidebarPanelOpen}) => {
    const theme = useTheme();
    return (
        <div
            style={{
                zIndex: 1,
                marginLeft: "10px",
                opacity: !isSidebarPanelOpen ? "0" : "1",
                transition: "all .1s ease",
                visibility: !isSidebarPanelOpen ? "hidden" : "visible",
                minWidth: `${isSidebarPanelOpen ? SIDEBAR_PANEL_DRAWER_WIDTH : 0}px`,
                maxWidth: `${isSidebarPanelOpen ? SIDEBAR_PANEL_DRAWER_WIDTH : 0}px`,
                backgroundColor: "white",
            }}
        >
            {children}
        </div>
    );
};

export default SidebarPanelDrawer;
