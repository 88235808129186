import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const VertidoGeneralDataSection = ({vertido}) => {
    const {ROLES} = useAuth();

    const sections = [
        {
            label: "Código externo",
            value: vertido.codigo_externo,
        },
        {
            label: "Concello",
            value: vertido.concello_label,
            linkPath: `/concellos/${vertido.concello}`,
        },
        {
            label: "Aglomeración",
            value: vertido.aglomeracion_label,
            linkPath: `/aglomeracions/${vertido.aglomeracion}/summary`,
        },
        {
            label: "Bacía",
            value: vertido.masa_auga_label,
            linkPath: `/medioreceptor/masaauga/${vertido.masa_auga}/principal`,
        },
        {
            label: "Sistema de explotación",
            value: vertido.sistema_explotacion_label,
            linkPath: `/medioreceptor/sistemaexplotacion/${vertido.sistema_explotacion}`,
        },
    ];

    return (
        <>
            <EntityGeneralDataSection
                featured_image={vertido.featured_image}
                featured_document={vertido.featured_document}
                name={
                    vertido.codigo ? `Código: ${vertido.codigo}` : `ID: ${vertido.id}`
                }
                sections={sections}
            />
        </>
    );
};

export default VertidoGeneralDataSection;
