import {FormProvider, useForm} from "react-hook-form";
import {FormUtil} from "base/utilities";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {VertidoCreationForm, VertidoModificationForm, VertidoGeoForm} from ".";

const VertidoForm = ({
    vertido = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(vertido?.id),
        codigo: FormUtil.getFormValue(vertido?.codigo),
        notas: FormUtil.getFormValue(vertido?.notas),
        medio_receptor: FormUtil.getFormValue(vertido?.medio_receptor),
        orixe_datos: FormUtil.getFormValue(vertido?.orixe_datos),
        tipo_punto_vertido: FormUtil.getFormValue(vertido?.tipo_punto_vertido),
        tipo: FormUtil.getFormValue(vertido?.tipo),
        tipo_titular: FormUtil.getFormValue(vertido?.tipo_titular),
        codigo_externo: FormUtil.getFormValue(vertido?.codigo_externo),
        caracteristicas: FormUtil.getFormValue(vertido?.caracteristicas),
        volume_autorizado: FormUtil.getFormValue(vertido?.volume_autorizado),
        autorizacion_titular: FormUtil.getFormValue(vertido?.autorizacion_titular),
        codigo_cnae: FormUtil.getFormValue(vertido?.codigo_cnae),
        cnae: FormUtil.getFormValue(vertido?.cnae),
        x_cnv: FormUtil.getFormValue(vertido?.x_cnv),
        y_cnv: FormUtil.getFormValue(vertido?.y_cnv),
        codigo_ue: FormUtil.getFormValue(vertido?.codigo_ue),
        nome_ue: FormUtil.getFormValue(vertido?.nome_ue),
        // we must create an object with the same representation as expected search results
        aglomeracion: vertido?.aglomeracion
            ? {id: vertido.aglomeracion, nome: vertido?.aglomeracion_label}
            : null,
        concello: vertido?.concello
            ? {codigo: vertido.concello, nome: vertido?.concello_label}
            : {codigo: concello, nome: ""},
        masa_auga: vertido?.masa_auga
            ? {id: vertido.masa_auga, nome: vertido?.masa_auga_label}
            : null,
        geom: FormUtil.getFormValue(vertido?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            notas: FormUtil.getDataValue(data.notas),
            medio_receptor: FormUtil.getDataValue(data.medio_receptor),
            orixe_datos: FormUtil.getDataValue(data.orixe_datos),
            tipo_punto_vertido: FormUtil.getDataValue(data.tipo_punto_vertido),
            tipo: FormUtil.getDataValue(data.tipo),
            tipo_titular: FormUtil.getDataValue(data.tipo_titular),
            codigo_externo: FormUtil.getDataValue(data.codigo_externo),
            volume_autorizado: FormUtil.getDataValue(data.volume_autorizado),
            caracteristicas: FormUtil.getDataValue(data.caracteristicas),
            autorizacion_titular: FormUtil.getDataValue(data.autorizacion_titular),
            codigo_cnae: FormUtil.getDataValue(data.codigo_cnae),
            cnae: FormUtil.getDataValue(data.cnae),
            x_cnv: FormUtil.getDataValue(data.x_cnv),
            y_cnv: FormUtil.getDataValue(data.y_cnv),
            codigo_ue: FormUtil.getDataValue(data.codigo_ue),
            nome_ue: FormUtil.getDataValue(data.nome_ue),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            masa_auga: FormUtil.getDataValue(data.masa_auga?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <VertidoGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <VertidoModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <VertidoCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default VertidoForm;
