import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionRedeDatosSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`rededatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Datos" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Desbordamento:"
                        value={subaglomeracion.desbordamento_label}
                        tooltipText="Frecuencia de desbordamentos do sistema"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Augas brancas:"
                        value={subaglomeracion.augas_brancas}
                        tooltipText="Número de mananciais / regatos que descargan na rede de saneamento"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionRedeDatosSection;
