import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "l",
    fontSize: 35,
    addStroke: true,
    weight: 2,
    color: "#abd5ff",
    fillOpacity: 0,
    opacity: 1,
    lineJoin: "round",
    pane: mapOverlayPanes[2],
};

export const LayerLegend = createLayerLegend({
    layerName: "Sistemas PMAA",
    menuIconShape: "c",
    defaultMarkerOptions: markerBaseOptions,
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        maxZoomOnDetail: 12,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Sistema: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Código</i>: ${data.codigo ? data.codigo : "---"}</li>`;
            return tooltip + "</ul>";
        },
        getCustomMarkerOptions: ({options}) =>
            detail ? {...options, weight: 3, opacity: 1} : options,
        defaultOnClick: ({feature}) => navigate(`info/sistemas/${feature.id}`),
        onMouseOver: ({layer}) =>
            layer.setStyle({
                weight: 2,
            }),
        onMouseOut: ({layer}) =>
            layer.setStyle({
                weight: 1,
            }),
    });
}
