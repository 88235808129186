import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaMonitoreoAvaliacionSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`monitoreo_avaliacion/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Cumpriméntase este formulario de forma anual:",
            value: gobernanza.cumprimentase_formulario_anualmente_label,
        },
        {
            label: "Avalíanse os resultados do formulario de forma anual:",
            value: gobernanza.avalianse_resultados_anualmente_label,
        },
        {
            label: "Compártense os resultados da avaliación cos interesados:",
            value: gobernanza.compartense_resultados_label,
        },
        {
            label: "Realízanse axustes en base aos resultados da avaliación:",
            value: gobernanza.realizanse_axustes_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Monitoreo e avaliación"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_monitoreo_avaliacion}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_monitoreo_avaliacion,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaMonitoreoAvaliacionSection;
