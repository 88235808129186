import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";
import {SisbagalMap} from "sisbagal/map";
import {CoordinatesGeojsonSection} from "base/component/geo";

const EntityLocationSection = ({entity, mapConfig = {}}) => {
    return (
        entity && (
            <>
                <CoordinatesGeojsonSection geom={entity.geom} />
                <MapConfigProvider>
                    <MapProvider config={mapConfig} geom={entity.geom}>
                        <SisbagalMap
                            legend={<SisbagalMapMenu />}
                            mapOptions={{minZoom: 5}}
                            height="400px"
                        />
                    </MapProvider>
                </MapConfigProvider>
            </>
        )
    );
};

export default EntityLocationSection;
