import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {SubaglomeracionService} from "saneamiento/service";
import {PageLayout} from "base/layout";
import {SubaglomeracionSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";

const ViewSubaglomeracionPage = () => {
    const {setSubpagePath} = useAppConfig();

    const {idSubaglomeracion, codigo} = useParams();
    // To find if is from concello or from subaglomeración
    const isFromConcello = codigo != null;

    const [subaglomeracion, setSubaglomeracion] = useState(null);
    const location = useLocation();

    useEffect(() => {
        SubaglomeracionService.get(idSubaglomeracion).then(data => {
            setSubaglomeracion(data);
            setSubpagePath([
                {
                    name: "Listado de sistemas colectores",
                    path: "subaglomeracions",
                },
                {
                    name: `Aglomeración de ${data.aglomeracion_label}, Concello de ${data.concello_label}`,
                    path: `${data.id}/map`,
                },
            ]);
        });
        return () => {
            setSubpagePath([]);
        };
    }, [idSubaglomeracion, location.state?.lastRefreshDate]);

    return (
        subaglomeracion && (
            <PageLayout
                menu={<SubaglomeracionSubPageMenu subaglomeracion={subaglomeracion} />}
                context={[subaglomeracion]}
                subPage={true}
            />
        )
    );
};

export default ViewSubaglomeracionPage;
