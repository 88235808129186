import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormInnovacionFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="realizanse_experiencias_piloto"
                    label="Realízanse experiencias piloto nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="O concello realiza experiencias piloto innovadoras en relación cos servizos ou foméntase a súa realización polos usuarios dende o concello."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="fomentanse_mecanismos_cooperacion_innovadores"
                    label="Foméntanse mecanismos de cooperación innovadora nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, cooperacións innovadoras con outros organismos u outras áreas para buscar sinerxias: recuperación de substancias dos lodos, xeración de enerxía aproveitando a auga ou os lodos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="fomentanse_ferramentas_innovadoras"
                    label="Foméntanse ferramentas e procesos innovadores nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Foméntanse ferramentas e procesos innovadores  nos servizos para: concienciar, involucrar ás partes interesadas, compartir información, participar... Por exemplo, visitas virtuais ás istalacións, publicacións en redes sociais, etc."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormInnovacionFields;
