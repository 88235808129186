import {PageLayout} from "base/layout";
import {MasaAugaService} from "medioreceptor/service";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {MasaAugaPageMenu} from "../menu";
import {AlertError, SpinnerFullHeight} from "base/component/presentational";
import {useAppConfig} from "base/provider";

const ViewMasaAugaPage = () => {
    const {setPagePath} = useAppConfig();

    const {id} = useParams();
    const [masaAuga, setMasaAuga] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        MasaAugaService.get(id)
            .then(data => {
                setPagePath([
                    {
                        name: `Bacía: ${data.nome}`,
                        path: `masaauga/${data.id}/principal`,
                    },
                ]);
                setMasaAuga(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
        return () => {
            setPagePath([]);
            setMasaAuga(null);
        };
    }, [id, location.state?.lastRefreshDate]);

    return loading ? (
        <SpinnerFullHeight />
    ) : error ? (
        <AlertError error={error} />
    ) : (
        masaAuga && (
            <PageLayout
                menu={<MasaAugaPageMenu masaAuga={masaAuga} />}
                context={[masaAuga]}
            />
        )
    );
};
export default ViewMasaAugaPage;
