import {Route} from "react-router-dom";
import {
    ViewGobernanzaPage,
    ViewGobernanzaSummarySubPage,
    ViewGobernanzaEficaciaSubPage,
    ViewGobernanzaEficienciaSubPage,
    ViewGobernanzaConfianzaSubPage,
    UpdateGobernanzaPanel,
} from "../container";

const gobernanzaRoutes = [
    <Route key="gobernanza-view" path="gobernanza" element={<ViewGobernanzaPage />}>
        <Route
            key="gobernanza-summary"
            path="summary"
            element={<ViewGobernanzaSummarySubPage />}
        />
        <Route
            key="gobernanza-eficacia"
            path="eficacia"
            element={<ViewGobernanzaEficaciaSubPage />}
        >
            <Route
                key="gobernanza-general-edit"
                path=":section/:action"
                element={<UpdateGobernanzaPanel />}
            />
        </Route>
        <Route
            key="gobernanza-eficiencia"
            path="eficiencia"
            element={<ViewGobernanzaEficienciaSubPage />}
        >
            <Route
                key="gobernanza-general-edit"
                path=":section/:action"
                element={<UpdateGobernanzaPanel />}
            />
        </Route>
        <Route
            key="gobernanza-confianza"
            path="confianza"
            element={<ViewGobernanzaConfianzaSubPage />}
        >
            <Route
                key="gobernanza-general-edit"
                path=":section/:action"
                element={<UpdateGobernanzaPanel />}
            />
        </Route>
    </Route>,
];

export default gobernanzaRoutes;
