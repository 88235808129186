import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionPoboacionNonConectadaSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Non conectada">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Saneamento autónomo:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.domesticaautonomo
                        )}
                        unit="Heq"
                        tooltipText="Poboación con saneamento autónomo dentro da aglomeración"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sen depuración:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.domesticasentratamento
                        )}
                        unit="Heq"
                        tooltipText="Poboación dentro da aglomeración conectada a rede de saneamento que non recibe tratamento de depuración"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionPoboacionNonConectadaSection;
