import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const EDARCargasDomesticaSection = ({edar}) => {
    return (
        <SectionCard title="Doméstica">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fixa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.cargadomesticafixa
                        )}
                        unit="Heq"
                        tooltipText="Poboación fixa."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacional:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.cargadomesticaestacional
                        )}
                        unit="Heq"
                        tooltipText="Poboación estacional orixe doméstica."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default EDARCargasDomesticaSection;
