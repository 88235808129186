import {createEntityService} from "base/entity/service";
import {
    createBombeo,
    createBombeos,
    bombeo_api_adapter,
    bombeos_api_adapter,
} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/bombeos",
    createBombeo,
    createBombeos,
    bombeo_api_adapter,
    bombeos_api_adapter
);

const BombeoService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(bombeo) {
        return entityService.create(bombeo);
    },

    update(bombeo) {
        return entityService.update(bombeo);
    },

    updateWithPatch(bombeo) {
        return entityService.updateWithPatch(bombeo);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default BombeoService;
