import {useETAPMap} from "abastecemento/etap/hooks";
import {EntityLocationSection} from "base/entity";

const ETAPLocationSection = ({etap}) => {
    const {mapConfig} = useETAPMap();

    return <EntityLocationSection entity={etap} mapConfig={mapConfig} />;
};

export default ETAPLocationSection;
