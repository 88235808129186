import {FormProvider, useForm} from "react-hook-form";

import {SearchBoxControlled} from "base/component/search";
import {EntityCounter} from "base/entity";

import Grid from "@mui/material/Grid";
import {useConfigModule, useList} from "base/provider";
import {useCallback, useEffect, useState} from "react";
import {FormSelect} from "base/component/form";
import {SistemaService} from "abastecemento/service";
import {useParams, useSearchParams} from "react-router-dom";

const EntityWithSistemaFilterForm = ({entityName}) => {
    const {moduleFilter, setModuleFilter} = useConfigModule();
    const {filter, setFilter, setPage, size} = useList();
    const [sistemas, setSistemas] = useState([]);

    const {codigo} = useParams();
    const [searchParams] = useSearchParams();

    const formMethods = useForm({
        defaultValues: {
            search: filter?.search || "",
            sistema: filter?.sistema || "",
        },
    });

    useEffect(() => {
        handleChange({sistema: searchParams.get("sistema")});
        setModuleFilter({...moduleFilter, sistema: searchParams.get("sistema")});
    }, [searchParams]);

    useEffect(() => {
        SistemaService.getList({concello: codigo}).then(sistemas => {
            const sistemasOptions = sistemas.map(sistema => {
                return {
                    value: sistema.id,
                    label: `[${sistema.codigo}] ${sistema.nome}`,
                };
            });
            sistemasOptions.sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            );
            setSistemas(sistemasOptions);
        });
    }, []);

    const handleChange = useCallback(
        attributeValue => {
            setPage(1);
            setFilter({...filter, ...attributeValue});
        },
        [filter]
    );

    return (
        <FormProvider {...formMethods}>
            <Grid container mb={3} component="form" spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <SearchBoxControlled
                        name="search"
                        onChangeHandler={value => handleChange({search: value})}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelect
                        name="sistema"
                        label="Sistema"
                        options={sistemas}
                        onChangeHandler={value => handleChange({sistema: value})}
                        showEmptyOption={true}
                    />
                </Grid>
                <Grid item>
                    <EntityCounter size={size} entityName={entityName} />
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default EntityWithSistemaFilterForm;
