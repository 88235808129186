import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ColectorService} from "saneamiento/service";
import {colector_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {ColectorForm} from "../presentational/form";

const CreateColectorPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = colector => {
        ColectorService.create(colector_view_adapter({...colector}))
            .then(createdColector => {
                navigate(`${basePath}/${createdColector.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <ColectorForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de Colector"
            error={error}
        />
    );
};

export default CreateColectorPage;
