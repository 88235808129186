import {useEffect, useState} from "react";

import {StatsService} from "stats/service";
import {DoughnutChart} from "base/component/chart";

import CircularProgress from "@mui/material/CircularProgress";

const findLegendColor = (legendItems, label) => {
    return legendItems.find(legendItem => legendItem.label === label)?.color;
};

const ViewStatsConcellosCargasChart = ({statsFilter}) => {
    const chartStatsConfig = {
        title: "% carga conectada vs. total",
        legendItems: [
            {
                color: "#F3D6F6",
                label: "-",
            },
            {
                color: "#EBAEE0",
                label: "0-0.25",
            },
            {
                color: "#DF88B7",
                label: "0.25-0.5",
            },
            {
                color: "#D2637F",
                label: "0.5-0.75",
            },
            {
                color: "#c3423f",
                label: "0.75-1",
            },
        ],
    };

    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        setLoading(true);
        setChartData(null);
        StatsService.getConcelloCargasData(statsFilter).then(data => {
            setLoading(false);
            const orderedData = chartStatsConfig.legendItems
                .map(legendItem =>
                    data.find(labelResult => labelResult.label === legendItem.label)
                )
                .filter(n => n); // Remove nulls
            setChartData(orderedData);
        });
    }, [statsFilter]);

    const getChartLabels = chartData => {
        return chartData.map(item => item.label);
    };

    const getChartColors = chartData => {
        return chartData.map(item =>
            findLegendColor(chartStatsConfig.legendItems, item.label)
        );
    };

    const getChartDataset = chartData => {
        const data = chartData.map(item => item.value);
        return [
            {
                label: chartStatsConfig.title,
                data,
                backgroundColor: getChartColors(chartData),
                borderWidth: 1,
            },
        ];
    };

    return loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        chartData && (
            <DoughnutChart
                labels={getChartLabels(chartData)}
                datasets={getChartDataset(chartData)}
                title={chartStatsConfig.title}
            />
        )
    );
};
export default ViewStatsConcellosCargasChart;
