import {useNavigate, useParams} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewBombeoPanel = () => {
    const {idBombeo} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = bombeo => {
        const basePath =
            path === "concellos"
                ? `/concellos/${bombeo.concello}`
                : `/aglomeracions/${bombeo.aglomeracion}`;
        navigate(`${basePath}/bombeos/${bombeo.id}/summary`);
    };

    const getSectionData = bombeo => {
        return (
            <>
                <SectionField label="Código:" value={bombeo.codigo} />
                <SectionField label="Bombas activas:" value={bombeo.bombas_activas} />
                <SectionField label="Bombas reserva:" value={bombeo.bombas_reserva} />
                <SectionField
                    label="Potencia:"
                    value={NumberUtil.formatDecimal(bombeo.potencia)}
                    unit="kW"
                />
                <SectionField
                    label="Caudal bombeo:"
                    value={NumberUtil.formatDecimal(bombeo.caudal_bombeo)}
                    unit="l/s"
                />
                <SectionField
                    label="Altura:"
                    value={NumberUtil.formatDecimal(bombeo.altura_bombeo)}
                    unit="m.c.a"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={BombeoService}
            id={idBombeo}
            title="Resumo de bombeo"
            getSectionTitle={entity => entity?.nome || entity?.codigo || entity?.id}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewBombeoPanel;
