import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const BombeoSubPageMenu = ({bombeo}) => {
    return (
        <ChangesSubPageMenu
            pending_status={bombeo.pending_status}
            headingPrimaryText={bombeo.nome || bombeo.codigo || bombeo.id}
            headingSecondaryText="Bombeo"
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Ficha"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="detail"
                text="Datos"
                icon={<ViewListOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={bombeo} />
        </ChangesSubPageMenu>
    );
};

export default BombeoSubPageMenu;
