import {NumberUtil} from "base/utilities";

export function useSistemaTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 15,
        },
        {
            id: "nome",
            label: "Nome",
            width: 20,
        },
        {
            id: "titular_label",
            label: "Titular",
            width: 15,
        },
        {
            id: "xestion_label",
            label: "Xestión",
            width: 15,
        },
        {
            id: "expediente",
            label: "Expediente",
            width: 15,
        },
        {
            id: "porcentaxe_conexion",
            label: "% Conexión",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.porcentaxe_conexion),
        },
    ];

    return {tableColumns};
}
