import {BombeoFormLocationFields} from ".";
import {EntityGeoForm} from "base/entity";

const BombeoGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <BombeoFormLocationFields />
        </EntityGeoForm>
    );
};

export default BombeoGeoForm;
