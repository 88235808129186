import {useEffect, useRef} from "react";
import L from "leaflet";
import "proj4leaflet";
import "leaflet/dist/leaflet.css";
import {useCRS} from "./CRSHook";

export const mapOverlayPanes = [...Array(15).keys()].map(i => "overlayPane" + i);

export function useMap() {
    const mapRef = useRef(null);
    const mapObjectRef = useRef(null);

    const {crs} = useCRS();

    // const {addDownloadMapControl} = useDownloadMapControl(mapRef); // DISABLED CONTROL (executed from map actions)

    const createMap = (mapOptions = {}) => {
        const map = L.map(mapRef.current, {
            crs: crs,
            minZoom: 1,
            maxZoom: 12,
            center: [42.791806, -7.976074],
            zoom: 2,
            zoomSnap: 0, // http://leafletjs.com/reference.html#map-zoomsnap
            maxBounds: [
                //south west
                [41.635868, -9.3681649],
                //north east
                [43.918351, -6.5019299],
            ],
            maxBoundsViscosity: 1.0,
            ...mapOptions,
        });
        // addDownloadMapControl(map);

        // Add a series of custom panes for overlay layers
        mapOverlayPanes.forEach((p, i) => {
            map.createPane(p);
            map.getPane(p).style.zIndex = (400 + (i + 1) * 5).toString();
        });

        mapObjectRef.current = map;

        return map;
    };

    return {mapRef, createMap};
}
