import {useState} from "react";

import {useMapProvider} from "sisbagal/map";
import {useDownloadMapWithTablesAsPDF} from "base/component/geo";
import {useEDARTable} from "saneamiento/edar/hooks";
import {useBombeoTable} from "saneamiento/bombeo/hooks";
import {useTanqueTable} from "saneamiento/tanque/hooks";
import {useVertidoTable} from "saneamiento/vertido/hooks";
import {usePoligonoTable} from "saneamiento/poligono/hooks";
import {useSistemaTable} from "abastecemento/sistema/hooks";
import {useETAPTable} from "abastecemento/etap/hooks";
import {useDepositoTable} from "abastecemento/deposito/hooks";
import {useCaptacionTable} from "abastecemento/captacion/hooks";

import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const SisbagalMapDownloadPdfAction = ({
    generateMapAsPng,
    getMapAsPngDimensions,
    entityData = {},
}) => {
    const [loading, setLoading] = useState(false);

    const {
        download: handleDownloadMapWithTablesAsPdf,
    } = useDownloadMapWithTablesAsPDF();

    const {
        edarMapProvider: {
            visible: edarsVisible,
            included: edarsIncluded,
            loadTableData: edarsLoadTableData,
        },
        bombeoMapProvider: {
            visible: bombeosVisible,
            included: bombeosIncluded,
            loadTableData: bombeosLoadTableData,
        },
        tanqueMapProvider: {
            visible: tanquesVisible,
            included: tanquesIncluded,
            loadTableData: tanquesLoadTableData,
        },
        vertidoMapProvider: {
            visible: vertidosVisible,
            included: vertidosIncluded,
            loadTableData: vertidosLoadTableData,
        },
        poligonoMapProvider: {
            visible: poligonosVisible,
            included: poligonosIncluded,
            loadTableData: poligonosLoadTableData,
        },
        sistemaMapProvider: {
            visible: sistemasVisible,
            included: sistemasIncluded,
            loadTableData: sistemasLoadTableData,
        },
        etapMapProvider: {
            visible: etapsVisible,
            included: etapsIncluded,
            loadTableData: etapsLoadTableData,
        },
        depositoMapProvider: {
            visible: depositosVisible,
            included: depositosIncluded,
            loadTableData: depositosLoadTableData,
        },
        captacionMapProvider: {
            visible: captacionsVisible,
            included: captacionsIncluded,
            loadTableData: captacionsLoadTableData,
        },
    } = useMapProvider();

    const {tableColumns: edarsTableColumns} = useEDARTable();
    const {tableColumns: bombeosTableColumns} = useBombeoTable();
    const {tableColumns: tanquesTableColumns} = useTanqueTable();
    const {tableColumns: vertidosTableColumns} = useVertidoTable();
    const {tableColumns: poligonosTableColumns} = usePoligonoTable();
    const {tableColumns: sistemasTableColumns} = useSistemaTable();
    const {tableColumns: etapsTableColumns} = useETAPTable();
    const {tableColumns: depositosTableColumns} = useDepositoTable();
    const {tableColumns: captacionsTableColumns} = useCaptacionTable();

    const loadData = (service, load) => {
        if (load) {
            return service();
        }
        return Promise.resolve(null);
    };

    const handleGeneratePDF = async () => {
        setLoading(true);
        const mapDataUrl = await generateMapAsPng();
        const mapDimensions = getMapAsPngDimensions();

        const serviceTableEntities = entityData.hasOwnProperty("serviceTableEntities")
            ? entityData["serviceTableEntities"]
            : [];

        const promises = [
            loadData(edarsLoadTableData, edarsVisible && edarsIncluded),
            loadData(bombeosLoadTableData, bombeosVisible && bombeosIncluded),
            loadData(tanquesLoadTableData, tanquesVisible && tanquesIncluded),
            loadData(vertidosLoadTableData, vertidosVisible && vertidosIncluded),
            loadData(poligonosLoadTableData, poligonosVisible && poligonosIncluded),
            loadData(sistemasLoadTableData, sistemasVisible && sistemasIncluded),
            loadData(etapsLoadTableData, etapsVisible && etapsIncluded),
            loadData(depositosLoadTableData, depositosVisible && depositosIncluded),
            loadData(captacionsLoadTableData, captacionsVisible && captacionsIncluded),
        ];

        serviceTableEntities.forEach(serviceTableEntity => {
            promises.push(serviceTableEntity.service());
        });

        Promise.all(promises).then(
            ([
                edars,
                bombeos,
                tanques,
                vertidos,
                poligonos,
                sistemas,
                etaps,
                depositos,
                captacions,
                ...serviceTableEntitiesResults
            ]) => {
                let tableEntities = [];

                serviceTableEntities.forEach((serviceTableEntity, index) => {
                    tableEntities.push({
                        columns: serviceTableEntity.columns,
                        data: serviceTableEntitiesResults[index],
                        title: serviceTableEntity.title,
                    });
                });

                if (edars) {
                    tableEntities.push({
                        columns: edarsTableColumns,
                        data: edars,
                        title: "EDAR",
                    });
                }
                if (bombeos) {
                    tableEntities.push({
                        columns: bombeosTableColumns,
                        data: bombeos,
                        title: "Bombeos",
                    });
                }
                if (tanques) {
                    tableEntities.push({
                        columns: tanquesTableColumns,
                        data: tanques,
                        title: "Tanques",
                    });
                }
                if (vertidos) {
                    tableEntities.push({
                        columns: vertidosTableColumns,
                        data: vertidos,
                        title: "Vertidos",
                    });
                }
                if (poligonos) {
                    tableEntities.push({
                        columns: poligonosTableColumns,
                        data: poligonos,
                        title: "Polígonos",
                    });
                }
                if (sistemas) {
                    tableEntities.push({
                        columns: sistemasTableColumns,
                        data: sistemas,
                        title: "Sistemas PMAA",
                    });
                }
                if (etaps) {
                    tableEntities.push({
                        columns: etapsTableColumns,
                        data: etaps,
                        title: "ETAP",
                    });
                }
                if (depositos) {
                    tableEntities.push({
                        columns: depositosTableColumns,
                        data: depositos,
                        title: "Depósitos",
                    });
                }
                if (captacions) {
                    tableEntities.push({
                        columns: captacionsTableColumns,
                        data: captacions,
                        title: "Captacións",
                    });
                }
                handleDownloadMapWithTablesAsPdf(
                    tableEntities,
                    mapDataUrl,
                    mapDimensions,
                    entityData,
                    () => {
                        setLoading(false);
                    }
                );
            }
        );
    };

    return (
        <Box
            sx={{
                width: "40px",
                height: "40px",
            }}
        >
            {loading ? (
                <CircularProgress size={22} sx={{ml: 1, mt: 1}} />
            ) : (
                <Tooltip title={"Descargar como PDF"}>
                    <IconButton
                        aria-label="download-pdf"
                        onClick={handleGeneratePDF}
                        color="primary"
                    >
                        <PrintOutlinedIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default SisbagalMapDownloadPdfAction;
