import {Route} from "react-router-dom";
import {ViewDepositoPanel} from "abastecemento/deposito/container";
import {
    CreateSistemaPage,
    ListSistemaPage,
    ViewSistemaPanel,
    ViewSistemaPage,
    ViewSistemaLocationSubPage,
    ViewSistemaDocumentsSubPage,
    UpdateSistemaPanel,
    ManageSistemaPage,
    ViewSistemaDocumentPanel,
    ViewSistemaMapSubPage,
    ViewSistemaChangesSubPage,
} from "abastecemento/sistema/container";
import {ViewCaptacionPanel} from "abastecemento/captacion/container";
import ViewSistemaDetailSubPage from "../container/ViewSistemaDetailSubPage";
import {ViewETAPPanel} from "abastecemento/etap/container";

const sistemaRoutes = [
    <Route key="sistema-new" path="sistemas/new" element={<CreateSistemaPage />} />,
    <Route key="sistema-manage" path="sistemas" element={<ManageSistemaPage />}>
        <Route key="sistema-list" path="" element={<ListSistemaPage />}>
            <Route
                key="sistema-info"
                path="info/:idSistema"
                element={<ViewSistemaPanel />}
            />
        </Route>
        <Route key="sistema-detail" path=":idSistema" element={<ViewSistemaPage />}>
            <Route
                key="sistema-location"
                path="location"
                element={<ViewSistemaLocationSubPage />}
            >
                <Route
                    key="sistema-location-edit"
                    path=":section/:action"
                    element={<UpdateSistemaPanel />}
                />
            </Route>
            <Route
                key="sistema-documents"
                path="documents/*"
                element={<ViewSistemaDocumentsSubPage />}
            >
                <Route
                    key="sistema-documents-view"
                    path="detail/*"
                    element={<ViewSistemaDocumentPanel />}
                />
            </Route>
            <Route
                key="sistema-summary"
                path="summary"
                element={<ViewSistemaMapSubPage />}
            >
                <Route
                    key="sistema-etaps-info"
                    path="info/etaps/:idEtap"
                    element={<ViewETAPPanel />}
                />
                <Route
                    key="sistema-depositos-info"
                    path="info/depositos/:idDeposito"
                    element={<ViewDepositoPanel />}
                />
                <Route
                    key="sistema-captacions-info"
                    path="info/captacions/:idCaptacion"
                    element={<ViewCaptacionPanel />}
                />
            </Route>
            <Route
                key="sistema-detail"
                path="detail"
                element={<ViewSistemaDetailSubPage />}
            >
                <Route
                    key="sistema-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateSistemaPanel />}
                />
            </Route>
            <Route
                key="sistema-changes"
                path="changes"
                element={<ViewSistemaChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default sistemaRoutes;
