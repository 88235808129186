import {Route} from "react-router-dom";
import {UpdatePerdidasPanel, ViewPerdidasPage, ViewPerdidasSubPage} from "../container";

const perdidasRoutes = [
    <Route key="perdidas-view" path="datos" element={<ViewPerdidasPage />}>
        <Route key="perdidas" path="perdidas" element={<ViewPerdidasSubPage />}>
            <Route
                key="perdidas-datos-edit"
                path=":section/:action"
                element={<UpdatePerdidasPanel />}
            />
        </Route>
    </Route>,
];

export default perdidasRoutes;
