import {FieldUtil} from "base/utilities";

export function useCaptacionData(captacion) {
    const getEntityData = captacion => {
        return {
            image: captacion?.featured_image,
            name: `Captación ${captacion?.codigo || captacion?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(captacion?.codigo)],
                        ["Concello:", FieldUtil.getValue(captacion?.concello_label)],
                        ["Titular:", FieldUtil.getValue(captacion?.titular_label)],
                        ["Xestión:", FieldUtil.getValue(captacion?.xestion_label)],
                        ["Estado:", FieldUtil.getValue(captacion?.estado_label)],
                        ["Descripción:", FieldUtil.getValue(captacion?.descripcion)],
                        [
                            "Sistema PMAA:",
                            FieldUtil.getValue(
                                captacion?.sistema_label || captacion?.sistema
                            ),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                captacion?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(
                                captacion?.geom.coordinates[1]
                            )}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        ["Bacía:", FieldUtil.getValue(captacion?.bacia)],
                        ["Orixe:", FieldUtil.getValue(captacion?.material_label)],
                        ["Planta:", FieldUtil.getValue(captacion?.planta_label)],
                        ["Tipo:", FieldUtil.getValue(captacion?.tipo_label)],
                        ["Uso:", FieldUtil.getValue(captacion?.uso_label)],
                        [
                            "Legalizada:",
                            FieldUtil.getValue(captacion?.legalizada_label),
                        ],
                        ["Bombeo:", FieldUtil.getValue(captacion?.bombeo_label)],
                        ["Notas:", FieldUtil.getValue(captacion?.notas)],
                        [
                            "Protección:",
                            FieldUtil.getValue(captacion?.proteccion_label),
                        ],
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(captacion?.suministro_electrico_label),
                        ],
                        [
                            "Telexestión:",
                            FieldUtil.getValue(captacion?.telexestion_label),
                        ],
                        [
                            "Caudalímetro:",
                            FieldUtil.getValue(captacion?.caudalimetro_label),
                        ],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
