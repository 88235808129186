import Tooltip from "@mui/material/Tooltip";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

const ChangesAlert = ({status}) => {
    const getPendingStatusValue = status => {
        switch (status) {
            case "INSERT":
                return (
                    <Tooltip title="Nuevo elemento (Pendiente de aprobación)">
                        <AddBoxRoundedIcon color="success" />
                    </Tooltip>
                );
            case "UPDATE":
                return (
                    <Tooltip title="Elemento modificado (Pendiente de aprobación)">
                        <IndeterminateCheckBoxRoundedIcon color="warning" />
                    </Tooltip>
                );
            case "DELETE":
                return (
                    <Tooltip title="Elemento eliminado (Pendiente de aprobación)">
                        <DisabledByDefaultRoundedIcon color="error" />
                    </Tooltip>
                );
        }
    };

    return getPendingStatusValue(status);
};

export default ChangesAlert;
