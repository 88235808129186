import {EDARFormLocationFields} from ".";
import {EntityGeoForm} from "base/entity";

const EDARGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <EDARFormLocationFields />
        </EntityGeoForm>
    );
};

export default EDARGeoForm;
