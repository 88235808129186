import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionExplotacionSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Datos de explotación">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Mantemento:"
                        value={aglomeracion.stats.mantemento}
                        tooltipText="Nivel de mantemento do sistema"
                    />
                    <SectionField
                        label="Telecontrol:"
                        value={aglomeracion.stats.telecontrol}
                        tooltipText="Implantación de tecnoloxias de telecontrol"
                    />
                    <SectionField
                        label="Medición alivios:"
                        value={aglomeracion.stats.medicionalivios}
                        tooltipText="Lugares onde se miden os alivios producidos na rede"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Desbordamentos:"
                        value={aglomeracion.stats.desbordamentos}
                        tooltipText="Episodios de desbordamentos no sistema"
                    />
                    <SectionField
                        label="Reutilización de auga:"
                        value={aglomeracion.stats.reutilizacion}
                        tooltipText="Reutilización de auga"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionExplotacionSection;
