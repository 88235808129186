import {useNavigate, useParams} from "react-router-dom";
import {ColectorService} from "saneamiento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewColectorPanel = () => {
    const {idColector} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = colector => {
        const basePath =
            path === "concellos"
                ? `/concellos/${colector.concello}`
                : `/aglomeracions/${colector.aglomeracion}`;
        navigate(`${basePath}/colectores/${colector.id}/summary`);
    };

    const getSectionData = colector => {
        return (
            <>
                <SectionField
                    label="Concello:"
                    value={colector.concello_label}
                    linkPath={`/concellos/${colector.concello}`}
                />
                <SectionField
                    label="Aglomeración:"
                    value={colector.aglomeracion_label}
                    linkPath={`/aglomeracions/${colector.aglomeracion}`}
                />
                <SectionField label="Tipo:" value={colector.tipo_conduccion_label} />
                <SectionField
                    label="Fluxo:"
                    value={NumberUtil.formatDecimal(colector.fluxo)}
                />
                <SectionField label="Natureza:" value={colector.tipo_label} />
                <SectionField
                    label="Diámetro:"
                    value={NumberUtil.formatDecimal(colector.diametro)}
                    unit="mm"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={ColectorService}
            id={idColector}
            title="Resumo de colector"
            getSectionTitle={entity => entity?.nome || entity?.codigo || entity?.id}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewColectorPanel;
