import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useSistemaData} from "../hooks";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {useConfigModule} from "base/provider";
import {SisbagalMap} from "sisbagal/map";
import {PageLayout} from "base/layout";
import {SistemaMapMenu} from "../menu";

const ViewSistemaMapSubPage = () => {
    let sistema;
    [sistema] = useOutletContext();

    const {moduleFilter, setModuleFilter} = useConfigModule();
    const {getEntityData} = useSistemaData();

    useEffect(() => {
        setModuleFilter({...moduleFilter, sistema: sistema.id});
    }, []);

    return (
        moduleFilter.sistema != null && ( // To avoid duplicate call while sistema is not inside the filter
            <MapConfigProvider>
                <MapProvider
                    config={{
                        sistema: {
                            customLoader: () => {
                                return () => Promise.resolve(sistema.geom);
                            },
                            detail: true,
                        },
                        etap: {},
                        deposito: {},
                        captacion: {},
                        tuberia: {
                            visible: false,
                        },
                    }}
                >
                    <PageLayout disablePadding={true}>
                        <SisbagalMap
                            legend={<SistemaMapMenu sistema={sistema} />}
                            showDownloadPdfAction={true}
                            entityData={getEntityData(sistema)}
                        />
                    </PageLayout>
                </MapProvider>
            </MapConfigProvider>
        )
    );
};
export default ViewSistemaMapSubPage;
