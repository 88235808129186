const perdidas_api_adapter = perdidas => {
    perdidas["created_at"] = new Date(perdidas["created_at"]);
    perdidas["updated_at"] = new Date(perdidas["updated_at"]);
    return perdidas;
};

const perdidas_view_adapter = perdidas => {
    delete perdidas["concello_label"];

    delete perdidas["folder"];
    delete perdidas["featured_image"];
    delete perdidas["featured_document"];
    delete perdidas["created_at"];
    delete perdidas["created_by"];
    delete perdidas["updated_at"];
    delete perdidas["updated_by"];
    return perdidas;
};

const createPerdidas = ({
    id = null,
    porcentaxe_volume_perdidas = null,
    cantidade_volume_perdidas = null,
    concello = null,
    concello_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        porcentaxe_volume_perdidas,
        cantidade_volume_perdidas,
        concello,
        concello_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {createPerdidas as default, perdidas_api_adapter, perdidas_view_adapter};
