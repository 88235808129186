import {SectionHeading} from ".";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import SectionActionsMenu from "./SectionActionsMenu";

const SectionCard = ({
    isSidePanelOpen = null,
    headingLabel = true,
    title = "",
    secondaryActions = null,
    ...props
}) => {
    const cardStyle = {
        overflow: "auto",
        width: {xs: 0, sm: "20%", md: "40%", lg: "68%", xl: "100%"},
    };

    const contentMarginTop =
        secondaryActions && secondaryActions.length && title === ""
            ? {mt: "-56px"}
            : {mt: "unset"};

    return (
        <Card
            sx={isSidePanelOpen === true ? cardStyle : {width: "100%"}}
            variant="outlined"
        >
            {(secondaryActions && secondaryActions.length) || title ? (
                <CardHeader
                    title={
                        <SectionHeading label={headingLabel}>{title}</SectionHeading>
                    }
                    action={
                        secondaryActions && secondaryActions.length ? (
                            <SectionActionsMenu>{secondaryActions}</SectionActionsMenu>
                        ) : null
                    }
                />
            ) : null}
            <CardContent sx={{...props.contentStyle, ...contentMarginTop}}>
                {props.children}
            </CardContent>
        </Card>
    );
};

export default SectionCard;
