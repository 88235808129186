import {useDomain} from "domain/provider";
import {SistemaFormSearch} from "../../../sistema/presentational/form";
import {FormInputText, FormSelect, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";

const DepositoFormGeneralDataFields = ({layout = "row"}) => {
    const {dominioabastecementoxestion, dominioabastecementoestado} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="codigo" label="Código" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="titular"
                    label="Titular"
                    options={dominioabastecementoxestion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="xestion"
                    label="Xestión"
                    options={dominioabastecementoxestion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="estado"
                    label="Estado"
                    options={dominioabastecementoestado}
                />
            </Grid>
            <Grid item xs={12}>
                <SistemaFormSearch name="sistema" />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name="descripcion" label="Descripción" />
            </Grid>
        </Grid>
    );
};

export default DepositoFormGeneralDataFields;
