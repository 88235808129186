import {useNavigate} from "react-router-dom";

import {SisbagalSectionCard} from "sisbagal/auth";
import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";

const CaptacionCaracteristicasSection = ({captacion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Bacía:" value={captacion.bacia} />
                    <SectionField label="Orixe:" value={captacion.orixe_label} />
                    <SectionField label="Tipo:" value={captacion.tipo_label} />
                    <SectionField label="Uso:" value={captacion.uso_label} />
                    <SectionField
                        label="Legalizada:"
                        value={captacion.legalizada_label}
                    />
                    <SectionField label="Bombeo:" value={captacion.bombeo_label} />
                    <SectionField label="Notas:" value={captacion.notas} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Protección:"
                        value={captacion.proteccion_label}
                    />
                    <SectionField
                        label="Suministro eléctrico:"
                        value={captacion.suministro_electrico_label}
                        tooltipText="Existencia de acometida eléctrica"
                    />
                    <SectionField
                        label="Telexestión:"
                        value={captacion.telexestion_label}
                    />
                    <SectionField
                        label="Caudalímetro:"
                        value={captacion.caudalimetro_label}
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default CaptacionCaracteristicasSection;
