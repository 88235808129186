import {useDomain} from "domain/provider";
import {FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";

const EDARFormTratamentoConservacionFields = ({layout = "row"}) => {
    const {dominioedarurbanizacion, dominioedarestadoinstalacions} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="conservacion_instalacions"
                    label="Instalacións"
                    options={dominioedarestadoinstalacions}
                    tooltipText="Conservación das instalacions e procesos de depuración"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="conservacion_urbanizacion"
                    label="Urbanización"
                    options={dominioedarurbanizacion}
                    tooltipText="Conservación da urbanización da parcela da EDAR"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormTratamentoConservacionFields;
