import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionInventarioEDARSection = ({aglomeracion}) => {
    return (
        <SectionCard title="EDAR">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Antigüidade:"
                        value={aglomeracion.stats.antiguidadeedar}
                        tooltipText="Antigüidade da EDAR"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.potenciainstaladaedar
                        )}
                        unit="Kw"
                        tooltipText="Potencia instalada"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.consumoelectricoedar
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico medio anual nos últimos cinco anos."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionInventarioEDARSection;
