import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {PoligonoService} from "saneamiento/service";
import {poligono_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {PoligonoForm} from "../presentational/form";

const UpdatePoligonoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let poligono;
    [poligono] = useOutletContext();

    const handleSubmit = poligono => {
        PoligonoService.update(poligono_view_adapter({...poligono}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <PoligonoForm
                    poligono={poligono}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar Polígono"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdatePoligonoPanel;
