import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {EDARExplotacionSection} from "../presentational/section";

const ViewEDARExplotacionSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [<EDARExplotacionSection edar={edar} />];

    return edar && <EntityViewSubPage sections={sections} />;
};

export default ViewEDARExplotacionSubPage;
