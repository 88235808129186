import {useState, useEffect} from "react";
import {useLocation, useOutletContext} from "react-router-dom";
import {AuthAction, useAuth} from "base/auth";
import {DocumentService} from "document/service";
import {useDownloadDocument} from "base/hooks";

import {useFolderView} from "../provider";
import {
    FolderBreadcrumb,
    FolderChangeView,
    FolderList,
    FolderTable,
} from "../presentational";
import {FileUploadSection} from "../common";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";

const ListFolder = ({
    folderPath,
    basePath,
    selectedElement = null,
    onSelectElement = null,
}) => {
    const downloadDocument = useDownloadDocument();
    const location = useLocation();
    const {ROLES} = useAuth();

    const {view} = useFolderView();

    const [folderElement, setFolderElement] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        DocumentService.get(folderPath).then(element => {
            setFolderElement(element);
            setLoading(false);
        });
    }, [folderPath, location.state?.lastRefreshDate]);

    const reloadFolder = file => {
        DocumentService.get(folderPath).then(folder => {
            setFolderElement(folder);
        });
    };

    const downloadFolder = () => {
        downloadDocument(folderElement.name + ".zip", folderPath, "application/zip");
    };

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item container justifyContent="space-between" mb={2}>
                <FolderBreadcrumb path={folderPath} basePath={basePath} />
                <FolderChangeView />
            </Grid>
            <Grid item container xs={12} justifyContent="center">
                {loading ? (
                    <Grid item justifyContent="center">
                        <CircularProgress color="inherit" size={20} />
                    </Grid>
                ) : view === "list" ? (
                    <FolderList
                        folderElements={folderElement?.children}
                        selectedElement={selectedElement}
                        onSelectElement={onSelectElement}
                        basePath={basePath}
                    />
                ) : (
                    <FolderTable
                        basePath={basePath}
                        folderElements={folderElement?.children}
                        selectedElement={selectedElement}
                        onSelectElement={onSelectElement}
                    />
                )}
            </Grid>
            {folderElement?.children?.length !== 0 ? (
                <Grid item container justifyContent="flex-end">
                    <IconButton aria-label="download-zip" onClick={downloadFolder}>
                        <DownloadIcon />
                    </IconButton>
                </Grid>
            ) : null}
            <AuthAction
                roles={[
                    ROLES.ADMINISTRADOR,
                    ROLES.XESTOR,
                    ROLES.EDITOR,
                    ROLES.CONCELLO,
                ]}
            >
                <FileUploadSection path={folderPath} onFinishUpload={reloadFolder} />
            </AuthAction>
        </Grid>
    );
};

export default ListFolder;
