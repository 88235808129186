import {Route} from "react-router-dom";
import {
    CreateETAPPage,
    ListETAPPage,
    ViewETAPPanel,
    ViewETAPPage,
    ViewETAPLocationSubPage,
    ViewETAPDocumentsSubPage,
    ViewETAPSummarySubPage,
    ViewETAPDetailSubPage,
    UpdateETAPPanel,
    ManageETAPPage,
    ViewETAPDocumentPanel,
    ViewETAPChangesSubPage,
} from "abastecemento/etap/container";
import {ViewNucleoPanel} from "nucleo/container";

const etapRoutes = [
    <Route key="etap-new" path="etaps/new" element={<CreateETAPPage />} />,
    <Route key="etap-manage" path="etaps" element={<ManageETAPPage />}>
        <Route key="etap-list" path="" element={<ListETAPPage />}>
            <Route key="etap-info" path="info/:idEtap" element={<ViewETAPPanel />} />
        </Route>
        <Route key="etap-detail" path=":idEtap" element={<ViewETAPPage />}>
            <Route
                key="etap-location"
                path="location"
                element={<ViewETAPLocationSubPage />}
            >
                <Route
                    key="etap-location-edit"
                    path=":section/:action"
                    element={<UpdateETAPPanel />}
                />
                <Route
                    key="nucleo-info"
                    path="info/nucleos/:idNucleo"
                    element={<ViewNucleoPanel />}
                />
            </Route>
            <Route
                key="etap-documents"
                path="documents/*"
                element={<ViewETAPDocumentsSubPage />}
            >
                <Route
                    key="etap-documents-view"
                    path="detail/*"
                    element={<ViewETAPDocumentPanel />}
                />
            </Route>
            <Route
                key="etap-summary"
                path="summary"
                element={<ViewETAPSummarySubPage />}
            >
                <Route
                    key="etap-general-edit"
                    path=":section/:action"
                    element={<UpdateETAPPanel />}
                />
            </Route>
            <Route key="etap-detail" path="detail" element={<ViewETAPDetailSubPage />}>
                <Route
                    key="etap-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateETAPPanel />}
                />
            </Route>
            <Route
                key="etap-changes"
                path="changes"
                element={<ViewETAPChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default etapRoutes;
