import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";

import {useConfigModule} from "base/provider";
import {ConcelloPageMenu} from "concello/menu";
import {ConcelloService} from "concello/service";
import {PageLayout} from "base/layout";
import {AlertError, SpinnerFullHeight} from "base/component/presentational";
import {useAppConfig} from "base/provider";

const ViewConcelloPage = () => {
    const {codigo} = useParams();

    const {setPagePath} = useAppConfig();
    const {moduleFilter, setModuleFilter} = useConfigModule();

    const [concello, setConcello] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        ConcelloService.get(codigo)
            .then(data => {
                setPagePath([{name: data.nome, path: data.codigo}]);
                setModuleFilter({
                    ...moduleFilter,
                    concello: data.codigo,
                });
                setConcello(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [codigo, location.state?.lastRefreshDate]);

    return loading ? (
        <SpinnerFullHeight />
    ) : error ? (
        <AlertError error={error} />
    ) : (
        concello && (
            <PageLayout
                menu={<ConcelloPageMenu concello={concello} />}
                context={[concello]}
            />
        )
    );
};
export default ViewConcelloPage;
