import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const ETAPCaracteristicasSection = ({etap}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Pretratamento:"
                        value={etap.pretratamento_label}
                    />
                    <SectionField
                        label="Físico-químico:"
                        value={etap.fisicoquimico_label}
                    />
                    <SectionField label="Filtración:" value={etap.filtracion_label} />
                    <SectionField
                        label="Desinfección:"
                        value={etap.desinfeccion_label}
                    />
                    <SectionField label="Notas:" value={etap.notas} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Protección:" value={etap.proteccion_label} />
                    <SectionField
                        label="Suministro eléctrico:"
                        value={etap.suministro_electrico_label}
                        tooltipText="Existencia de acometida eléctrica"
                    />
                    <SectionField label="Telexestión:" value={etap.telexestion_label} />
                    <SectionField
                        label="Caudalímetro:"
                        value={etap.caudalimetro_label}
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default ETAPCaracteristicasSection;
