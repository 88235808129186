import {
    FormStepper,
    EntityFormGeneralDataSection,
    EntityFormCaracteristicasSection,
} from "base/component/form";
import {ColectorFormGeneralDataFields, ColectorFormCaracteristicasFields} from ".";
import Box from "@mui/material/Box";

const ColectorCreationForm = ({onSubmit, onCancel = null}) => {
    const steps = ["Datos xerais", "Características"];

    const stepComponents = [
        <EntityFormGeneralDataSection
            fields={<ColectorFormGeneralDataFields creation={true} />}
        />,
        <EntityFormCaracteristicasSection
            fields={<ColectorFormCaracteristicasFields />}
        />,
    ];
    return (
        <Box component="form" width="100%" mt={4}>
            <FormStepper
                onSubmit={onSubmit}
                onCancel={onCancel}
                steps={steps}
                stepComponents={stepComponents}
            />
        </Box>
    );
};

export default ColectorCreationForm;
