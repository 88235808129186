import {useParams} from "react-router-dom";
import {ViewDocumentPanel} from "document/component/container";
import {AglomeracionService} from "../service";

const ViewAglomeracionDocumentPanel = () => {
    const {idAglomeracion} = useParams();

    const handleSetFeaturedImage = documentId => {
        return AglomeracionService.updateWithPatch({
            id: idAglomeracion,
            featured_image: documentId,
        });
    };

    const handleSetFeaturedDocument = documentId => {
        return AglomeracionService.updateWithPatch({
            id: idAglomeracion,
            featured_document: documentId,
        });
    };

    return (
        <ViewDocumentPanel
            onSetFeaturedImage={handleSetFeaturedImage}
            onSetFeaturedDocument={handleSetFeaturedDocument}
        />
    );
};

export default ViewAglomeracionDocumentPanel;
