import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {bombeo_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {BombeoForm} from "../presentational/form";

const UpdateBombeoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let bombeo;
    [bombeo] = useOutletContext();

    const handleSubmit = bombeo => {
        BombeoService.update(bombeo_view_adapter({...bombeo}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <BombeoForm
                    bombeo={bombeo}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar Bombeo"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateBombeoPanel;
