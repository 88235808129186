import {
    FormInputDecimal,
    FormInputText,
    FormSelect,
    FormTextArea,
} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const SistemaFormCaracteristicasFields = ({layout = "row"}) => {
    const {dominiosino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="rexistrado"
                    label="Rexistrado en AdG"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText name="expediente" label="Número de expediente" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="porcentaxe_conexion"
                    label="Porcentaxe de conexión"
                    endAdornment="%"
                    placeholder="0,00"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormTextArea name="notas" label="notas" />
            </Grid>
        </Grid>
    );
};

export default SistemaFormCaracteristicasFields;
