import {useState, useEffect} from "react";
import {PageLayout} from "base/layout";
import {SinteseSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";
import {useOutletContext} from "react-router-dom";

const ViewSintesePage = () => {
    const {setSubpagePath} = useAppConfig();
    let concello;
    [concello] = useOutletContext();

    useEffect(() => {
        setSubpagePath([
            {
                name: "Síntese",
                path: "sintese/principal",
            },
        ]);
        return () => {
            setSubpagePath([]);
        };
    }, []);

    return (
        <PageLayout menu={<SinteseSubPageMenu />} subPage={true} context={[concello]} />
    );
};

export default ViewSintesePage;
