import {FormSection} from "base/component/form";
import {
    SubaglomeracionFormGeneralDataFields,
    SubaglomeracionFormLocationFields,
} from ".";

const SubaglomeracionCreationForm = () => {
    return (
        <>
            <FormSection title="Información general">
                <SubaglomeracionFormGeneralDataFields />
            </FormSection>
            <FormSection title="Ubicación">
                <SubaglomeracionFormLocationFields orientation="horizontal" />
            </FormSection>
        </>
    );
};

export default SubaglomeracionCreationForm;
