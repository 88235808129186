import {FieldUtil, NumberUtil} from "base/utilities";

export function useDepositoData(deposito) {
    const getEntityData = deposito => {
        return {
            image: deposito?.featured_image,
            name: `Depósito ${deposito?.codigo || deposito?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(deposito?.codigo)],
                        ["Concello:", FieldUtil.getValue(deposito?.concello_label)],
                        ["Titular:", FieldUtil.getValue(deposito?.titular_label)],
                        ["Xestión:", FieldUtil.getValue(deposito?.xestion_label)],
                        ["Estado:", FieldUtil.getValue(deposito?.estado_label)],
                        ["Descripción:", FieldUtil.getValue(deposito?.descripcion)],
                        [
                            "Sistema PMAA:",
                            FieldUtil.getValue(
                                deposito?.sistema_label || deposito?.sistema
                            ),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                deposito?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(
                                deposito?.geom.coordinates[1]
                            )}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        ["Posición:", FieldUtil.getValue(deposito?.posicion_label)],
                        ["Material:", FieldUtil.getValue(deposito?.material_label)],
                        ["Planta:", FieldUtil.getValue(deposito?.planta_label)],
                        ["Cloración:", FieldUtil.getValue(deposito?.cloracion_label)],
                        ["Notas:", FieldUtil.getValue(deposito?.notas)],
                        ["Protección:", FieldUtil.getValue(deposito?.proteccion_label)],
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(deposito?.suministro_electrico_label),
                        ],
                        [
                            "Telexestión:",
                            FieldUtil.getValue(deposito?.telexestion_label),
                        ],
                        [
                            "Caudalímetro:",
                            FieldUtil.getValue(deposito?.caudalimetro_label),
                        ],
                    ],
                },
                {
                    head: "Medidas",
                    content: [
                        [
                            "Diámetro:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(deposito?.diametro)
                            ),
                        ],
                        [
                            "Largo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(deposito?.largo)
                            ),
                        ],
                        [
                            "Ancho:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(deposito?.ancho)
                            ),
                        ],
                        [
                            "Alto:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(deposito?.alto)
                            ),
                        ],
                        [
                            "Capacidade:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(deposito?.capacidade)
                            ),
                        ],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
