import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {VertidoService} from "saneamiento/service";

const ViewVertidoChangesSubPage = () => {
    let vertido;
    [vertido] = useOutletContext();

    return (
        vertido && (
            <ViewEntityChangesSubPage
                entityService={VertidoService}
                entityType="vertido"
                entityId={vertido.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewVertidoChangesSubPage;
