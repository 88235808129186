import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {ETAPCreationForm, ETAPModificationForm} from ".";
import {FormUtil} from "base/utilities";
import ETAPGeoForm from "../section/ETAPGeoForm";

const ETAPForm = ({
    etap = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(etap?.id),
        codigo: FormUtil.getFormValue(etap?.codigo),
        titular: FormUtil.getFormValue(etap?.titular),
        xestion: FormUtil.getFormValue(etap?.xestion),
        estado: FormUtil.getFormValue(etap?.estado),
        descripcion: FormUtil.getFormValue(etap?.descripcion),
        pretratamento: FormUtil.getFormValue(etap?.pretratamento),
        fisicoquimico: FormUtil.getFormValue(etap?.fisicoquimico),
        filtracion: FormUtil.getFormValue(etap?.filtracion),
        desinfeccion: FormUtil.getFormValue(etap?.desinfeccion),
        proteccion: FormUtil.getFormValue(etap?.proteccion),
        suministro_electrico: FormUtil.getFormValue(etap?.suministro_electrico),
        telexestion: FormUtil.getFormValue(etap?.telexestion),
        caudalimetro: FormUtil.getFormValue(etap?.caudalimetro),
        notas: FormUtil.getFormValue(etap?.notas),
        // we must create an object with the same representation as expected search results
        sistema: etap?.sistema ? {id: etap.sistema, nome: etap?.sistema_label} : null,
        geom: FormUtil.getFormValue(etap?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            titular: FormUtil.getDataValue(data.titular),
            xestion: FormUtil.getDataValue(data.xestion),
            estado: FormUtil.getDataValue(data.estado),
            descripcion: FormUtil.getDataValue(data.descripcion),
            pretratamento: FormUtil.getDataValue(data.pretratamento),
            fisicoquimico: FormUtil.getDataValue(data.fisicoquimico),
            filtracion: FormUtil.getDataValue(data.filtracion),
            desinfeccion: FormUtil.getDataValue(data.desinfeccion),
            proteccion: FormUtil.getDataValue(data.proteccion),
            suministro_electrico: FormUtil.getDataValue(data.suministro_electrico),
            telexestion: FormUtil.getDataValue(data.telexestion),
            caudalimetro: FormUtil.getDataValue(data.caudalimetro),
            notas: FormUtil.getDataValue(data.notas),
            sistema: FormUtil.getDataValue(data.sistema?.id),
            concello: etap?.concello || concello,
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <ETAPGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <ETAPModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <ETAPCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default ETAPForm;
