import {Route} from "react-router-dom";
import {
    CreateVertidoPage,
    ListVertidoPage,
    ViewVertidoPanel,
    ViewVertidoPage,
    ViewVertidoLocationSubPage,
    ViewVertidoSummarySubPage,
    ViewVertidoDetailSubPage,
    UpdateVertidoPanel,
    ManageVertidoPage,
    ViewVertidoChangesSubPage,
} from "saneamiento/vertido/container";

const vertidoRoutes = [
    <Route key="vertido-new" path="vertidos/new" element={<CreateVertidoPage />} />,
    <Route key="vertido-manage" path="vertidos" element={<ManageVertidoPage />}>
        <Route key="vertido-list" path="" element={<ListVertidoPage />}>
            <Route
                key="vertido-info"
                path="info/:idVertido"
                element={<ViewVertidoPanel />}
            />
        </Route>
        <Route key="vertido-detail" path=":idVertido" element={<ViewVertidoPage />}>
            <Route
                key="vertido-location"
                path="location"
                element={<ViewVertidoLocationSubPage />}
            >
                {" "}
                <Route
                    key="vertido-location-edit"
                    path=":section/:action"
                    element={<UpdateVertidoPanel />}
                />
            </Route>
            <Route
                key="vertido-summary"
                path="summary"
                element={<ViewVertidoSummarySubPage />}
            >
                <Route
                    key="vertido-general-edit"
                    path=":section/:action"
                    element={<UpdateVertidoPanel />}
                />
            </Route>
            <Route
                key="vertido-detail"
                path="detail"
                element={<ViewVertidoDetailSubPage />}
            >
                <Route
                    key="vertido-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdateVertidoPanel />}
                />
            </Route>
            <Route
                key="vertido-changes"
                path="changes"
                element={<ViewVertidoChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default vertidoRoutes;
