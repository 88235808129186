import {EntityLocationSection} from "base/entity";
import {useColectorMap} from "saneamiento/colector/hooks";

const ColectorLocationSection = ({colector}) => {
    const {mapConfig} = useColectorMap(colector);

    return <EntityLocationSection entity={colector} mapConfig={mapConfig} />;
};

export default ColectorLocationSection;
