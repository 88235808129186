import {useNavigate, useParams} from "react-router-dom";
import {NumberUtil} from "base/utilities";
import {EDARService} from "saneamiento/service";

import {ConexionEntitySummaryPanel} from "conexion/container";
import {conexionElementTypesConstants} from "conexion/model";
import {SectionField} from "base/component/presentational";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewEDARPanel = ({allowConnectionManagement = false}) => {
    const {idEdar} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = edar => {
        const basePath =
            path !== "concellos" && edar.aglomeracion
                ? `/aglomeracions/${edar.aglomeracion}`
                : `/concellos/${edar.concello}`;
        console.log({basePath});
        navigate(`${basePath}/edars/${edar.id}/summary`);
    };

    const getSectionData = edar => {
        return (
            <>
                <SectionField label="Código:" value={edar.codigo} />
                <SectionField label="Código interno:" value={edar.codigo_interno} />
                <SectionField
                    label="Carga urbana:"
                    value={NumberUtil.formatDecimalAsInteger(edar.stats.urbanatotal)}
                    unit="Heq"
                    tooltipText="Poboación urbana total da aglomeración (domestica e non doméstica)"
                />
                <SectionField
                    label="Carga doméstica:"
                    value={NumberUtil.formatDecimalAsInteger(edar.stats.cargadomestica)}
                    unit="Heq"
                    tooltipText="Poboación de orixe doméstica (Fixa e estacional)"
                />
                <SectionField
                    label="Carga non doméstica:"
                    value={NumberUtil.formatDecimalAsInteger(
                        edar.stats.carganondomestica
                    )}
                    unit="Heq"
                    tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                />
                <SectionField label="Pretratamento:" value={edar.pretratamento_label} />
                <SectionField
                    label="Tratamento primario:"
                    value={edar.trat_primario_label}
                />
                <SectionField
                    label="Tratamento secundario (extensivos):"
                    value={getTratamentosSecundarios(edar)}
                />
                <SectionField
                    label="Tratamento terciario:"
                    value={edar.trat_terciario_label}
                />
                <SectionField
                    label="Tratamento avanzado:"
                    value={edar.trat_avanzado_label}
                />
            </>
        );
    };

    const getTratamentosSecundarios = edar => {
        let tratamentosSecundarios = [];
        const fields = [
            edar.trat_secundario_pequenas_label,
            edar.trat_secundario_extensivos_label,
            edar.trat_secundario_outros_label,
        ];
        fields.map(field => field && tratamentosSecundarios.push(field));
        return tratamentosSecundarios.join(", ");
    };

    return (
        <ConexionEntitySummaryPanel
            service={EDARService}
            id={idEdar}
            title="Resumo de EDAR"
            getSectionTitle={entity =>
                `EDAR: ${entity?.nome || entity?.codigo || entity?.id}`
            }
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
            allowConnectionsAsDestino={allowConnectionManagement}
            conexionElementType={conexionElementTypesConstants.EDAR}
        />
    );
};

export default ViewEDARPanel;
