import {useNavigate} from "react-router-dom";
import {NumberUtil} from "base/utilities";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const ColectorCaracteristicasSection = ({colector}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tipo de conducción:"
                        value={colector.tipo_conduccion_label}
                        tooltipText="Tipo de colector"
                    />
                    <SectionField
                        label="Natureza:"
                        value={colector.tipo_label}
                        tooltipText="Natureza da rede do colector"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fluxo:"
                        value={colector.fluxo_label}
                        tooltipText="Fluxo da auga no colector"
                    />
                    <SectionField
                        label="Material:"
                        value={colector.material_label}
                        tooltipText="Material do colector"
                    />
                    <SectionField
                        label="Diámetro:"
                        value={NumberUtil.formatDecimal2(colector.diametro)}
                        tooltipText="Diámetro nominal do colector"
                        unit="mm"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default ColectorCaracteristicasSection;
