import {useOutletContext} from "react-router-dom";
import {PageLayout} from "base/layout";
import {SisbagalMapMenu} from "sisbagal/menu";
import {SisbagalMap} from "sisbagal/map";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {useConcelloData} from "concello/hooks";
import {useConfigModule} from "base/provider";
import {useEffect} from "react";

const ViewConcelloViewerSubPage = () => {
    let concello;
    [concello] = useOutletContext();
    const {moduleFilter, setModuleFilter} = useConfigModule();

    const {getEntityData} = useConcelloData();

    useEffect(() => {
        if (moduleFilter["sistema"] || moduleFilter["subaglomeracion"]) {
            // only cleaning when sistema or subaglomeracion are present in filter
            // to avoid a second api call when changing module filter
            setModuleFilter({...moduleFilter, sistema: null, subaglomeracion: null});
        }
    }, []);

    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    concello: {
                        customLoader: () => {
                            return () => Promise.resolve(concello.geom);
                        },
                        detail: true,
                    },
                    subaglomeracion: {},
                    nucleo: {},
                    edar: {},
                    tanque: {},
                    bombeo: {},
                    colector: {
                        visible: false,
                    },
                    vertido: {},
                    poligono: {},
                    sistema: {},
                    etap: {},
                    deposito: {},
                    captacion: {},
                    tuberia: {
                        visible: false,
                    },
                }}
            >
                <PageLayout subPage={true} disablePadding={true}>
                    <SisbagalMap
                        mapOptions={{minZoom: 4}}
                        legend={<SisbagalMapMenu />}
                        showDownloadPdfAction={true}
                        entityData={getEntityData(concello)}
                    />
                </PageLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewConcelloViewerSubPage;
