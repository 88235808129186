import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {CaptacionService} from "abastecemento/service";

const ViewCaptacionChangesSubPage = () => {
    let captacion;
    [captacion] = useOutletContext();

    return (
        captacion && (
            <ViewEntityChangesSubPage
                entityService={CaptacionService}
                entityType="captacion"
                entityId={captacion.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewCaptacionChangesSubPage;
