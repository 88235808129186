import {SectionField} from ".";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem";
import CircleIcon from "@mui/icons-material/Circle";
import ListItemIcon from "@mui/material/ListItemIcon";

const TextList = ({listItems = []}) => {
    return (
        <List sx={{pl: 1}}>
            {listItems?.map((listItem, index) => {
                return (
                    <ListItem key={index} sx={{p: 0, alignItems: "baseline"}}>
                        <ListItemIcon sx={{minWidth: "24px"}}>
                            <CircleIcon sx={{fontSize: "10px"}} />
                        </ListItemIcon>
                        <SectionField
                            label={listItem.label}
                            value={listItem.value}
                            containerWidth="long"
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default TextList;
