import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARDesenoSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`desenodatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Deseño" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Ano posta en marcha:"
                        value={edar.ano_posta_marcha}
                        tooltipText="Ano de posta en marcha das instalacions"
                    />
                    <SectionField
                        label="Antigüidade:"
                        value={edar.antiguidade_label}
                        tooltipText="Antiguidade instalacions"
                    />
                    <SectionField
                        label="Habitantes equivalentes:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.habitantes_equivalentes_desenho
                        )}
                        unit="Heq"
                        tooltipText="Habitantes equivalentes de deseño"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Caudal medio:"
                        value={NumberUtil.formatDecimal(edar.caudal_medio_desenho)}
                        unit="m³/día"
                        tooltipText="Caudal medio de deseño da planta"
                    />
                    <SectionField
                        label="Caudal tratamento primario:"
                        value={NumberUtil.formatDecimal(edar.qp_pretrat_primario)}
                        unit="m³/día"
                        tooltipText="Caudal punta de deseño do tratamento primario"
                    />
                    <SectionField
                        label="Caudal tratamento secundario:"
                        value={NumberUtil.formatDecimal(edar.qp_secundario)}
                        unit="m³/día"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARDesenoSection;
