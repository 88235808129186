import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {EDARService} from "saneamiento/service";
import {edar_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {EDARForm} from "../presentational/form";

const UpdateEDARPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(`/${section}/`)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let edar;
    [edar] = useOutletContext();

    const handleSubmit = edar => {
        EDARService.update(edar_view_adapter({...edar}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <EDARForm
                    edar={edar}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar EDAR"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateEDARPanel;
