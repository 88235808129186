import {FieldUtil, NumberUtil} from "base/utilities";

export function useSubaglomeracionData(subaglomeracion) {
    const getEntityData = subaglomeracion => {
        return {
            image: subaglomeracion?.featured_image,
            name: `Concello: ${subaglomeracion?.concello_label}, Aglomeración: ${subaglomeracion?.aglomeracion_label}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        [
                            "Código aglomeración:",
                            FieldUtil.getValue(
                                subaglomeracion.stats.codigoaglomeracion
                            ),
                        ],
                        [
                            "Nome aglomeración:",
                            FieldUtil.getValue(subaglomeracion.stats.nomeaglomeracion),
                        ],
                        [
                            "Código concello:",
                            FieldUtil.getValue(subaglomeracion.stats.codigoconcello),
                        ],
                        [
                            "Nome concello:",
                            FieldUtil.getValue(subaglomeracion.stats.nomeconcello),
                        ],
                        [
                            "Código EDAR:",
                            FieldUtil.getValue(subaglomeracion.stats.codigoedar),
                        ],
                        [
                            "Nome EDAR:",
                            FieldUtil.getValue(subaglomeracion.stats.nomeedar),
                        ],
                        [
                            "Tratamento:",
                            FieldUtil.getValue(subaglomeracion.stats.tratamento_label),
                        ],
                    ],
                },
                {
                    head: "Territorio",
                    content: [
                        [
                            "Superficie:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.superficie
                                ),
                                " Ha."
                            ),
                        ],
                        [
                            "Núcleos:",
                            FieldUtil.getValue(subaglomeracion.stats.nucleosconectados),
                        ],
                        [
                            "Superficie industrial:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.superficieindustrial
                                ),
                                "Ha."
                            ),
                        ],
                        [
                            "Densidade:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.densidade
                                ),
                                "Heq/Ha"
                            ),
                        ],
                    ],
                },
                {
                    head: "Poboación > Variabilidade",
                    content: [
                        [
                            "Superficie:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.estacionalidade,
                                    1
                                ),
                                "%"
                            ),
                        ],
                        [
                            "Crecemento:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    subaglomeracion.stats.crecemento
                                ),
                                "%"
                            ),
                        ],
                    ],
                },
                {
                    head: "Poboación > Total",
                    content: [
                        [
                            "Total:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.cargatotal
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Urbana:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.cargaurbana
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Total",
                    content: [
                        [
                            "Doméstica:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.cargadomestica
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Non doméstica:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.carganondomestica
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Doméstica",
                    content: [
                        [
                            "Fixa:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.cargadomesticafixa
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Estacional:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.cargadomesticaestacional
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Non doméstica",
                    content: [
                        [
                            "Industrial:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.carganondomesticaindustrial
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Outras non domésticas:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.stats.carganondomesticaoutros
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Inventario > Total",
                    content: [
                        [
                            "Lonxitude rede:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.lonxitudecolector,
                                    3
                                ),
                                "Km."
                            ),
                        ],
                        [
                            "Potencia instalada:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.potenciainstalada
                                ),
                                "Kw"
                            ),
                        ],
                        [
                            "Consumo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.consumoelectrico
                                ),
                                "Kwh/ano"
                            ),
                        ],
                    ],
                },
                {
                    head: "Inventario > Bombeos",
                    content: [
                        [
                            "Totais:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(subaglomeracion.stats.bombeos)
                            ),
                        ],
                        [
                            "Potencia instalada:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.potenciabombeo
                                ),
                                "Kw"
                            ),
                        ],
                        [
                            "Consumo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.consumoelectricobombeo
                                ),
                                "Kwh/ano"
                            ),
                        ],
                    ],
                },
                {
                    head: "Inventario > Tanques",
                    content: [
                        [
                            "Totais:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(subaglomeracion.stats.tanques)
                            ),
                        ],
                        [
                            "Volume:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.capacidadetanque
                                )
                            ),
                        ],
                        [
                            "Potencia instalada:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.potenciainstaladatanque
                                ),
                                "Kw"
                            ),
                        ],
                        [
                            "Consumo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion.stats.consumoelectricotanque
                                ),
                                "Kwh/ano"
                            ),
                        ],
                    ],
                },
                {
                    head: "Cargas dispersas",
                    content: [
                        [
                            "Industria dispersa:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.heq_industria_dispersa
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Outras non domésticas:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.heq_outros_usos_non_domesticos
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Cargas dispersas > Non conectada",
                    content: [
                        [
                            "Saneamento autónomo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.saneamento_autonomo
                                ),
                                "Heq"
                            ),
                        ],
                        [
                            "Sen depuración:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    subaglomeracion.domestica_sen_tramento
                                ),
                                "Heq"
                            ),
                        ],
                    ],
                },
                {
                    head: "Explotación",
                    content: [
                        [
                            "Telecontrol:",
                            FieldUtil.getValue(subaglomeracion?.telecontrol_label),
                        ],
                        [
                            "Plan de mantemento:",
                            FieldUtil.getValue(subaglomeracion?.plan_mantemento_label),
                        ],
                        [
                            "Manual de explotación:",
                            FieldUtil.getValue(
                                subaglomeracion?.manual_explotacion_label
                            ),
                        ],
                        [
                            "Limpeza de colectores:",
                            FieldUtil.getValue(
                                subaglomeracion?.limpeza_colectores_label
                            ),
                        ],
                        [
                            "Control de alivios:",
                            FieldUtil.getValue(subaglomeracion?.control_alivios_label),
                        ],
                        [
                            "Reutilización outras instalacións:",
                            FieldUtil.getValue(
                                subaglomeracion?.reutilizacion_outras_instalacions_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Rede > Datos",
                    content: [
                        [
                            "Desbordamento:",
                            FieldUtil.getValue(subaglomeracion.desbordamento_label),
                        ],
                        [
                            "Augas brancas:",
                            FieldUtil.getValue(subaglomeracion.augas_brancas),
                        ],
                    ],
                },
                {
                    head: "Rede > Natureza da rede",
                    content: [
                        [
                            "Natureza pluviais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.natureza_pluviais
                                ),
                                "%"
                            )}`,
                        ],
                        [
                            "Natureza fecais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.natureza_fecais
                                ),
                                "%"
                            )}`,
                        ],
                        [
                            "Natureza unitarios:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.natureza_unitarios
                                ),
                                "%"
                            )}`,
                        ],
                    ],
                },
                {
                    head: "Rede > Materiais da rede",
                    content: [
                        [
                            "Material plástico:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.material_plastico
                                ),
                                "%"
                            )}`,
                        ],
                        [
                            "Material formigón:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.material_formigon
                                ),
                                "%"
                            )}`,
                        ],
                        [
                            "Material fibrocemento:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    subaglomeracion?.material_fibrocemento
                                ),
                                "%"
                            )}`,
                        ],
                    ],
                },
                {
                    head: "Problemas",
                    content: [
                        [
                            "Sen datos:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_sen_datos_label
                            ),
                        ],
                        [
                            "Sen problemas:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_sen_problema_label
                            ),
                        ],
                    ],
                },

                {
                    head: "Infiltración",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_infiltracion_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_infiltracion_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Auga de mar",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_augamar_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_augamar_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Auga blanca",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_augablanca_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_augablanca_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Contrapendente",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_contrapendente_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_contrapendente_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Rotura",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_rotura_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_rotura_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Atasco",
                    content: [
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_atasco_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_atasco_alcance_label
                            ),
                        ],
                    ],
                },
                {
                    head: "Outros",
                    content: [
                        [
                            "Descripción:",
                            FieldUtil.getValue(subaglomeracion?.problema_outro),
                        ],
                        [
                            "Frecuencia:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_outro_frecuencia_label
                            ),
                        ],
                        [
                            "Alcance:",
                            FieldUtil.getValue(
                                subaglomeracion?.problema_outro_alcance_label
                            ),
                        ],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
