import {FormProvider, useForm} from "react-hook-form";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {ColectorCreationForm, ColectorModificationForm} from ".";
import {FormUtil} from "base/utilities";

const ColectorForm = ({
    colector = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(colector?.id),
        orixe_datos: FormUtil.getFormValue(colector?.orixe_datos),
        tipo_conduccion: FormUtil.getFormValue(colector?.tipo_conduccion),
        material: FormUtil.getFormValue(colector?.material),
        fluxo: FormUtil.getFormValue(colector?.fluxo),
        tipo: FormUtil.getFormValue(colector?.tipo),
        diametro: FormUtil.getFormValue(colector?.diametro),
        aglomeracion: colector?.aglomeracion
            ? {codigo: colector.aglomeracion, nome: colector?.aglomeracion_label}
            : null,
        concello: colector?.concello
            ? {codigo: colector.concello, nome: colector?.concello_label}
            : {codigo: concello, nome: ""},
        geom: FormUtil.getFormValue(colector?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            orixe_datos: FormUtil.getDataValue(data.orixe_datos),
            tipo_conduccion: FormUtil.getDataValue(data.tipo_conduccion),
            material: FormUtil.getDataValue(data.material),
            fluxo: FormUtil.getDataValue(data.fluxo),
            tipo: FormUtil.getDataValue(data.tipo),
            diametro: FormUtil.getDataValue(data.diametro),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                {updatedSection ? (
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    >
                        <ColectorModificationForm section={updatedSection} />
                    </EntityForm>
                ) : (
                    <ColectorCreationForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    />
                )}
            </FormProvider>
        </DomainProvider>
    );
};

export default ColectorForm;
