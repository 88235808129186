import {SubPageMenu} from "base/layout";
import {
    PageMenuListGroup,
    SubPageMenuListGroup,
    SubPageMenuListItemButton,
    TextLink,
} from "base/component/presentational";
import Box from "@mui/material/Box";

import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import {useParams} from "react-router-dom";

import Stack from "@mui/material/Stack";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Divider from "@mui/material/Divider";

const SubaglomeracionSubPageMenu = ({subaglomeracion}) => {
    const theme = useTheme();
    // To find if is from concello or from subaglomeración
    const {codigo} = useParams();
    const isFromConcello = codigo != null;

    const informacionSubmenuItems = [
        {
            to: `cargasdispersas`,
            text: "Cargas dispersas",
        },
        {
            to: `explotacion`,
            text: "Explotación",
        },
        {
            to: `rede`,
            text: "Rede",
        },
        {
            to: `problemas`,
            text: "Problemas",
        },
    ];

    const indicadoresSubmenuItems = [
        {
            to: `summary`,
            text: "Ficha",
        },
        {
            to: `poboacion`,
            text: "Poboación",
        },
        {
            to: `inventario`,
            text: "Inventario",
        },
    ];

    return (
        <ChangesSubPageMenu
            pending_status={subaglomeracion.pending_status}
            headingPrimaryText="Sistema colector"
            headingInfo={
                isFromConcello ? (
                    <Box
                        sx={{
                            pl: 1,
                        }}
                    >
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <AccountBalanceOutlinedIcon sx={{fontSize: "12pt"}} />
                                <Typography sx={{fontSize: "10pt"}}>
                                    Concello
                                </Typography>
                            </Stack>
                            <Box sx={{pl: 3}}>
                                <TextLink
                                    text={subaglomeracion.concello_label}
                                    to={`/concellos/${subaglomeracion.concello}`}
                                />
                            </Box>
                            <Divider />
                            <Stack direction="row" spacing={1}>
                                <PolylineOutlinedIcon sx={{fontSize: "12pt"}} />
                                <Typography sx={{fontSize: "10pt"}}>
                                    Aglomeración
                                </Typography>
                            </Stack>
                            <Box sx={{pl: 3}}>
                                <TextLink
                                    text={subaglomeracion.aglomeracion_label}
                                    to={`/aglomeracions/${subaglomeracion.aglomeracion}/summary`}
                                />
                            </Box>
                        </Stack>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            pl: 1,
                        }}
                    >
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <PolylineOutlinedIcon sx={{fontSize: "12pt"}} />
                                <Typography sx={{fontSize: "10pt"}}>
                                    Aglomeración
                                </Typography>
                            </Stack>
                            <Box sx={{pl: 3}}>
                                <TextLink
                                    text={subaglomeracion.aglomeracion_label}
                                    to={`/aglomeracions/${subaglomeracion.aglomeracion}/summary`}
                                />
                            </Box>
                            <Divider />
                            <Stack direction="row" spacing={1}>
                                <AccountBalanceOutlinedIcon sx={{fontSize: "12pt"}} />
                                <Typography sx={{fontSize: "10pt"}}>
                                    Concello
                                </Typography>
                            </Stack>
                            <Box sx={{pl: 3}}>
                                <TextLink
                                    text={subaglomeracion.concello_label}
                                    to={`/concellos/${subaglomeracion.concello}`}
                                />
                            </Box>
                        </Stack>
                    </Box>
                )
            }
        >
            <SubPageMenuListGroup
                headerText="Resumo"
                headerIcon={<QueryStatsIcon />}
                items={indicadoresSubmenuItems}
            />
            <SubPageMenuListGroup
                headerText="Información"
                headerIcon={<ViewListOutlinedIcon />}
                items={informacionSubmenuItems}
            />
            <SubPageMenuListItemButton
                to="map"
                text="Mapa do sistema"
                icon={<MapOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={subaglomeracion} />
        </ChangesSubPageMenu>
    );
};

export default SubaglomeracionSubPageMenu;
