import {locale, localIntegerFormatter} from "base/config";

const DECIMAL_SEPARATOR = ",";

const NumberUtil = {
    formatDecimal(value, decimalSize = 2) {
        if (value == null || value === "undefined") {
            return "";
        }
        if (isNaN(value)) {
            return value;
        }
        const floatNumber = parseFloat(value);
        return floatNumber.toLocaleString(locale, {
            minimumFractionDigits: decimalSize,
            maximumFractionDigits: decimalSize,
        });
    },

    formatDecimal2(value) {
        return this.formatDecimal(value, 2);
    },

    parseDecimal(value) {
        if (value == null || value === "undefined") {
            return null;
        }
        return value.replace(DECIMAL_SEPARATOR, ".");
    },

    formatDecimalAsInteger(value) {
        if (value == null || value === "undefined") {
            return "";
        }
        if (isNaN(value)) {
            return value;
        }
        return localIntegerFormatter.format(Math.round(value));
    },

    formatInteger(value) {
        if (value == null || value === "undefined") {
            return "";
        }
        if (isNaN(value)) {
            return value;
        }
        return localIntegerFormatter.format(value);
    },

    formatNumber(value) {
        if (value) {
            if (Number.isInteger(value)) {
                return this.formatInteger(value);
            } else {
                return this.formatDecimal(value);
            }
        }
    },
};

export default NumberUtil;
