import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloPoboacionDomesticaSection = ({concello}) => {
    return (
        <SectionCard title="Doméstica">
            <Grid container>
                <SectionSubheading text="Rede saneamento" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fixa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            concello.stats.cargadomesticafixa
                        )}
                        unit="Heq"
                        tooltipText="Poboación fixa orixe doméstica"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacional:"
                        value={NumberUtil.formatDecimalAsInteger(
                            concello.stats.cargadomesticaestacional
                        )}
                        unit="Heq"
                        tooltipText="Poboación estacional orixe doméstica."
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Concello" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fixa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            concello.stats.cargadomesticafixatotal
                        )}
                        unit="Heq"
                        tooltipText="Poboación fixa orixe doméstica"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacional:"
                        value={NumberUtil.formatDecimalAsInteger(
                            concello.stats.cargadomesticaestacionaltotal
                        )}
                        unit="Heq"
                        tooltipText="Poboación estacional orixe doméstica."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloPoboacionDomesticaSection;
