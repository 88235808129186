import {FormSection} from "base/component/form";
import {TanqueFormCaracteristicasFields} from ".";

const TanqueFormCaracteristicasSection = () => {
    return (
        <>
            <FormSection title="Características">
                <TanqueFormCaracteristicasFields />
            </FormSection>
        </>
    );
};

export default TanqueFormCaracteristicasSection;
