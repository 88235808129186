import {useState, createContext, useContext, useEffect} from "react";

let AppContext = createContext(null);

export default function AppConfigProvider({children}) {
    const [modulePath, setModulePath] = useState([]);
    const [pagePath, setPagePath] = useState([]);
    const [subpagePath, setSubpagePath] = useState([]);

    const [breadcrumbPath, setBreadcrumbPath] = useState([]);

    const [moduleActions, setModuleActions] = useState([]);

    useEffect(() => {
        setBreadcrumbPath([...modulePath, ...pagePath, ...subpagePath]);
    }, [modulePath, pagePath, subpagePath]);

    let value = {
        modulePath,
        setModulePath,
        setPagePath,
        setSubpagePath,
        breadcrumbPath,
        moduleActions,
        setModuleActions,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppConfig() {
    return useContext(AppContext);
}

export {useAppConfig};
