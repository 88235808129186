import {SubPageMenu} from "base/layout";
import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";

const SinteseSubPageMenu = ({}) => {
    return (
        <SubPageMenu headingPrimaryText="Síntese">
            <SubPageMenuListItemButton
                to="principal"
                text="Ficha"
                icon={<ViewListOutlinedIcon />}
                key="principal"
            />
            <SubPageMenuListItemButton
                to="poboacion"
                text="Poboación"
                icon={<GroupOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="resumosistemas"
                text="Resumo sistemas"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="inventario"
                text="Inventario"
                icon={<InventoryOutlinedIcon />}
            />
        </SubPageMenu>
    );
};

export default SinteseSubPageMenu;
