import {Link, useLocation, useNavigate, useResolvedPath} from "react-router-dom";

import useTheme from "@mui/material/styles/useTheme";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import Divider from "@mui/material/Divider";
import {useEffect} from "react";
import {usePageMenu} from "./PageMenuProvider";

const PageMenuListGroupItemButton = ({
    parentId,
    to,
    text,
    pathForSelected = null,
    icon = null,
    ...props
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {setOpened, setSelectedGroup} = usePageMenu();

    let location = useLocation();
    let checkedPath = useResolvedPath(to)?.pathname;
    let resolvedPathForSelected = useResolvedPath(pathForSelected)?.pathname;
    if (pathForSelected) {
        checkedPath = resolvedPathForSelected;
    }

    const selected = location.pathname.startsWith(checkedPath);

    const handleClick = () => {
        navigate(to);
    };

    useEffect(() => {
        if (selected) {
            setSelectedGroup(parentId);
            setOpened(parentId);
        }
    }, [location]);

    return (
        <>
            <ListItemButton
                sx={{
                    borderLeft: selected
                        ? `5px solid ${theme.palette.menu.primary.header.text}`
                        : null,
                    fontWeight: selected ? "bold" : "inherit",
                    paddingLeft: 4,
                }}
                {...props}
                selected={selected}
                onClick={handleClick}
            >
                <ListItemIcon
                    style={{
                        minWidth: "20px",
                        color: theme.palette.menu.secondary.options.text,
                    }}
                >
                    <ArrowRightOutlinedIcon sx={{fontSize: "0.8rem"}} />
                </ListItemIcon>
                <ListItemText
                    sx={{
                        color: selected
                            ? theme.palette.menu.primary.header.text
                            : theme.palette.menu.secondary.options.text,
                    }}
                    primary={text}
                    primaryTypographyProps={{fontWeight: selected ? "bold" : "inherit"}}
                />
            </ListItemButton>
            <Divider sx={{borderColor: "#ededed"}} />
        </>
    );
};

export default PageMenuListGroupItemButton;
