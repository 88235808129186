import {availableBaseLayers} from "base/component/geo";
import {useConcelloAuth} from "concello/auth";
import {useState, createContext, useContext, useEffect} from "react";

let SisbagalContext = createContext(null);

export default function SisbagalAppProvider({children}) {
    const [selectedBaseLayer, setSelectedBaseLayer] = useState(availableBaseLayers[0]);

    const {isConcelloUser} = useConcelloAuth();
    const [consolidated, setConsolidated] = useState(!isConcelloUser());
    const [visibleSectors, setVisibleSectors] = useState([
        "saneamento",
        "abastecemento",
    ]);

    const toggleConsolidated = () => {
        setConsolidated(!consolidated);
    };

    const toggleSaneamento = () => {
        const index = visibleSectors.indexOf("saneamento");
        const visibleSectorsAux = [...visibleSectors];
        if (index === -1) {
            setVisibleSectors([...visibleSectorsAux, "saneamento"]);
        } else {
            visibleSectorsAux.splice(index, 1);
            setVisibleSectors(visibleSectorsAux);
        }
    };

    const toggleAbastecemento = () => {
        const index = visibleSectors.indexOf("abastecemento");
        const visibleSectorsAux = [...visibleSectors];
        if (index === -1) {
            setVisibleSectors([...visibleSectorsAux, "abastecemento"]);
        } else {
            visibleSectorsAux.splice(index, 1);
            setVisibleSectors(visibleSectorsAux);
        }
    };

    const isSaneamentoVisible = () => {
        return visibleSectors.includes("saneamento");
    };

    const isAbastecementoVisible = () => {
        return visibleSectors.includes("abastecemento");
    };

    let value = {
        selectedBaseLayer,
        setSelectedBaseLayer,
        consolidated,
        toggleConsolidated,
        visibleSectors,
        toggleSaneamento,
        toggleAbastecemento,
        isSaneamentoVisible,
        isAbastecementoVisible,
    };

    return (
        <SisbagalContext.Provider value={value}>{children}</SisbagalContext.Provider>
    );
}

function useSisbagalAppConfig() {
    return useContext(SisbagalContext);
}

export {useSisbagalAppConfig};
