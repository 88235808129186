import {useRef} from "react";
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Title, Tooltip, Legend} from "chart.js";
import {DateUtil} from "base/utilities";
import DownloadChart from "./DownloadChart";
import Grid from "@mui/material/Grid";

const whiteBackgroundPlugin = {
    id: "custom_canvas_background_color",
    beforeDraw: chart => {
        const ctx = chart.canvas.getContext("2d");
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    },
};

ChartJS.register(ArcElement, Title, Tooltip, Legend, whiteBackgroundPlugin);

const DoughnutChart = ({title, labels, datasets, options = {}}) => {
    const chartRef = useRef(null);

    const chartOptions = {
        plugins: {
            title: {
                display: true,
                position: "bottom",
                text: title,
            },
        },
        responsive: true,
        ...options,
    };

    const chartData = {
        labels,
        datasets: datasets,
    };

    const chartFilename = `${DateUtil.formatDateTimeForFilenames(
        new Date()
    )}_${title.toLowerCase().replace(" ", "_")}.png`;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Doughnut options={chartOptions} data={chartData} ref={chartRef} />
            </Grid>
            <Grid item container justifyContent="flex-end">
                <DownloadChart chartRef={chartRef} filename={chartFilename} />
            </Grid>
        </Grid>
    );
};

export default DoughnutChart;
