import {useParams} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {useVertidoTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";
import {useConcelloAuth} from "concello/auth";

const ListVertidoPage = () => {
    const {codigo: codigoConcello} = useParams();
    const {tableColumns} = useVertidoTable();
    const {isConcelloUser} = useConcelloAuth();

    return (
        <ListInfraestructuraPage
            entityName="Vertidos"
            entityPath="vertidos"
            service={VertidoService}
            tableColumns={tableColumns}
            createButton={!!codigoConcello && !isConcelloUser()}
        />
    );
};
export default ListVertidoPage;
