import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {PoligonoService} from "saneamiento/service";

const ViewPoligonoChangesSubPage = () => {
    let poligono;
    [poligono] = useOutletContext();

    return (
        poligono && (
            <ViewEntityChangesSubPage
                entityService={PoligonoService}
                entityType="poligonoindustrial"
                entityId={poligono.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewPoligonoChangesSubPage;
