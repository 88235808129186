import {AglomeracionFormSearch} from "../../../aglomeracion/presentational/form";
import {FormInputText, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";
import {ConcelloFormSearch} from "concello/presentational/form";

const BombeoFormGeneralDataFields = ({creation = false, layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputText name="codigo" label="Código" />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome" label="Nombre" />
            </Grid>
            <ConcelloHiddenAuth>
                {!creation && (
                    <Grid item xs={12}>
                        <ConcelloFormSearch
                            name="concello"
                            rules={{required: "O campo é obrigatorio"}}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <AglomeracionFormSearch name="aglomeracion" />
                </Grid>
            </ConcelloHiddenAuth>
            <Grid item xs={12} md={6}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default BombeoFormGeneralDataFields;
