import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARTratamentoSecundarioSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`secundario/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Secundario" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Compacto:"
                        value={edar.instalacion_compacta_label}
                        tooltipText="Instalación de tipo compacto"
                    />
                    <SectionField
                        label="Número de liñas:"
                        value={edar.trat_secundario_lineas}
                        tooltipText="Número de liñas de tratamento secundario"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Intensivos:"
                        value={edar.trat_secundario_pequenas_label}
                    />
                    <SectionField
                        label="Extensivos:"
                        value={edar.trat_secundario_extensivos_label}
                    />
                    <SectionField
                        label="Grandes instalacións:"
                        value={edar.trat_secundario_outros_label}
                    />
                    <SectionField
                        label="Outro:"
                        value={edar.trat_secundario_outrost}
                        tooltipText="Outro tipo de tratamento non especificado"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARTratamentoSecundarioSection;
