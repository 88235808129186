import {MasaAugaFormSearch} from "medioreceptor/masaauga/presentational/form";
import Grid from "@mui/material/Grid";
import {FormInputText} from "base/component/form";
import {ConcelloHiddenAuth} from "concello/auth";
import {AglomeracionFormSearch} from "saneamiento/aglomeracion/presentational/form";
import {ConcelloFormSearch} from "concello/presentational/form";

const VertidoFormGeneralDataFields = ({creation = false, layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12}>
                    <FormInputText name="codigo" label="Código" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputText name="codigo_externo" label="Código externo" />
                </Grid>
                {!creation && (
                    <Grid item xs={12}>
                        <ConcelloFormSearch
                            name="concello"
                            rules={{required: "O campo é obrigatorio"}}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <MasaAugaFormSearch
                        name="masa_auga"
                        rules={{required: "O campo é obrigatorio"}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AglomeracionFormSearch name="aglomeracion" />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default VertidoFormGeneralDataFields;
