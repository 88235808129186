import {mapOverlayPanes} from "base/component/geo";
import {useLayerObjectWithLegend} from "base/map";
import {useNavigate} from "react-router-dom";

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObjectWithLegend({
        legendOptions: {
            layerName: "S. explotación",
            defaultMarkerOptions: {
                marker: "l",
                addStroke: false,
                fontSize: 35,
                weight: 2,
                color: "blue",
                opacity: 1,
                lineJoin: "round",
                pane: mapOverlayPanes[0],
            },
        },
        layerOptions: {
            detail: detail,
            getTooltip: ({feature}) => {
                let data = feature.properties;
                let tooltip = `<b>Sistema explotación: ${
                    data["nome"] ? data["nome"] : "---"
                }</b><ul class="attributes">`;
                tooltip += `<li><i>Código</i>: ${
                    data["codigo"] ? data["codigo"] : "---"
                }</li>`;
                tooltip += `<li><i>Demarcación</i>: ${
                    data["demarcacion_label"] ? data["demarcacion_label"] : "---"
                }</li>`;
                return tooltip + "</ul>";
            },
            defaultOnClick: ({feature}) =>
                navigate(`info/sistemasexplotacion/${feature.id}`),
        },
    });
}
