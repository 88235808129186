import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SubaglomeracionDatosPrincipaisSection,
    SubaglomeracionTerritorioSection,
} from "../presentational/section";

const ViewSubaglomeracionSummarySubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionDatosPrincipaisSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionTerritorioSection subaglomeracion={subaglomeracion} />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionSummarySubPage;
