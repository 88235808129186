import {jsPDF} from "jspdf";

import {callAddBoldFont} from "fonts/XuntaSans-Bold-normal";
import {callAddRegularFont} from "fonts/XuntaSans-Regular-normal";
import PDFElements from "base/pdf/PDFElements";

export function useDownloadEntityPDFReport() {
    const download = (entityData = {}, imageUrl) => {
        // See solution to use custom fonts: https://stackoverflow.com/questions/63378821/how-to-add-custom-font-in-jspdf-with-react
        jsPDF.API.events.push(
            ["addFonts", callAddBoldFont],
            ["addFonts", callAddRegularFont]
        );

        let doc = new jsPDF({orientation: "portrait"});

        const imageDimensions = {
            x: 750,
            y: 750,
        };

        const dimensions = PDFElements.getPDFDimensions(doc, imageDimensions);

        let summaryPositionTop = imageUrl
            ? dimensions.canvasHeight + PDFElements.pagePaddingTop
            : dimensions.imagePositionTop;

        PDFElements.drawHeader(doc, entityData);

        if (imageUrl) {
            // Draw image
            doc.addImage(
                imageUrl,
                "PNG",
                dimensions.imagePositionLeft,
                dimensions.imagePositionTop,
                dimensions.imageWidth,
                dimensions.canvasHeight
            );
        }
        // Draw summary table
        entityData.sections.map((section, index) => {
            PDFElements.drawSummaryTable(doc, entityData, section, summaryPositionTop);
        });

        doc.save(`download_${entityData.name}.pdf`);
    };
    return {download};
}
