class Aglomeracions extends Array {}

const aglomeracion_api_adapter = edar => {
    if (edar["geom"]) {
        edar["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    edar["created_at"] = new Date(edar["created_at"]);
    edar["updated_at"] = new Date(edar["updated_at"]);
    return edar;
};

const aglomeracions_api_adapter = aglomeracions =>
    aglomeracions.map(aglomeracion_api_adapter);

const createAglomeracions = (data = []) => {
    const aglomeracions = Aglomeracions.from(data, aglomeracion =>
        createAglomeracion(aglomeracion)
    );
    return aglomeracions;
};

const aglomeracion_view_adapter = aglomeracion => {
    delete aglomeracion["folder"];
    delete aglomeracion["featured_image"];
    delete aglomeracion["featured_document"];
    delete aglomeracion["stats"];
    delete aglomeracion["created_at"];
    delete aglomeracion["created_by"];
    delete aglomeracion["updated_at"];
    delete aglomeracion["updated_by"];
    return aglomeracion;
};

const createAglomeracion = ({
    id = null,
    codigo = "",
    nome = "",
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    stats = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        nome,
        folder,
        featured_image,
        featured_document,
        geom,
        stats,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createAglomeracion as default,
    createAglomeracions,
    aglomeracion_api_adapter,
    aglomeracions_api_adapter,
    aglomeracion_view_adapter,
};
