import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewTanqueDocumentsSubPage = () => {
    let tanque;
    [tanque] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/tanques/")[0];

    return (
        tanque && (
            <ViewDocumentsSubPage entity={tanque} basePath={`${basePath}/tanques`} />
        )
    );
};

export default ViewTanqueDocumentsSubPage;
