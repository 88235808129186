import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject, useLayerObjectWithLegend} from "base/map";
import {NumberUtil} from "base/utilities";

const markerBaseOptions = {
    marker: "c",
    fillColor: "white",
    radius: 4,
    color: "black",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.85,
    pane: mapOverlayPanes[5],
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    POBOACION_FIXA: "poboacion_fixa",
});

export const LayerLegend = createLayerLegend({
    layerName: "Núcleos",
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.POBOACION_FIXA,
            text: "Poboación fixa",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "0 - 25",
                    filterFn: val => val >= 0 && val < 25,
                    markerOptions: markerBaseOptions,
                },
                {
                    text: "25 - 250",
                    filterFn: val => val >= 25 && val < 250,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 7,
                    },
                },
                {
                    text: "> 250",
                    filterFn: val => val > 250,
                    markerOptions: {...markerBaseOptions, radius: 10},
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        selectedMarkerOptions: {
            weight: 2,
            opacity: 1,
            color: "#ff0",
        },
        highlightMarkerOptions: {
            weight: 2,
            opacity: 1,
            color: "#fff",
        },
        superHighlightMarkerOptions: {
            weight: 4,
            opacity: 1,
            fillOpacity: 1,
            color: "#fff",
            radius: 15,
        },
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Núcleo: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Parroquia</i>: ${
                data["parroquia_label"] ? data["parroquia_label"] : "---"
            }</li>`;
            tooltip += `<li><i>Poboación fixa</i>: ${
                data["poboacion_fixa"]
                    ? `${NumberUtil.formatInteger(data["poboacion_fixa"])} habs.`
                    : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/nucleos/${feature.id}`),
    });
}
