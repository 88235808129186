import {useController, useFormContext} from "react-hook-form";
import {FormInputLabel} from ".";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const FormSelect = ({
    name: propsName,
    label,
    tooltipText = "",
    options,
    rules = {},
    onChangeHandler = null,
    showEmptyOption = true,
    disabled = false,
}) => {
    const {control} = useFormContext();
    const {
        field: {onChange, name, value, ref},
        fieldState: {error},
    } = useController({
        name: propsName,
        control,
        rules,
    });

    const emptyOption = {
        value: "",
        label: "‌‌", // This is not an empty character. It's U+200C unicode character.
    };

    // TO-DO: Find a better way to do this: now we are adding a string ("extra") to increase the space that the hidden label is taking so that there is enough space for the info icon.
    const hiddenLabel = tooltipText ? label + "extra" : label;

    return (
        <FormControl fullWidth error={Boolean(error)}>
            <FormInputLabel name={name} label={label} tooltipText={tooltipText} />
            <Select
                labelId={`${name}-label`}
                name={name}
                inputRef={ref}
                value={value}
                label={hiddenLabel}
                onChange={event => {
                    event.preventDefault();
                    onChange(event);
                    if (onChangeHandler) {
                        onChangeHandler(event.target.value);
                    }
                }}
                disabled={disabled}
                notched
            >
                {options &&
                    (showEmptyOption ? [emptyOption, ...options] : options).map(
                        ({label, value}) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        )
                    )}
            </Select>
            <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
    );
};

export default FormSelect;
