import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {TanqueService} from "saneamiento/service";

const ViewTanqueChangesSubPage = () => {
    let tanque;
    [tanque] = useOutletContext();

    return (
        tanque && (
            <ViewEntityChangesSubPage
                entityService={TanqueService}
                entityType="tanque"
                entityId={tanque.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewTanqueChangesSubPage;
