import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SubaglomeracionCargasDispersasNonConectadaSection,
    SubaglomeracionCargasDispersasSection,
} from "../presentational/section";

const ViewSubaglomeracionCargasDispersasSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionCargasDispersasSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionCargasDispersasNonConectadaSection
            subaglomeracion={subaglomeracion}
        />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionCargasDispersasSubPage;
