import React from "react";
import {useEffect} from "react";
import {FormProvider, useForm, useFieldArray} from "react-hook-form";
import {useMapProvider} from "sisbagal/map";
import {EntityForm} from "base/component/form";
import {createConexion, conexionElementTypesConstants} from "conexion/model";
import {SectionHeading} from "base/component/presentational";

import {ConexionFormFields} from ".";

export const ConexionesFormContext = React.createContext({
    remove: idx => {},
    append: conexiones => {},
});

const ConexionesForm = ({
    conexiones,
    showOrixe,
    isSaneamiento,
    elementType,
    elementId,
    title = null,
    subheader = null,
    onSubmit,
    onCancel = null,
}) => {
    const {
        edarMapProvider: {
            addSelectedId: addEdarSelectedId,
            removeSelectedId: removeEdarSelectedId,
            addHighlightId: addEdarHighlightId,
            removeHighlightId: removeEdarHighlightId,
            setCustomClickHandler: setEdarCustomClickHandler,
        },
        depositoMapProvider: {
            addSelectedId: addDepositoSelectedId,
            removeSelectedId: removeDepositoSelectedId,
            addHighlightId: addDepositoHighlightId,
            removeHighlightId: removeDepositoHighlightId,
            setCustomClickHandler: setDepositoCustomClickHandler,
        },
        etapMapProvider: {
            addSelectedId: addEtapSelectedId,
            removeSelectedId: removeEtapSelectedId,
            addHighlightId: addEtapHighlightId,
            removeHighlightId: removeEtapHighlightId,
            setCustomClickHandler: setEtapCustomClickHandler,
        },
        nucleoMapProvider: {
            addSelectedId: addNucleoSelectedId,
            removeSelectedId: removeNucleoSelectedId,
            addHighlightId: addNucleoHighlightId,
            removeHighlightId: removeNucleoHighlightId,
            setCustomClickHandler: setNucleoCustomClickHandler,
        },
        captacionMapProvider: {
            addSelectedId: addCaptacionSelectedId,
            removeSelectedId: removeCaptacionSelectedId,
            addHighlightId: addCaptacionHighlightId,
            removeHighlightId: removeCaptacionHighlightId,
            setCustomClickHandler: setCaptacionCustomClickHandler,
        },
    } = useMapProvider();

    const formMethods = useForm({
        defaultValues: {
            conexiones: conexiones,
        },
        reValidateMode: "onSubmit",
    });

    const {control} = formMethods;

    const {append, remove, fields} = useFieldArray({
        control,
        name: "conexiones",
    });

    const highlightFeature = (id, highlight) => () => {
        let conexion = formMethods.getValues().conexiones[id];
        const conexionEnd = showOrixe ? "orixe" : "destino";
        let fn = null;
        switch (conexion["tipo_" + conexionEnd]) {
            case conexionElementTypesConstants.EDAR:
                fn = highlight ? addEdarSelectedId : removeEdarSelectedId;
                break;
            case conexionElementTypesConstants.DEPOSITO:
                fn = highlight ? addDepositoSelectedId : removeDepositoSelectedId;
                break;
            case conexionElementTypesConstants.ETAP:
                fn = highlight ? addEtapSelectedId : removeEtapSelectedId;
                break;
            case conexionElementTypesConstants.NUCLEO:
                fn = highlight ? addNucleoSelectedId : removeNucleoSelectedId;
                break;
            case conexionElementTypesConstants.CAPTACION:
                fn = highlight ? addCaptacionSelectedId : removeCaptacionSelectedId;
                break;
            default:
                return;
        }
        if (fn !== null) {
            fn(conexion["id_" + conexionEnd]);
        }
    };

    const onDelete = id => {
        let conexion = formMethods.getValues().conexiones[id];
        const conexionEnd = showOrixe ? "orixe" : "destino";
        let fn = null;
        switch (conexion["tipo_" + conexionEnd]) {
            case conexionElementTypesConstants.EDAR:
                fn = removeEdarHighlightId;
                break;
            case conexionElementTypesConstants.DEPOSITO:
                fn = removeDepositoHighlightId;
                break;
            case conexionElementTypesConstants.ETAP:
                fn = removeEtapHighlightId;
                break;
            case conexionElementTypesConstants.NUCLEO:
                fn = removeNucleoHighlightId;
                break;
            case conexionElementTypesConstants.CAPTACION:
                fn = removeCaptacionHighlightId;
                break;
            default:
                return;
        }
        if (fn !== null) {
            fn(conexion["id_" + conexionEnd]);
        }
    };

    const isValid = (conexionData, isSaneamento) => {
        if (isSaneamento) {
            switch (conexionData["tipo_orixe"]) {
                case conexionElementTypesConstants.NUCLEO:
                    return (
                        conexionData["tipo_destino"] ===
                        conexionElementTypesConstants.EDAR
                    );
                default:
                    return false;
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        const addConexion = (featureType, feature) => {
            if (elementType === featureType && elementId === feature.id) {
                // An element cannot be connected to itself
                return false;
            }
            let endBase = showOrixe ? "destino" : "orixe";
            let endForm = showOrixe ? "orixe" : "destino";
            let conexs = formMethods.getValues().conexiones;
            for (let i = 0, iLen = conexs.length; i < iLen; i++) {
                if (
                    conexs[i]["tipo_" + endForm] === featureType &&
                    conexs[i]["id_" + endForm] === feature.id
                ) {
                    // Connection already exists, so just focus its input
                    formMethods.setFocus(`conexiones.${i}.porcentaxe`);
                    return false;
                }
            }
            let conexionData = {
                porcentaxe: 0,
            };
            conexionData["tipo_" + endBase] = elementType;
            conexionData["id_" + endBase] = elementId;
            conexionData["tipo_" + endForm] = featureType;
            conexionData["id_" + endForm] = feature.id;
            if (!isValid(conexionData, isSaneamiento)) {
                return false;
            }
            switch (featureType) {
                case conexionElementTypesConstants.EDAR:
                case conexionElementTypesConstants.CAPTACION:
                case conexionElementTypesConstants.DEPOSITO:
                case conexionElementTypesConstants.ETAP:
                    conexionData["nome_" + endForm] = feature.properties.nome;
                    conexionData["codigo_" + endForm] =
                        feature.properties.codigo || feature.id;
                    break;
                case conexionElementTypesConstants.NUCLEO:
                    conexionData["nome_" + endForm] = feature.properties.nome;
                    conexionData["codigo_" + endForm] =
                        feature.properties.codigo || feature.id;
                    break;
                default:
                    return false;
            }
            append(createConexion(conexionData));
            return true;
        };

        setNucleoCustomClickHandler(() => ({feature}) => {
            if (addConexion(conexionElementTypesConstants.NUCLEO, feature)) {
                addNucleoHighlightId(feature.id);
            }
        });

        if (isSaneamiento) {
            setEdarCustomClickHandler(() => ({feature}) => {
                if (addConexion(conexionElementTypesConstants.EDAR, feature)) {
                    addEdarHighlightId(feature.id);
                }
            });
        } else {
            setDepositoCustomClickHandler(() => ({feature}) => {
                if (addConexion(conexionElementTypesConstants.DEPOSITO, feature)) {
                    addDepositoHighlightId(feature.id);
                }
            });

            setEtapCustomClickHandler(() => ({feature}) => {
                if (addConexion(conexionElementTypesConstants.ETAP, feature)) {
                    addEtapHighlightId(feature.id);
                }
            });

            setCaptacionCustomClickHandler(() => ({feature}) => {
                if (addConexion(conexionElementTypesConstants.CAPTACION, feature)) {
                    addCaptacionHighlightId(feature.id);
                }
            });
        }

        return () => {
            setEdarCustomClickHandler(null);
            setNucleoCustomClickHandler(null);
            setDepositoCustomClickHandler(null);
            setEtapCustomClickHandler(null);
            setCaptacionCustomClickHandler(null);
        };
    }, [
        setEdarCustomClickHandler,
        setDepositoCustomClickHandler,
        setEtapCustomClickHandler,
        setNucleoCustomClickHandler,
        setCaptacionCustomClickHandler,
        formMethods,
        append,
        elementId,
        elementType,
        showOrixe,
    ]);

    const onFormSubmit = data => {
        onSubmit(data.conexiones);
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <FormProvider {...formMethods}>
            <ConexionesFormContext.Provider value={{remove, append}}>
                {title && <SectionHeading>{title}</SectionHeading>}
                {subheader && (
                    <div style={{margin: "10px 0px 5px", width: "100%"}}>
                        {subheader}
                    </div>
                )}
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    {fields.map((element, idx) => (
                        <ConexionFormFields
                            key={element.id}
                            conexionPath={`conexiones.${idx}`}
                            showOrixe={showOrixe}
                            isSaneamiento={isSaneamiento}
                            idx={idx}
                            onDelete={onDelete}
                            onFocus={highlightFeature(idx, true)}
                            onBlur={highlightFeature(idx, false)}
                        />
                    ))}
                </EntityForm>
            </ConexionesFormContext.Provider>
        </FormProvider>
    );
};

export default ConexionesForm;
