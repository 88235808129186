import {DepositoService} from "abastecemento/service";
import {useDepositoTable} from "../hooks";
import {EntityWithSistemaFilterForm} from "abastecemento/sistema/presentational";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListDepositoPage = () => {
    const {tableColumns} = useDepositoTable();

    return (
        <ListInfraestructuraPage
            entityName="Depósitos"
            entityPath="depositos"
            service={DepositoService}
            tableColumns={tableColumns}
            filterForm={<EntityWithSistemaFilterForm entityName="Depósitos" />}
        />
    );
};
export default ListDepositoPage;
