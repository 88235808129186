import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const CaptacionGeneralDataSection = ({captacion}) => {
    const {ROLES} = useAuth();

    let sections = [
        {label: "Código", value: captacion.codigo},
        {label: "Titular", value: captacion.titular_label},
        {label: "Xestión", value: captacion.xestion_label},
        {label: "Estado", value: captacion.estado_label},
        {label: "Descripción", value: captacion.descripcion},
    ];
    if (captacion.sistema) {
        sections.push({
            label: "Sistema PMAA",
            value: captacion.sistema_label || captacion.sistema,
            linkPath: `/concellos/${captacion.concello}/sistemas/${captacion.sistema}/summary`,
        });
    }
    return (
        <EntityGeneralDataSection
            featured_image={captacion.featured_image}
            featured_document={captacion.featured_document}
            name={`Captación: ${captacion.codigo || captacion.id}`}
            sections={sections}
        />
    );
};

export default CaptacionGeneralDataSection;
