import {useEffect} from "react";

import {PageLayout} from "base/layout";
import {useStatsConfig} from "stats/provider";
import {StatsFilter} from "stats/presentational";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {ViewStatsInfrastructuresCountChart, ViewStatsInfrastructuresCountMap} from ".";
import {SectionCard} from "base/component/presentational";
import {useAppConfig} from "base/provider";
import {useLocation} from "react-router-dom";
import Alert from "@mui/material/Alert";

const ViewStatsInfrastructuresCountSubPage = ({sector}) => {
    const {setPagePath} = useAppConfig();
    const {statsFilter, setStatsFilter} = useStatsConfig();

    const location = useLocation();
    const path = location.pathname
        .split("/")
        .slice(-1)
        .pop();

    useEffect(() => {
        setPagePath([
            {
                name: `Totales (${
                    path === "saneamento_infrastructures_count"
                        ? "Saneamento"
                        : "Abastecemento"
                })`,
                path,
            },
        ]);
        return () => {
            setPagePath([]);
        };
    }, [location.pathname]);

    const handleFilterChange = filter => {
        setStatsFilter(filter);
    };

    useEffect(() => {
        setStatsFilter(null);
    }, [sector]);

    return (
        <PageLayout subPage={true}>
            <Stack spacing={2} sx={{width: "100%"}}>
                <StatsFilter
                    sector={sector}
                    filter={statsFilter}
                    onChange={handleFilterChange}
                />
                <SectionCard title="Estadísticas de infraestructuras por totales">
                    {statsFilter?.infrastructure_type ? (
                        <Grid container alignItems="flex-end">
                            <Grid item xs={12} md={8}>
                                <ViewStatsInfrastructuresCountMap
                                    statsFilter={statsFilter}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ViewStatsInfrastructuresCountChart
                                    statsFilter={statsFilter}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Alert severity="warning">
                            Seleccione o <b>tipo de infraestructura</b> no filtro
                        </Alert>
                    )}
                </SectionCard>
            </Stack>
        </PageLayout>
    );
};
export default ViewStatsInfrastructuresCountSubPage;
