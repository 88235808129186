import {useOutletContext} from "react-router-dom";
import {EDARService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {EDARGeneralDataSection} from "../presentational/section";
import {UpdateEDARGeoPanel} from ".";

const ViewEDARSummarySubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [
        <EDARGeneralDataSection edar={edar} />,
        <UpdateEDARGeoPanel />,
        <EntityAuditSection entity={edar} />,
    ];

    return edar && <EntityViewSubPage sections={sections} service={EDARService} />;
};

export default ViewEDARSummarySubPage;
