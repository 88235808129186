import {useDomain} from "domain/provider";
import {FormInputDecimal, FormSelectMultiple} from "base/component/form";
import Grid from "@mui/material/Grid";

const BombeoFormExplotacionFields = ({layout = "row"}) => {
    const {dominiobombeoproblema} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="horas_funcionamento_diarias"
                    label="Horas de funcionamento"
                    endAdornment="h"
                    placeholder="0,00"
                    tooltipText="Horas de funcionamento diarias"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="caudal_medio_anual"
                    label="Caudal medio anual"
                    endAdornment="m³/ano"
                    placeholder="0,00"
                    tooltipText="Caudal anual bombeado"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="consumo_electrico"
                    label="Consumo eléctrico"
                    endAdornment="kWh/ano"
                    placeholder="0,00"
                    tooltipText="Consumo eléctrico anual"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="potencia"
                    label="Potencia"
                    endAdornment="kW"
                    placeholder="0,00"
                    tooltipText="Potencia eléctrica instalada"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="problemas"
                    label="Problemas"
                    options={dominiobombeoproblema}
                />
            </Grid>
        </Grid>
    );
};

export default BombeoFormExplotacionFields;
