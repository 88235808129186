import {useNavigate, useParams} from "react-router-dom";
import {SistemaExplotacionService} from "medioreceptor/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";

const ViewSistemaExplotacionPanel = () => {
    const {idSistemaExplotacion} = useParams();
    const navigate = useNavigate();

    const handleClickDetail = sistemaExplotacion => {
        navigate(`/medioreceptor/sistemaexplotacion/${sistemaExplotacion.id}`);
    };

    const getSectionData = sistemaExplotacion => {
        return (
            <>
                <SectionField label="Código:" value={sistemaExplotacion.codigo} />
                <SectionField
                    label="Dem. hidrográfica:"
                    value={sistemaExplotacion.demarcacion_label}
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={SistemaExplotacionService}
            id={idSistemaExplotacion}
            title="Resumo de sistema de explotación"
            getSectionTitle={entity => entity?.nome || entity?.codigo}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
        />
    );
};

export default ViewSistemaExplotacionPanel;
