import {Fragment, useState} from "react";
import {useController, useFormContext} from "react-hook-form";

import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const WAIT_INTERVAL = 500;
let timerID;

const SearchAutocomplete = ({
    name: propsName,
    label,
    optionComponent,
    optionIdAttribute = "id",
    optionLabelAttribute = "name",
    optionLabelSecondAttribute = "code",
    getFilterOption = null,
    search,
    handleSelect = null,
    disabled = false,
    rules = {},
}) => {
    // If "loading" and "data" are managed in different state
    // properties, component will re-render on every change.
    // https://reactjs.org/docs/state-and-lifecycle.html#state-updates-may-be-asynchronous
    // https://blog.isquaredsoftware.com/2020/05/blogged-answers-a-mostly-complete-guide-to-react-rendering-behavior/#render-batching-and-timing
    // This problem is mitigated by batching the state solutions
    // planned for version 18
    // https://github.com/reactwg/react-18/discussions/21
    const [state, setState] = useState({
        loading: false,
        data: [],
    });

    const {control} = useFormContext();
    const {
        field: {onChange, name, value, ref},
        fieldState: {error},
    } = useController({
        name: propsName,
        control,
        rules,
    });

    const handleSelectOption = (event, value) => {
        handleSelect && handleSelect(value);
        onChange(value ? value : null);
    };

    const searchData = async value => {
        setState(prevState => {
            return {
                ...prevState,
                loading: true,
            };
        });
        const values = await search(value);
        setState({
            loading: false,
            data: values,
        });
    };

    const handleSearchChange = async value => {
        clearTimeout(timerID);

        timerID = setTimeout(() => {
            searchData(value);
        }, WAIT_INTERVAL);
    };

    const getOptionLabel = option => {
        if (option && option[optionLabelAttribute]) return option[optionLabelAttribute];
        else if (option && option[optionLabelSecondAttribute])
            return option[optionLabelSecondAttribute];
        else return option;
    };

    return (
        <FormControl fullWidth>
            <Autocomplete
                id="check-autocomplete"
                options={state.data}
                noOptionsText={"No hay opciones disponibles"}
                onChange={handleSelectOption}
                value={value}
                getOptionLabel={option => getOptionLabel(option)}
                isOptionEqualToValue={(option, value) =>
                    value &&
                    value !== "" &&
                    option[optionIdAttribute] === value[optionIdAttribute]
                }
                filterOptions={createFilterOptions({
                    stringify: option =>
                        getFilterOption
                            ? getFilterOption(option)
                            : option[optionLabelAttribute] +
                              option[optionLabelSecondAttribute],
                })}
                renderOption={(props, option, {selected}) => {
                    return (
                        <Box component="li" {...props} key={option.codigo || option.id}>
                            {optionComponent(option)}
                        </Box>
                    );
                }}
                forcePopupIcon={false}
                loading={state.loading}
                disabled={disabled}
                renderInput={params => (
                    <TextField
                        {...params}
                        sx={{my: 0}}
                        variant="outlined"
                        name={name}
                        label={label}
                        inputRef={ref}
                        onChange={ev => {
                            // dont fire API if the user delete or not entered anything
                            if (ev.target.value !== "" || ev.target.value !== null) {
                                handleSearchChange(ev.target.value);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {" "}
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <Fragment>
                                    {state.loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                        placeholder="Comience a escribir para buscar"
                        error={Boolean(error)}
                        helperText={error?.message}
                    />
                )}
            />
        </FormControl>
    );
};

export default SearchAutocomplete;
