import {FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";

const SubaglomeracionFormCargasDispersasFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="heq_industria_dispersa"
                        label="Industria dispersa"
                        endAdornment="Heq"
                        tooltipText="Carga industrial non localizada en áreas industriais"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormInputDecimal
                        name="heq_outros_usos_non_domesticos"
                        label="Outras non domésticas"
                        endAdornment="Heq"
                        tooltipText="Outras cargas non domésticas, tales como a xerada pola poboación flotante, dotacional etc."
                    />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default SubaglomeracionFormCargasDispersasFields;
