import {useEffect} from "react";

import {ContentLayout, PageLayout} from "base/layout";
import {SectionCard} from "base/component/presentational";
import Alert from "@mui/material/Alert";

const ViewStatsNoSelectedSubPage = () => {
    return (
        <ContentLayout>
            <SectionCard title="Estatísticas">
                <Alert severity="warning">
                    Seleccione no menú lateral unha opción para consultar un gráfico
                </Alert>
            </SectionCard>
        </ContentLayout>
    );
};
export default ViewStatsNoSelectedSubPage;
