import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {SubaglomeracionService} from "saneamiento/service";
import {subaglomeracion_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {SubaglomeracionForm} from "../presentational/form";

const UpdateSubaglomeracionPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const handleSubmit = subaglomeracion => {
        SubaglomeracionService.update(
            subaglomeracion_view_adapter({...subaglomeracion})
        )
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <SubaglomeracionForm
                    subaglomeracion={subaglomeracion}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title={`Modificar rede: ${subaglomeracion.concello_label}`}
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateSubaglomeracionPanel;
