import {useDomain} from "domain/provider";
import {FormSelectMultiple, FormTextArea} from "base/component/form";
import Grid from "@mui/material/Grid";

const EDARFormTratamentoProblemasFields = ({layout = "row"}) => {
    const {dominioedarproblemas} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="problemas"
                    label="Problemas"
                    options={dominioedarproblemas}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormTextArea
                    name="problemas_outro"
                    label="Outros problemas"
                    tooltipText="Outro tipo de problemas"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormTratamentoProblemasFields;
