import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {NumberUtil} from "base/utilities";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "l",
    addStroke: false,
    fontSize: 35,
    weight: 1,
    color: "#832161",
    fillOpacity: 0.5,
    opacity: 1,
    lineJoin: "round",
    pane: mapOverlayPanes[2],
};

export const LayerLegend = createLayerLegend({
    layerName: "Polígonos",
    menuIconShape: markerBaseOptions.marker,
    defaultMarkerOptions: markerBaseOptions,
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Polígono: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Superficie</i>: ${
                data.superficie
                    ? `${NumberUtil.formatDecimal2(data.superficie)} ha`
                    : "---"
            }</li>`;
            tooltip += `<li><i>Grado de ocupación</i>: ${
                data.grado_ocupacion
                    ? `${NumberUtil.formatDecimal2(data.grado_ocupacion)} %`
                    : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        getCustomMarkerOptions: ({options}) =>
            detail ? {...options, weight: 3, opacity: 1} : options,
        defaultOnClick: ({feature}) => navigate(`info/poligonos/${feature.id}`),
        onMouseOver: ({layer}) =>
            layer.setStyle({
                weight: 2,
            }),
        onMouseOut: ({layer}) =>
            layer.setStyle({
                weight: 1,
            }),
    });
}
