import {FormSection} from "base/component/form";
import {VertidoFormCNVFields, VertidoFormOutrasReferenciasFields} from ".";

const VertidoFormCensoSection = () => {
    return (
        <>
            <FormSection title="Censo nacional de vertidos">
                <VertidoFormCNVFields />
            </FormSection>
            <FormSection title="Outras referencias">
                <VertidoFormOutrasReferenciasFields />
            </FormSection>
        </>
    );
};

export default VertidoFormCensoSection;
