import {FieldUtil, NumberUtil} from "base/utilities";

export function useColectorData(colector) {
    const getEntityData = colector => {
        return {
            image: colector?.featured_image,
            name: `Colector ${colector?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Concello:", FieldUtil.getValue(colector?.concello_label)],
                        [
                            "Aglomeración:",
                            FieldUtil.getValue(colector?.aglomeracion_label),
                        ],
                        [
                            "Coordenadas UTM:",
                            `${FieldUtil.getValue(
                                colector?.geom.coordinates[0][0]
                            )}, ${FieldUtil.getValue(
                                colector?.geom.coordinates[0][1]
                            )}; ${FieldUtil.getValue(
                                colector?.geom.coordinates[1][0]
                            )}, ${FieldUtil.getValue(
                                colector?.geom.coordinates[1][1]
                            )}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Tipo de conducción:",
                            FieldUtil.getValue(colector?.tipo_conduccion_label),
                        ],
                        ["Natureza:", FieldUtil.getValue(colector?.tipo_label)],
                        ["Fluxo:", FieldUtil.getValue(colector?.fluxo_label)],
                        ["Material:", FieldUtil.getValue(colector?.material_label)],
                        [
                            "Diámetro:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(colector?.diametro)
                            ),
                        ],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
