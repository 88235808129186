import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";

import {useNavigateWithReload} from "base/hooks";
import EditIcon from "@mui/icons-material/Edit";
import {VertidoForm} from "../presentational/form";
import {SectionCardHeaderAction} from "base/component/presentational";
import {VertidoService} from "saneamiento/service";
import {vertido_view_adapter} from "saneamiento/model";
import {VertidoLocationSection} from "../presentational/section";
import {AugasHiddenAuth} from "concello/auth";
import {SisbagalSectionCard} from "sisbagal/auth";

const UpdateVertidoGeoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [mode, setMode] = useState("view");
    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let vertido;
    [vertido] = useOutletContext();

    const handleSubmit = vertido => {
        VertidoService.update(vertido_view_adapter({...vertido}))
            .then(() => {
                navigate(location.pathname, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        setMode("view");
    };

    const secondaryActions = [
        <AugasHiddenAuth>
            <SectionCardHeaderAction
                key="edit"
                name="edit"
                text="Modificar"
                icon={<EditIcon />}
                onClick={() => {
                    setMode("edit");
                }}
                roles={[]}
            />
        </AugasHiddenAuth>,
    ];

    return (
        <SisbagalSectionCard title="Ubicación" secondaryActions={secondaryActions}>
            {mode === "edit" ? (
                <VertidoForm
                    vertido={vertido}
                    updatedSection="geo"
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            ) : (
                <VertidoLocationSection vertido={vertido} />
            )}
        </SisbagalSectionCard>
    );
};

export default UpdateVertidoGeoPanel;
