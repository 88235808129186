import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";
import {SisbagalMap} from "sisbagal/map";
import {ConexionService} from "conexion/service";

const ViewConexionsViewer = ({concello, rede}) => {
    return (
        <SisbagalMap
            mapOptions={{minZoom: 4}}
            legend={<SisbagalMapMenu />}
            height="780px"
        />
    );
};
export default ViewConexionsViewer;
