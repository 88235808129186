import {EntityGeoForm} from "base/entity";
import {CaptacionFormLocationFields} from ".";

const CaptacionGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <CaptacionFormLocationFields />
        </EntityGeoForm>
    );
};

export default CaptacionGeoForm;
