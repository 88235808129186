import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {createConexion, conexion_api_adapter} from "conexion/model";
import {useMapProvider} from "sisbagal/map";
import {ETAPService, DepositoService, CaptacionService} from "abastecemento/service";
import {NucleoService} from "nucleo/service";
import {EDARService} from "saneamiento/service";
import {useNavigateWithReload} from "base/hooks";
import {conexiones_view_adapter, conexionElementTypesConstants} from "conexion/model";
import {ConexionService} from "conexion/service";
import {EntityUpdatePanel} from "base/entity";
import {ConexionesForm} from "conexion/presentational/form";
import {SectionField} from "base/component/presentational";
import {useSisbagalAppConfig} from "sisbagal/provider";

const ConexionesPanel = ({isSaneamiento}) => {
    const {consolidated} = useSisbagalAppConfig();

    const {
        conexionMapProvider: {setItems: setConexionesLayer},
        edarMapProvider: {
            setSuperHighlightId: setEDARSuperHighlightId,
            addHighlightId: addEDARHighlightId,
            setHighlightIds: setEDARHighlightIds,
        },
        etapMapProvider: {
            setSuperHighlightId: setETAPSuperHighlightId,
            addHighlightId: addETAPHighlightId,
            setHighlightIds: setETAPHighlightIds,
        },
        depositoMapProvider: {
            setSuperHighlightId: setDepositoSuperHighlightId,
            addHighlightId: addDepositoHighlightId,
            setHighlightIds: setDepositoHighlightIds,
        },
        captacionMapProvider: {
            setSuperHighlightId: setCaptacionSuperHighlightId,
            addHighlightId: addCaptacionHighlightId,
            setHighlightIds: setCaptacionHighlightIds,
        },
        nucleoMapProvider: {
            setSuperHighlightId: setNucleoSuperHighlightId,
            addHighlightId: addNucleoHighlightId,
            setHighlightIds: setNucleoHighlightIds,
        },
    } = useMapProvider();

    const {connectionEnd, elementType, idElement} = useParams();
    const [element, setElement] = useState(null);
    const [conexiones, setConexiones] = useState(null);
    const isOrixe = connectionEnd === "orixe";

    useEffect(() => {
        let superHighlightFn = null;
        let getElementService = null;
        switch (elementType) {
            case conexionElementTypesConstants.EDAR:
                superHighlightFn = setEDARSuperHighlightId;
                getElementService = EDARService;
                break;
            case conexionElementTypesConstants.ETAP:
                superHighlightFn = setETAPSuperHighlightId;
                getElementService = ETAPService;
                break;
            case conexionElementTypesConstants.DEPOSITO:
                superHighlightFn = setDepositoSuperHighlightId;
                getElementService = DepositoService;
                break;
            case conexionElementTypesConstants.CAPTACION:
                superHighlightFn = setCaptacionSuperHighlightId;
                getElementService = CaptacionService;
                break;
            case conexionElementTypesConstants.NUCLEO:
                superHighlightFn = setNucleoSuperHighlightId;
                getElementService = NucleoService;
                break;
            default:
                return;
        }
        superHighlightFn(parseInt(idElement));
        let filter = isOrixe
            ? {tipo_orixe: elementType, id_orixe: idElement}
            : {tipo_destino: elementType, id_destino: idElement};
        filter = {
            ...filter,
            consolidated,
            rede: isSaneamiento ? "saneamento" : "abastecemento",
        };
        Promise.all([
            getElementService.get(idElement),
            ConexionService.getAllDetailed(filter),
        ]).then(([elemento, conexiones]) => {
            setElement(elemento);
            let otherEnd = isOrixe ? "destino" : "orixe";
            setConexiones(
                conexiones.features.map(f => {
                    let addHighlightIdFn;
                    switch (f.properties["tipo_" + otherEnd]) {
                        case conexionElementTypesConstants.EDAR:
                            addHighlightIdFn = addEDARHighlightId;
                            break;
                        case conexionElementTypesConstants.ETAP:
                            addHighlightIdFn = addETAPHighlightId;
                            break;
                        case conexionElementTypesConstants.DEPOSITO:
                            addHighlightIdFn = addDepositoHighlightId;
                            break;
                        case conexionElementTypesConstants.CAPTACION:
                            addHighlightIdFn = addCaptacionHighlightId;
                            break;
                        case conexionElementTypesConstants.NUCLEO:
                            addHighlightIdFn = addNucleoHighlightId;
                            break;
                        default:
                            addHighlightIdFn = null;
                    }
                    if (addHighlightIdFn !== null) {
                        addHighlightIdFn(f.properties["id_" + otherEnd]);
                    }
                    return createConexion(
                        conexion_api_adapter({id: f.id, ...f.properties})
                    );
                })
            );
        });
        return () => {
            setEDARHighlightIds(null);
            setETAPHighlightIds(null);
            setNucleoHighlightIds(null);
            setCaptacionHighlightIds(null);
            setDepositoHighlightIds(null);
            superHighlightFn(null);
        };
    }, [
        idElement,
        elementType,
        isOrixe,
        setEDARSuperHighlightId,
        setCaptacionSuperHighlightId,
        setDepositoSuperHighlightId,
        setETAPSuperHighlightId,
        setNucleoSuperHighlightId,
    ]);

    const location = useLocation();
    const baseLocationPath = location.pathname.split("/conexions/")[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    const handleSubmit = conexiones => {
        ConexionService.bulkUpdate(
            isOrixe,
            elementType,
            idElement,
            conexiones_view_adapter(conexiones)
        )
            .then(() => {
                // Trigger a layer reload
                setConexionesLayer(null);
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    const getPanelTitle = () => {
        let elementTypeName = null;
        switch (elementType) {
            case conexionElementTypesConstants.EDAR:
                elementTypeName = "EDAR";
                break;
            case conexionElementTypesConstants.ETAP:
                elementTypeName = "ETAP";
                break;
            case conexionElementTypesConstants.DEPOSITO:
                elementTypeName = "depósito";
                break;
            case conexionElementTypesConstants.CAPTACION:
                elementTypeName = "captación";
                break;
            case conexionElementTypesConstants.NUCLEO:
                elementTypeName = "núcleo";
                break;
            default:
                return null;
        }
        return `Xestionar conexións con ${
            isOrixe ? "orixe en" : "destino"
        } ${elementTypeName}`;
    };

    const getFormTitle = () => {
        switch (elementType) {
            case conexionElementTypesConstants.EDAR:
            case conexionElementTypesConstants.NUCLEO:
                return element.nome !== null && element.nome.trim().length > 0
                    ? element.nome.trim()
                    : "---";
            case conexionElementTypesConstants.ETAP:
            case conexionElementTypesConstants.DEPOSITO:
            case conexionElementTypesConstants.CAPTACION:
                return element.id;
            default:
                return "---";
        }
    };

    const getFormSubheader = () => {
        switch (elementType) {
            case conexionElementTypesConstants.EDAR:
            case conexionElementTypesConstants.ETAP:
            case conexionElementTypesConstants.DEPOSITO:
            case conexionElementTypesConstants.NUCLEO:
            case conexionElementTypesConstants.CAPTACION:
                return <SectionField label="Código:" value={element.codigo} />;
            default:
                return null;
        }
    };

    return (
        element && (
            <EntityUpdatePanel
                form={
                    <ConexionesForm
                        conexiones={conexiones}
                        elementType={elementType}
                        elementId={element.id}
                        showOrixe={!isOrixe}
                        isSaneamiento={isSaneamiento}
                        title={getFormTitle()}
                        subheader={getFormSubheader()}
                        onSubmit={handleSubmit}
                        onCancel={handleFormCancel}
                    />
                }
                title={getPanelTitle()}
                error={error}
                onCancel={handleFormCancel}
            />
        )
    );
};

export default ConexionesPanel;
