import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    ConcelloDatosPrincipaisSection,
    ConcelloMedioFisicoSection,
} from "../presentational/section";

const ViewConcelloPrincipalSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    const sections = [
        <ConcelloDatosPrincipaisSection concello={concello} />,
        <ConcelloMedioFisicoSection concello={concello} />,
    ];

    return concello && <EntityViewSubPage sections={sections} />;
};

export default ViewConcelloPrincipalSubPage;
