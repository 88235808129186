import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {useLayerObjectWithLegend} from "base/map";

export function useLayer(detail = false, fitBounds = false) {
    const navigate = useNavigate();

    return useLayerObjectWithLegend({
        legendOptions: {
            layerName: "Aglomeracións",
            defaultMarkerOptions: {
                marker: "l",
                fontSize: 35,
                addStroke: true,
                weight: 2,
                color: "#764248",
                fillOpacity: 0.2,
                opacity: 1,
                lineJoin: "round",
                pane: mapOverlayPanes[1],
            },
        },
        layerOptions: {
            detail: detail,
            fitBounds: fitBounds,
            getTooltip: ({feature}) => {
                let data = feature.properties;
                let tooltip = `<b>Aglomeración: ${
                    data["nome"] ? data["nome"] : "---"
                }</b><ul class="attributes">`;
                tooltip += `<li><i>Código</i>: ${
                    data["codigo"] ? data["codigo"] : "---"
                }</li>`;
                return tooltip + "</ul>";
            },
            getCustomMarkerOptions: ({options}) =>
                detail ? {...options, weight: 3, opacity: 1} : options,
            defaultOnClick: ({feature}) => navigate(`info/aglomeracions/${feature.id}`),
            onMouseOver: ({layer}) =>
                layer.setStyle({
                    weight: 3,
                }),
            onMouseOut: ({layer}) =>
                layer.setStyle({
                    weight: 2,
                }),
        },
    });
}
