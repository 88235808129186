import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaCompromisoSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`compromiso/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Están indentificadas todas as partes interesadas:",
            value: gobernanza.identificadas_partes_interesadas_label,
        },
        {
            label:
                "Compártese información cos interesados para lograr o seu compromiso:",
            value: gobernanza.informacion_compartida_label,
        },
        {
            label: "Existen mecanismos para involucrar aos interesados nas decisións:",
            value: gobernanza.existe_mecanismos_involucrar_interesados_label,
        },
        {
            label: "Pódense realizar visitas ás instalacións:",
            value: gobernanza.posible_visitas_instalacions_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Compromiso das partes interesadas"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_compromiso}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_compromiso,
                    "low"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaCompromisoSection;
