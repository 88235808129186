import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const PoligonoSubPageMenu = ({poligono}) => {
    return (
        <ChangesSubPageMenu
            pending_status={poligono.pending_status}
            headingPrimaryText={poligono.nome || poligono.id}
            headingSecondaryText="Polígono"
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Ficha"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="detail"
                text="Datos"
                icon={<ViewListOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={poligono} />
        </ChangesSubPageMenu>
    );
};

export default PoligonoSubPageMenu;
