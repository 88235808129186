import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {SectionCard} from "base/component/presentational";
import {EntitySummaryPanel} from "base/entity";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "base/auth";

const ConexionEntitySummaryPanel = ({
    service,
    id,
    title,
    getSectionTitle,
    getSectionData,
    onClickDetailButton = null,
    showClickDetailButton = () => true,
    allowConnectionsAsOrigen = false,
    allowConnectionsAsDestino = false,
    conexionElementType = null,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/info/")[0];
    const {ROLES, hasRole} = useAuth();

    const handleClickConnections = isOrigen => {
        navigate(
            `${basePath}/conexions/` +
                (isOrigen ? "orixe" : "destino") +
                `/${conexionElementType}/${id}`
        );
    };

    return (
        <EntitySummaryPanel
            service={service}
            id={id}
            title={title}
            getSectionTitle={getSectionTitle}
            getSectionData={getSectionData}
            onClickDetailButton={onClickDetailButton}
            showClickDetailButton={showClickDetailButton}
        >
            {(allowConnectionsAsOrigen || allowConnectionsAsDestino) &&
            !hasRole(ROLES.AUDITOR) ? (
                <SectionCard title="Xestionar conexións">
                    {allowConnectionsAsOrigen && (
                        <Grid container justifyContent="center" sx={{mb: 2}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleClickConnections(true);
                                }}
                                startIcon={
                                    <AccountTreeIcon
                                        sx={{transform: "rotate(180deg)"}}
                                    />
                                }
                            >
                                Conexións saíntes
                            </Button>
                        </Grid>
                    )}
                    {allowConnectionsAsDestino && (
                        <Grid container justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleClickConnections(false);
                                }}
                                startIcon={<AccountTreeIcon />}
                            >
                                Conexións entrantes
                            </Button>
                        </Grid>
                    )}
                </SectionCard>
            ) : null}
        </EntitySummaryPanel>
    );
};

export default ConexionEntitySummaryPanel;
