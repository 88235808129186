import {FormSection} from "base/component/form";
import {
    EDARFormOutrasInstalacionsFields,
    EDARFormXestionLodosXeradosFields,
    EDARFormXestionLodosAlleosFields,
    EDARFormDesenoFields,
    EDARFormExplotacionFields,
} from ".";

const EDARExplotacionFormSection = () => {
    return (
        <>
            <FormSection title="Servicios auxiliares">
                <EDARFormOutrasInstalacionsFields />
            </FormSection>
            <FormSection title="Deseño">
                <EDARFormDesenoFields />
            </FormSection>
            <FormSection title="Explotación">
                <EDARFormExplotacionFields />
            </FormSection>
            <FormSection title="Xestión de lodos xerados">
                <EDARFormXestionLodosXeradosFields />
            </FormSection>
            <FormSection title="Xestión de lodos alleos">
                <EDARFormXestionLodosAlleosFields />
            </FormSection>
        </>
    );
};

export default EDARExplotacionFormSection;
