import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloPoboacionNonDomesticaSection = ({concello}) => {
    return (
        <SectionCard title="Non doméstica">
            <Grid container>
                <SectionSubheading text="Rede saneamento" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomesticaindustrial,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe industrial"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomesticaoutros,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non domestica ( dotacional,flotante...)"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Concello" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomesticaindustrialtotal,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe industrial"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomesticaoutrostotal,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non domestica ( dotacional,flotante...)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloPoboacionNonDomesticaSection;
