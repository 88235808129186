import {ModuleLayout} from "base/layout";
import {ModuleConfigProvider} from "base/provider";

const StatsModule = () => {
    return (
        <ModuleConfigProvider name="Estatísticas" path="stats">
            <ModuleLayout title="Estatísticas" />
        </ModuleConfigProvider>
    );
};

export default StatsModule;
