import {NO_CONTENT_HEIGHT} from "base/config/measurements";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const SpinnerFullHeight = ({message = "Cargando datos..."}) => {
    return (
        <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                minHeight: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
            }}
        >
            <Stack
                sx={{
                    backgroundColor: "white",
                    pt: 5,
                    pb: 5,
                    pl: 20,
                    pr: 20,
                    borderRadius: "10px",
                }}
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress size={40} color="primary" />
                <Typography sx={{pt: 3}}>{message}</Typography>
            </Stack>
        </Grid>
    );
};
export default SpinnerFullHeight;
