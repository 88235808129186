import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewBombeoDocumentsSubPage = () => {
    let bombeo;
    [bombeo] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/bombeos/")[0];

    return (
        bombeo && (
            <ViewDocumentsSubPage entity={bombeo} basePath={`${basePath}/bombeos`} />
        )
    );
};

export default ViewBombeoDocumentsSubPage;
