import {useState, createContext, useContext} from "react";
import {AuthService} from "base/auth";
import {ROLES} from "base/config";

let AuthContext = createContext(null);

export default function AuthProvider({children}) {
    const [user, setUser] = useState(() => {
        return AuthService.getUsername();
    });

    const login = (username, password) => {
        return AuthService.login(username, password).then(userData => {
            setUser(AuthService.getUsername());
        });
    };

    const logout = next => {
        console.log("AuthProvider.logout()", {next});
        return AuthService.logout().then(() => {
            console.log("isLoginWithCAS", isLoginWithCAS());
            setUser(null);
            if (isLoginWithCAS()) {
                AuthService.logoutCAS(next);
            }
        });
    };

    const getTokenFromCAS = () => {
        return AuthService.casTokenLogin().then(userData => {
            setUser(AuthService.getUsername());
        });
    };

    const isLoginWithCAS = () => {
        console.log("isLoginWithCAS", user?.cas_login);
        return user && user.cas_login;
    };

    const hasRole = role => {
        return user && user.roles && user.roles.includes(role);
    };

    let value = {user, login, getTokenFromCAS, logout, hasRole, ROLES};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
    return useContext(AuthContext);
}

export {useAuth};
