import {useEffect} from "react";
import {useNavigateWithReload} from "base/hooks";
import {useAuth} from "base/auth";
import {APP_NAME, FULL_APP_NAME} from "base/config/appInfo";
import {SisbagalMenuSearch} from "sisbagal/menu";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const ViewHomePage = () => {
    let {ROLES, hasRole, user} = useAuth();
    const navigate = useNavigateWithReload();

    useEffect(() => {
        if (hasRole(ROLES.CONCELLO)) {
            navigate(`/concellos/${user.codigo_concello}`, true);
        }
    }, []);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid
                item
                container
                component={Paper}
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{width: "70%", mt: 10, p: 5}}
            >
                <Typography variant="h3" sx={{mb: 1}}>
                    Benvido a
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: "700",
                        textTransform: "uppercase",
                        color: theme => theme.palette.primary.main,
                    }}
                >
                    {APP_NAME}
                </Typography>
                <Typography sx={{mb: 8}}>{FULL_APP_NAME}</Typography>
                <Grid item container justifyContent="space-around">
                    <SisbagalMenuSearch homePage={true} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ViewHomePage;
