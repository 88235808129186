import {
    FormStepper,
    EntityFormGeneralDataSection,
    EntityFormCaracteristicasSection,
} from "base/component/form";
import {
    ETAPFormGeneralDataFields,
    ETAPFormCaracteristicasFields,
    ETAPFormLocationFields,
} from ".";
import Box from "@mui/material/Box";

const ETAPCreationForm = ({onSubmit, onCancel = null}) => {
    const steps = ["Datos xerais", "Características"];

    const stepComponents = [
        <EntityFormGeneralDataSection
            fields={<ETAPFormGeneralDataFields />}
            location={<ETAPFormLocationFields isCreationForm={true} />}
        />,
        <EntityFormCaracteristicasSection fields={<ETAPFormCaracteristicasFields />} />,
    ];
    return (
        <Box component="form" width="100%" mt={4}>
            <FormStepper
                onSubmit={onSubmit}
                onCancel={onCancel}
                steps={steps}
                stepComponents={stepComponents}
            />
        </Box>
    );
};

export default ETAPCreationForm;
