import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";

import {useNavigateWithReload} from "base/hooks";
import EditIcon from "@mui/icons-material/Edit";
import {CaptacionForm} from "../presentational/form";
import {SectionCardHeaderAction} from "base/component/presentational";
import {CaptacionService} from "abastecemento/service";
import {captacion_view_adapter} from "abastecemento/model";
import {CaptacionLocationSection} from "../presentational/section";
import {SisbagalSectionCard} from "sisbagal/auth";

const UpdateCaptacionGeoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [mode, setMode] = useState("view");
    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let captacion;
    [captacion] = useOutletContext();

    const handleSubmit = captacion => {
        CaptacionService.update(captacion_view_adapter({...captacion}))
            .then(() => {
                navigate(location.pathname, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        setMode("view");
    };

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                setMode("edit");
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Ubicación" secondaryActions={secondaryActions}>
            {mode === "edit" ? (
                <CaptacionForm
                    captacion={captacion}
                    updatedSection="geo"
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            ) : (
                <CaptacionLocationSection captacion={captacion} />
            )}
        </SisbagalSectionCard>
    );
};

export default UpdateCaptacionGeoPanel;
