import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaEquidadeSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`equidade/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label:
                "Existen medidas explícitas de acceso aos servizos de persoas vulnerables:",
            value: gobernanza.existe_medidas_acceso_vulnerables_label,
        },
        {
            label:
                "Existen tarifas sociais ou outras medidas para reducir as desigualdades:",
            value: gobernanza.existe_tarifas_sociais_label,
        },
        {
            label: "Analízase a capacidade e a vontade de pago dos usuarios:",
            value: gobernanza.analizase_capacidade_pago_usuarios_label,
        },
        {
            label:
                "Considéranse as disparidades xeográficas na avaliación de actuacións:",
            value: gobernanza.consideranse_disparidades_xeograficas_actuacions_label,
        },
        {
            label: "Considéranse as disparidades xeográficas nas tarifas:",
            value: gobernanza.consideranse_disparidades_xeograficas_tarifas_label,
        },
    ];

    return (
        <SisbagalSectionCard title="Equidade" secondaryActions={secondaryActions}>
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_equidade}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_equidade,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaEquidadeSection;
