import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {SubaglomeracionProblemasSection} from "../presentational/section";

const ViewSubaglomeracionProblemasSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionProblemasSection subaglomeracion={subaglomeracion} />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionProblemasSubPage;
