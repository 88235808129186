import {useState} from "react";
import {Outlet} from "react-router-dom";

import {SidebarPanelDrawer} from "base/component/presentational";
import Stack from "@mui/material/Stack";

const ContentLayout = ({children, context = []}) => {
    const [isSidebarPanelOpen, setSidebarPanelOpen] = useState(false);

    return (
        <Stack direction="row">
            {children ? (
                children
            ) : (
                <Outlet context={[...context, isSidebarPanelOpen]} />
            )}
            {children && (
                <SidebarPanelDrawer isSidebarPanelOpen={isSidebarPanelOpen}>
                    <Outlet context={[...context, setSidebarPanelOpen]} />
                </SidebarPanelDrawer>
            )}
        </Stack>
    );
};

export default ContentLayout;
