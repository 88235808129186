import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARXestionLodosXeradosSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`xestionlodosxerados/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Xerados" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Cantidade lodos:"
                        value={NumberUtil.formatDecimal2(edar.lodos_saida)}
                        tooltipText="Cantidade de lodos xerados polas instalacións de depuración de auga"
                        unit="t/ano"
                    />
                    <SectionField
                        label="Destino lodos:"
                        value={edar.lodos_destino_label}
                        tooltipText="Destino dos lodos tratados na EDAR"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="EDAR destino:"
                        value={edar.lodos_edar_destino}
                        tooltipText="Nome da EDAR onde se envían os lodos"
                    />
                    <SectionField
                        label="Xestor destino:"
                        value={edar.lodos_xestor_destino}
                        tooltipText="Nome do xestor autorizado o que se envían os lodos"
                    />
                    <SectionField
                        label="Distancia destino:"
                        value={NumberUtil.formatDecimal2(
                            edar.lodos_distancia_tratamiento
                        )}
                        tooltipText="Distancia ó punto de destino dos lodos da planta"
                        unit="Km"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARXestionLodosXeradosSection;
