import {useEffect, useState} from "react";

import {StatsService} from "stats/service";
import {MapStats} from "base/component/geo";

import CircularProgress from "@mui/material/CircularProgress";
import useInfrastructureStatsCountConfig from "./InfrastructuresStatsCountConfig";

const ViewStatsInfrastructuresCountMap = ({statsFilter}) => {
    const {getInfrastructureTypeConfig} = useInfrastructureStatsCountConfig();
    const mapStatsConfig = getInfrastructureTypeConfig(statsFilter.infrastructure_type);

    const [loading, setLoading] = useState(false);
    const [geoData, setGeoData] = useState(null);

    useEffect(() => {
        if (statsFilter) {
            setLoading(true);
            StatsService.getInfrastructuresCountGeoData(statsFilter).then(data => {
                setLoading(false);
                setGeoData(data);
            });
        }
    }, [statsFilter]);

    return !mapStatsConfig ? null : loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        geoData && (
            <MapStats
                geojson={geoData}
                attribute="total"
                attributeLabel={mapStatsConfig.title}
                legendItems={mapStatsConfig.legendItems}
                fillColor={mapStatsConfig.fillColor}
            />
        )
    );
};
export default ViewStatsInfrastructuresCountMap;
