import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {EDARService} from "saneamiento/service";

const ViewEDARChangesSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    return (
        edar && (
            <ViewEntityChangesSubPage
                entityService={EDARService}
                entityType="edar"
                entityId={edar.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewEDARChangesSubPage;
