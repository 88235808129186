import {ModuleLayout} from "base/layout";
import {ModuleConfigProvider} from "base/provider";

const ConcellosModule = () => {
    return (
        <ModuleConfigProvider name="Concellos" path="concellos">
            <ModuleLayout title="Concellos" />
        </ModuleConfigProvider>
    );
};

export default ConcellosModule;
