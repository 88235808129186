import {useNavigate} from "react-router-dom";

import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";
import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaConsideracionSistemasSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`consideracion_sistemas/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Coñece o estado das masas de auga ás que verten os seus sistemas:",
            value: gobernanza.conhece_estado_masas_label,
        },
        {
            label: "No caso de estar en mal estado, sabe cales son as causas:",
            value: gobernanza.sabe_causas_mal_estado_label,
        },
        {
            label: "Sabe que os obxectivos de vertido poden variar en función da masa:",
            value: gobernanza.sabe_obxectivos_vertido_label,
        },
        {
            label: "Ten inventariados os alivios dos seus sistemas:",
            value: gobernanza.ten_inventariados_alivios_label,
        },
        {
            label: "Realiza un control dos alivios dos seus sistemas:",
            value: gobernanza.realiza_control_alivios_label,
        },
        {
            label: "Realiza unha xestión do ciclo integral da auga:",
            value: gobernanza.realiza_xestion_ciclo_auga_label,
        },
        {
            label: "Compara os volumes de auga captada, rexistrada e retornada:",
            value: gobernanza.compara_volumes_auga_label,
        },
        {
            label:
                "Sabe cales son os organismos de conca con competencias no seu concello:",
            value: gobernanza.sabe_organismos_conca_label,
        },
        {
            label: "Existen mecanismos de coordinación con estes organismos:",
            value: gobernanza.existe_mecanismo_coordinacion_organismos_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Consideración dos sistemas de conca"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_consideracion_sistemas}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_consideracion_sistemas,
                    "high"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaConsideracionSistemasSection;
