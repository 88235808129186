import {useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {EDARService} from "saneamiento/service";
import {edar_view_adapter} from "saneamiento/model";
import {EntityCreatePage} from "base/entity";
import {EDARForm} from "../presentational/form";

const CreateEDARPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const basePath = location.pathname.split("/new")[0];
    const {codigo} = useParams();
    const codigoConcello = codigo;

    const [error, setError] = useState("");

    const handleFormSubmit = edar => {
        EDARService.create(edar_view_adapter({...edar}))
            .then(createdEdar => {
                navigate(`${basePath}/${createdEdar.id}/summary`);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(basePath);
    };

    return (
        <EntityCreatePage
            form={
                <EDARForm
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    concello={codigoConcello}
                />
            }
            title="Rexistro de EDAR"
            error={error}
        />
    );
};

export default CreateEDARPage;
