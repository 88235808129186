import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloInventarioTanquesSection = ({concello}) => {
    return (
        <SectionCard title="Tanques">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Totais:"
                        value={NumberUtil.formatInteger(concello.stats.tanques)}
                        tooltipText="Número de tanques de tormenta instalados na rede"
                    />
                    <SectionField
                        label="Volume:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.capacidadetanque
                        )}
                        tooltipText="Volume total de retención"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.potenciainstaladatanque
                        )}
                        unit="Kw"
                        tooltipText="Potencia total dos equipos instalados nos tanques de tormenta( sen ter en conta os bombeos definidos)"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.consumoelectricotanque
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico anual dos equipos instalados nos tanques de tormenta( sen ter en conta os bombeos definidos)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloInventarioTanquesSection;
