import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormCapacitacionMediosFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_registro_infraestructuras"
                    label="Existe un rexistro das infraestruturas e medios adscritos"
                    options={dominiogobernanzasino}
                    tooltipText="A relación de infraestruturas e medios adscritos aos servizos é básica; para realizar unha correcta xestión do servizo, primeiro necesitamos saber qué é o que temos que xestionar."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_información_xeorreferenciada"
                    label="Existe información xeorreferenciada das infraestruturas"
                    options={dominiogobernanzasino}
                    tooltipText="Existe información da localización e características das infraestruturas relacionadas cons servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="sabe_porcentaxe_poboacion"
                    label="Sabe a porcentaxe de poboación non conectada"
                    options={dominiogobernanzasino}
                    tooltipText="Sabe a porcentaxe de poboación non conectada a redes no seu concello (con saneamento autónomo)."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_rexistro_infraestructuras"
                    label="Existe un rexistro das infraestruturas de saneamento autónomo"
                    options={dominiogobernanzasino}
                    tooltipText="Titular, localización, características, autorización de vertido, tipo de vertido."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_balances_custos_ingresos"
                    label="Existen balances periódicos de custos e ingresos nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Incluidos ou no en auditorías de contas."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_rexistro_investimentos"
                    label="Existe un rexistro dos investimentos nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Existe un rexistro dos investimentos que se levan a cabo en relación cos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismo_xestion_datos"
                    label="Existen mecanismos para xestionar os datos"
                    options={dominiogobernanzasino}
                    tooltipText="Existen mecanismos para xestionar a recompilación, uso, intercambio e distribución de datos."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormCapacitacionMediosFields;
