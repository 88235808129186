import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "l",
    fontSize: 35,
    addStroke: false,
    weight: 2,
    color: "#a0e2ff",
    opacity: 1,
    lineJoin: "round",
    pane: mapOverlayPanes[2],
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    ORIXE_DATO: "orixe_dato",
});

export const LayerLegend = createLayerLegend({
    layerName: "Rede",
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.ORIXE_DATO,
            text: "Orixe do dato",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "INDICADA",
                    filterFn: val => val === "INDICADA",
                    markerOptions: markerBaseOptions,
                },
                {
                    text: "ESTIMADA",
                    filterFn: val => val === "ESTIMADA",
                    markerOptions: {
                        ...markerBaseOptions,
                        dashArray: "4,4",
                    },
                },
                {
                    text: "SISBAGAL",
                    filterFn: val => val === "SISBAGAL",
                    markerOptions: {...markerBaseOptions, color: "#ffdfd3"},
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Tubería: ${
                feature.id ? feature.id : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Orixe do dato</i>: ${
                data["orixe_dato"] ? data["orixe_dato"] : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
    });
}
