import {PageMenu} from "base/layout";
import {useAuth} from "base/auth";
import {useLocation} from "react-router-dom";

import {useAppConfig} from "base/provider";
import {PageMenuListGroup, PageMenuListItemButton} from "base/component/presentational";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid4x4OutlinedIcon from "@mui/icons-material/Grid4x4Outlined";

const SistemaExplotacionPageMenu = ({sistemaExplotacion}) => {
    const {ROLES} = useAuth();

    const submenuItems = [
        {
            to: "",
            text: "Presións saneamento",
        },
    ];

    return (
        <PageMenu
            headingPrimaryText={sistemaExplotacion.nome}
            headingSecondaryText={`Sist. de explotación:`}
            headingIcon={Grid4x4OutlinedIcon}
        >
            <PageMenuListGroup
                id="indicadores"
                headerText="Indicadores"
                headerIcon={<QueryStatsIcon />}
                items={submenuItems}
            />
        </PageMenu>
    );
};

export default SistemaExplotacionPageMenu;
