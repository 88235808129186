import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {AuthAction, useAuth} from "base/auth";
import {DateUtil} from "base/utilities";

import {useList} from "base/provider";
import {AlertError} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import {DataChangesCell} from ".";
import {ChangeActionState, ChangeActionTypes} from "saneamiento/model/Change";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);

const ChangesTable = ({service, onChangesLoaded}) => {
    const {ROLES} = useAuth();
    const location = useLocation();
    const theme = useTheme();

    const {filter, page, setPage, size, setSize} = useList();

    const [elements, setElements] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        service(filter, page)
            .then(data => {
                setElements(data.results);
                setSize(data.count);
                setLoading(false);
                onChangesLoaded(data.results);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }, [filter, page, location.state?.lastRefreshDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    let noElementsMessage =
        filter && filter.length === 0
            ? "No existen elementos para mostrar"
            : "No se ha encontrado ningún elemento que coincida con su búsqueda. Por favor, intente realizar otra búsqueda o borre los filtros activos.";

    const formatActionType = actionType => {
        switch (actionType) {
            case ChangeActionTypes.INSERT:
                return "Inserción";
            case ChangeActionTypes.UPDATE:
                return "Edición";
            case ChangeActionTypes.DELETE:
                return "Eliminación";
            default:
                return null;
        }
    };

    const formatActionState = state => {
        switch (state) {
            case ChangeActionState.PENDING:
                return <Box sx={{color: theme.palette.warning.main}}>Pendiente</Box>;
            case ChangeActionState.APPROVED:
                return <Box sx={{color: theme.palette.success.main}}>Aprobado</Box>;
            case ChangeActionState.REJECTED:
                return <Box sx={{color: theme.palette.error.main}}>Rechazado</Box>;
            default:
                return null;
        }
    };

    const formatChanges = changes => {
        if (changes) {
            const labelChanges = changes
                .filter(change => {
                    const attribute = change[1];
                    return attribute && attribute.indexOf("_label") >= 0;
                })
                .map(change => change[1].replace("_label", ""));
            changes = changes.filter(change => {
                const attribute = change[1];
                return !labelChanges.includes(attribute);
            });
            return (
                <Stack>
                    {changes.map((change, index) => (
                        <DataChangesCell change={change} key={index} />
                    ))}
                </Stack>
            );
        }
        return null;
    };

    const tableColumns = [
        {
            id: "action_type",
            label: "Tipo",
            formatFunction: element => formatActionType(element.action_type),
            width: 8,
        },
        {
            id: "action_user_label",
            label: "Usuario",
            width: 8,
        },
        {
            id: "action_date",
            label: "Fecha",
            width: 10,
            formatFunction: element => DateUtil.formatDateTime(element.action_date),
        },
        {
            id: "action_state",
            label: "Estado",
            width: 8,
            formatFunction: element => formatActionState(element.action_state),
        },
        {
            id: "data_changes",
            label: "Cambio",
            width: 33,
            formatFunction: element => formatChanges(element.data_changes),
        },
        {
            id: "action_comments",
            label: "Observaciones",
            width: 15,
        },
        {
            id: "resolution_user_label",
            label: "Usuario de resolución",
            width: 8,
        },
        {
            id: "resolution_date",
            label: "Fecha de resolución",
            width: 10,
            formatFunction: element => DateUtil.formatDateTime(element.resolution_date),
        },
    ];

    return loading ? (
        <Grid item container justifyContent="center" my={6}>
            <CircularProgress size={40} />
        </Grid>
    ) : elements && elements.length === 0 ? (
        <Container sx={{textAlign: "center"}}>
            <Typography py={12} sx={{fontStyle: "italic"}}>
                {noElementsMessage}
            </Typography>
        </Container>
    ) : (
        <>
            <AlertError error={error} />
            <TableContainer sx={{width: "100%"}}>
                <Table aria-labelledby="Tabla" size="small" sx={{tableLayout: "fixed"}}>
                    <colgroup>
                        {tableColumns.map((headCell, index) => (
                            <col key={index} width={headCell.width + "%"} />
                        ))}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map(headCell => (
                                <Tooltip
                                    title={headCell.title ? headCell.title : ""}
                                    key={headCell.id}
                                    disableHoverListener={!headCell.title}
                                >
                                    <TableCell key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                </Tooltip>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {elements &&
                            elements.map((element, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        {tableColumns.map(cellAttribute => {
                                            return (
                                                <TableCell
                                                    key={cellAttribute.id}
                                                    sx={
                                                        element.action_state !=
                                                        ChangeActionState.PENDING
                                                            ? {
                                                                  fontStyle: "italic",
                                                                  color: "#aaa",
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    {cellAttribute.formatFunction
                                                        ? cellAttribute.formatFunction(
                                                              element
                                                          )
                                                        : element[cellAttribute.id]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <Grid container justifyContent="flex-end" sx={{mt: 3}}>
                    <Grid item>
                        <Pagination
                            count={Math.ceil(size / pageSize)}
                            page={page}
                            onChange={handleChangePage}
                        />
                    </Grid>
                </Grid>
            </TableContainer>
        </>
    );
};

export default ChangesTable;
