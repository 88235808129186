import {ETAPFormCaracteristicasFields, ETAPFormGeneralDataFields} from ".";

const ETAPModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <ETAPFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <ETAPFormCaracteristicasFields layout="column" />;
    }
    return null;
};

export default ETAPModificationForm;
