import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    DepositoCaracteristicasSection,
    DepositoMedidasSection,
} from "../presentational/section";

const ViewDepositoDetailSubPage = () => {
    let deposito;
    [deposito] = useOutletContext();

    const sections = [
        <DepositoCaracteristicasSection deposito={deposito} />,
        <DepositoMedidasSection deposito={deposito} />,
    ];

    return deposito && <EntityViewSubPage sections={sections} />;
};

export default ViewDepositoDetailSubPage;
