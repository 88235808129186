import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";
import {ResultsSummaryList} from "base/component/presentational";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaSummaryEficienciaSection = ({gobernanza}) => {
    const {
        getDimensionEvaluationLabel,
        getDimensionEvaluationColor,
        getPrincipiosEvaluationColor,
    } = useGobernanzaEvaluation();

    const basePath = `/concellos/${gobernanza.concello}/gobernanza`;

    const listItems = [
        {
            label: "Datos e información:",
            to: `${basePath}/eficiencia`,
            total: gobernanza.total_datos_informacion,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_datos_informacion,
                "medium"
            ),
        },
        {
            label: "Financiamento:",
            to: `${basePath}/eficiencia#2`,
            total: gobernanza.total_financiamento,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_financiamento,
                "high"
            ),
        },
        {
            label: "Marcos regulatorios:",
            to: `${basePath}/eficiencia#3`,
            total: gobernanza.total_marcos_regulatorios,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_marcos_regulatorios,
                "low"
            ),
        },
        {
            label: "Gobernanza innovadora:",
            to: `${basePath}/eficiencia#4`,
            total: gobernanza.total_gobernanza_innovadora,
            evaluationColor: getPrincipiosEvaluationColor(
                gobernanza.total_gobernanza_innovadora,
                "low"
            ),
        },
    ];

    return (
        <SisbagalSectionCard title="Eficiencia">
            <ResultsSummaryList
                listItems={listItems}
                totalValue={gobernanza.total_eficiencia}
                totalLabel={getDimensionEvaluationLabel(gobernanza.total_eficiencia)}
                getChipColor={getDimensionEvaluationColor}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaSummaryEficienciaSection;
