import {Route} from "react-router-dom";
import {
    CreateEDARPage,
    ListEDARPage,
    ViewEDARPanel,
    ViewEDARPage,
    ViewEDARDocumentsSubPage,
    ViewEDARSummarySubPage,
    UpdateEDARPanel,
    ManageEDARPage,
    ViewEDARDocumentPanel,
    ViewEDARTratamentoSubPage,
    ViewEDARDesenoSubPage,
    ViewEDARCargasSubPage,
    ViewEDAROutrasInstalacionsSubPage,
    ViewEDARExplotacionSubPage,
    ViewEDARXestionLodosSubPage,
    ViewEDARChangesSubPage,
} from "saneamiento/edar/container";

const edarRoutes = [
    <Route key="edar-new" path="edars/new" element={<CreateEDARPage />} />,
    <Route key="edar-manage" path="edars" element={<ManageEDARPage />}>
        <Route key="edar-list" path="" element={<ListEDARPage />}>
            <Route key="edar-info" path="info/:idEdar" element={<ViewEDARPanel />} />
        </Route>
        <Route key="edar-detail" path=":idEdar" element={<ViewEDARPage />}>
            <Route
                key="edar-documents"
                path="documents/*"
                element={<ViewEDARDocumentsSubPage />}
            >
                <Route
                    key="edar-documents-view"
                    path="detail/*"
                    element={<ViewEDARDocumentPanel />}
                />
            </Route>
            <Route
                key="edar-summary"
                path="summary"
                element={<ViewEDARSummarySubPage />}
            >
                <Route
                    key="edar-general-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route
                key="edar-cargas"
                path="cargas"
                element={<ViewEDARCargasSubPage />}
            />
            <Route
                key="edar-tratamento"
                path="tratamento"
                element={<ViewEDARTratamentoSubPage />}
            >
                <Route
                    key="edar-tratamento-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route
                key="edar-outrainstalacions"
                path="outrasinstalacions"
                element={<ViewEDAROutrasInstalacionsSubPage />}
            >
                <Route
                    key="edar-explotaciondeseno-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route key="edar-deseno" path="deseno" element={<ViewEDARDesenoSubPage />}>
                <Route
                    key="edar-deseno-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route
                key="edar-explotacion"
                path="explotacion"
                element={<ViewEDARExplotacionSubPage />}
            >
                <Route
                    key="edar-explotacion-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route
                key="edar-xestionlodos"
                path="xestionlodos"
                element={<ViewEDARXestionLodosSubPage />}
            >
                <Route
                    key="edar-xestionlodos-edit"
                    path=":section/:action"
                    element={<UpdateEDARPanel />}
                />
            </Route>
            <Route
                key="edar-changes"
                path="changes"
                element={<ViewEDARChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default edarRoutes;
