import {useNavigate} from "react-router-dom";

import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";
import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaCoherenciaPoliticasSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`coherencia_politicas/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "As políticas locais urbanísticas son coherentes coas da auga:",
            value: gobernanza.politicas_urbanisticas_label,
        },
        {
            label:
                "As políticas locais de limpeza e residuos son coherentes coas da auga:",
            value: gobernanza.politicas_limpeza_label,
        },
        {
            label:
                "As políticas locais de parques e xardíns son coherentes coas da auga:",
            value: gobernanza.politicas_parques_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Coherencia de políticas"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_coherencia_politicas}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_coherencia_politicas,
                    "low"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaCoherenciaPoliticasSection;
