import {ModuleLayout} from "base/layout";
import {ModuleConfigProvider} from "base/provider";
import {useSisbagalAppConfig} from "sisbagal/provider";

const AglomeracionsModule = () => {
    return (
        <ModuleConfigProvider name="Aglomeracións" path="aglomeracions">
            <ModuleLayout title="Aglomeracións" />
        </ModuleConfigProvider>
    );
};

export default AglomeracionsModule;
