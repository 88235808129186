import {SubaglomeracionService} from "saneamiento/service";
import {useSubaglomeracionTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListSubaglomeracionPage = () => {
    const {tableColumns} = useSubaglomeracionTable();

    return (
        <ListInfraestructuraPage
            entityName="Sistemas colectores"
            entityPath="subaglomeracions"
            service={SubaglomeracionService}
            tableColumns={tableColumns}
            createButton={false}
        />
    );
};
export default ListSubaglomeracionPage;
