import {useState} from "react";
import {useMapConfig} from "sisbagal/map";

import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MotionPhotosOffIcon from "@mui/icons-material/MotionPhotosOff";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const SisbagalBufferSelector = () => {
    const {getBuffer, setBuffer} = useMapConfig();
    const buffer = getBuffer();

    const [anchorEl, setAnchorEl] = useState(null);
    const openSectorMenu = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeBuffer = buffer => {
        setBuffer(buffer);
        handleClose();
    };

    const getMenuText = () => {
        switch (getBuffer()) {
            case 1:
                return "1 km";
            case 3:
                return "3 km";
            case 5:
                return "5 km";
            case 10:
                return "10 km";
            case 25:
                return "25 km";
            default:
                return "";
        }
    };

    const getMenuIcon = () => {
        if (buffer === 0) {
            return <MotionPhotosOffIcon />;
        }
        return <RadioButtonCheckedIcon color="warning" />;
    };

    return (
        <Box>
            <Tooltip title={`Buffer ${getMenuText()}`}>
                <Stack direction="row" alignItems="center">
                    <IconButton
                        aria-label="select-buffer"
                        onClick={handleClick}
                        color="primary"
                    >
                        {getMenuIcon()}
                    </IconButton>
                </Stack>
            </Tooltip>
            <Menu
                id="buffer-menu"
                anchorEl={anchorEl}
                open={openSectorMenu}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "buffer-button",
                }}
            >
                <MenuItem
                    sx={{
                        ml: 1,
                        py: 0,
                        "&.MuiButtonBase-root:hover": {
                            bgcolor: "transparent",
                        },
                    }}
                    disableRipple
                >
                    <FormControl>
                        <FormLabel id="buffer-group-label" sx={{pb: 1}}>
                            Tamaño do buffer
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="buffer-group-label"
                            defaultValue="Sin buffer"
                            name="buffer-radio-buttons-group"
                        >
                            <FormControlLabel
                                checked={buffer === 0}
                                control={<Radio />}
                                label="Sin buffer"
                                onClick={() => handleChangeBuffer(0)}
                            />
                            <FormControlLabel
                                checked={buffer === 1}
                                control={<Radio />}
                                label="1 km"
                                onClick={() => handleChangeBuffer(1)}
                            />
                            <FormControlLabel
                                checked={buffer === 3}
                                control={<Radio />}
                                label="3 km"
                                onClick={() => handleChangeBuffer(3)}
                            />
                            <FormControlLabel
                                checked={buffer === 5}
                                control={<Radio />}
                                label="5 km"
                                onClick={() => handleChangeBuffer(5)}
                            />
                            <FormControlLabel
                                checked={buffer === 10}
                                control={<Radio />}
                                label="10 km"
                                onClick={() => handleChangeBuffer(10)}
                            />
                            <FormControlLabel
                                checked={buffer === 25}
                                control={<Radio />}
                                label="25 km"
                                onClick={() => handleChangeBuffer(25)}
                            />
                        </RadioGroup>
                    </FormControl>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default SisbagalBufferSelector;
