import {useOutletContext} from "react-router-dom";

import {useDeleteEntity} from "./hooks";

import {ContentLayout} from "base/layout";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {useAuth} from "base/auth";

const EntityViewSubPage = ({sections = [], service = null, children = null}) => {
    const context = useOutletContext();
    const {button: deleteButton, dialog: deleteDialog} = useDeleteEntity(service);
    const {ROLES, hasRole} = useAuth();

    const sectionsToDisplay = sections.map((section, index) => (
        <Grid item xs={12} key={index} id={`${index + 2}`}>
            {section}
        </Grid>
    ));

    return (
        <ContentLayout context={[...context]}>
            <Stack sx={{width: "100%"}}>
                <Grid container spacing={1} marginBottom={1}>
                    {sections.length !== 0 ? sectionsToDisplay : null}
                    {children}
                </Grid>
                {service ? deleteDialog : null}
                {!hasRole(ROLES.AUDITOR) && (
                    <Grid container justifyContent="flex-end" marginTop={1}>
                        {service ? deleteButton : null}
                    </Grid>
                )}
            </Stack>
        </ContentLayout>
    );
};

export default EntityViewSubPage;
