export function useTanqueMap(concello = null) {
    const concelloConfig = concello
        ? {
              customLoader: () => {
                  return () => Promise.resolve(concello.geom);
              },
              detail: true,
          }
        : {customClickHandler: () => {}};

    const mapConfig = {
        concello: {...concelloConfig},
        edar: {customClickHandler: () => {}},
        bombeo: {customClickHandler: () => {}},
        tanque: {customClickHandler: () => {}},
        colector: {
            visible: false,
            customClickHandler: () => {},
        },
        nucleo: {customClickHandler: () => {}},
    };

    return {mapConfig};
}
