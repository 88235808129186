import {AuthApiService} from "base/service";
import {createEntityService} from "base/entity/service";

import {
    createSubaglomeracion,
    createSubaglomeracions,
    subaglomeracion_api_adapter,
    subaglomeracions_api_adapter,
} from "saneamiento/model";
import {ServiceUtil} from "base/utilities";

const entityService = createEntityService(
    "/api/infrastructures/subaglomeracions",
    createSubaglomeracion,
    createSubaglomeracions,
    subaglomeracion_api_adapter,
    subaglomeracions_api_adapter
);

const basePathForStats = "/api/infrastructures/stats/subaglomeracion";

const SubaglomeracionService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(subaglomeracion) {
        return entityService.create(subaglomeracion);
    },

    update(subaglomeracion) {
        return entityService.update(subaglomeracion);
    },

    updateWithPatch(subaglomeracion) {
        return entityService.updateWithPatch(subaglomeracion);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },

    getStatsList(filter, page = null, sort = null, order = null) {
        return AuthApiService.get(
            "/api/infrastructures/subaglomeracions/stats/list" +
                `?${ServiceUtil.getQueryString(page, filter, sort, order)}`
        );
    },
};

export default SubaglomeracionService;
