import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useAuth} from "base/auth";
import {DateUtil} from "base/utilities";

import {AlertError} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import Tooltip from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import {ChangeActionState, ChangeActionTypes} from "saneamiento/model/Change";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import {ChangeService} from "saneamiento/service";
import Typography from "@mui/material/Typography";

import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";

const ConexionChangesTable = ({idConexion, onChangesLoaded}) => {
    const theme = useTheme();

    const [elements, setElements] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        ChangeService.getList({
            entity_type: "conexion",
            entity_id: idConexion,
        })
            .then(data => {
                setElements(data.results);
                setLoading(false);
                onChangesLoaded(data.results);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }, [idConexion]);

    const formatActionType = actionType => {
        switch (actionType) {
            case ChangeActionTypes.INSERT:
                return "Inserción";
            case ChangeActionTypes.UPDATE:
                return "Edición";
            case ChangeActionTypes.DELETE:
                return "Eliminación";
            default:
                return null;
        }
    };

    const formatActionState = state => {
        switch (state) {
            case ChangeActionState.PENDING:
                return <Box sx={{color: theme.palette.warning.main}}>Pendiente</Box>;
            case ChangeActionState.APPROVED:
                return <Box sx={{color: theme.palette.success.main}}>Aprobado</Box>;
            case ChangeActionState.REJECTED:
                return <Box sx={{color: theme.palette.error.main}}>Rechazado</Box>;
            default:
                return null;
        }
    };

    const formatChanges = changes => {
        return (
            changes && (
                <Stack>
                    {changes.map((change, index) => {
                        const values = change[2];
                        return (
                            <Stack direction="row" spacing={2}>
                                <Stack direction="row" spacing={1}>
                                    <Typography component="span">
                                        {values[0]}%
                                    </Typography>
                                    <ArrowRightAltOutlinedIcon fontSize="small" />
                                    <Typography component="span">
                                        {values[1]}%
                                    </Typography>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            )
        );
    };

    const getTooltipText = change => {
        return (
            <Stack>
                <Box>Tipo: {formatActionType(change.action_type)}</Box>
                <Box>Usuario: {change.action_user_label}</Box>
                <Box>Fecha: {DateUtil.formatDateTime(change.action_date)}</Box>
                <Box>Estado: {formatActionState(change.action_state)}</Box>
                <Box>Cambio: {formatChanges(change.data_changes)}</Box>
                <Box>Observaciones: {change.action_comments}</Box>
                <Box>Usuario de resolución: {change.resolution_user_label}</Box>
                <Box>
                    Fecha de resolución:{" "}
                    {DateUtil.formatDateTime(change.resolution_date)}
                </Box>
            </Stack>
        );
    };

    const tableColumns = [
        {
            id: "action_type",
            label: "Tipo",
            formatFunction: element => formatActionType(element.action_type),
            width: 25,
        },
        {
            id: "action_state",
            label: "Estado",
            width: 25,
            formatFunction: element => formatActionState(element.action_state),
        },
        {
            id: "data_changes",
            label: "Cambio",
            width: 50,
            formatFunction: element => formatChanges(element.data_changes),
        },
    ];

    return loading ? (
        <Grid item container justifyContent="center" my={6}>
            <CircularProgress size={40} />
        </Grid>
    ) : elements && elements.length === 0 ? null : (
        <>
            <AlertError error={error} />
            <TableContainer sx={{width: "100%"}}>
                <Table
                    aria-labelledby="Tabla"
                    size="small"
                    sx={{tableLayout: "fixed", fontSize: "6pt !important"}}
                >
                    <colgroup>
                        {tableColumns.map((headCell, index) => (
                            <col key={index} width={headCell.width + "%"} />
                        ))}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map(headCell => (
                                <Tooltip
                                    title={headCell.title ? headCell.title : ""}
                                    key={headCell.id}
                                    disableHoverListener={!headCell.title}
                                >
                                    <TableCell key={headCell.id}>
                                        {headCell.label}
                                    </TableCell>
                                </Tooltip>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {elements &&
                            elements.map((element, index) => {
                                return (
                                    <Tooltip title={getTooltipText(element)}>
                                        <TableRow hover key={index}>
                                            {tableColumns.map(cellAttribute => {
                                                return (
                                                    <TableCell
                                                        key={cellAttribute.id}
                                                        sx={
                                                            element.action_state !=
                                                            ChangeActionState.PENDING
                                                                ? {
                                                                      fontStyle:
                                                                          "italic",
                                                                      color: "#aaa",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {cellAttribute.formatFunction
                                                            ? cellAttribute.formatFunction(
                                                                  element
                                                              )
                                                            : element[cellAttribute.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </Tooltip>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ConexionChangesTable;
