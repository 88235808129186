import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {AglomeracionExplotacionSection} from "../presentational/section";

const ViewAglomeracionExplotacionSubPage = () => {
    let aglomeracion;
    [aglomeracion] = useOutletContext();

    const sections = [<AglomeracionExplotacionSection aglomeracion={aglomeracion} />];

    return aglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewAglomeracionExplotacionSubPage;
