import {SistemaService} from "abastecemento/service";
import {useSistemaTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListSistemaPage = () => {
    const {tableColumns} = useSistemaTable();

    return (
        <ListInfraestructuraPage
            entityName="Sistemas"
            entityPath="sistemas"
            service={SistemaService}
            tableColumns={tableColumns}
            createButton={false}
        />
    );
};
export default ListSistemaPage;
