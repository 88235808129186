import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {NumberUtil} from "base/utilities";
import {SisbagalSectionCard} from "sisbagal/auth";

const DepositoMedidasSection = ({deposito}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`medidas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Medidas" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Diámetro:"
                        value={NumberUtil.formatDecimal2(deposito.diametro)}
                        unit="m."
                    />
                    <SectionField
                        label="Largo:"
                        value={NumberUtil.formatDecimal2(deposito.largo)}
                        unit="m."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Ancho:"
                        value={NumberUtil.formatDecimal2(deposito.ancho)}
                        unit="m."
                    />
                    <SectionField
                        label="Alto:"
                        value={NumberUtil.formatDecimal2(deposito.alto)}
                        unit="m."
                    />
                    <SectionField
                        label="Capacidade:"
                        value={NumberUtil.formatDecimal2(deposito.capacidade)}
                        unit="m³"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default DepositoMedidasSection;
