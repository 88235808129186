import {useDomain} from "domain/provider";
import {FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";
import {ConcelloHiddenAuth} from "concello/auth";

const VertidoFormCaracteristicasFields = ({layout = "row"}) => {
    const {
        dominiovertidotipopunto,
        dominiovertidotipo,
        dominiovertidotipotitular,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="tipo_punto_vertido"
                        label="Tipo punto de vertido"
                        options={dominiovertidotipopunto}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="tipo"
                        label="Tipo de vertido"
                        options={dominiovertidotipo}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormSelect
                        name="tipo_titular"
                        label="Tipo de titular"
                        options={dominiovertidotipotitular}
                    />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default VertidoFormCaracteristicasFields;
