import {TanqueFormLocationFields} from ".";
import {EntityGeoForm} from "base/entity";

const TanqueGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <TanqueFormLocationFields />
        </EntityGeoForm>
    );
};

export default TanqueGeoForm;
