import {useEffect, useRef} from "react";
import {useBaseLayer, useMap} from "base/component/geo";
import {useMapProvider} from "sisbagal/map";
import SisbagalMapActions from "./SisbagalMapActions";
import Stack from "@mui/material/Stack";
import {NO_CONTENT_HEIGHT} from "base/config/measurements";
import {useSisbagalAppConfig} from "sisbagal/provider";
import useTheme from "@mui/material/styles/useTheme";

const mapStyle = {
    width: "100%",
    height: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
};

const SisbagalMap = ({
    legend,
    mapOptions = {},
    showDownloadPdfAction = false,
    entityData = {},
    height = null,
    handleClickOnMap = null,
}) => {
    const theme = useTheme();
    const {getAllLayerProviders, getIncludedLayerProviders} = useMapProvider();
    const {selectedBaseLayer} = useSisbagalAppConfig();
    const {setBaseLayer} = useBaseLayer();

    const {mapRef, createMap} = useMap();
    const mapObjectRef = useRef(null);

    useEffect(() => {
        const map = createMap(mapOptions);
        getIncludedLayerProviders().forEach(layerProvider =>
            layerProvider.layer ? layerProvider.layer.createLayer(map) : null
        );
        mapObjectRef.current = map;

        return () => {
            if (map._loaded) {
                map.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (mapObjectRef.current) {
            setBaseLayer(mapObjectRef.current, selectedBaseLayer);
        }
    }, [selectedBaseLayer]);

    getAllLayerProviders().forEach(layerProvider => layerProvider.useProvider());

    return (
        <Stack direction="row" sx={{width: "100%"}}>
            <div
                id="map"
                style={height ? {...mapStyle, height} : {...mapStyle}}
                ref={mapRef}
            />
            <Stack
                sx={{
                    height: height || "100%",
                    borderLeft: `1px solid ${theme.palette.secondary.main}`,
                }}
            >
                <SisbagalMapActions
                    mapObjectRef={mapObjectRef}
                    mapRef={mapRef}
                    entityData={entityData}
                    showDownloadPdfAction={showDownloadPdfAction}
                />
                {legend}
            </Stack>
        </Stack>
    );
};

export default SisbagalMap;
