import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {DepositoService} from "abastecemento/service";

const ViewDepositoChangesSubPage = () => {
    let deposito;
    [deposito] = useOutletContext();

    return (
        deposito && (
            <ViewEntityChangesSubPage
                entityService={DepositoService}
                entityType="deposito"
                entityId={deposito.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewDepositoChangesSubPage;
