import {createEntityService} from "base/entity/service";

const entityService = createEntityService("/api/infrastructures/tuberias");

const TuberiaService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },
};

export default TuberiaService;
