import {useNavigate, useParams} from "react-router-dom";
import {SubaglomeracionService} from "saneamiento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewSubaglomeracionPanel = () => {
    const {idSubaglomeracion} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = subaglomeracion => {
        const basePath =
            path === "concellos"
                ? `/concellos/${subaglomeracion.concello}`
                : `/aglomeracions/${subaglomeracion.aglomeracion}`;
        navigate(`${basePath}/subaglomeracions/${subaglomeracion.id}/summary`);
    };

    const getSectionData = subaglomeracion => {
        return (
            <>
                <SectionField
                    label="Aglomeración:"
                    value={subaglomeracion.stats?.nomeaglomeracion}
                    linkPath={`/aglomeracions/${subaglomeracion.stats?.idaglomeracion}/summary`}
                />
                <SectionField
                    label="Concello:"
                    value={subaglomeracion.stats?.nomeconcello}
                    linkPath={`/concellos/${subaglomeracion.stats?.codigoconcello}`}
                />
                <SectionField
                    label="Urbana:"
                    value={NumberUtil.formatDecimalAsInteger(
                        subaglomeracion.stats.cargaurbana
                    )}
                    unit="Heq"
                    tooltipText="Poboación urbana total da aglomeración (domestica e non doméstica)"
                />
                <SectionField
                    label="Doméstica:"
                    value={NumberUtil.formatDecimalAsInteger(
                        subaglomeracion.stats.cargadomestica,
                        0
                    )}
                    unit="Heq"
                    tooltipText="Carga de orixe doméstica a partires de datos do estudo de segundas vivendas e prazas turísticas."
                />
                <SectionField
                    label="Non doméstica:"
                    value={NumberUtil.formatDecimalAsInteger(
                        subaglomeracion.stats.carganondomestica
                    )}
                    unit="Heq"
                    tooltipText="Outras cargas de orixe non doméstico"
                />
                <SectionField
                    label="Lonxitude rede:"
                    value={NumberUtil.formatDecimal(
                        subaglomeracion.stats.lonxitudecolector,
                        3
                    )}
                    unit="Km."
                    tooltipText="Lonxitude da rede unitaria/fecais"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={SubaglomeracionService}
            id={idSubaglomeracion}
            title="Resumo de sistema colector"
            getSectionTitle={subaglomeracion => ``}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewSubaglomeracionPanel;
