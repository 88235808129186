import {MapMenu} from "base/component/geo/menu";
import {LayerMenu} from "base/component/geo";
import {useMapProvider} from "sisbagal/map";

import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import {useNavigate} from "react-router-dom";
import {MenuAction} from "base/component/menu";
import {EditLayer} from "base/map";

const SistemaMapMenu = ({sistema}) => {
    const navigate = useNavigate();

    const {getIncludedLayerProviders} = useMapProvider();

    const tableETAPAction = (
        <MenuAction
            id="list-layer"
            key="list-layer"
            icon={<GridOnOutlinedIcon />}
            text="Ver táboa"
            handleClick={() => {
                navigate(`/concellos/${sistema.concello}/etaps?sistema=${sistema.id}`);
            }}
        />
    );
    const tableDepositoAction = (
        <MenuAction
            id="list-layer"
            key="list-layer"
            icon={<GridOnOutlinedIcon />}
            text="Ver táboa"
            handleClick={() => {
                navigate(
                    `/concellos/${sistema.concello}/depositos?sistema=${sistema.id}`
                );
            }}
        />
    );
    const tableCaptacionAction = (
        <MenuAction
            id="list-layer"
            key="list-layer"
            icon={<GridOnOutlinedIcon />}
            text="Ver táboa"
            handleClick={() => {
                navigate(
                    `/concellos/${sistema.concello}/captacions?sistema=${sistema.id}`
                );
            }}
        />
    );

    const getCustomActions = layerName => {
        // TODO: Use key and not layer name?
        if (layerName === "ETAPs") {
            return [tableETAPAction];
        }
        if (layerName === "Depósitos") {
            return [tableDepositoAction];
        }
        if (layerName === "Captacións") {
            return [tableCaptacionAction];
        }
        return [];
    };

    const getLayerItem = (layerProvider, index) => {
        if (layerProvider.layer instanceof EditLayer) {
            return null;
        }
        return (
            <LayerMenu
                key={index}
                layerProvider={layerProvider}
                customActions={getCustomActions(
                    layerProvider?.layer?.legend?.layerName
                )}
            />
        );
    };

    return (
        <MapMenu>
            {getIncludedLayerProviders().map((layerProvider, index) =>
                getLayerItem(layerProvider, index)
            )}
        </MapMenu>
    );
};

export default SistemaMapMenu;
