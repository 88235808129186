class Conexiones extends Array {}

const conexionElementTypesConstants = Object.freeze({
    EDAR: "edar",
    ETAP: "etap",
    DEPOSITO: "deposito",
    CAPTACION: "captacion",
    NUCLEO: "nucleo",
});

const conexion_api_adapter = conexion => {
    if (conexion["geom"]) {
        conexion["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    conexion["created_at"] = new Date(conexion["created_at"]);
    conexion["updated_at"] = new Date(conexion["updated_at"]);
    return conexion;
};

const conexiones_api_adapter = conexiones => conexiones.map(conexion_api_adapter);

const createConexiones = (data = []) => {
    return Conexiones.from(data, conexiones => createConexion(conexiones));
};

const conexion_view_adapter = conexion => {
    delete conexion["created_at"];
    delete conexion["created_by"];
    delete conexion["updated_at"];
    delete conexion["updated_by"];
    return conexion;
};

const conexiones_view_adapter = conexiones => conexiones.map(conexion_view_adapter);

const createConexion = ({
    id = null,
    tipo_orixe = "",
    id_orixe = null,
    tipo_destino = "",
    id_destino = null,
    porcentaxe = null,
    nome_orixe = null,
    codigo_orixe = null,
    nome_destino = null,
    codigo_destino = null,
    pending_status = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        tipo_orixe,
        id_orixe,
        tipo_destino,
        id_destino,
        porcentaxe,
        nome_orixe,
        codigo_orixe,
        nome_destino,
        codigo_destino,
        pending_status,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createConexion as default,
    createConexiones,
    conexion_api_adapter,
    conexiones_api_adapter,
    conexion_view_adapter,
    conexiones_view_adapter,
    conexionElementTypesConstants,
};
