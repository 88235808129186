import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloPoboacionVariabilidadeSection = ({concello}) => {
    return (
        <SectionCard title="Variabilidade">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacionalidade:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.estacionalidade,
                            1
                        )}
                        unit="%"
                        tooltipText="Porcentaxe de estacionalidade"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Crecemento:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.factorcrecemento,
                            3
                        )}
                        unit="%"
                        tooltipText="Porcentaxe de crecemento interanual"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloPoboacionVariabilidadeSection;
