import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {BombeoService} from "saneamiento/service";

const ViewBombeoChangesSubPage = () => {
    let bombeo;
    [bombeo] = useOutletContext();

    return (
        bombeo && (
            <ViewEntityChangesSubPage
                entityService={BombeoService}
                entityType="bombeo"
                entityId={bombeo.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewBombeoChangesSubPage;
