import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormMonitoreoAvaliacionFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="cumprimentase_formulario_anualmente"
                    label="Cumpriméntase este formulario de forma anual"
                    options={dominiogobernanzasino}
                    tooltipText="Cumpriméntase este formulario de gobernanza cunha periodicidade mínima anual, co obxecto de analizar a evolución do desempeño da gobernanza no tempo."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="avalianse_resultados_anualmente"
                    label="Avalíanse os resultados do formulario de forma anual"
                    options={dominiogobernanzasino}
                    tooltipText="Avalíanse os resultados do formulario de gobernanza cunha periodicidade mínima anual, co obxecto de poder diagnosticar as brechas e propoñer solucións para mellorar a gobernanza."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="compartense_resultados"
                    label="Compártense os resultados da avaliación cos interesados"
                    options={dominiogobernanzasino}
                    tooltipText="Os resultados do desempeño da gobernanza en relación con cada un dos principios e/ou dimensións da mesma deberían compartirse cos interesados."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="realizanse_axustes"
                    label="Realízanse axustes en base aos resultados da avaliación"
                    options={dominiogobernanzasino}
                    tooltipText="Realízanse axustes nos aspectos nos que o desempeño da gobernanza é deficiente, en base aos resultados da autoavaliación."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormMonitoreoAvaliacionFields;
