import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AlertError, DeleteItemDialog} from "base/component/presentational";

import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export function useDeleteEntity(service) {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const urlSlugs = location.pathname.split("/");
    const entityId = urlSlugs.slice().reverse()[1];

    const urlSlugsForEntity = 2;
    urlSlugs.splice(urlSlugs.length - urlSlugsForEntity, urlSlugsForEntity);

    const entitiesListPageUrl = urlSlugs.join("/");

    const handleClickDelete = () => {
        setIsDeleteDialogOpen(true);
    };

    const handleDelete = () => {
        service
            .delete(entityId)
            .then(() => {
                navigate(entitiesListPageUrl);
            })
            .catch(error => {
                setError(error);
            });
    };

    const button = (
        <Button
            aria-label="delete-item"
            onClick={handleClickDelete}
            startIcon={<DeleteOutlineOutlinedIcon />}
            variant="contained"
            color="error"
        >
            Eliminar
        </Button>
    );

    const dialog = (
        <>
            <AlertError error={error} />
            <DeleteItemDialog
                isDialogOpen={isDeleteDialogOpen}
                setIsDialogOpen={setIsDeleteDialogOpen}
                onDelete={handleDelete}
            />
        </>
    );

    return {button, dialog};
}
