import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styled from "@mui/material/styles/styled";

const TableCellTitle = styled(TableCell)(({theme}) => ({
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    textAlign: "left",
    width: "30%",
}));
const TableCellContent = styled(TableCell)(({theme}) => ({
    textAlign: "left",
    width: "70%",
}));

const DataProtectionDialog = ({open, onClose}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{m: 0, p: 2}}>
                Información básica sobre a protección de datos persoais
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <Paper sx={{width: "100%"}}>
                    <TableContainer sx={{maxHeight: 440}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                <TableRow key="responsable">
                                    <TableCellTitle>
                                        Responsable do tratamento
                                    </TableCellTitle>
                                    <TableCellContent>
                                        Xunta de Galicia - Entidade pública empresarial
                                        Augas de Galicia
                                    </TableCellContent>
                                </TableRow>
                                <TableRow key="responsable">
                                    <TableCellTitle>
                                        Finalidades do tratamento
                                    </TableCellTitle>
                                    <TableCellContent>
                                        Xestión das peticións de acceso ao Sistema de
                                        Información de Servicios Básicos de Augas de
                                        Galicia
                                    </TableCellContent>
                                </TableRow>
                                <TableRow key="responsable">
                                    <TableCellTitle>
                                        Lexitimación para o tratamento
                                    </TableCellTitle>
                                    <TableCellContent>
                                        O tratamento é necesario para o cumprimento
                                        dunha misión realizada en interese público ou no
                                        exercicio de poderes públicos conferidos ao
                                        responsable do tratamento.
                                    </TableCellContent>
                                </TableRow>
                                <TableRow key="responsable">
                                    <TableCellTitle>
                                        Destinatarios dos datos
                                    </TableCellTitle>
                                    <TableCellContent>
                                        Non se prevén cesións de datos.
                                    </TableCellContent>
                                </TableRow>
                                <TableRow key="responsable">
                                    <TableCellTitle>Exercicio dos datos</TableCellTitle>
                                    <TableCellContent>
                                        As persoas interesadas poderán solicitar o
                                        acceso, rectificación, supresión, limitación,
                                        portabilidade e oposición dos seus datos, ou
                                        retirar no seu caso o consentimento outorgado, a
                                        través da sede electrónica da Xunta de Galicia
                                        ou nos lugares e rexistros establecidos na
                                        normativa reguladora do procedemento
                                        administrativo común segundo se recolle en{" "}
                                        <a
                                            href="https://www.xunta.gal/exercicio-de-dereitos"
                                            target="_blank"
                                        >
                                            https://www.xunta.gal/exercicio-de-dereitos.
                                        </a>
                                        . Así mesmo, as persoas interesadas poderán
                                        retirar o consentimento previamente outorgado
                                        dirixindo a súa solicitude a{" "}
                                        <a
                                            href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}
                                        >
                                            {process.env.REACT_APP_CONTACT_MAIL}
                                        </a>
                                        . A retirada do consentimento non afectará a
                                        licitude do tratamento baseada no consentimento
                                        previo á súa retirada.
                                    </TableCellContent>
                                </TableRow>
                                <TableRow key="responsable">
                                    <TableCellTitle>
                                        Contacto delegado/a de protección de datos e
                                        información adicional
                                    </TableCellTitle>
                                    <TableCellContent>
                                        <a
                                            href="https://www.xunta.gal/informacion-xeral-proteccion-datos"
                                            target="_blank"
                                        >
                                            https://www.xunta.gal/informacion-xeral-proteccion-datos
                                        </a>
                                    </TableCellContent>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default DataProtectionDialog;
