import {useNavigate, useParams} from "react-router-dom";
import {ConcelloService} from "concello/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConcelloAuth} from "concello/auth";

const ViewConcelloPanel = () => {
    const {codigo} = useParams();
    const navigate = useNavigate();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = concello => {
        navigate(`/concellos/${concello.codigo}`);
    };

    const getSectionData = concello => {
        return (
            <>
                <SectionField label="Código:" value={concello.codigo} />
                <SectionField
                    label="Poboación fixa:"
                    value={NumberUtil.formatInteger(concello.poboacion_fixa)}
                    unit="habs"
                />
                <SectionField
                    label="Estacionalidade:"
                    value={NumberUtil.formatDecimal(concello.stats.estacionalidade)}
                    unit="%"
                />
                <SectionField
                    label="Crecemento:"
                    value={NumberUtil.formatDecimal(concello.stats.factorcrecemento, 3)}
                    unit="%"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={ConcelloService}
            id={codigo}
            title="Resumo de concello"
            getSectionTitle={entity => entity?.nome}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.id)}
        />
    );
};

export default ViewConcelloPanel;
