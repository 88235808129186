import {EntityFormGeneralDataSection, FormStepper} from "base/component/form";
import {
    BombeoFormCaracteristicasSection,
    BombeoFormExplotacionSection,
    BombeoFormGeneralDataFields,
    BombeoFormLocationFields,
} from ".";
import Box from "@mui/material/Box";

const BombeoCreationForm = ({onSubmit, onCancel = null}) => {
    const steps = ["Datos xerais", "Características e elementos", "Explotación"];

    const stepComponents = [
        <EntityFormGeneralDataSection
            fields={<BombeoFormGeneralDataFields creation={true} />}
            location={<BombeoFormLocationFields isCreationForm={true} />}
        />,
        <BombeoFormCaracteristicasSection />,
        <BombeoFormExplotacionSection />,
    ];

    return (
        <Box component="form" width="100%" mt={4}>
            <FormStepper
                onSubmit={onSubmit}
                onCancel={onCancel}
                steps={steps}
                stepComponents={stepComponents}
            />
        </Box>
    );
};

export default BombeoCreationForm;
