import {useNavigate} from "react-router-dom";
import {mapOverlayPanes} from "base/component/geo";
import {createLayerLegend, useLayerObject} from "base/map";

const markerBaseOptions = {
    marker: "d",
    fillColor: "#970099",
    radius: 12,
    color: "white",
    weight: 2,
    opacity: 1,
    fillOpacity: 1,
    pane: mapOverlayPanes[12],
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    TIPO_PUNTO_VERTIDO: "tipo_punto_vertido",
});

export const LayerLegend = createLayerLegend({
    layerName: "Vertidos",
    menuIconShape: markerBaseOptions.marker,
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.TIPO_PUNTO_VERTIDO,
            text: "Tipo de punto de vertido",
            defaultIconOptions: markerBaseOptions,
            // 1	Urbano
            // 2	DSU
            // 3	Industrial
            // 4	Pluviais
            // 5	Industrial sen clasificar
            entries: [
                {
                    text: "Urbano",
                    filterFn: val => val === 1,
                    markerOptions: {...markerBaseOptions, fillColor: "#500052"},
                },
                {
                    text: "DSU",
                    filterFn: val => val === 2,
                    markerOptions: {...markerBaseOptions, fillColor: "#A000A3"},
                },
                {
                    text: "Industrial",
                    filterFn: val => val === 3,
                    markerOptions: {...markerBaseOptions, fillColor: "#F100F5"},
                },
                {
                    text: "Pluviais",
                    filterFn: val => val === 4,
                    markerOptions: {...markerBaseOptions, fillColor: "#FC47FF"},
                },
                {
                    text: "Industrial sen clasificar",
                    filterFn: val => val === 5,
                    markerOptions: {...markerBaseOptions, fillColor: "#FD70FF"},
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        getTooltip: ({feature}) => {
            let data = feature.properties;
            let tooltip = `<b>Vertido: ${
                data["codigo"] ? data["codigo"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Código externo</i>: ${
                data["codigo_externo"] ? data["codigo_externo"] : "---"
            }</li>`;
            tooltip += `<li><i>Tipo punto de vertido</i>: ${
                data["tipo_punto_vertido_label"]
                    ? data["tipo_punto_vertido_label"]
                    : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/vertidos/${feature.id}`),
    });
}
