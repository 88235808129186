import {ColectorFormCaracteristicasFields, ColectorFormGeneralDataFields} from ".";

const ColectorModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <ColectorFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <ColectorFormCaracteristicasFields layout="column" />;
    }
    return null;
};

export default ColectorModificationForm;
