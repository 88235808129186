import {ViewConexionsSubPage} from "conexion/container";
import {useOutletContext} from "react-router-dom";

const ViewSaneamentoConexionsSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    return (
        <ViewConexionsSubPage
            concello={concello}
            rede="saneamento"
            title="Conexions de saneamento"
            layersConfig={{
                subaglomeracion: {},
                edar: {},
                tanque: {},
                bombeo: {},
                colector: {},
            }}
        />
    );
};
export default ViewSaneamentoConexionsSubPage;
