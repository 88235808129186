import {FieldUtil} from "base/utilities";

export function useETAPData(etap) {
    const getEntityData = etap => {
        return {
            image: etap?.featured_image,
            name: `Etap ${etap?.codigo || etap?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(etap?.codigo)],
                        ["Concello:", FieldUtil.getValue(etap?.concello_label)],
                        ["Xestión:", FieldUtil.getValue(etap?.xestion_label)],
                        ["Estado:", FieldUtil.getValue(etap?.estado_label)],
                        ["Descripción:", FieldUtil.getValue(etap?.descripcion)],
                        [
                            "Sistema PMAA:",
                            FieldUtil.getValue(etap?.sistema_label || etap?.sistema),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                etap?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(etap?.geom.coordinates[1])}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Pretratamento:",
                            FieldUtil.getValue(etap?.pretratamiento_label),
                        ],
                        [
                            "Físico-químico:",
                            FieldUtil.getValue(etap?.fisicoquimico_label),
                        ],
                        ["Filtración:", FieldUtil.getValue(etap?.filtracion_label)],
                        ["Desinfección:", FieldUtil.getValue(etap?.desinfeccion_label)],
                        ["Notas:", FieldUtil.getValue(etap?.notas)],
                        ["Protección:", FieldUtil.getValue(etap?.proteccion_label)],
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(etap?.suministro_electrico_label),
                        ],
                        ["Telexestión:", FieldUtil.getValue(etap?.telexestion_label)],
                        ["Caudalímetro:", FieldUtil.getValue(etap?.caudalimetro_label)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
