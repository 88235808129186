import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const EDARCargasTotalSection = ({edar}) => {
    return (
        <SectionCard title="Total">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.urbanatotal
                        )}
                        unit="Heq"
                        tooltipText="Carga urbana total (domestica e non doméstica) "
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.cargadomestica
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe doméstica (Fixa e estacional)"
                    />
                    <SectionField
                        label="Non doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            edar.stats.carganondomestica
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default EDARCargasTotalSection;
