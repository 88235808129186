import {useEDARMap} from "saneamiento/edar/hooks";
import {EntityLocationSection} from "base/entity";

const EDARLocationSection = ({edar}) => {
    const {mapConfig} = useEDARMap();

    return <EntityLocationSection entity={edar} mapConfig={mapConfig} />;
};

export default EDARLocationSection;
