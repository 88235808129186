import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloPoboacionTotalSection = ({concello}) => {
    return (
        <SectionCard title="Total">
            <Grid container>
                <SectionSubheading text="Rede saneamento" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana:"
                        value={NumberUtil.formatDecimal(concello.stats.cargaurbana, 0)}
                        unit="Heq"
                        tooltipText="Poboación urbana total (domestica e non doméstica)"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Doméstica:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.cargadomestica,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe doméstica (Fixa e estacional)."
                    />
                    <SectionField
                        label="Non doméstica:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomestica,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Total concello" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.cargaurbanatotal,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación urbana total (domestica e non doméstica)"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Doméstica:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.cargadomesticatotal,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe doméstica (Fixa e estacional)."
                    />
                    <SectionField
                        label="Non doméstica:"
                        value={NumberUtil.formatDecimal(
                            concello.stats.carganondomesticatotal,
                            0
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloPoboacionTotalSection;
