import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    GobernanzaCapacitacionMediosSection,
    GobernanzaRolesResponsabilidadesSection,
    GobernanzaConsideracionSistemasSection,
    GobernanzaCoherenciaPoliticasSection,
} from "../presentational/section";

const ViewGobernanzaEficaciaSubPage = () => {
    let gobernanza;
    [gobernanza] = useOutletContext();

    const sections = [
        <GobernanzaRolesResponsabilidadesSection gobernanza={gobernanza} />,
        <GobernanzaConsideracionSistemasSection gobernanza={gobernanza} />,
        <GobernanzaCoherenciaPoliticasSection gobernanza={gobernanza} />,
        <GobernanzaCapacitacionMediosSection gobernanza={gobernanza} />,
    ];

    return gobernanza && <EntityViewSubPage sections={sections} />;
};

export default ViewGobernanzaEficaciaSubPage;
