import {Route} from "react-router-dom";
import {ViewAbastecementoConexionsSubPage} from "abastecemento/container";
import {ConexionesPanel, ViewConexionPanel} from "conexion/container";
import {ViewETAPPanel} from "abastecemento/etap/container";
import {ViewDepositoPanel} from "abastecemento/deposito/container";
import {ViewCaptacionPanel} from "abastecemento/captacion/container";
import {ViewSistemaPanel} from "abastecemento/sistema/container";
import {ViewNucleoPanel} from "nucleo/container";

const conexionesRoutes = [
    <Route
        key="concello-abast-conexions-viewer"
        path="abast-conexions"
        element={<ViewAbastecementoConexionsSubPage />}
    >
        <Route
            key="concellos-abast-conexion-info"
            path="info/conexions/:idConexion"
            element={<ViewConexionPanel />}
        />
        <Route
            key="concellos-abast-conexions-sistema-info"
            path="info/sistemas/:idSistema"
            element={<ViewSistemaPanel />}
        />
        <Route
            key="concellos-abast-conexions-etap-info"
            path="info/etaps/:idEtap"
            element={<ViewETAPPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-abast-conexions-deposito-info"
            path="info/depositos/:idDeposito"
            element={<ViewDepositoPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-abast-conexions-captacion-info"
            path="info/captacions/:idCaptacion"
            element={<ViewCaptacionPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-abast-conexions-nucleo-info"
            path="info/nucleos/:idNucleo"
            element={<ViewNucleoPanel allowConnectionManagement={true} />}
        />
        <Route
            key="concellos-abast-conexions-connections-form"
            path="conexions/:connectionEnd/:elementType/:idElement"
            element={<ConexionesPanel isSaneamiento={false} />}
        />
    </Route>,
];

export default conexionesRoutes;
