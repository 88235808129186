import {FormInputText} from "base/component/form";
import Grid from "@mui/material/Grid";
import {EDARFormSearch} from "saneamiento/edar/presentational/form";
import {ConcelloHiddenAuth} from "concello/auth";
import {AglomeracionFormSearch} from "saneamiento/aglomeracion/presentational/form";
import {ConcelloFormSearch} from "concello/presentational/form";

const PoligonoFormGeneralDataFields = ({creation = false, layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="nome" label="Nome" />
            </Grid>
            <Grid item xs={12} md={6}>
                <EDARFormSearch name="edar" />
            </Grid>
            <ConcelloHiddenAuth>
                {!creation && (
                    <Grid item xs={12}>
                        <ConcelloFormSearch
                            name="concello"
                            rules={{required: "O campo é obrigatorio"}}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <AglomeracionFormSearch name="aglomeracion" />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default PoligonoFormGeneralDataFields;
