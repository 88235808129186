import {
    GobernanzaFormRolesResponsabilidadesFields,
    GobernanzaFormConsideracionSistemasFields,
    GobernanzaFormCoherenciaPoliticasFields,
    GobernanzaFormCapacitacionMediosFields,
    GobernanzaFormDatosInformacionFields,
    GobernanzaFormFinanciamentoFields,
    GobernanzaFormMarcosRegulatoriosFields,
    GobernanzaFormInnovacionFields,
    GobernanzaFormIntegridadeFields,
    GobernanzaFormCompromisoFields,
    GobernanzaFormEquidadeFields,
    GobernanzaFormMonitoreoAvaliacionFields,
} from ".";

const GobernanzaModificationForm = ({section}) => {
    if (section === "roles_responsabilidades") {
        return <GobernanzaFormRolesResponsabilidadesFields layout="column" />;
    }
    if (section === "consideracion_sistemas") {
        return <GobernanzaFormConsideracionSistemasFields layout="column" />;
    }
    if (section === "coherencia_politicas") {
        return <GobernanzaFormCoherenciaPoliticasFields layout="column" />;
    }
    if (section === "capacitacion_medios") {
        return <GobernanzaFormCapacitacionMediosFields layout="column" />;
    }
    if (section === "datos_informacion") {
        return <GobernanzaFormDatosInformacionFields layout="column" />;
    }
    if (section === "financiamento") {
        return <GobernanzaFormFinanciamentoFields layout="column" />;
    }
    if (section === "marcos_regulatorios") {
        return <GobernanzaFormMarcosRegulatoriosFields layout="column" />;
    }
    if (section === "gobernanza_innovadora") {
        return <GobernanzaFormInnovacionFields layout="column" />;
    }
    if (section === "integridade") {
        return <GobernanzaFormIntegridadeFields layout="column" />;
    }
    if (section === "compromiso") {
        return <GobernanzaFormCompromisoFields layout="column" />;
    }
    if (section === "equidade") {
        return <GobernanzaFormEquidadeFields layout="column" />;
    }
    if (section === "monitoreo_avaliacion") {
        return <GobernanzaFormMonitoreoAvaliacionFields layout="column" />;
    }
    return null;
};

export default GobernanzaModificationForm;
