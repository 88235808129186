import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormConsideracionSistemasFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="conhece_estado_masas"
                    label="Coñece o estado das masas de auga ás que verten os seus sistemas"
                    options={dominiogobernanzasino}
                    tooltipText="As masas de auga poden incumprir os obxectivos ambientais da planificación hidrolóxica ben porque non acadan o bo estado ou ben porque o seu estado empeorou con respecto do ciclo de planificación anterior."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="sabe_causas_mal_estado"
                    label="No caso de estar en mal estado, sabe cales son as causas"
                    options={dominiogobernanzasino}
                    tooltipText="As masas de auga que non acadan o bo estado é debido a presencia de presións ou actividades humanas que inciden sobre as mesmas causando un impacto que pode poñelas en risco de non acadar os obxectivos medioambientais. As presións poden ser por fontes de contaminación puntual (entre as que se atopan os vertidos), fontes de contaminación difusa, extraccións de auga e retornos, obras de regulación, alteracións morfolóxicas, usos de solo u outras presións."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="sabe_obxectivos_vertido"
                    label="Sabe que os obxectivos de vertido poden variar en función da masa"
                    options={dominiogobernanzasino}
                    tooltipText="Os obxectivos de vertido que limitan o tipo de tratamento a aplicar ás augas residuais poden ser diferentes en función do tipo de medio receptor (masa de auga/ non masa de auga), do tipo de masa de auga, do estado da masa de auga, do cumprimento do principio de non deterioro da masa de auga, dos requirimentos específicos en zonas de baño, zonas de captación e espacios protexidos e dos requirimentos en zonas sensibles e áreas de captación de zonas sensibles."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="ten_inventariados_alivios"
                    label="Ten inventariados os alivios dos seus sistemas"
                    options={dominiogobernanzasino}
                    tooltipText="Segundo a Disposición adicional segunda do Regulamento do DPH, os titulares de verteduras industriais e de verteduras urbanas de máis de 2.000 habitantes equivalentes, cuxos sistemas de saneamento orixinen desbordamentos en episodios de choiva, deberían ter presentado aos Organismos de conca unha relación dos puntos de desbordamento antes do 31 de decembro de 2014."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="realiza_control_alivios"
                    label="Realiza un control dos alivios dos seus sistemas"
                    options={dominiogobernanzasino}
                    tooltipText="Segundo a Disposición adicional terceira do Regulamento do DPH, os titulares das autorizacións de vertedura vixentes deberían ter dotado de sistemas de cuantificación de alivios aos puntos de desbordamento en 2016, nos seguintes casos: 
                    a) Verteduras procedentes de aglomeracións urbanas de máis de 50.000 he.
                    b) Verteduras procedentes de instalacións industriais que requiran AAI, e zonas industriais onde se sitúe algunha destas instalacións. 
                    c) Verteduras procedentes de aglomeracións urbanas de máis de 2.000 he ou zonas industriais diferentes aos anteriores situados nunha zona protexida declarada augas de baño incluída no Rexistro de Zonas Protexidas da Demarcación Hidrográfica."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="realiza_xestion_ciclo_auga"
                    label="Realiza unha xestión do ciclo integral da auga"
                    options={dominiogobernanzasino}
                    tooltipText="A xestión do ciclo integral da auga, na que se inclúe o abastecemento o saneamento e a depuración, pode mellorar a eficiencia e conseguir unha xestión mais sustentable na prestación dos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="compara_volumes_auga"
                    label="Compara os volumes de auga captada, rexistrada e retornada"
                    options={dominiogobernanzasino}
                    tooltipText="A comparación dos volumes captados, rexistrados e retornados pode dar unha idea da eficiencia dos sistemas relacionados co ciclo integral da auga: perdas en abastecemento, grao de retornos, infiltracións, pluviais, etc. Tamén da unha idea dos volumes da auga residual que non se cobran aos usuarios, a pesar de que o seu tratamento e bombeo xera custos importantes (o saneamento cóbrase en xeral en base aos rexistros do abastecemento). 
                    Doutra banda, a realización da captación nunha masa de auga e o vertido noutra diferente pode ter efectos sobre o estado das masas de auga, sobre todo cando os volumes son significativos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="sabe_organismos_conca"
                    label="Sabe cales son os organismos de conca con competencias no seu concello"
                    options={dominiogobernanzasino}
                    tooltipText="Os vertidos a concas xestionadas por diferentes organismos de conca poden ter requirimentos diferentes."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismo_coordinacion_organismos"
                    label="Existen mecanismos de coordinación con estes organismos"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, para avisar de vertidos imprevistos ou para remitir información."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormConsideracionSistemasFields;
