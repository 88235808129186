import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARTratamentoRendementoSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`rendementoinstalacion/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Tratamento" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tratamento:"
                        value={edar.rendemento_tratamento_label}
                        tooltipText="Tipo de tratamento da instalación"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Tratamento avanzado:"
                        value={edar.rendemento_desinfeccion_label}
                        tooltipText="A planta conta con tratamento avanzado"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARTratamentoRendementoSection;
