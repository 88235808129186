import {NumberUtil} from "base/utilities";

class Subaglomeracions extends Array {}

const subaglomeracion_api_adapter = edar => {
    if (edar["geom"]) {
        edar["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    edar["created_at"] = new Date(edar["created_at"]);
    edar["updated_at"] = new Date(edar["updated_at"]);
    return edar;
};

const subaglomeracions_api_adapter = subaglomeracions =>
    subaglomeracions.map(subaglomeracion_api_adapter);

const createSubaglomeracions = (data = []) => {
    const subaglomeracions = Subaglomeracions.from(data, subaglomeracion =>
        createSubaglomeracion(subaglomeracion)
    );
    return subaglomeracions;
};

const subaglomeracion_view_adapter = subaglomeracion => {
    delete subaglomeracion["concello_label"];
    delete subaglomeracion["aglomeracion_label"];
    delete subaglomeracion["poboacion_saneada_abastecida_label"];
    delete subaglomeracion["antiguidade_colectores_label"];
    delete subaglomeracion["antiguidade_sumidoiros_label"];
    delete subaglomeracion["telecontrol_label"];
    delete subaglomeracion["control_alivios_label"];
    delete subaglomeracion["reutilizacion_outras_instalacions_label"];
    delete subaglomeracion["problema_sen_datos_label"];
    delete subaglomeracion["problema_sen_problema_label"];
    delete subaglomeracion["problema_infiltracion_frecuencia_label"];
    delete subaglomeracion["problema_infiltracion_alcance_label"];
    delete subaglomeracion["problema_augamar_frecuencia_label"];
    delete subaglomeracion["problema_augamar_alcance_label"];
    delete subaglomeracion["problema_augablanca_frecuencia_label"];
    delete subaglomeracion["problema_augablanca_alcance_label"];
    delete subaglomeracion["problema_contrapendente_frecuencia_label"];
    delete subaglomeracion["problema_contrapendente_alcance_label"];
    delete subaglomeracion["problema_rotura_frecuencia_label"];
    delete subaglomeracion["problema_rotura_alcance_label"];
    delete subaglomeracion["problema_atasco_frecuencia_label"];
    delete subaglomeracion["problema_atasco_alcance_label"];
    delete subaglomeracion["problema_outro_frecuencia_label"];
    delete subaglomeracion["problema_outro_alcance_label"];

    delete subaglomeracion["codigoaglomeracion"];
    delete subaglomeracion["nomeaglomeracion"];
    delete subaglomeracion["codigoconcello"];
    delete subaglomeracion["nomeconcello"];
    delete subaglomeracion["cargaurbana"];
    delete subaglomeracion["cargadomestica"];
    delete subaglomeracion["carganondomestica"];
    delete subaglomeracion["lonxitudecolector"];
    delete subaglomeracion["bombeos"];
    delete subaglomeracion["tanques"];

    delete subaglomeracion["created_at"];
    delete subaglomeracion["created_by"];
    delete subaglomeracion["updated_at"];
    delete subaglomeracion["updated_by"];
    return subaglomeracion;
};

const createSubaglomeracion = ({
    id = null,
    codigo = "",
    nome = "",
    geom = null,
    notas = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    poboacion_saneada_abastecida = null,
    poboacion_saneada_abastecida_label = null,
    caudal_abastecemento = null,
    antiguidade_colectores = null,
    antiguidade_colectores_label = null,
    antiguidade_sumidoiros = null,
    antiguidade_sumidoiros_label = null,
    telecontrol = null,
    telecontrol_label = null,
    plan_mantemento = null,
    plan_mantemento_label = null,
    manual_explotacion = null,
    manual_explotacion_label = null,
    limpeza_colectores = null,
    limpeza_colectores_label = null,
    control_alivios = null,
    control_alivios_label = null,
    desbordamento = null,
    desbordamento_label = null,
    augas_brancas = null,
    reutilizacion_outras_instalacions = null,
    reutilizacion_outras_instalacions_label = null,
    material_plastico = null,
    material_formigon = null,
    material_fibrocemento = null,
    natureza_pluviais = null,
    natureza_fecais = null,
    natureza_unitarios = null,
    problema_sen_datos = null,
    problema_sen_datos_label = null,
    problema_sen_problema = null,
    problema_sen_problema_label = null,
    problema_infiltracion_frecuencia = null,
    problema_infiltracion_frecuencia_label = null,
    problema_infiltracion_alcance = null,
    problema_infiltracion_alcance_label = null,
    problema_augamar_frecuencia = null,
    problema_augamar_frecuencia_label = null,
    problema_augamar_alcance = null,
    problema_augamar_alcance_label = null,
    problema_augablanca_frecuencia = null,
    problema_augablanca_frecuencia_label = null,
    problema_augablanca_alcance = null,
    problema_augablanca_alcance_label = null,
    problema_contrapendente_frecuencia = null,
    problema_contrapendente_frecuencia_label = null,
    problema_contrapendente_alcance = null,
    problema_contrapendente_alcance_label = null,
    problema_rotura_frecuencia = null,
    problema_rotura_frecuencia_label = null,
    problema_rotura_alcance = null,
    problema_rotura_alcance_label = null,
    problema_atasco_frecuencia = null,
    problema_atasco_frecuencia_label = null,
    problema_atasco_alcance = null,
    problema_atasco_alcance_label = null,
    problema_outro = null,
    problema_outro_frecuencia = null,
    problema_outro_frecuencia_label = null,
    problema_outro_alcance = null,
    problema_outro_alcance_label = null,
    heq_industria_dispersa = null,
    heq_outros_usos_non_domesticos = null,
    saneamento_autonomo = null,
    domestica_sen_tramento = null,
    pending_status = null,
    stats = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
    // list attributes
    codigoaglomeracion = null,
    nomeaglomeracion = null,
    codigoconcello = null,
    nomeconcello = null,
    cargaurbana = null,
    cargadomestica = null,
    carganondomestica = null,
    lonxitudecolector = null,
    bombeos = null,
    tanques = null,
    tratamento = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        nome,
        geom,
        notas,
        folder,
        featured_image,
        featured_document,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        poboacion_saneada_abastecida,
        poboacion_saneada_abastecida_label,
        caudal_abastecemento,
        antiguidade_colectores,
        antiguidade_colectores_label,
        antiguidade_sumidoiros,
        antiguidade_sumidoiros_label,
        telecontrol,
        telecontrol_label,
        plan_mantemento,
        plan_mantemento_label,
        manual_explotacion,
        manual_explotacion_label,
        limpeza_colectores,
        limpeza_colectores_label,
        control_alivios,
        control_alivios_label,
        desbordamento,
        desbordamento_label,
        augas_brancas,
        reutilizacion_outras_instalacions,
        reutilizacion_outras_instalacions_label,
        material_plastico,
        material_formigon,
        material_fibrocemento,
        natureza_pluviais,
        natureza_fecais,
        natureza_unitarios,
        problema_sen_datos,
        problema_sen_datos_label,
        problema_sen_problema,
        problema_sen_problema_label,
        problema_infiltracion_frecuencia,
        problema_infiltracion_frecuencia_label,
        problema_infiltracion_alcance,
        problema_infiltracion_alcance_label,
        problema_augamar_frecuencia,
        problema_augamar_frecuencia_label,
        problema_augamar_alcance,
        problema_augamar_alcance_label,
        problema_augablanca_frecuencia,
        problema_augablanca_frecuencia_label,
        problema_augablanca_alcance,
        problema_augablanca_alcance_label,
        problema_contrapendente_frecuencia,
        problema_contrapendente_frecuencia_label,
        problema_contrapendente_alcance,
        problema_contrapendente_alcance_label,
        problema_rotura_frecuencia,
        problema_rotura_frecuencia_label,
        problema_rotura_alcance,
        problema_rotura_alcance_label,
        problema_atasco_frecuencia,
        problema_atasco_frecuencia_label,
        problema_atasco_alcance,
        problema_atasco_alcance_label,
        problema_outro,
        problema_outro_frecuencia,
        problema_outro_frecuencia_label,
        problema_outro_alcance,
        problema_outro_alcance_label,
        heq_industria_dispersa,
        heq_outros_usos_non_domesticos,
        saneamento_autonomo,
        domestica_sen_tramento,
        pending_status,
        stats,
        created_by,
        created_at,
        updated_by,
        updated_at,
        codigoaglomeracion,
        nomeaglomeracion,
        codigoconcello,
        nomeconcello,
        cargaurbana,
        cargadomestica,
        carganondomestica,
        lonxitudecolector,
        bombeos,
        tanques,
        tratamento,
    };

    return Object.freeze(publicApi);
};

export {
    createSubaglomeracion as default,
    createSubaglomeracions,
    subaglomeracion_api_adapter,
    subaglomeracions_api_adapter,
    subaglomeracion_view_adapter,
};
