import useTheme from "@mui/material/styles/useTheme";

import {TextLink} from ".";

import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const ResultsSummaryList = ({listItems = [], getChipColor, totalValue, totalLabel}) => {
    const theme = useTheme();
    return (
        <List sx={{pl: 1, pt: 0}}>
            {listItems?.map((listItem, index) => {
                return (
                    <ListItem
                        key={index}
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            px: 0,
                            py: 0.5,
                        }}
                    >
                        <Box>
                            <ListItemIcon sx={{minWidth: "24px"}}>
                                {index + 1}.
                            </ListItemIcon>
                            <TextLink text={listItem.label} to={listItem.to} />
                        </Box>
                        <Chip
                            label={`Total de puntos: ${listItem?.total} `}
                            color={listItem.evaluationColor}
                            variant="outlined"
                            sx={{fontWeight: "medium"}}
                        />
                    </ListItem>
                );
            })}
            <ListItem
                key="total"
                component={Paper}
                variant="outlined"
                sx={{
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    mt: 2,
                    borderColor: theme.palette.secondary.light,
                }}
            >
                <Box>
                    <Typography
                        sx={{fontWeight: "medium", textTransform: "uppercase", ml: 1}}
                        color="text.secondary"
                    >
                        Grado de cumprimento:
                    </Typography>
                </Box>
                <Chip
                    label={`${totalLabel}: ${totalValue} puntos`}
                    color={getChipColor(totalValue)}
                    sx={{ml: 2, fontWeight: "medium"}}
                />
            </ListItem>
        </List>
    );
};

export default ResultsSummaryList;
