import {useOutletContext} from "react-router-dom";
import {CaptacionService} from "abastecemento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {CaptacionGeneralDataSection} from "../presentational/section";
import {UpdateCaptacionGeoPanel} from ".";

const ViewCaptacionSummarySubPage = () => {
    let captacion;
    [captacion] = useOutletContext();

    const sections = [
        <CaptacionGeneralDataSection captacion={captacion} />,
        <UpdateCaptacionGeoPanel />,
        <EntityAuditSection entity={captacion} />,
    ];

    return (
        captacion && (
            <EntityViewSubPage sections={sections} service={CaptacionService} />
        )
    );
};

export default ViewCaptacionSummarySubPage;
