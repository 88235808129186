import {TextList} from ".";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

const ItemsWithChipList = ({listItems, chipLabel, chipColor}) => {
    return (
        <>
            <TextList listItems={listItems} />
            <Grid container pl={1}>
                <Chip label={chipLabel} color={chipColor} />
            </Grid>
        </>
    );
};

export default ItemsWithChipList;
