import {useVertidoMap} from "saneamiento/vertido/hooks";
import {EntityLocationSection} from "base/entity";

const VertidoLocationSection = ({vertido}) => {
    const {mapConfig} = useVertidoMap();

    return <EntityLocationSection entity={vertido} mapConfig={mapConfig} />;
};

export default VertidoLocationSection;
