import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SubaglomeracionInventarioRexistroConDatosSection = ({subaglomeracion}) => {
    return (
        <SectionCard title="Rexistros con datos">
            <Grid container>
                <SectionSubheading text="Bombeos" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatInteger(
                            subaglomeracion.stats.consumodatosrexistrosbombeo
                        )}
                        tooltipText="Número de bombeos con datos de consumo electrico."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatInteger(
                            subaglomeracion.stats.potenciadatosrexistrosbombeo
                        )}
                        tooltipText="Numero de bombeos con datos de potencia"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Tanques" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatInteger(
                            subaglomeracion.stats.consumodatosrexistrostanque
                        )}
                        tooltipText="Número de tanques con datos de consumo electrico"
                    />
                    <SectionField
                        label="Capacidade:"
                        value={NumberUtil.formatInteger(
                            subaglomeracion.stats.capacidadetanquedatosrexistrostanque
                        )}
                        tooltipText="Número de tanques con datos de capacidade"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia:"
                        value={NumberUtil.formatInteger(
                            subaglomeracion.stats.potenciadatosrexistrostanque
                        )}
                        tooltipText="Número de tanques con datos de potencia"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SubaglomeracionInventarioRexistroConDatosSection;
