class Nucleos extends Array {}

const nucleo_api_adapter = nucleo => {
    if (nucleo["geom"]) {
        nucleo["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    nucleo["created_at"] = new Date(nucleo["created_at"]);
    nucleo["updated_at"] = new Date(nucleo["updated_at"]);
    return nucleo;
};

const nucleos_api_adapter = nucleos => nucleos.map(nucleo_api_adapter);

const createNucleos = (data = []) => {
    const nucleos = Nucleos.from(data, nucleos => createNucleo(nucleos));
    return nucleos;
};

const nucleo_view_adapter = nucleo => {
    // in front-end falsy values are "" or undefined or null
    delete nucleo["created_at"];
    delete nucleo["created_by"];
    delete nucleo["updated_at"];
    delete nucleo["updated_by"];
    return nucleo;
};

const createNucleo = ({
    id = null,
    codigo_iet = "",
    nome = "",
    poboacion_fixa = null,
    poboacion_estacional = null,
    parroquia = null,
    parroquia_label = null,
    concello = null,
    concello_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo_iet,
        nome,
        poboacion_fixa,
        poboacion_estacional,
        parroquia,
        parroquia_label,
        concello,
        concello_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createNucleo as default,
    createNucleos,
    nucleo_api_adapter,
    nucleos_api_adapter,
    nucleo_view_adapter,
};
