import {ConcelloHiddenAuth} from "concello/auth";
import {
    EDARFormTratamentoConservacionFields,
    EDARFormDesenoFields,
    EDARFormExplotacionFields,
    EDARFormGeneralDataFields,
    EDARFormTramaentoLodosFields,
    EDARFormTratamentoSecundarioFields,
    EDARFormTratamentoTerciarioFields,
    EDARFormTratamentoRendementoFields,
    EDARFormTratamentoPretratamentoPrimarioFields,
    EDARFormTratamentoProblemasFields,
    EDARFormOutrasInstalacionsFields,
    EDARFormXestionLodosXeradosFields,
    EDARFormXestionLodosAlleosFields,
} from ".";

const EDARModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <EDARFormGeneralDataFields layout="column" />;
    }
    if (section === "rendementoinstalacion") {
        return <EDARFormTratamentoRendementoFields layout="column" />;
    }
    if (section === "conservacion") {
        return (
            <ConcelloHiddenAuth>
                <EDARFormTratamentoConservacionFields layout="column" />
            </ConcelloHiddenAuth>
        );
    }
    if (section === "pretratamentoprimario") {
        return <EDARFormTratamentoPretratamentoPrimarioFields layout="column" />;
    }
    if (section === "secundario") {
        return <EDARFormTratamentoSecundarioFields layout="column" />;
    }
    if (section === "terciario") {
        return (
            <ConcelloHiddenAuth>
                <EDARFormTratamentoTerciarioFields layout="column" />
            </ConcelloHiddenAuth>
        );
    }
    if (section === "problemas") {
        return <EDARFormTratamentoProblemasFields layout="column" />;
    }
    if (section === "lodos") {
        return <EDARFormTramaentoLodosFields layout="column" />;
    }
    if (section === "outrasinstalacionsdatos") {
        return <EDARFormOutrasInstalacionsFields layout="column" />;
    }
    if (section === "desenodatos") {
        return <EDARFormDesenoFields layout="column" />;
    }
    if (section === "explotaciondatos") {
        return <EDARFormExplotacionFields layout="column" />;
    }
    if (section === "xestionlodosxerados") {
        return <EDARFormXestionLodosXeradosFields layout="column" />;
    }
    if (section === "xestionlodosalleos") {
        return <EDARFormXestionLodosAlleosFields layout="column" />;
    }
    return null;
};

export default EDARModificationForm;
