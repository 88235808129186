import {Route} from "react-router-dom";
import {
    ViewConcelloDocumentPanel,
    ViewConcelloDocumentsSubPage,
    ViewConcelloInventarioSubPage,
    ViewConcelloPage,
    ViewConcelloPanel,
    ViewConcelloPoboacionSubPage,
    ViewConcelloPrincipalSubPage,
    ViewConcelloResumoSistemasSubPage,
    ViewConcellosPage,
    ViewConcelloStatsAbastecementoVolumeSubPage,
    ViewConcelloStatsInfrastructuresTypeSubPage,
    ViewConcelloStatsSubPage,
    ViewConcelloViewerSubPage,
} from "concello/container";
import {ViewSubaglomeracionPanel} from "saneamiento/subaglomeracion/container";
import {conexionesRoutes as abastecementoConexionesRoutes} from "abastecemento/conexion/routes";
import {conexionesRoutes as saneamientoConexionesRoutes} from "saneamiento/conexion/routes";
import {ViewEDARPanel} from "saneamiento/edar/container";
import {ViewBombeoPanel} from "saneamiento/bombeo/container";
import {ViewTanquePanel} from "saneamiento/tanque/container";
import {ViewColectorPanel} from "saneamiento/colector/container";
import {gobernanzaRoutes} from "saneamiento/gobernanza/routes";
import {subaglomeracionRoutes} from "saneamiento/subaglomeracion/routes";
import {edarRoutes} from "saneamiento/edar/routes";
import {bombeoRoutes} from "saneamiento/bombeo/routes";
import {tanqueRoutes} from "saneamiento/tanque/routes";
import {colectorRoutes} from "saneamiento/colector/routes";
import {etapRoutes} from "abastecemento/etap/routes";
import {ViewETAPPanel} from "abastecemento/etap/container";
import {depositoRoutes} from "abastecemento/deposito/routes";
import {ViewDepositoPanel} from "abastecemento/deposito/container";
import {ViewCaptacionPanel} from "abastecemento/captacion/container";
import {captacionRoutes} from "abastecemento/captacion/routes";
import {ViewAglomeracionPanel} from "saneamiento/aglomeracion/container";
import {sistemaRoutes} from "abastecemento/sistema/routes";
import {ViewSistemaPanel} from "abastecemento/sistema/container";
import {ViewNucleoPanel} from "nucleo/container";
import {ViewSistemaExplotacionPanel} from "medioreceptor/sistemaexplotacion/container";
import {ViewMasaAugaPanel} from "medioreceptor/masaauga/container";
import {vertidoRoutes} from "saneamiento/vertido/routes";
import {ViewVertidoPanel} from "saneamiento/vertido/container";
import {poligonoRoutes} from "saneamiento/poligono/routes";
import {ViewPoligonoPanel} from "saneamiento/poligono/container";
import {perdidasRoutes} from "abastecemento/perdidas/routes";
import {ConcelloAuthRequired} from "concello/auth";
import {ViewStatsNoSelectedSubPage} from "stats/container";
import {sinteseRoutes} from "saneamiento/sintese/routes";

const concelloRoutes = [
    <Route
        key="concello-page"
        path=":codigo"
        element={
            <ConcelloAuthRequired>
                <ViewConcelloPage />
            </ConcelloAuthRequired>
        }
    >
        <Route key="concello-stats" path="stats" element={<ViewConcelloStatsSubPage />}>
            <Route
                key="concello-stats-abastecemento-infrastructures-type-page"
                path="abastecemento_infrastructures_type"
                element={
                    <ViewConcelloStatsInfrastructuresTypeSubPage sector="abastecemento" />
                }
            />
            <Route
                key="concello-stats-abastecemento-volume-page"
                path="abastecemento_volume"
                element={<ViewConcelloStatsAbastecementoVolumeSubPage />}
            />
            <Route
                key="concello-stats-abastecemento-no-selected"
                path=""
                element={<ViewStatsNoSelectedSubPage />}
            />
        </Route>
        <Route
            key="edar-documents"
            path="documents/*"
            element={<ViewConcelloDocumentsSubPage />}
        >
            <Route
                key="edar-documents-view"
                path="detail/*"
                element={<ViewConcelloDocumentPanel />}
            />
        </Route>
        <Route
            key="concello-subpage-viewer"
            path=""
            element={<ViewConcelloViewerSubPage />}
        >
            <Route
                key="concellos-page-aglomeracion-info"
                path="info/aglomeracions/:idAglomeracion"
                element={<ViewAglomeracionPanel />}
            />
            <Route
                key="concellos-page-subaglomeracion-info"
                path="info/subaglomeracions/:idSubaglomeracion"
                element={<ViewSubaglomeracionPanel />}
            />
            <Route
                key="concellos-page-edar-info"
                path="info/edars/:idEdar"
                element={<ViewEDARPanel />}
            />
            <Route
                key="concellos-page-bombeo-info"
                path="info/bombeos/:idBombeo"
                element={<ViewBombeoPanel />}
            />
            <Route
                key="concellos-page-tanque-info"
                path="info/tanques/:idTanque"
                element={<ViewTanquePanel />}
            />
            <Route
                key="concellos-page-colector-info"
                path="info/colectores/:idColector"
                element={<ViewColectorPanel />}
            />
            <Route
                key="concellos-page-vertido-info"
                path="info/vertidos/:idVertido"
                element={<ViewVertidoPanel />}
            />
            <Route
                key="concellos-page-poligono-info"
                path="info/poligonos/:idPoligono"
                element={<ViewPoligonoPanel />}
            />
            <Route
                key="concellos-page-sistema-info"
                path="info/sistemas/:idSistema"
                element={<ViewSistemaPanel />}
            />
            <Route
                key="concellos-page-etap-info"
                path="info/etaps/:idEtap"
                element={<ViewETAPPanel />}
            />
            <Route
                key="concellos-page-deposito-info"
                path="info/depositos/:idDeposito"
                element={<ViewDepositoPanel />}
            />
            <Route
                key="concellos-page-captacion-info"
                path="info/captacions/:idCaptacion"
                element={<ViewCaptacionPanel />}
            />
            <Route
                key="concellos-page-nucleo-info"
                path="info/nucleos/:idNucleo"
                element={<ViewNucleoPanel />}
            />
        </Route>
        {gobernanzaRoutes}
        {subaglomeracionRoutes}
        {edarRoutes}
        {bombeoRoutes}
        {tanqueRoutes}
        {colectorRoutes}
        {saneamientoConexionesRoutes}
        {sinteseRoutes}
        {perdidasRoutes}
        {sistemaRoutes}
        {etapRoutes}
        {depositoRoutes}
        {captacionRoutes}
        {vertidoRoutes}
        {poligonoRoutes}
        {abastecementoConexionesRoutes}
    </Route>,
    <Route key="concellos-page" path="" element={<ViewConcellosPage />}>
        <Route
            key="concellos-page-concello-info"
            path="info/concellos/:codigo"
            element={<ViewConcelloPanel />}
        />
        <Route
            key="concellos-page-aglomeracion-info"
            path="info/aglomeracions/:idAglomeracion"
            element={<ViewAglomeracionPanel />}
        />
        <Route
            key="concellos-page-subaglomeracion-info"
            path="info/subaglomeracions/:idSubaglomeracion"
            element={<ViewSubaglomeracionPanel />}
        />
        <Route
            key="concellos-page-edar-info"
            path="info/edars/:idEdar"
            element={<ViewEDARPanel />}
        />
        <Route
            key="concellos-page-sistema-info"
            path="info/sistemas/:idSistema"
            element={<ViewSistemaPanel />}
        />
        <Route
            key="concellos-page-etap-info"
            path="info/etaps/:idEtap"
            element={<ViewETAPPanel />}
        />
        <Route
            key="concellos-page-deposito-info"
            path="info/depositos/:idDeposito"
            element={<ViewDepositoPanel />}
        />
        <Route
            key="concellos-page-captacion-info"
            path="info/captacions/:idCaptacion"
            element={<ViewCaptacionPanel />}
        />
        <Route
            key="concellos-page-sistema-explotacion-info"
            path="info/sistemasexplotacion/:idSistemaExplotacion"
            element={<ViewSistemaExplotacionPanel />}
        />
        <Route
            key="concellos-page-masa-auga-info"
            path="info/masasauga/:idMasaAuga"
            element={<ViewMasaAugaPanel />}
        />
    </Route>,
];

export default concelloRoutes;
