import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const TileLayerMenu = ({layerProvider}) => {
    const {
        included,
        visible,
        showLayer,
        layer: {legend},
    } = layerProvider;

    return (
        included && (
            <>
                <ListItem disablePadding sx={{pl: 2}} className="LayerMenuListItem">
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        flexWrap="nowrap"
                    >
                        <Grid item>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{"aria-labelledby": "bombeos"}}
                                checked={visible}
                                onChange={event => {
                                    showLayer(event.target.checked);
                                }}
                                className="LayerMenuCheckbox"
                            />
                        </Grid>
                        <Grid item>
                            <div className="LayerMenuIcon" style={{fontSize: "7pt"}}>
                                wms
                            </div>
                        </Grid>
                        <Grid item>{legend.layerName}</Grid>
                    </Grid>
                </ListItem>
            </>
        )
    );
};

export default TileLayerMenu;
