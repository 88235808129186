class Bombeos extends Array {}

const bombeo_api_adapter = bombeo => {
    if (bombeo["geom"]) {
        bombeo["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    bombeo["created_at"] = new Date(bombeo["created_at"]);
    bombeo["updated_at"] = new Date(bombeo["updated_at"]);
    return bombeo;
};

const bombeos_api_adapter = bombeos => bombeos.map(bombeo_api_adapter);

const createBombeos = (data = []) => {
    const bombeos = Bombeos.from(data, bombeos => createBombeo(bombeos));
    return bombeos;
};

const bombeo_view_adapter = bombeo => {
    delete bombeo["pretratamiento_label"];
    delete bombeo["proteccion_label"];
    delete bombeo["telecontrol_label"];
    delete bombeo["problemas_label"];

    delete bombeo["concello_label"];
    delete bombeo["aglomeracion_label"];

    delete bombeo["folder"];
    delete bombeo["featured_image"];
    delete bombeo["featured_document"];
    delete bombeo["created_at"];
    delete bombeo["created_by"];
    delete bombeo["updated_at"];
    delete bombeo["updated_by"];
    return bombeo;
};

const createBombeo = ({
    id = null,
    codigo = "",
    nome = "",
    folder = "",
    featured_image = "",
    featured_document = "",
    geom = null,
    notas = null,
    potencia = null,
    consumo_electrico = null,
    caudal_bombeo = null,
    altura_bombeo = null,
    bombas_activas = null,
    bombas_reserva = null,
    pretratamiento = [],
    pretratamiento_label = [],
    proteccion = [],
    proteccion_label = [],
    telecontrol = null,
    telecontrol_label = null,
    problemas = [],
    problemas_label = [],
    horas_funcionamento_diarias = null,
    caudal_medio_anual = null,
    active = null,
    pending_status = null,
    concello = null,
    concello_label = null,
    aglomeracion = null,
    aglomeracion_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        nome,
        folder,
        featured_image,
        featured_document,
        geom,
        notas,
        potencia,
        consumo_electrico,
        caudal_bombeo,
        altura_bombeo,
        bombas_activas,
        bombas_reserva,
        pretratamiento,
        pretratamiento_label,
        proteccion,
        proteccion_label,
        telecontrol,
        telecontrol_label,
        problemas,
        problemas_label,
        horas_funcionamento_diarias,
        caudal_medio_anual,
        active,
        pending_status,
        concello,
        concello_label,
        aglomeracion,
        aglomeracion_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createBombeo as default,
    createBombeos,
    bombeo_api_adapter,
    bombeos_api_adapter,
    bombeo_view_adapter,
};
