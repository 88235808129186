import {PageMenu} from "base/layout";
import {useAuth} from "base/auth";
import {useLocation} from "react-router-dom";

import {PageMenuListGroup, PageMenuListItemButton} from "base/component/presentational";

import WaterIcon from "@mui/icons-material/Water";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";

const AglomeracionPageMenu = ({aglomeracion}) => {
    const {ROLES} = useAuth();
    const location = useLocation();
    const basePath = location.pathname.split("/aglomeracions/")[0];

    const aglomeracionSubmenuItems = [
        {
            to: `subaglomeracions`,
            text: "Sistemas colectores",
        },
        {
            to: `edars`,
            text: "EDAR",
        },
        {
            to: `bombeos`,
            text: "Bombeos",
        },
        {
            to: `tanques`,
            text: "Tanques",
        },
        {
            to: `colectores`,
            text: "Colectores",
        },
        {
            to: `vertidos`,
            text: "Vertidos",
        },
        {
            to: `poligonos`,
            text: "Polígonos",
        },
    ];

    const indicadoresSubmenuItems = [
        {
            to: `summary`,
            text: "Ficha",
        },
        {
            to: `poboacion`,
            text: "Poboación",
        },
        {
            to: `inventario`,
            text: "Inventario",
        },
        {
            to: `explotacion`,
            text: "Explotación",
        },
    ];

    return (
        <PageMenu
            headingPrimaryText={aglomeracion.nome || aglomeracion.codigo}
            headingSecondaryText={`Aglomeración de`}
            headingIcon={PolylineOutlinedIcon}
        >
            <PageMenuListGroup
                id="sintese"
                headerText="Síntese"
                headerIcon={<QueryStatsIcon />}
                items={indicadoresSubmenuItems}
            />
            <PageMenuListGroup
                id="saneamento"
                headerText="Infraestructuras"
                headerIcon={<WaterIcon />}
                items={aglomeracionSubmenuItems}
            />
            <PageMenuListItemButton
                key="visor"
                to={`${basePath}/aglomeracions/${aglomeracion.id}/map`}
                text="Mapa da aglomeración"
                icon={<MapOutlinedIcon />}
            />
            <PageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
        </PageMenu>
    );
};

export default AglomeracionPageMenu;
