import {SubPageMenuListItemButton} from "base/component/presentational";

import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import FmdBadOutlinedIcon from "@mui/icons-material/FmdBadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import {ChangesMenuListItemButtom, ChangesSubPageMenu} from "changes/presentational";

const EDARSubPageMenu = ({edar}) => {
    return (
        <ChangesSubPageMenu
            pending_status={edar.pending_status}
            headingPrimaryText={edar.nome || edar.codigo || edar.id}
            headingSecondaryText="EDAR"
        >
            <SubPageMenuListItemButton
                to="summary"
                text="Ficha"
                icon={<AssignmentOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="cargas"
                text="Cargas"
                icon={<DataThresholdingOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="deseno"
                text="Deseño"
                icon={<DesignServicesOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="tratamento"
                text="Tratamento"
                icon={<ScienceOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="outrasinstalacions"
                text="Servicios auxiliares"
                icon={<HomeWorkOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="explotacion"
                text="Explotación"
                icon={<ElectricalServicesOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="xestionlodos"
                text="Xestión de lodos"
                icon={<FmdBadOutlinedIcon />}
            />
            <SubPageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
            <ChangesMenuListItemButtom entity={edar} />
        </ChangesSubPageMenu>
    );
};

export default EDARSubPageMenu;
