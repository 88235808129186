import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useEDARData} from "../hooks";
import {EDARService} from "saneamiento/service";
import {PageLayout} from "base/layout";
import {EDARSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewEDARPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();
    const location = useLocation();

    const {idEdar} = useParams();
    const [edar, setEdar] = useState(null);
    const {getEntityData} = useEDARData();

    useEffect(() => {
        EDARService.get(idEdar).then(data => {
            setEdar(data);
            setSubpagePath([
                {name: "Listado de EDAR", path: "edars"},
                {
                    name: data.nome || data.codigo || "-",
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setEdar(null);
            setModuleActions([]);
            setSubpagePath([]);
        };
    }, [idEdar, location.state?.lastRefreshDate]);

    return (
        edar && (
            <PageLayout
                menu={<EDARSubPageMenu edar={edar} />}
                context={[edar]}
                subPage={true}
            />
        )
    );
};

export default ViewEDARPage;
