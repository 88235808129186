import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const ConcelloDatosPrincipaisSection = ({concello}) => {
    return (
        <SectionCard title="Datos principais">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Provincia:" value={concello.stats.provincia} />
                    <SectionField label="Comarca:" value={concello.stats.comarca} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Código:" value={concello.stats.codigo} />
                    <SectionField label="Nome:" value={concello.stats.nome} />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default ConcelloDatosPrincipaisSection;
