import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormCapacitacionMediosFields = ({layout = "row"}) => {
    const {dominiogobernanzasino, dominiogobernanzanumeropersoas} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="numero_tecnicos"
                    label=" Nº de técnicos/responsables adscritos aos servizos de saneamento e depuración"
                    options={dominiogobernanzanumeropersoas}
                    tooltipText="Persoa ou persoas responsables dos servizos de saneamento e depuración, preferentemente técnicos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="tecnicos_formacion"
                    label="Os técnicos teñen una formación que os capacita para o seu cargo ou ben recibiron formación para o cargo que ostentan"
                    options={dominiogobernanzasino}
                    tooltipText="Os técnicos teñen una formación que os capacita para o seu cargo ou ben recibiron formación para o cargo que ostentan."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="roles_definidos"
                    label="Os roles e funcións do/s técnico/s están claramente definidos"
                    options={dominiogobernanzasino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_suplir_baixas_tecnicos"
                    label="Existen mecanismos para suplir baixas/vacacións/turnos"
                    options={dominiogobernanzasino}
                    tooltipText="Existen mecanismos para suplir as baixas/vacacións/turnos do/s técnico/s sen que se vexa afectada a calidade do servizo, tanto en situación ordinaria como en emerxencia."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="numero_operarios"
                    label="Nº de operarios dos servizos"
                    options={dominiogobernanzanumeropersoas}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="operarios_formacion"
                    label="Os operarios están capacitados"
                    options={dominiogobernanzasino}
                    tooltipText="Os operarios teñen una formación que os capacita para o seu cargo ou ben recibiron formación para o cargo que ostentan."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="roles_definidos_operarios"
                    label="Os roles e funcións do/s operario/s están claramente difinidos"
                    options={dominiogobernanzasino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_suplir_baixas_operarios"
                    label="Existen mecanismos para suplir as baixas/vacacións/turnos"
                    options={dominiogobernanzasino}
                    tooltipText="Existen mecanismos para suplir as baixas/vacacións/turnos do/s operario/s sen que se vexa afectada a calidade do servizo, tanto en situación ordinaria como en emerxencia."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_comunicacion_concellos"
                    label="Existen mecanismos de comunicación con medios doutros concellos"
                    options={dominiogobernanzasino}
                    tooltipText="Existen mecanismos de comunicación con técnicos/operarios dos servizos de saneamento e depuración doutros concellos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_comunicacion_supramunicipais"
                    label="Existen mecanismos de comunicación con medios supramunicipais"
                    options={dominiogobernanzasino}
                    tooltipText="Existen mecanismos de comunicación con técnicos especializados de administracións supramunicipais."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormCapacitacionMediosFields;
