import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaDatosInformacionSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`datos_informacion/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Existe un rexistro das infraestruturas e medios adscritos:",
            value: gobernanza.existe_registro_infraestructuras_label,
        },
        {
            label: "Existe información xeorreferenciada das infraestruturas:",
            value: gobernanza.existe_información_xeorreferenciada_label,
        },
        {
            label: "Sabe a porcentaxe de poboación non conectada:",
            value: gobernanza.sabe_porcentaxe_poboacion_label,
        },
        {
            label: "Existe un rexistro das infraestruturas de saneamento autónomo:",
            value: gobernanza.existe_rexistro_infraestructuras_label,
        },
        {
            label: "Existen balances periódicos de custos e ingresos nos servizos:",
            value: gobernanza.existe_balances_custos_ingresos_label,
        },
        {
            label: "Existe un rexistro dos investimentos nos servizos:",
            value: gobernanza.existe_rexistro_investimentos_label,
        },
        {
            label: "Existen mecanismos para xestionar os datos:",
            value: gobernanza.existe_mecanismo_xestion_datos_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Datos e información"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_datos_informacion}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_datos_informacion,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaDatosInformacionSection;
