import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormEquidadeFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_medidas_acceso_vulnerables"
                    label="Existen medidas explícitas de acceso aos servizos de persoas vulnerables"
                    options={dominiogobernanzasino}
                    tooltipText="Existen medidas explícitas para identificar o acceso aos servizos de saneamento e depuración por persoas vulnerables, por razón de idade ou recursos económicos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_tarifas_sociais"
                    label="Existen tarifas sociais ou outras medidas para reducir as desigualdades"
                    options={dominiogobernanzasino}
                    tooltipText="Existen tarifas sociais u outras medidas para as categorías vulnerables de usuarios (por idade ou recursos económicos)."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="analizase_capacidade_pago_usuarios"
                    label="Analízase a capacidade e a vontade de pago dos usuarios"
                    options={dominiogobernanzasino}
                    tooltipText="Avalíase a capacidade de pago e a vontade de pago dos usuarios mediante unha análise económica e a realización de enquisas."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="consideranse_disparidades_xeograficas_actuacions"
                    label="Considéranse as disparidades xeográficas na avaliación de actuacións"
                    options={dominiogobernanzasino}
                    tooltipText="Considéranse as disparidades xeográficas rural/ urbano á hora de avaliar alternativas de actuación."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="consideranse_disparidades_xeograficas_tarifas"
                    label="Considéranse as disparidades xeográficas nas tarifas"
                    options={dominiogobernanzasino}
                    tooltipText="Considéranse as disparidades xeográficas rural/urbano á hora de establecer as tarifas dos servizos."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormEquidadeFields;
