import {useNavigate, useParams} from "react-router-dom";
import {PoligonoService} from "saneamiento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewPoligonoPanel = () => {
    const {idPoligono} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = poligono => {
        const basePath =
            path === "concellos"
                ? `/concellos/${poligono.concello}`
                : `/aglomeracions/${poligono.aglomeracion}`;
        navigate(`${basePath}/poligonos/${poligono.id}/summary`);
    };

    const getSectionData = poligono => {
        return (
            <>
                <SectionField
                    label="Superficie:"
                    value={NumberUtil.formatDecimal(poligono.superficie)}
                    unit="ha"
                    tooltipText="Superficie da área industrial"
                />
                <SectionField
                    label="Grado de ocupación:"
                    value={NumberUtil.formatDecimal(poligono.grado_ocupacion)}
                    unit="%"
                    tooltipText="Porcentaxe de ocupación da área industrial"
                />
                <SectionField
                    label="Habitantes equivalentes:"
                    value={NumberUtil.formatDecimalAsInteger(
                        poligono.habitantes_equivalentes
                    )}
                    unit="heq"
                    tooltipText="Carga xerada na área industrial"
                />
                <SectionField
                    label="Carga:"
                    value={NumberUtil.formatDecimal(poligono.carga)}
                    unit="mg/l"
                    tooltipText="Carga de materia orgánica das augas residuais xeradas na área industrial"
                />
                <SectionField
                    label="Caudal de saneamento:"
                    value={NumberUtil.formatDecimal(poligono.caudal_saneamento)}
                    unit="l/s"
                    tooltipText="Caudal vertido a rede de saneamento na área industrial"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={PoligonoService}
            id={idPoligono}
            title="Resumo de polígono"
            getSectionTitle={entity => entity?.nome || entity?.codigo || entity?.id}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewPoligonoPanel;
