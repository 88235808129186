import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {AugasHiddenAuth} from "concello/auth";
import {SisbagalSectionCard} from "sisbagal/auth";

const VertidoCNVSection = ({vertido}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <AugasHiddenAuth>
            <SectionCardHeaderAction
                key="edit"
                name="edit"
                text="Modificar"
                icon={<EditIcon />}
                onClick={() => {
                    navigate(`cnv/edit`);
                }}
                roles={[]}
            />
        </AugasHiddenAuth>,
    ];

    return (
        <SisbagalSectionCard
            title="Censo nacional de vertidos"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Características:"
                        value={vertido.caracteristicas}
                        tooltipText="Natureza e características da actividade xeradora do vertido segundo RDPH Anexo IV"
                    />
                    <SectionField
                        label="Titular da autorización:"
                        value={vertido.autorizacion_titular}
                        tooltipText="Nome do titular ou razón social"
                    />
                    <SectionField
                        label="X (CNV):"
                        value={vertido.x_cnv}
                        tooltipText="Coordenada X en ETRS89 do punto de vertido publicado no CNV"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Código CNAE:" value={vertido.codigo_cnae} />
                    <SectionField
                        label="Volume autorizado:"
                        value={vertido.volume_autorizado}
                    />
                    <SectionField
                        label="Actividade:"
                        value={vertido.cnae}
                        tooltipText="Denominación da Clasificación Nacional de Actividades Económicas (CNAE) da actividade xeradora do vertido"
                    />
                    <SectionField
                        label="Y (CNV):"
                        value={vertido.y_cnv}
                        tooltipText="Coordenada Y en ETRS89 do punto de vertido publicado no CNV"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default VertidoCNVSection;
