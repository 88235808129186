import {useDomain} from "domain/provider";
import {FormInputDecimal, FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";

const ColectorFormCaracteristicasFields = ({layout = "row"}) => {
    const {
        dominiocolectortipocanalizacion,
        dominiocolectormaterial,
        dominiocolectorfluxo,
        dominiocolectortipo,
    } = useDomain();

    // TO-DO: Check dominiocolectortipocanalizacion (it returns undefined)

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item container xs={12} md={6}>
                <FormSelect
                    name="tipo_conduccion"
                    label="Tipo de conducción"
                    options={dominiocolectortipocanalizacion}
                    tooltipText="Tipo de colector"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelect
                    name="tipo"
                    label="Natureza"
                    options={dominiocolectortipo}
                    tooltipText="Natureza da rede do colector"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelect
                    name="fluxo"
                    label="Fluxo"
                    options={dominiocolectorfluxo}
                    tooltipText="Fluxo da auga no colector"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormInputDecimal
                    name="diametro"
                    label="Diámetro (m)"
                    endAdornment="mm"
                    placeholder="0,00"
                    tooltipText="Diámetro nominal do colector"
                />
            </Grid>
            <Grid item container xs={12} md={6}>
                <FormSelect
                    name="material"
                    label="Material"
                    options={dominiocolectormaterial}
                    tooltipText="Material do colector"
                />
            </Grid>
        </Grid>
    );
};

export default ColectorFormCaracteristicasFields;
