import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const BombeoGeneralDataSection = ({bombeo}) => {
    const {ROLES} = useAuth();

    const sections = [
        {label: "Código", value: bombeo.codigo},
        {
            label: "Concello",
            value: bombeo.concello_label,
            linkPath: `/concellos/${bombeo.concello}`,
        },
        {
            label: "Aglomeración",
            value: bombeo.aglomeracion_label,
            linkPath: `/aglomeracions/${bombeo.aglomeracion}/summary`,
        },
        {label: "Notas", value: bombeo.notas},
    ];

    return (
        <>
            <EntityGeneralDataSection
                featured_image={bombeo.featured_image}
                featured_document={bombeo.featured_document}
                name={bombeo.nome || `Bombeo ${bombeo.codigo}`}
                sections={sections}
            />
        </>
    );
};

export default BombeoGeneralDataSection;
