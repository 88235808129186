import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    EDARXestionLodosXeradosSection,
    EDARXestionLodosAlleosSection,
} from "../presentational/section";

const ViewEDARXestionLodosSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [
        <EDARXestionLodosXeradosSection edar={edar} />,
        <EDARXestionLodosAlleosSection edar={edar} />,
    ];

    return edar && <EntityViewSubPage sections={sections} />;
};

export default ViewEDARXestionLodosSubPage;
