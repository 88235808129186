import {useNavigate, useParams} from "react-router-dom";
import {TanqueService} from "saneamiento/service";
import {EntitySummaryPanel} from "base/entity";
import {SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {useConfigModule} from "base/provider";
import {useConcelloAuth} from "concello/auth";

const ViewTanquePanel = () => {
    const {idTanque} = useParams();
    const navigate = useNavigate();
    const {path} = useConfigModule();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = tanque => {
        const basePath =
            path === "concellos"
                ? `/concellos/${tanque.concello}`
                : `/aglomeracions/${tanque.aglomeracion}`;
        navigate(`${basePath}/tanques/${tanque.id}/summary`);
    };

    const getSectionData = tanque => {
        return (
            <>
                <SectionField label="Código:" value={tanque.codigo} />
                <SectionField label="Capacidade:" value={tanque.capacidade} unit="m³" />
                <SectionField label="Regulación:" value={tanque.regulacion_label} />
                <SectionField label="Telecontrol:" value={tanque.telecontrol_label} />
                <SectionField
                    label="Potencia instalada:"
                    value={NumberUtil.formatDecimal(tanque.potencia)}
                    unit="kW"
                />
            </>
        );
    };

    return (
        <EntitySummaryPanel
            service={TanqueService}
            id={idTanque}
            title="Resumo de tanque"
            getSectionTitle={entity => entity?.nome || entity?.codigo || entity?.id}
            getSectionData={getSectionData}
            onClickDetailButton={handleClickDetail}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
        />
    );
};

export default ViewTanquePanel;
