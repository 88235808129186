import {FieldUtil} from "base/utilities";

export function useSistemaData(sistema) {
    const getEntityData = sistema => {
        return {
            image: sistema?.featured_image,
            name: `Sistema de ${sistema?.nome || sistema?.codigo || sistema?.id}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(sistema?.codigo)],
                        ["Concello:", FieldUtil.getValue(sistema?.concello_label)],
                        ["Titular:", FieldUtil.getValue(sistema?.titular_label)],
                        ["Xestión:", FieldUtil.getValue(sistema?.xestion_label)],
                        ["Descripción:", FieldUtil.getValue(sistema?.descripcion)],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Rexistrado en AdG:",
                            FieldUtil.getValue(sistema?.rexistrado_label),
                        ],
                        [
                            "Número de expediente:",
                            FieldUtil.getValue(sistema?.expediente),
                        ],
                        [
                            "Porcentaxe de conexión:",
                            FieldUtil.getValue(sistema?.porcentaxe_conexion),
                        ],
                        ["Notas:", FieldUtil.getValue(sistema?.notas)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
