import {useParams} from "react-router-dom";
import {ViewDocumentPanel} from "document/component/container";
import {BombeoService} from "saneamiento/service";

const ViewBombeoDocumentPanel = () => {
    const {idBombeo} = useParams();

    const handleSetFeaturedImage = documentId => {
        return BombeoService.updateWithPatch({
            id: idBombeo,
            featured_image: documentId,
        });
    };

    const handleSetFeaturedDocument = documentId => {
        return BombeoService.updateWithPatch({
            id: idBombeo,
            featured_document: documentId,
        });
    };

    return (
        <ViewDocumentPanel
            onSetFeaturedImage={handleSetFeaturedImage}
            onSetFeaturedDocument={handleSetFeaturedDocument}
        />
    );
};

export default ViewBombeoDocumentPanel;
