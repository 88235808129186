import {FieldUtil, NumberUtil} from "base/utilities";

export function useTanqueData(tanque) {
    const getEntityData = tanque => {
        return {
            image: tanque?.featured_image,
            name: `Tanque ${tanque?.nome || tanque?.codigo}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(tanque?.codigo)],
                        ["Concello:", FieldUtil.getValue(tanque?.concello_label)],
                        [
                            "Aglomeración:",
                            FieldUtil.getValue(tanque?.aglomeracion_label),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                tanque?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(tanque?.geom.coordinates[1])}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Capacidade:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(tanque?.capacidade)
                            ),
                        ],
                        ["Regulación:", FieldUtil.getValue(tanque?.regulacion_label)],
                        ["Bombeo:", FieldUtil.getValue(tanque?.bombeo_label)],
                        [
                            "Pretratamento:",
                            FieldUtil.getValue(tanque?.pretratamiento_label),
                        ],
                        [
                            "Tratamento aliviadoiro:",
                            FieldUtil.getValue(tanque?.tratamento_aliviadoiro_label),
                        ],
                        ["Limpeza:", FieldUtil.getValue(tanque?.sistema_limpeza_label)],
                        ["Telecontrol:", FieldUtil.getValue(tanque?.telecontrol_label)],
                        [
                            "Potencia instalada:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(tanque?.potencia)
                            ),
                        ],
                        [
                            "Consumo eléctrico:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(tanque?.consumo_electrico)
                            ),
                        ],
                        ["Problemas:", FieldUtil.getValue(tanque?.problemas_label)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
