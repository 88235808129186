import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewEDARDocumentsSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/edars/")[0];

    return (
        edar && <ViewDocumentsSubPage entity={edar} basePath={`${basePath}/edars`} />
    );
};

export default ViewEDARDocumentsSubPage;
