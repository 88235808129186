import {useParams} from "react-router-dom";
import {BombeoService} from "saneamiento/service";
import {useBombeoTable} from "../hooks";
import {ListInfraestructuraPage} from "sisbagal/container";

const ListBombeoPage = () => {
    const {codigo: codigoConcello} = useParams();
    const {tableColumns} = useBombeoTable();

    return (
        <ListInfraestructuraPage
            entityName="Bombeos"
            entityPath="bombeos"
            service={BombeoService}
            tableColumns={tableColumns}
            createButton={!!codigoConcello}
        />
    );
};
export default ListBombeoPage;
