import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionPoboacionConectadaSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Conectada">
            <Grid container>
                <SectionSubheading text="Total" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.cargadomestica
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe doméstica (Fixa e estacional)."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Non doméstica:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.carganondomestica
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non doméstica (industrial , dotacional, flotante...)"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Doméstica" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fixa:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.cargadomesticafixa
                        )}
                        unit="Heq"
                        tooltipText="Poboación fixa orixe doméstica."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Estacional:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.cargadomesticaestacional
                        )}
                        unit="Heq"
                        tooltipText="Poboación estacional orixe doméstica."
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Non doméstica" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.carganondomesticaindustrial
                        )}
                        unit="Heq"
                        tooltipText="Carga de orixe industrial"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Outras non domésticas:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.carganondomesticaoutros
                        )}
                        unit="Heq"
                        tooltipText="Poboación de orixe non domestica ( dotacional,flotante...)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionPoboacionConectadaSection;
