import {PageMenu} from "base/layout";
import {useAuth} from "base/auth";

import {PageMenuListGroup} from "base/component/presentational";

import WaterIcon from "@mui/icons-material/Water";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import {useSisbagalAppConfig} from "sisbagal/provider";

const StatsPageMenu = () => {
    const {ROLES} = useAuth();

    const {visibleSectors} = useSisbagalAppConfig();

    const saneamientoStatsItems = [
        {
            to: `saneamento_infrastructures_count`,
            text: "Totales",
        },
        {
            to: `saneamento_concellos_cargas`,
            text: "Cargas",
        },
    ];

    const abastecementoStatsItems = [
        {
            to: `abastecemento_volume`,
            text: "Capacidades",
        },
        {
            to: `abastecemento_infrastructures_count`,
            text: "Totales",
        },
        {
            to: `abastecemento_infrastructures_type`,
            text: "Por atributo",
        },
    ];

    return (
        <PageMenu>
            {visibleSectors.includes("saneamento") ? (
                <PageMenuListGroup
                    id="saneamento"
                    headerText="Saneamento"
                    headerIcon={<WaterIcon />}
                    items={saneamientoStatsItems}
                />
            ) : null}
            {visibleSectors.includes("abastecemento") ? (
                <PageMenuListGroup
                    id="abastecemento"
                    headerText="Abastecemento"
                    headerIcon={<InvertColorsIcon />}
                    items={abastecementoStatsItems}
                />
            ) : null}
        </PageMenu>
    );
};

export default StatsPageMenu;
