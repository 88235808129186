import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewVertidoLocationSubPage = () => {
    let vertido;
    [vertido] = useOutletContext();

    const sections = [<EntityLocationSection geom={vertido.geom} />];

    return vertido && <EntityViewSubPage sections={sections} />;
};

export default ViewVertidoLocationSubPage;
