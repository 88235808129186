import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const SistemaExplotacionDatosNonDescargaDomesticaSection = ({sistemaExplotacion}) => {
    return (
        <SectionCard title="Non doméstica">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Industrial Urbana:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentonondomesticaindustrial
                        )}
                        unit="Heq."
                        tooltipText="Carga industrial descargada procedente dos puntos de vertido das EDAR urbanas."
                    />
                    <SectionField
                        label="Industrial Non Urbana:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.nonsaneamentourbanoindustrial
                        )}
                        unit="Heq."
                        tooltipText="Carga industrial descargada procedente das áreas industriais inventarias non conectadas a unha EDAR urbana."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Non doméstica urbana:"
                        value={NumberUtil.formatDecimalAsInteger(
                            sistemaExplotacion.stats.saneamentonondomestica
                        )}
                        unit="Heq."
                        tooltipText="Carga industrial descargada procedente das áreas industriais inventarias non conectadas a unha EDAR urbana."
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default SistemaExplotacionDatosNonDescargaDomesticaSection;
