import {
    VertidoFormCaracteristicasFields,
    VertidoFormGeneralDataFields,
    VertidoFormCNVFields,
    VertidoFormOutrasReferenciasFields,
} from ".";

const VertidoModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <VertidoFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <VertidoFormCaracteristicasFields layout="column" />;
    }
    if (section === "cnv") {
        return <VertidoFormCNVFields layout="column" />;
    }
    if (section === "outrasreferencias") {
        return <VertidoFormOutrasReferenciasFields layout="column" />;
    }
    return null;
};

export default VertidoModificationForm;
