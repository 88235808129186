import {useAuth} from "base/auth";

import {EntityGeneralDataSection} from "base/entity";

const DepositoGeneralDataSection = ({deposito}) => {
    const {ROLES} = useAuth();

    let sections = [
        {label: "Código", value: deposito.codigo},
        {label: "Titular", value: deposito.titular_label},
        {label: "Xestión", value: deposito.xestion_label},
        {label: "Estado", value: deposito.estado_label},
        {label: "Descripción", value: deposito.descripcion},
    ];

    if (deposito.sistema) {
        sections.push({
            label: "Sistema PMAA",
            value: deposito.sistema_label || deposito.sistema,
            linkPath: `/concellos/${deposito.concello}/sistemas/${deposito.sistema}/summary`,
        });
    }

    return (
        <EntityGeneralDataSection
            featured_image={deposito.featured_image}
            featured_document={deposito.featured_document}
            name={`Depósito: ${deposito.codigo || deposito.id}`}
            sections={sections}
        />
    );
};

export default DepositoGeneralDataSection;
