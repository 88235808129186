import {useNavigate} from "react-router-dom";
import {NumberUtil} from "base/utilities";
import {createLayerLegend, useLayerObject} from "base/map";
import {mapOverlayPanes} from "base/component/geo";

const markerBaseOptions = {
    marker: "c",
    fillColor: "#f60",
    radius: 10,
    color: "white",
    weight: 2,
    opacity: 1,
    fillOpacity: 1,
    pane: mapOverlayPanes[6],
};

const markerSelectedOptions = {
    weight: 2,
    opacity: 1,
    color: "#ff0",
};

const markerHighlightOptions = {
    weight: 2,
    opacity: 1,
    color: "#fff",
};

const markerSuperHighlightOptions = {
    weight: 4,
    opacity: 1,
    fillOpacity: 1,
    color: "#fff",
    radius: 18,
};

const discriminators = Object.freeze({
    DESACTIVADA: null,
    CARGA_URBANA: "urbanatotal",
    HABITANTES_EQUIVALENTES: "habitantes_equivalentes_desenho",
    TIPO_TRATAMENTO: "tipotratamento",
});

export const LayerLegend = createLayerLegend({
    layerName: "EDARs",
    menuIconShape: markerBaseOptions.marker,
    discriminatorsAttributes: discriminators,
    discriminatorsLegends: [
        {
            field: discriminators.DESACTIVADA,
            text: "- Desactivada -",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "",
                    filterFn: val => true,
                    markerOptions: {
                        ...markerBaseOptions,
                    },
                },
            ],
        },
        {
            field: discriminators.CARGA_URBANA,
            text: "Carga urbana",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "> 10000",
                    filterFn: val => val > 10000,
                    markerOptions: {...markerBaseOptions, radius: 18},
                },
                {
                    text: "> 1000, ≤ 10000",
                    filterFn: val => val > 1000 && val <= 10000,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 14,
                        fillColor: "#f57f33",
                    },
                },
                {
                    text: "> 250, ≤ 1000",
                    filterFn: val => val > 250 && val <= 1000,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 11,
                        fillColor: "#eb9866",
                    },
                },
                {
                    text: "≤ 250",
                    filterFn: val => val !== null && val <= 250,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 7,
                        fillColor: "#e1b199",
                    },
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
        {
            field: discriminators.HABITANTES_EQUIVALENTES,
            text: "Habitantes equiv.",
            defaultIconOptions: markerBaseOptions,
            entries: [
                {
                    text: "> 10000",
                    filterFn: val => val > 10000,
                    markerOptions: {...markerBaseOptions, radius: 18},
                },
                {
                    text: "> 1000, ≤ 10000",
                    filterFn: val => val > 1000 && val <= 10000,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 14,
                        fillColor: "#f57f33",
                    },
                },
                {
                    text: "> 250, ≤ 1000",
                    filterFn: val => val > 250 && val <= 1000,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 11,
                        fillColor: "#eb9866",
                    },
                },
                {
                    text: "≤ 250",
                    filterFn: val => val !== null && val <= 250,
                    markerOptions: {
                        ...markerBaseOptions,
                        radius: 7,
                        fillColor: "#e1b199",
                    },
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
        {
            field: discriminators.TIPO_TRATAMENTO,
            text: "Tipo de tratamento",
            defaultIconOptions: markerBaseOptions,
            // 0 Non axeitado
            // 1 Primario
            // 2 Secundario
            // 3 Avanzado
            entries: [
                {
                    text: "Non axeitado",
                    filterFn: val => val === 0,
                    markerOptions: {...markerBaseOptions, fillColor: "#CC0033"},
                },
                {
                    text: "Primario",
                    filterFn: val => val === 1,
                    markerOptions: {...markerBaseOptions, fillColor: "#883333"},
                },
                {
                    text: "Secundario",
                    filterFn: val => val === 2,
                    markerOptions: {...markerBaseOptions, fillColor: "#446633"},
                },
                {
                    text: "Avanzado",
                    filterFn: val => val === 3,
                    markerOptions: {...markerBaseOptions, fillColor: "#009933"},
                },
                {
                    text: "(sen datos)",
                    filterFn: val => val === null,
                    markerOptions: {
                        ...markerBaseOptions,
                        fillColor: "lightgrey",
                        radius: 5,
                    },
                },
            ],
        },
    ],
});

export function useLayer(detail = false) {
    const navigate = useNavigate();

    return useLayerObject({
        legend: LayerLegend,
        detail: detail,
        selectedMarkerOptions: markerSelectedOptions,
        highlightMarkerOptions: markerHighlightOptions,
        superHighlightMarkerOptions: markerSuperHighlightOptions,
        getTooltip: ({feature, discriminator}) => {
            let data = feature.properties;
            let tooltip = `<b>EDAR: ${
                data["nome"] ? data["nome"] : "---"
            }</b><ul class="attributes">`;
            tooltip += `<li><i>Código</i>: ${
                data["codigo"] ? data["codigo"] : "---"
            }</li>`;
            tooltip += `<li><i>Carga urbana</i>: ${
                "urbanatotal" in data && data["urbanatotal"] !== null
                    ? `${NumberUtil.formatDecimalAsInteger(data["urbanatotal"])} Heq`
                    : "---"
            }</li>`;
            tooltip += `<li><i>Habitantes equiv.</i>: ${
                "habitantes_equivalentes_desenho" in data &&
                data["habitantes_equivalentes_desenho"] !== null
                    ? `${NumberUtil.formatDecimalAsInteger(
                          data["habitantes_equivalentes_desenho"]
                      )} Heq`
                    : "---"
            }</li>`;
            return tooltip + "</ul>";
        },
        defaultOnClick: ({feature}) => navigate(`info/edars/${feature.id}`),
    });
}
