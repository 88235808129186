import {NumberUtil} from "base/utilities";
import {
    SectionCard,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const AglomeracionInventarioColectoresSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Colectores">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Antigüidade:"
                        value={aglomeracion.stats.antiguidaderede}
                        tooltipText="Antigüidade media da rede de colectores"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Tipoloxia de rede" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fecais:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.naturezafecais,
                            1
                        )}
                        unit="%"
                        tooltipText="Porcentaxe da rede onde esta instalada rede de fecais (separativa)"
                    />
                    <SectionField
                        label="Pluviais:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.naturezapluviais,
                            1
                        )}
                        unit="%"
                        tooltipText="Porcentaxe da rede onde esta instalada rede de pluviais (separativa)"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Unitaria:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.naturezaunitarios,
                            1
                        )}
                        unit="%"
                        tooltipText="Porcentaxe da rede onde a rede é unitaria, pero si hai elementos de recollida de augas pluviais tales como imbornales etc."
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Materiais" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Plásticos:"
                        value={NumberUtil.formatDecimal(aglomeracion.stats.plastico, 1)}
                        unit="%"
                        tooltipText="Porcentaxe da rede de saneamento instalada en material plástico (PVC,PRFV...)"
                    />
                    <SectionField
                        label="Formigón:"
                        value={NumberUtil.formatDecimal(aglomeracion.stats.formigon, 1)}
                        unit="%"
                        tooltipText="Porcentaxe da rede de saneamento instalada en formigón."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Fibrocemento:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.fibrocemento,
                            1
                        )}
                        unit="%"
                        tooltipText="Porcentaxe da rede de saneamento con fibrocemento"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionInventarioColectoresSection;
