import {FormSection} from "base/component/form";
import {BombeoFormExplotacionFields} from ".";

const BombeoFormExplotacionSection = () => {
    return (
        <>
            <FormSection title="Explotación">
                <BombeoFormExplotacionFields />
            </FormSection>
        </>
    );
};

export default BombeoFormExplotacionSection;
