import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    ConcelloPoboacionDomesticaSection,
    ConcelloPoboacionNonDomesticaSection,
    ConcelloPoboacionTotalSection,
    ConcelloPoboacionVariabilidadeSection,
} from "../presentational/section";

const ViewConcelloPoboacionSubPage = () => {
    let concello;
    [concello] = useOutletContext();

    const sections = [
        <ConcelloPoboacionVariabilidadeSection concello={concello} />,
        <ConcelloPoboacionTotalSection concello={concello} />,
        <ConcelloPoboacionDomesticaSection concello={concello} />,
        <ConcelloPoboacionNonDomesticaSection concello={concello} />,
    ];

    return concello && <EntityViewSubPage sections={sections} />;
};

export default ViewConcelloPoboacionSubPage;
