import {SubPageMenu} from "base/layout";
import {
    SubPageMenuListGroup,
    SubPageMenuListItemButton,
} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";

const GobernanzaSubPageMenu = ({gobernanza}) => {
    const gobernanzaSubmenuItems = [
        {
            to: `eficacia`,
            text: "Eficacia",
        },
        {
            to: `eficiencia`,
            text: "Eficiencia",
        },
        {
            to: `confianza`,
            text: "Confianza e participación",
        },
    ];

    return (
        <SubPageMenu headingPrimaryText="Formulario de gobernanza">
            <SubPageMenuListItemButton
                to="summary"
                text="Resumen"
                icon={<AssignmentOutlinedIcon />}
                key="summary"
            />
            <SubPageMenuListGroup
                headerText="Preguntas"
                headerIcon={<FormatListBulletedOutlinedIcon />}
                items={gobernanzaSubmenuItems}
            />
        </SubPageMenu>
    );
};

export default GobernanzaSubPageMenu;
