import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import {ModuleHeader} from ".";

const ModuleLayout = ({title = null, menu = null}) => {
    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <ModuleHeader />
            <Box sx={{display: "flex"}} role="main">
                {menu}
                <Box sx={{flexGrow: 1}} role="module">
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default ModuleLayout;
