import {PageMenu} from "base/layout";
import {useAuth} from "base/auth";
import {useLocation} from "react-router-dom";

import {PageMenuListGroup, PageMenuListItemButton} from "base/component/presentational";

import WaterIcon from "@mui/icons-material/Water";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import {useSisbagalAppConfig} from "sisbagal/provider";

const ConcelloPageMenu = ({concello}) => {
    const {ROLES} = useAuth();
    const location = useLocation();
    const basePath = location.pathname.split("/concellos/")[0];

    const {visibleSectors} = useSisbagalAppConfig();

    const saneamientoSubmenuItems = [
        {
            to: `gobernanza/summary`,
            text: "Gobernanza",
            pathForSelected: "gobernanza",
        },
        {
            to: `subaglomeracions`,
            text: "Sistemas colectores",
        },
        {
            to: `edars`,
            text: "EDAR",
        },
        {
            to: `bombeos`,
            text: "Bombeos",
        },
        {
            to: `tanques`,
            text: "Tanques",
        },
        {
            to: `colectores`,
            text: "Colectores",
        },
        {
            to: `vertidos`,
            text: "Vertidos",
        },
        {
            to: `poligonos`,
            text: "Polígonos",
        },
        {
            to: `saneam-conexions`,
            text: "Conexións",
        },
        {
            to: `sintese/principal`,
            text: "Síntese",
            pathForSelected: "sintese",
        },
    ];

    const abastecimientoSubmenuItems = [
        {
            to: `datos/perdidas`,
            text: "Datos de indicadores",
            pathForSelected: "datos",
        },
        {
            to: `sistemas`,
            text: "Sistemas PMAA",
        },
        {
            to: `etaps`,
            text: "ETAP",
        },
        {
            to: `depositos`,
            text: "Depósitos",
        },
        {
            to: `captacions`,
            text: "Captacións",
        },
        {
            to: `abast-conexions`,
            text: "Conexións",
        },
        {
            to: `stats`,
            text: "Estatísticas",
        },
    ];

    return (
        <PageMenu
            headingPrimaryText={concello.nome}
            headingSecondaryText={`Concello de`}
            headingIcon={AccountBalanceOutlinedIcon}
        >
            <PageMenuListItemButton
                key="visor"
                to={`${basePath}/concellos/${concello.codigo}`}
                text="Mapa do concello"
                icon={<MapOutlinedIcon />}
            />
            {visibleSectors.includes("saneamento") ? (
                <PageMenuListGroup
                    id="saneamento"
                    headerText="Saneamento"
                    headerIcon={<WaterIcon />}
                    items={saneamientoSubmenuItems}
                />
            ) : null}

            {visibleSectors.includes("abastecemento") ? (
                <PageMenuListGroup
                    id="abastecemento"
                    headerText="Abastecemento"
                    headerIcon={<InvertColorsIcon />}
                    items={abastecimientoSubmenuItems}
                />
            ) : null}

            <PageMenuListItemButton
                to="documents"
                text="Documentos"
                icon={<TopicOutlinedIcon />}
            />
        </PageMenu>
    );
};

export default ConcelloPageMenu;
