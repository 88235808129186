import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionPoboacionTotalSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Total">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Total:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.cargatotal
                        )}
                        unit="Heq"
                        tooltipText="Poboación urbana total da aglomeración incluindo a tratada na EDAR (urbana) , saneamento autónomo e a poboacion conectada a rede de saneamento sen tratamento de depuración."
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Urbana:"
                        value={NumberUtil.formatDecimalAsInteger(
                            aglomeracion.stats.cargaurbana
                        )}
                        unit="Heq"
                        tooltipText="Poboación urbana total da aglomeración (domestica e non doméstica)"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionPoboacionTotalSection;
