import {DialogLayout} from "base/component/presentational";
import {DocumentService} from "document/service";
import {useState} from "react";

const DeleteDocumentDialog = ({
    folderElement,
    onDeletedFolderElement = null,
    isDialogOpen,
    setIsDialogOpen,
}) => {
    const [error, setError] = useState(null);

    const handleDialog = isOpen => {
        setError(null);
        setIsDialogOpen(isOpen);
    };

    const handleConfirmDeletion = () => {
        DocumentService.delete(folderElement.path)
            .then(() => {
                if (onDeletedFolderElement) {
                    onDeletedFolderElement();
                    setIsDialogOpen(false);
                }
            })
            .catch(error => {
                setError(error);
            });
    };

    return (
        <DialogLayout
            dialogTitle="¿Quiere eliminar este archivo?"
            dialogContentText="Si hace clic en Eliminar, el archivo se eliminará y no se podrá recuperar."
            mainActionClick={handleConfirmDeletion}
            mainActionText="Eliminar"
            mainActionColor="error"
            handleDialog={handleDialog}
            isDialogOpen={isDialogOpen}
            error={error}
        />
    );
};

export default DeleteDocumentDialog;
