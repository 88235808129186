import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const GobernanzaFormRolesResponsabilidadesFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="politicas_urbanisticas"
                    label="As políticas locais urbanísticas son coherentes coas da auga"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, os novos desenvolvementos urbanísticos teñen en conta a capacidade das instalacións de saneamento e depuración, as instalacións de saneamento e depuración son dimensionadas en base as previsións de desenvolvemento urbanístico, as novas urbanizacións e as reformas urbanísticas teñen en conta a necesidade de reducir volumes de auga residual nas redes, fomentando a execución de técnicas de drenaxe urbano sostible (pavimentos drenantes, zanxas de infiltración, etc.)."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="politicas_limpeza"
                    label="As políticas locais de limpeza e residuos son coherentes coas da auga"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, en materia de limpeza, prohíbese o baldeo de augas de limpeza aos sumidoiros cando as redes son separativas, contrólanse as conexións de augas procedentes de lavadoiros en uso, foméntase o emprego de auga reutilizada para baldeo de rúas. En relación cos residuos, os residuos procedentes das instalacións de saneamento e depuración son tratados conforme a ordenanza municipal e lei de residuos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="politicas_parques"
                    label="As políticas locais de parques e xardíns son coherentes coas da auga"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, no mantemento de parques e xardíns respétanse as doses e distancias de seguridade na aplicación de produtos fitosanitarios para evitar a contaminación das augas, evítase a conexión de augas procedentes de fontes á rede de saneamento."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormRolesResponsabilidadesFields;
