import {useDomain} from "domain/provider";
import {FormSelect, FormTextArea, FormInputText} from "base/component/form";

import Grid from "@mui/material/Grid";

const CaptacionFormCaracteristicasFields = ({layout = "row"}) => {
    const {
        dominiosino,
        dominioabastecementoproteccion,
        dominioabastecementoorixe,
        dominioabastecementotipo,
        dominioabastecementouso,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputText name="bacia" label="Bacía" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="orixe"
                    label="Orixe"
                    options={dominioabastecementoorixe}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="tipo"
                    label="Tipo"
                    options={dominioabastecementotipo}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect name="uso" label="Uso" options={dominioabastecementouso} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="legalizada"
                    label="Legalizada"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect name="bombeo" label="Bombeo" options={dominiosino} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="proteccion"
                    label="Protección"
                    options={dominioabastecementoproteccion}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="suministro_electrico"
                    label="Suministro eléctrico"
                    options={dominiosino}
                    tooltipText="Existencia de acometida eléctrica"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telexestion"
                    label="Telexestión"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="caudalimetro"
                    label="Caudalímetro"
                    options={dominiosino}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextArea name="notas" label="Notas" />
            </Grid>
        </Grid>
    );
};

export default CaptacionFormCaracteristicasFields;
