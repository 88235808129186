import {Route} from "react-router-dom";
import {ViewMedioReceptorPage} from "../container";
import {
    ViewSistemaExplotacionPage,
    ViewSistemaExplotacionPanel,
    ViewSistemaExplotacionDatosDescargaSubPage,
} from "medioreceptor/sistemaexplotacion/container";
import {
    ViewMasaAugaDatosDescargaSubPage,
    ViewMasaAugaPage,
    ViewMasaAugaPanel,
    ViewMasaAugaPrincipalSubPage,
} from "medioreceptor/masaauga/container";

const medioReceptorRoutes = [
    <Route key="medio-receptor-page" path="" element={<ViewMedioReceptorPage />}>
        <Route
            key="concellos-page-sistema-explotacion-info"
            path="info/sistemasexplotacion/:idSistemaExplotacion"
            element={<ViewSistemaExplotacionPanel />}
        />
        <Route
            key="concellos-page-masa-auga-info"
            path="info/masasauga/:idMasaAuga"
            element={<ViewMasaAugaPanel />}
        />
    </Route>,
    <Route
        key="sistema-explotacion-page"
        path="sistemaexplotacion/:id"
        element={<ViewSistemaExplotacionPage />}
    >
        <Route
            key="sistema-explotacion-principal"
            path=""
            element={<ViewSistemaExplotacionDatosDescargaSubPage />}
        />
    </Route>,
    <Route key="masa-auga-page" path="masaauga/:id" element={<ViewMasaAugaPage />}>
        <Route
            key="masa-auga-principal"
            path="principal"
            element={<ViewMasaAugaPrincipalSubPage />}
        />
        <Route
            key="masa-auga-principal"
            path="descarga"
            element={<ViewMasaAugaDatosDescargaSubPage />}
        />
    </Route>,
];

export default medioReceptorRoutes;
