import {useOutletContext} from "react-router-dom";
import {ColectorService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {
    ColectorGeneralDataSection,
    ColectorLocationSection,
} from "../presentational/section";
import {SectionCard} from "base/component/presentational";

const ViewColectorSummarySubPage = () => {
    let colector;
    [colector] = useOutletContext();

    const sections = [
        <ColectorGeneralDataSection colector={colector} />,
        <SectionCard title="Ubicación">
            <ColectorLocationSection colector={colector} />
        </SectionCard>,
        <EntityAuditSection entity={colector} />,
    ];

    return (
        colector && <EntityViewSubPage sections={sections} service={ColectorService} />
    );
};

export default ViewColectorSummarySubPage;
