import {useAuth} from "base/auth";
import {PageLayout} from "base/layout";
import {useAppConfig} from "base/provider";
import {useConfigModule} from "base/provider/ModuleConfigProvider";
import {useEffect} from "react";
import {MapConfigProvider, MapProvider} from "sisbagal/map";
import {SisbagalMap} from "sisbagal/map";
import {SisbagalMapMenu} from "sisbagal/menu";

const ViewAglomeracionsPage = () => {
    // TODO: Improve to include layers in provider without config

    let auth = useAuth();
    const {setPagePath} = useAppConfig();
    const {moduleFilter, setModuleFilter} = useConfigModule();

    useEffect(() => {
        setPagePath([{name: "Visor", path: `/aglomeracions`}]);
    }, []);

    useEffect(() => {
        if (auth.hasRole(auth.ROLES.CONCELLO)) {
            setModuleFilter({...moduleFilter, concello: auth.user.codigo_concello});
        }
    }, [auth.user]);

    return (
        <MapConfigProvider>
            <MapProvider
                config={{
                    concello: {
                        visible: false,
                        customClickHandler: () => {}, // Empty click handler because concellos features are not clickable at this map
                    },
                    aglomeracion: {fitBounds: true},
                    edar: {
                        visible: false,
                    },
                    bombeo: {
                        visible: false,
                    },
                    tanque: {
                        visible: false,
                    },
                    masaAuga: {
                        visible: false,
                    },
                    rios: {
                        visible: false,
                    },
                    zonasCaptacion: {
                        visible: false,
                    },
                }}
            >
                <PageLayout>
                    <SisbagalMap legend={<SisbagalMapMenu />} />
                </PageLayout>
            </MapProvider>
        </MapConfigProvider>
    );
};
export default ViewAglomeracionsPage;
