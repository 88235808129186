import {Route} from "react-router-dom";
import {
    CreatePoligonoPage,
    ListPoligonoPage,
    ViewPoligonoPanel,
    ViewPoligonoPage,
    ViewPoligonoLocationSubPage,
    ViewPoligonoSummarySubPage,
    ViewPoligonoDetailSubPage,
    UpdatePoligonoPanel,
    ManagePoligonoPage,
    ViewPoligonoChangesSubPage,
} from "saneamiento/poligono/container";

const poligonoRoutes = [
    <Route key="poligono-new" path="poligonos/new" element={<CreatePoligonoPage />} />,
    <Route key="poligono-manage" path="poligonos" element={<ManagePoligonoPage />}>
        <Route key="poligono-list" path="" element={<ListPoligonoPage />}>
            <Route
                key="poligono-info"
                path="info/:idPoligono"
                element={<ViewPoligonoPanel />}
            />
        </Route>
        <Route key="poligono-detail" path=":idPoligono" element={<ViewPoligonoPage />}>
            <Route
                key="poligono-location"
                path="location"
                element={<ViewPoligonoLocationSubPage />}
            >
                {" "}
                <Route
                    key="poligono-location-edit"
                    path=":section/:action"
                    element={<UpdatePoligonoPanel />}
                />
            </Route>
            <Route
                key="poligono-summary"
                path="summary"
                element={<ViewPoligonoSummarySubPage />}
            >
                <Route
                    key="poligono-general-edit"
                    path=":section/:action"
                    element={<UpdatePoligonoPanel />}
                />
            </Route>
            <Route
                key="poligono-detail"
                path="detail"
                element={<ViewPoligonoDetailSubPage />}
            >
                <Route
                    key="poligono-caracteristicas-edit"
                    path=":section/:action"
                    element={<UpdatePoligonoPanel />}
                />
            </Route>
            <Route
                key="poligono-changes"
                path="changes"
                element={<ViewPoligonoChangesSubPage />}
            />
        </Route>
    </Route>,
];

export default poligonoRoutes;
