import {PageMenu} from "base/layout";
import {useAuth} from "base/auth";

import {PageMenuListGroup} from "base/component/presentational";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid3x3OutlinedIcon from "@mui/icons-material/Grid3x3Outlined";

const MasaAugaPageMenu = ({masaAuga}) => {
    const {ROLES} = useAuth();

    const submenuItems = [
        {
            to: "principal",
            text: "Principal",
        },
        {
            to: "descarga",
            text: "Presións saneamento",
        },
    ];

    return (
        <PageMenu
            headingPrimaryText={masaAuga.nome}
            headingSecondaryText={`Bacía:`}
            headingIcon={Grid3x3OutlinedIcon}
        >
            <PageMenuListGroup
                id="indicadores"
                headerText="Indicadores"
                headerIcon={<QueryStatsIcon />}
                items={submenuItems}
            />
        </PageMenu>
    );
};

export default MasaAugaPageMenu;
