import {AuthAction} from "base/auth";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

const SectionCardHeaderAction = ({name, text, icon, onClick, roles = []}) => {
    return (
        <AuthAction roles={roles}>
            <MenuItem key={name} onClick={onClick}>
                <ListItemIcon aria-label={name}>{icon}</ListItemIcon>
                {text}
            </MenuItem>
        </AuthAction>
    );
};

export default SectionCardHeaderAction;
