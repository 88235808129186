import {useParams} from "react-router-dom";
import {ViewDocumentPanel} from "document/component/container";
import {DepositoService} from "abastecemento/service";

const ViewDepositoDocumentPanel = () => {
    const {idDeposito} = useParams();

    const handleSetFeaturedImage = documentId => {
        return DepositoService.updateWithPatch({
            id: idDeposito,
            featured_image: documentId,
        });
    };

    const handleSetFeaturedDocument = documentId => {
        return DepositoService.updateWithPatch({
            id: idDeposito,
            featured_document: documentId,
        });
    };

    return (
        <ViewDocumentPanel
            onSetFeaturedImage={handleSetFeaturedImage}
            onSetFeaturedDocument={handleSetFeaturedDocument}
        />
    );
};

export default ViewDepositoDocumentPanel;
