import {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";

import {useGetDataUrl} from "base/hooks";
import {useDownloadEntityPDFReport} from "./hooks";
import {DocumentService} from "document/service";

import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const EntityPrintPDFAction = ({entityData}) => {
    const [imageUrl, setImageUrl] = useState(null);

    const {download: handleDownloadEntityReportPdf} = useDownloadEntityPDFReport();
    const getDataUrl = useGetDataUrl();

    useEffect(() => {
        if (entityData?.image) {
            // A Blob is necessary because we need to load the image using AuthService
            DocumentService.preview(entityData.image).then(response => {
                getDataUrl(response).then(dataUrl => {
                    setImageUrl(dataUrl);
                });
            });
        } else {
            setImageUrl(null);
        }
    }, [entityData]);

    const handleGeneratePDF = () => {
        handleDownloadEntityReportPdf(entityData, imageUrl);
    };

    return (
        <Tooltip title={"Imprimir informe"}>
            <IconButton
                aria-label="download-pdf"
                onClick={handleGeneratePDF}
                color="primary"
            >
                <PrintOutlinedIcon sx={{color: "white"}} />
            </IconButton>
        </Tooltip>
    );
};

export default EntityPrintPDFAction;
