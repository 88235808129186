import {SistemaFormCaracteristicasFields, SistemaFormGeneralDataFields} from ".";

const SistemaModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <SistemaFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <SistemaFormCaracteristicasFields layout="column" />;
    }
    return null;
};

export default SistemaModificationForm;
