import {useDomain} from "domain/provider";
import {FormSelect, FormInputText, FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const EDARFormXestionLodosXeradosFields = ({layout = "row"}) => {
    const {dominioedarlodosdestino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="lodos_saida"
                    label="Cantidade lodos"
                    tooltipText="Cantidade de lodos xerados polas instalacións de depuración de auga"
                    endAdornment="t/ano"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="lodos_destino"
                    label="Destino lodos"
                    options={dominioedarlodosdestino}
                    tooltipText="Destino dos lodos tratados na EDAR"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText
                    name="lodos_edar_destino"
                    label="EDAR destino"
                    tooltipText="Nome da EDAR onde se envían os lodos"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputText
                    name="lodos_xestor_destino"
                    label="Xestor"
                    tooltipText="Nome do xestor autorizado o que se envían os lodos"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="lodos_distancia_tratamiento"
                    label="Distancia destino"
                    endAdornment="Km"
                    tooltipText="Distancia ó punto de destino dos lodos da planta"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormXestionLodosXeradosFields;
