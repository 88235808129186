import {SidebarPanelLayout} from "base/layout";
import {AlertError} from "base/component/presentational";

const EntityUpdatePanel = ({form, title, error = null, onCancel}) => {
    return (
        <SidebarPanelLayout sidebarTitle={title} closeSidebarClick={onCancel}>
            {error && <AlertError error={error} sx={{mb: 2}} />}
            {form}
        </SidebarPanelLayout>
    );
};

export default EntityUpdatePanel;
