import {createEntityService} from "base/entity/service";
import {createGobernanza, gobernanza_api_adapter} from "saneamiento/model";

const entityService = createEntityService(
    "/api/infrastructures/gobernanzas",
    createGobernanza,
    null,
    gobernanza_api_adapter,
    null
);

const GobernanzaService = {
    get(id) {
        return entityService.get(id);
    },

    update(gobernanza) {
        return entityService.update(gobernanza);
    },
};

export default GobernanzaService;
