import {PageMenuHeading, PageMenuProvider} from "base/component/presentational";

import MenuList from "@mui/material/MenuList";
import {PAGE_MENU_DRAWER_WIDTH, NO_CONTENT_HEIGHT} from "base/config/measurements";
import useTheme from "@mui/material/styles/useTheme";

const PageMenu = ({
    headingPrimaryText = "",
    headingSecondaryText = "",
    headingIcon = null,
    headingUrl = null,
    children,
}) => {
    const theme = useTheme();

    return (
        <PageMenuProvider>
            <MenuList
                sx={{
                    pt: 0,
                    pb: 0,
                    backgroundColor: "white",
                    width: `${PAGE_MENU_DRAWER_WIDTH}px`,
                    minHeight: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
                    borderRight: `3px solid ${theme.palette.secondary.light}`,
                }}
            >
                {headingPrimaryText ? (
                    <PageMenuHeading
                        to={headingUrl}
                        headingPrimaryText={headingPrimaryText}
                        headingSecondaryText={headingSecondaryText}
                        headingIcon={headingIcon}
                    />
                ) : null}

                {children}
            </MenuList>
        </PageMenuProvider>
    );
};

export {PageMenu as default};
