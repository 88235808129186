import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormCompromisoFields = ({layout = "row"}) => {
    const {dominiogobernanzasino} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="identificadas_partes_interesadas"
                    label="Están indentificadas todas as partes interesadas"
                    options={dominiogobernanzasino}
                    tooltipText="O primeiro paso para lograr o compromiso dos intersados é o de indentificalos: abonados, persoal adscrito aos servizos, persoal do concello alleo ao servizos..."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="informacion_compartida"
                    label="Compártese información cos interesados para lograr o seu compromiso"
                    options={dominiogobernanzasino}
                    tooltipText="Para lograr o compromiso cos servizos dos diferentes interesados deberá seleccionarse a información que se comparte con cada un deles, en función dos roles de cada un con respecto dos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_mecanismos_involucrar_interesados"
                    label="Existen mecanismos para involucrar aos interesados nas decisións"
                    options={dominiogobernanzasino}
                    tooltipText="Por exemplo, enquisas de satisfacción, orzamentos participativos, talleres de consulta e participación, campañas de sensibilización..."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="posible_visitas_instalacions"
                    label="Pódense realizar visitas ás instalacións"
                    options={dominiogobernanzasino}
                    tooltipText="As visitas ás instalacións de depuración contribúen a mellorar a concienciación, a confianza e o compromiso dos usuarios."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormCompromisoFields;
