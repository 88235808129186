import {VertidoService} from "saneamiento/service";
import {SearchAutocomplete} from "base/component/search";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const VertidoFormSearch = ({
    handleSelect = null,
    name = null,
    rules = {},
    disabled = false,
}) => {
    const optionComponent = option => {
        return (
            <Stack>
                <Typography>{option.codigo}</Typography>
                <Typography variant="caption" sx={{ml: 1}}>
                    ID: {option.id}
                </Typography>
            </Stack>
        );
    };

    return (
        <SearchAutocomplete
            name={name}
            label="Vertido"
            optionLabelAttribute="codigo"
            optionLabelSecondAttribute="id"
            optionComponent={optionComponent}
            search={VertidoService.getBySearchText}
            handleSelect={handleSelect}
            rules={rules}
            disabled={disabled}
        />
    );
};

export default VertidoFormSearch;
