export const HERO_HEIGHT = 70;
export const NAVBAR_HEIGHT = 48;
export const BREADCRUMB_HEIGHT = 30;
export const HEADER_HEIGHT = HERO_HEIGHT + NAVBAR_HEIGHT + 2; // 2 pixels for top and bottom borders

export const FOOTER_HEIGHT = 48;

export const NO_CONTENT_HEIGHT = HEADER_HEIGHT + BREADCRUMB_HEIGHT + 1 + FOOTER_HEIGHT; // 1 pixels for bottom border

export const PAGE_MENU_DRAWER_WIDTH = 240;
export const SUBPAGE_MENU_DRAWER_WIDTH = 210;
export const SIDEBAR_PANEL_DRAWER_WIDTH = 440;
