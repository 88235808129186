import {useDomain} from "domain/provider";
import {FormSelect, FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const EDARFormXestionLodosAlleosFields = ({layout = "row"}) => {
    const {dominioedarlodosorixe} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="lodos_orixe"
                    label="Tratamento de lodos alleos"
                    options={dominioedarlodosorixe}
                    tooltipText="Recíbense lodos procedentes de outros sistemas ou instalacións?"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="lodos_cantidade_alleo"
                    label="Cantidade lodos alleos"
                    tooltipText="Cantidade de lodos alleos recibidos"
                    endAdornment="t/ano"
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormXestionLodosAlleosFields;
