import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    PoligonoSaneamentoSection,
    PoligonoInventarioSection,
} from "../presentational/section";

const ViewPoligonoDetailSubPage = () => {
    let poligono;
    [poligono] = useOutletContext();

    const sections = [
        <PoligonoSaneamentoSection poligono={poligono} />,
        <PoligonoInventarioSection poligono={poligono} />,
    ];

    return poligono && <EntityViewSubPage sections={sections} />;
};

export default ViewPoligonoDetailSubPage;
