import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {CaptacionService} from "abastecemento/service";
import {PageLayout} from "base/layout";
import {CaptacionSubPageMenu} from "../menu";
import {useCaptacionData} from "../hooks/CaptacionData";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewCaptacionPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();

    const {idCaptacion} = useParams();
    const [captacion, setCaptacion] = useState(null);
    const location = useLocation();
    const {getEntityData} = useCaptacionData();

    useEffect(() => {
        CaptacionService.get(idCaptacion).then(data => {
            setCaptacion(data);
            setSubpagePath([
                {name: "Listado de Captacións", path: "captacions"},
                {
                    name: data.nome || data.codigo || "-",
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setCaptacion(null);
            setSubpagePath([]);
            setModuleActions([]);
        };
    }, [idCaptacion, location.state?.lastRefreshDate]);

    return (
        captacion && (
            <PageLayout
                menu={<CaptacionSubPageMenu captacion={captacion} />}
                context={[captacion]}
                subPage={true}
            />
        )
    );
};

export default ViewCaptacionPage;
