import {useOutletContext} from "react-router-dom";

import {PageLayout} from "base/layout";
import {ConcelloStatsSubPageMenu} from "concello/menu";
import {StatsConfigProvider} from "stats/provider";
import {useAppConfig} from "base/provider";
import {useEffect} from "react";

const ViewConcelloStatsSubPage = () => {
    const {setSubpagePath} = useAppConfig();

    let concello;
    [concello] = useOutletContext();

    return (
        <StatsConfigProvider>
            <PageLayout
                menu={<ConcelloStatsSubPageMenu concello={concello} />}
                context={[concello]}
                subPage={true}
            />
        </StatsConfigProvider>
    );
};
export default ViewConcelloStatsSubPage;
