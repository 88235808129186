import {FieldUtil, NumberUtil} from "base/utilities";

export function useBombeoData(bombeo) {
    const getEntityData = bombeo => {
        return {
            image: bombeo?.featured_image,
            name: `Bombeo ${bombeo?.nome || bombeo?.codigo}`,
            sections: [
                {
                    head: "Datos xerais",
                    content: [
                        ["Código:", FieldUtil.getValue(bombeo?.codigo)],
                        ["Concello:", FieldUtil.getValue(bombeo?.concello_label)],
                        [
                            "Aglomeración:",
                            FieldUtil.getValue(bombeo?.aglomeracion_label),
                        ],
                        [
                            "Coordenadas UTM:",
                            `X: ${FieldUtil.getValue(
                                bombeo?.geom.coordinates[0]
                            )}, Y: ${FieldUtil.getValue(bombeo?.geom.coordinates[1])}`,
                        ],
                    ],
                },
                {
                    head: "Características",
                    content: [
                        [
                            "Caudal:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(bombeo?.caudal_bombeo)
                            ),
                        ],
                        [
                            "Altura bombeo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(bombeo?.altura_bombeo)
                            ),
                        ],
                        ["Bombas activas:", FieldUtil.getValue(bombeo?.bombas_activas)],
                        ["Bombas reserva:", FieldUtil.getValue(bombeo?.bombas_reserva)],
                    ],
                },
                {
                    head: "Elementos",
                    content: [
                        ["Telecontrol:", FieldUtil.getValue(bombeo?.telecontrol_label)],
                        [
                            "Sistema de protección:",
                            FieldUtil.getValue(bombeo?.proteccion_label),
                        ],
                        [
                            "Pretratamento:",
                            FieldUtil.getValue(bombeo?.pretratamiento_label),
                        ],
                    ],
                },
                {
                    head: "Explotación",
                    content: [
                        [
                            "Horas de funcionamento:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(
                                    bombeo?.horas_funcionamento_diarias
                                )
                            ),
                        ],
                        [
                            "Caudal medio anual:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(bombeo?.caudal_medio_anual)
                            ),
                        ],
                        [
                            "Consumo:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(bombeo?.consumo_electrico)
                            ),
                        ],
                        [
                            "Potencia:",
                            FieldUtil.getValue(
                                NumberUtil.formatDecimal2(bombeo?.potencia)
                            ),
                        ],
                        ["Problemas:", FieldUtil.getValue(bombeo?.problemas_label)],
                    ],
                },
            ],
        };
    };

    return {getEntityData};
}
