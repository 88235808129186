export function useGobernanzaEvaluation() {
    const getPrincipiosEvaluationValue = (total, range) => {
        let bo;

        if (range === "low") {
            bo = 2;
        } else if (range === "medium") {
            bo = 3;
        } else bo = 4;

        if (total >= bo) {
            return 1;
        } else if (total <= bo - 1 && total >= 0) {
            return 0;
        } else return -1;
    };

    const getPrincipiosEvaluationColor = (total, range) => {
        switch (getPrincipiosEvaluationValue(total, range)) {
            case 1:
                return "success";
            case 0:
                return "warning";
            default:
                return "error";
        }
    };

    const getDimensionEvaluationLabel = total => {
        switch (true) {
            case total >= 4:
                return "Moi bo";
            case total === 3 || total === 2:
                return "Bo";
            case total === 1 || total === 0:
                return "Medio";
            case total === -1 || total === -2:
                return "Deficiente";
            default:
                return "Moi deficiente";
        }
    };

    const getDimensionEvaluationColor = total => {
        switch (true) {
            case total >= 4:
                return "success";
            case total === 3 || total === 2:
                return "info";
            case total === 1 || total === 0:
                return "secondary";
            case total === -1 || total === -2:
                return "warning";
            default:
                return "error";
        }
    };

    return {
        getDimensionEvaluationLabel,
        getDimensionEvaluationColor,
        getPrincipiosEvaluationValue,
        getPrincipiosEvaluationColor,
    };
}
