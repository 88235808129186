import {TextUtil} from "base/utilities";

export function useCaptacionTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 10,
        },
        {
            id: "abastecemento_autonomo",
            label: "A.A.",
            width: 10,
            formatFunction: element =>
                TextUtil.formatBoolean(element.abastecemento_autonomo),
        },
        {
            id: "titular_label",
            label: "Titular",
            width: 15,
        },
        {
            id: "xestion_label",
            label: "Xestión",
            width: 15,
        },
        {
            id: "estado_label",
            label: "Estado",
            width: 10,
        },
        {
            id: "orixe_label",
            label: "Orixe",
            width: 15,
        },
        {
            id: "tipo_label",
            label: "Tipo",
            width: 15,
        },
        {
            id: "uso_label",
            label: "Uso",
            width: 10,
        },
    ];

    return {tableColumns};
}
