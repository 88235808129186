import {NumberUtil} from "base/utilities";

export function useSubaglomeracionTable() {
    const tableColumns = [
        {
            id: "codigoaglomeracion",
            label: "Código aglomeración",
            width: 16,
            ordering: false,
        },
        {
            id: "nomeaglomeracion",
            label: "Aglomeración",
            width: 14,
            ordering: false,
        },
        {
            id: "codigoconcello",
            label: "Código concello",
            width: 8,
            ordering: false,
        },
        {
            id: "nomeconcello",
            label: "Nome concello",
            width: 12,
            ordering: false,
        },
        {
            id: "tratamento",
            label: "Tipo de tratamento",
            width: 11,
            ordering: false,
        },
        {
            id: "cargaurbana",
            label: "Poboación urbana (heq)",
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.cargaurbana),
            width: 9,
            ordering: false,
        },
        {
            id: "carganondomestica",
            label: "Poboación non doméstica (heq)",
            formatFunction: element =>
                NumberUtil.formatDecimalAsInteger(element.carganondomestica),
            width: 9,
            ordering: false,
        },
        {
            id: "lonxitudecolector",
            label: "Lonxitude rede (Km.)",
            width: 9,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.lonxitudecolector, 3),
            ordering: false,
        },
        {
            id: "bombeos",
            label: "Bombeos",
            width: 7,
            ordering: false,
        },
        {
            id: "tanques",
            label: "Tanques",
            width: 7,
            ordering: false,
        },
    ];

    return {tableColumns};
}

export function useSubaglomeracionAglomeracionTable() {
    const tableColumns = [
        {
            id: "codigoconcello",
            label: "Código",
            width: 10,
        },
        {
            id: "nomeconcello",
            label: "Concello",
            width: 15,
        },
        {
            id: "cargadomesticafixa",
            label: "Doméstica fixa",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.cargadomesticafixa, 0),
        },
        {
            id: "cargadomesticaestacional",
            label: "Doméstica estacional",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.cargadomesticaestacional, 0),
        },
        {
            id: "carganondomesticaindustrial",
            label: "Industrial",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.carganondomesticaindustrial, 0),
        },
        {
            id: "carganondomesticaoutros",
            label: "Outra non doméstica",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.carganondomesticaoutros, 0),
        },
        {
            id: "porcentaxecargaurbana",
            label: "% Aglomeración",
            width: 15,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.porcentaxecargaurbana, 1),
        },
    ];

    return {tableColumns};
}

export function useSubaglomeracionConcelloTable() {
    const tableColumns = [
        {
            id: "codigoaglomeracion",
            label: "Código aglomeración",
            width: 14,
        },
        {
            id: "nome",
            label: "Nome",
            width: 10,
        },
        {
            id: "cargaurbana",
            label: "Carga urbana",
            width: 8,
            formatFunction: element => NumberUtil.formatDecimal(element.cargaurbana, 0),
        },
        {
            id: "cargadomesticafixa",
            label: "Doméstica fixa",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.cargadomesticafixa, 0),
        },
        {
            id: "cargadomesticaestacional",
            label: "Doméstica estacional",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.cargadomesticaestacional, 0),
        },
        {
            id: "carganondomesticaindustrial",
            label: "Industrial",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.carganondomesticaindustrial, 0),
        },
        {
            id: "carganondomesticaoutros",
            label: "Outra non doméstica",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.carganondomesticaoutros, 0),
        },
        {
            id: "tratamento",
            label: "Tratamento",
            width: 10,
        },
        {
            id: "porcentaxecargaurbanasobreaglomeracion",
            label: "% Aglomeración",
            width: 10,
            formatFunction: element =>
                NumberUtil.formatDecimal(
                    element.porcentaxecargaurbanasobreaglomeracion,
                    1
                ),
        },
        {
            id: "porcentaxecargaurbanasobreconcello",
            label: "% Concello",
            width: 8,
            formatFunction: element =>
                NumberUtil.formatDecimal(element.porcentaxecargaurbanasobreconcello, 1),
        },
    ];

    return {tableColumns};
}
