import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    GobernanzaDatosInformacionSection,
    GobernanzaFinanciamentoSection,
    GobernanzaMarcosRegulatoriosSection,
    GobernanzaInnovacionSection,
} from "../presentational/section";

const ViewGobernanzaEficienciaSubPage = () => {
    let gobernanza;
    [gobernanza] = useOutletContext();

    const sections = [
        <GobernanzaDatosInformacionSection gobernanza={gobernanza} />,
        <GobernanzaFinanciamentoSection gobernanza={gobernanza} />,
        <GobernanzaMarcosRegulatoriosSection gobernanza={gobernanza} />,
        <GobernanzaInnovacionSection gobernanza={gobernanza} />,
    ];

    return gobernanza && <EntityViewSubPage sections={sections} />;
};

export default ViewGobernanzaEficienciaSubPage;
