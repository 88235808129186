import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewSistemaDocumentsSubPage = () => {
    let sistema;
    [sistema] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/sistemas/")[0];

    return (
        sistema && (
            <ViewDocumentsSubPage entity={sistema} basePath={`${basePath}/sistemas`} />
        )
    );
};

export default ViewSistemaDocumentsSubPage;
