import AuthApiService from "./AuthApiService";

const FileService = {
    download(path, contentType) {
        return AuthApiService.get(path, {
            "Content-Type": "application/pdf",
        });
    },
};

export default FileService;
