import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionDatosPrincipaisSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Datos principais">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField label="Código:" value={aglomeracion.stats.codigo} />
                    <SectionField label="Nome:" value={aglomeracion.stats.nome} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Código EDAR:"
                        value={aglomeracion.stats.codigoedar}
                        linkPath={`/aglomeracions/${aglomeracion.id}/edars/${aglomeracion.stats.idedar}/summary`}
                        tooltipText="EDAR da aglomeración"
                    />
                    <SectionField
                        label="Nome EDAR:"
                        value={aglomeracion.stats.nomeedar}
                        linkPath={`/aglomeracions/${aglomeracion.id}/edars/${aglomeracion.stats.idedar}/summary`}
                        tooltipText="EDAR da aglomeración"
                    />
                    <SectionField
                        label="Tratamento:"
                        value={aglomeracion.stats.tratamento_label}
                        tooltipText="Nivel de tratamento"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionDatosPrincipaisSection;
