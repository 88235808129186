import {useEffect} from "react";

import {ContentLayout, PageLayout} from "base/layout";
import {useStatsConfig} from "stats/provider";
import {StatsInfrastructureTypeFilter} from "stats/presentational";

import Stack from "@mui/material/Stack";
import {SectionCard} from "base/component/presentational";
import ViewStatsInfrastructuresTypeChart from "stats/container/ViewStatsInfrastructuresTypeChart";
import {useOutletContext} from "react-router-dom";
import Alert from "@mui/material/Alert";
import {useAppConfig} from "base/provider";

const ViewConcelloStatsInfrastructuresTypeSubPage = ({sector}) => {
    let concello;
    [concello] = useOutletContext();
    const {setSubpagePath} = useAppConfig();

    const {statsFilter, setStatsFilter} = useStatsConfig();

    useEffect(() => {
        setSubpagePath([
            {name: "Estatísticas abastecemento", path: "stats"},
            {name: "Gráficas por atributo", path: "abastecemento_infrastructures_type"},
        ]);
        return () => {
            setSubpagePath([]);
        };
    }, []);

    useEffect(() => {
        setStatsFilter({concello: concello.codigo});
    }, [sector]);

    const handleFilterChange = filter => {
        setStatsFilter({...filter, concello: concello.codigo});
    };

    return (
        <ContentLayout>
            <Stack spacing={2} sx={{width: "100%"}}>
                <StatsInfrastructureTypeFilter
                    filter={statsFilter}
                    onChange={handleFilterChange}
                    showProvincia={false}
                />
                <SectionCard title="Estadísticas de infraestructuras por atributo">
                    {statsFilter?.infrastructure_type && statsFilter?.attribute ? (
                        <ViewStatsInfrastructuresTypeChart statsFilter={statsFilter} />
                    ) : (
                        <Alert severity="warning">
                            Seleccione o <b>atributo</b> e o{" "}
                            <b>tipo de infraestructura</b> no filtro
                        </Alert>
                    )}
                </SectionCard>
            </Stack>
        </ContentLayout>
    );
};
export default ViewConcelloStatsInfrastructuresTypeSubPage;
