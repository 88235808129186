import {useNavigate} from "react-router-dom";

import {
    SectionCardHeaderAction,
    SectionField,
    SectionSubheading,
} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionProblemasSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`problemasdatos/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Problemas" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sen datos:"
                        value={subaglomeracion.problema_sen_datos_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Sen problemas:"
                        value={subaglomeracion.problema_sen_problema_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Infiltración" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_infiltracion_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_infiltracion_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Auga de mar" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_augamar_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_augamar_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Auga blanca" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_augablanca_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_augablanca_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Contrapendente" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_contrapendente_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_contrapendente_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Rotura" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_rotura_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_rotura_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Atasco" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_atasco_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_atasco_alcance_label}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <SectionSubheading text="Outros" />
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Descripción:"
                        value={subaglomeracion.problema_outro}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Frecuencia:"
                        value={subaglomeracion.problema_outro_frecuencia_label}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Alcance:"
                        value={subaglomeracion.problema_outro_alcance_label}
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionProblemasSection;
