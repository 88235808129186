import {useState} from "react";
import {useNavigate} from "react-router-dom";

import {ConcelloService} from "concello/service";
import {
    CaptacionService,
    DepositoService,
    ETAPService,
    SistemaService,
} from "abastecemento/service";
import {
    BombeoService,
    EDARService,
    PoligonoService,
    TanqueService,
} from "saneamiento/service";
import {AglomeracionService} from "saneamiento/aglomeracion/service";

import {SearchAutocompleteExpansive} from "base/component/search";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

const SisbagalMenuSearch = ({homePage = false}) => {
    const theme = useTheme();

    const [bombeos, setBombeos] = useState([]);
    const [searchingBombeos, setSearchingBombeos] = useState(false);
    const [captaciones, setCaptaciones] = useState([]);
    const [searchingCaptaciones, setSearchingCaptaciones] = useState(false);
    const [concellos, setConcellos] = useState([]);
    const [searchingConcellos, setSearchingConcellos] = useState(false);
    const [depositos, setDepositos] = useState([]);
    const [searchingDepositos, setSearchingDepositos] = useState(false);
    const [edars, setEdars] = useState([]);
    const [searchingEdars, setSearchingEdars] = useState(false);
    const [etaps, setEtaps] = useState([]);
    const [searchingEtaps, setSearchingEtaps] = useState(false);
    const [poligonos, setPoligonos] = useState([]);
    const [searchingPoligonos, setSearchingPoligonos] = useState(false);
    const [sistemas, setSistemas] = useState([]);
    const [searchingSistemas, setSearchingSistemas] = useState(false);
    const [aglomeraciones, setAglomeraciones] = useState([]);
    const [searchingAglomeraciones, setSearchingAglomeraciones] = useState(false);
    const [tanques, setTanques] = useState([]);
    const [searchingTanques, setSearchingTanques] = useState(false);

    const isLoading = () => {
        return (
            searchingBombeos &&
            searchingCaptaciones &&
            searchingConcellos &&
            searchingDepositos &&
            searchingEdars &&
            searchingEtaps &&
            searchingPoligonos &&
            searchingSistemas &&
            searchingAglomeraciones &&
            searchingTanques
        );
    };

    const navigate = useNavigate();

    const getItemUrl = (item, category) => {
        if (category === "concellos") {
            return `/${category}/${item.codigo}`;
        }
        if (category === "aglomeracions") {
            return `/${category}/${item.id}/map`;
        }
        return `/concellos/${item.concello}/${category}/${item.id}/summary`;
    };

    // TO-DO: Autocomplete is only searching either in name or code; we'd rather have it searching BOTH fields
    const adaptDataFromAPI = (data, category) => {
        const adaptedData = data.map(item =>
            Object.assign(
                {},
                item,
                {category: category},
                {
                    nameForSearch:
                        item.nome || item.codigo.toString() || item.id.toString(),
                },
                {
                    url: getItemUrl(item, category),
                }
            )
        );
        return adaptedData;
    };

    const handleClear = () => {
        setBombeos([]);
        setCaptaciones([]);
        setConcellos([]);
        setDepositos([]);
        setEdars([]);
        setEtaps([]);
        setPoligonos([]);
        setSistemas([]);
        setAglomeraciones([]);
        setTanques([]);
    };

    const handleSearch = searchText => {
        setSearchingBombeos(true);
        BombeoService.getBySearchText(searchText).then(data => {
            setBombeos(adaptDataFromAPI(data, "bombeos"));
            setSearchingBombeos(true);
        });
        setSearchingCaptaciones(true);
        CaptacionService.getBySearchText(searchText).then(data => {
            setCaptaciones(adaptDataFromAPI(data, "captacions"));
            setSearchingCaptaciones(true);
        });
        setSearchingConcellos(true);
        ConcelloService.getBySearchText(searchText).then(data => {
            setConcellos(adaptDataFromAPI(data, "concellos"));
            setSearchingConcellos(true);
        });
        setSearchingDepositos(true);
        DepositoService.getBySearchText(searchText).then(data => {
            setDepositos(adaptDataFromAPI(data, "depositos"));
            setSearchingDepositos(true);
        });
        setSearchingEdars(true);
        EDARService.getBySearchText(searchText).then(data => {
            setEdars(adaptDataFromAPI(data, "edars"));
            setSearchingEdars(true);
        });
        setSearchingEtaps(true);
        ETAPService.getBySearchText(searchText).then(data => {
            setEtaps(adaptDataFromAPI(data, "etaps"));
            setSearchingEtaps(true);
        });
        setSearchingPoligonos(true);
        PoligonoService.getBySearchText(searchText).then(data => {
            setPoligonos(adaptDataFromAPI(data, "poligonos"));
            setSearchingPoligonos(true);
        });
        setSearchingSistemas(true);
        SistemaService.getBySearchText(searchText).then(data => {
            setSistemas(adaptDataFromAPI(data, "sistemas"));
            setSearchingSistemas(true);
        });
        setSearchingAglomeraciones(true);
        AglomeracionService.getBySearchText(searchText).then(data => {
            setAglomeraciones(adaptDataFromAPI(data, "aglomeracions"));
            setSearchingAglomeraciones(true);
        });
        setSearchingTanques(true);
        TanqueService.getBySearchText(searchText).then(data => {
            setTanques(adaptDataFromAPI(data, "tanques"));
            setSearchingTanques(false);
        });
    };

    const handleSelect = url => {
        navigate(url);
    };

    const optionComponent = (option, index) => {
        return (
            <Box
                key={index}
                sx={{
                    width: "100%",
                    borderBottom: "1px solid #f0f0f0",
                }}
            >
                <Typography sx={{fontSize: "0.9em"}}>{option.nome}</Typography>
                {option.codigo && (
                    <Typography sx={{color: "grey", fontSize: "0.7em", pl: 1}}>
                        [{option.codigo}]
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <SearchAutocompleteExpansive
            options={[
                ...concellos,
                ...aglomeraciones,
                ...poligonos,
                ...edars,
                ...bombeos,
                ...sistemas,
                ...tanques,
                ...etaps,
                ...depositos,
                ...captaciones,
            ]}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onClear={handleClear}
            loading={isLoading()}
            optionLabelAttribute="nameForSearch"
            optionComponent={optionComponent}
            optionGroupBy="category"
            searchIconColor={homePage ? theme.palette.primary.main : "white"}
        />
    );
};

export default SisbagalMenuSearch;
