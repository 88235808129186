import {useOutletContext} from "react-router-dom";
import {ViewEntityChangesSubPage} from "changes/container";
import {SubaglomeracionService} from "saneamiento/service";

const ViewSubaglomeracionChangesSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    return (
        subaglomeracion && (
            <ViewEntityChangesSubPage
                entityService={SubaglomeracionService}
                entityType="subaglomeracion"
                entityId={subaglomeracion.id}
            ></ViewEntityChangesSubPage>
        )
    );
};

export default ViewSubaglomeracionChangesSubPage;
