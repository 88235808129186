import {useOutletContext} from "react-router-dom";
import {ETAPService} from "abastecemento/service";
import {
    EntityAuditSection,
    EntityLocationSection,
    EntityViewSubPage,
} from "base/entity";
import {ETAPGeneralDataSection} from "../presentational/section";
import ETAPLocationSection from "../presentational/section/ETAPLocationSection";
import UpdateETAPGeoPanel from "./UpdateETAPGeoPanel";

const ViewETAPSummarySubPage = () => {
    let etap;
    [etap] = useOutletContext();

    const sections = [
        <ETAPGeneralDataSection etap={etap} />,
        <UpdateETAPGeoPanel />,
        <EntityAuditSection entity={etap} />,
    ];

    return etap && <EntityViewSubPage sections={sections} service={ETAPService} />;
};

export default ViewETAPSummarySubPage;
