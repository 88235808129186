import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "base/auth";

import {SpinnerFullHeight} from "base/component/presentational";

const LogoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {logout} = useAuth();

    useEffect(() => {
        logout(location.state?.from?.pathname).then(() => {
            // If it was not redirected to CAS
            navigate("/login", {replace: true, state: {from: location.state?.from}});
        });
    }, []);

    return <SpinnerFullHeight message="Cerrando sesión del usuario" />;
};

export default LogoutPage;
