import {BaseLayerSelector, useDownloadMapAsPng} from "base/component/geo";
import useTheme from "@mui/material/styles/useTheme";
import {SisbagalMapDownloadPdfAction, SisbagalMapDownloadPngAction} from "sisbagal/map";

import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import SisbagalBufferSelector from "./SisbagalBufferSelector";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const SisbagalMapActions = ({
    mapObjectRef,
    mapRef,
    entityData = {},
    showDownloadPdfAction = false,
}) => {
    const theme = useTheme();

    const {
        setConfig: setConfigMapAsPng,
        download: handleDownloadMapAsPng,
        generate: generateMapAsPng,
        getDimensions: getMapAsPngDimensions,
    } = useDownloadMapAsPng();

    setConfigMapAsPng(mapObjectRef.current, mapRef.current);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                bgcolor: theme.palette.secondary.light,
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
                height: "50px",
            }}
        >
            <Typography sx={{pl: 3, pt: 1, pb: 1}} variant="h6">
                Capas
            </Typography>
            <ButtonGroup variant="outlined" aria-label="map button group">
                <Divider orientation="vertical" flexItem />
                <BaseLayerSelector />
                <Divider orientation="vertical" flexItem />
                <SisbagalBufferSelector />
                {showDownloadPdfAction && (
                    <>
                        <Divider orientation="vertical" flexItem />
                        <SisbagalMapDownloadPdfAction
                            generateMapAsPng={generateMapAsPng}
                            getMapAsPngDimensions={getMapAsPngDimensions}
                            entityData={entityData}
                        />
                    </>
                )}
                <Divider orientation="vertical" flexItem />
                <SisbagalMapDownloadPngAction
                    onDownloadMapAsPng={handleDownloadMapAsPng}
                />
            </ButtonGroup>
        </Grid>
    );
};

export default SisbagalMapActions;
