import {ETAPFormLocationFields} from "../form";
import {EntityGeoForm} from "base/entity";

const ETAPGeoForm = ({onSubmit, onCancel = null}) => {
    return (
        <EntityGeoForm onSubmit={onSubmit} onCancel={onCancel}>
            <ETAPFormLocationFields />
        </EntityGeoForm>
    );
};

export default ETAPGeoForm;
