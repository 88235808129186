import {useDomain} from "domain/provider";
import {FormInputDecimal, FormSelect, FormSelectMultiple} from "base/component/form";
import {BombeoFormSearch} from "saneamiento/bombeo/presentational/form";

import Grid from "@mui/material/Grid";

const TanqueFormCaracteristicasFields = ({layout = "row"}) => {
    const {
        dominiotanquepretratamento,
        dominiotanqueregulacion,
        dominiotanquetratamentoaliviadero,
        dominiotanquelimpeza,
        dominiotanquetelecontrol,
        dominiotanqueproblemas,
    } = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="capacidade"
                    label="Capacidade"
                    endAdornment="m³"
                    placeholder="0,00"
                    tooltipText="Capacidade de almacenamento/retención"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="regulacion"
                    label="Regulación"
                    options={dominiotanqueregulacion}
                    tooltipText="Sistema de regulación do valeirado do tanque"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <BombeoFormSearch name="bombeo" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="pretratamiento"
                    label="Pre-tratamento"
                    options={dominiotanquepretratamento}
                    tooltipText="Sistema de pretratamento instalado"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="tratamento_aliviadoiro"
                    label="Tratamento aliviadoiro"
                    options={dominiotanquetratamentoaliviadero}
                    tooltipText="Sistema de tratamento no aliviadoiro"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="sistema_limpeza"
                    label="Limpeza"
                    options={dominiotanquelimpeza}
                    tooltipText="Sistema de limpeza instalado"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="telecontrol"
                    label="Telecontrol"
                    options={dominiotanquetelecontrol}
                    tooltipText="Sistema de telecontrol"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectMultiple
                    name="problemas"
                    label="Problemas"
                    options={dominiotanqueproblemas}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="potencia"
                    label="Potencia instalada"
                    endAdornment="kW"
                    placeholder="0,00"
                    tooltipText="Potencia eléctrica instalada no tanque (sen incluir o sistema de bombeo)"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="consumo_electrico"
                    label="Consumo eléctrico"
                    endAdornment="kWh/ano"
                    placeholder="0,00"
                    tooltipText="Consumo eléctrico anual medio dos últimos 5 anos"
                />
            </Grid>
        </Grid>
    );
};

export default TanqueFormCaracteristicasFields;
