export function useVertidoTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 16,
        },
        {
            id: "tipo_punto_vertido_label",
            label: "Tipo punto de vertido",
            width: 8,
        },
        {
            id: "tipo_label",
            label: "Tipo de vertido",
            width: 9,
        },
        {
            id: "tipo_titular_label",
            label: "Titular",
            width: 10,
        },
        {
            id: "codigo_externo",
            label: "Código CNV",
            width: 7,
        },
        {
            id: "aglomeracion_label",
            label: "Aglomeración",
            width: 12,
        },
        {
            id: "masa_auga_label",
            label: "Bacía",
            width: 14,
        },
        {
            id: "sistema_explotacion_label",
            label: "Sistema de explotación",
            width: 14,
        },
        {
            id: "demarcacion_label",
            label: "Demarcación",
            width: 10,
        },
    ];

    return {tableColumns};
}
