import {useParams} from "react-router-dom";
import {EntitySummaryPanel} from "base/entity";
import {AlertError, SectionCard, SectionField} from "base/component/presentational";
import {NumberUtil} from "base/utilities";
import {ConexionService} from "conexion/service";
import {conexionElementTypesConstants} from "conexion/model";
import {AuthAction} from "base/auth";
import {ROLES} from "base/config";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import {useState} from "react";
import {
    ApproveChangeDialog,
    ChangesTable,
    RejectChangeDialog,
} from "changes/presentational";
import {ChangeService} from "saneamiento/service";
import {useNavigateWithReload} from "base/hooks";
import {ChangeActionState} from "saneamiento/model/Change";
import ConexionChangesTable from "./ConexionChangesTable";
import Box from "@mui/material/Box";

function formatTipo(tipo) {
    switch (tipo) {
        case conexionElementTypesConstants.EDAR:
            return "EDAR";
        case conexionElementTypesConstants.NUCLEO:
            return "Núcleo";
        case conexionElementTypesConstants.DEPOSITO:
            return "Depósito";
        case conexionElementTypesConstants.CAPTACION:
            return "Captación";
        case conexionElementTypesConstants.ETAP:
            return "ETAP";
        default:
            return tipo;
    }
}

const ViewConexionPanel = () => {
    const {idConexion} = useParams();
    const navigate = useNavigateWithReload();

    const [error, setError] = useState(null);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    const [disabledActions, setDisabledActions] = useState(true);

    const getSectionData = conexion => {
        return (
            <>
                <SectionField
                    label="Porcentaxe:"
                    value={NumberUtil.formatDecimal(conexion.porcentaxe)}
                    unit="%"
                />
            </>
        );
    };

    const handleOpenApproveDialog = element => {
        setIsApproveDialogOpen(true);
    };
    const handleOpenRejectDialog = element => {
        setIsRejectDialogOpen(true);
    };

    const handleApproveChange = () => {
        ConexionService.approveChanges(idConexion)
            .then(change => {
                navigate("", true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleRejectChange = comments => {
        ConexionService.rejectChanges(idConexion, comments)
            .then(change => {
                navigate("", true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleChangesLoaded = changes => {
        setDisabledActions(
            !changes.some(change => change.action_state === ChangeActionState.PENDING)
        );
    };

    return (
        <EntitySummaryPanel
            service={ConexionService}
            id={idConexion}
            title="Resumo de conexión"
            getSectionTitle={entity =>
                `${formatTipo(entity.tipo_orixe)}: ${entity.nome_orixe ||
                    entity.codigo_orixe} → ${formatTipo(
                    entity.tipo_destino
                )}: ${entity.nome_destino || entity.codigo_destino} `
            }
            getSectionData={getSectionData}
        >
            <Box sx={{mt: 2, width: "100%"}}>
                <SectionCard title="Control de cambios">
                    <AuthAction roles={[ROLES.XESTOR, ROLES.ADMINISTRADOR]}>
                        <Grid
                            container
                            justifyContent="center"
                            spacing={2}
                            sx={{mb: 2}}
                        >
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleOpenApproveDialog}
                                    endIcon={<ThumbUpAltOutlinedIcon />}
                                    disabled={disabledActions}
                                >
                                    Aprobar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleOpenRejectDialog}
                                    endIcon={<ThumbDownAltOutlinedIcon />}
                                    disabled={disabledActions}
                                >
                                    Rechazar
                                </Button>
                            </Grid>
                        </Grid>
                    </AuthAction>
                    <AlertError error={error} />
                    <ConexionChangesTable
                        idConexion={idConexion}
                        onChangesLoaded={handleChangesLoaded}
                    />
                    <ApproveChangeDialog
                        isDialogOpen={isApproveDialogOpen}
                        setIsDialogOpen={setIsApproveDialogOpen}
                        onApproveChange={handleApproveChange}
                    />
                    <RejectChangeDialog
                        isDialogOpen={isRejectDialogOpen}
                        setIsDialogOpen={setIsRejectDialogOpen}
                        onRejectChange={handleRejectChange}
                    />
                </SectionCard>
            </Box>
        </EntitySummaryPanel>
    );
};

export default ViewConexionPanel;
