import {TanqueFormCaracteristicasFields, TanqueFormGeneralDataFields} from ".";

const TanqueModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <TanqueFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <TanqueFormCaracteristicasFields layout="column" />;
    }
    return null;
};

export default TanqueModificationForm;
