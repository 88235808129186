import {useState, createContext, useContext, useEffect} from "react";
import {useAppConfig} from "base/provider";
import {useSisbagalAppConfig} from "sisbagal/provider";

let ModuleConfigContext = createContext(null);

export default function ModuleConfigProvider({name, path, icon = null, children}) {
    const {setModulePath} = useAppConfig();
    const {consolidated} = useSisbagalAppConfig();

    const [moduleFilter, setModuleFilter] = useState({consolidated});

    useEffect(() => {
        setModulePath([{name, path, icon}]);
    }, []);

    // TODO, review this effect because affects to filter config
    useEffect(() => {
        setModuleFilter({...moduleFilter, consolidated});
    }, [consolidated]);

    let value = {
        moduleFilter,
        setModuleFilter,
        path,
    };

    return (
        <ModuleConfigContext.Provider value={value}>
            {children}
        </ModuleConfigContext.Provider>
    );
}

function useConfigModule() {
    return useContext(ModuleConfigContext);
}

export {useConfigModule};
