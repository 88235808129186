import {FormSelect, FormSelectMultiple} from "base/component/form";

import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";
import {ConcelloHiddenAuth} from "concello/auth";

const EDARFormTramaentoLodosFields = ({layout = "row"}) => {
    const {dominiosino, dominioedarlodostratamento} = useDomain();
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <ConcelloHiddenAuth>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelectMultiple
                        name="lodos_tipo_tratamiento"
                        label="Tipo de tratamento"
                        options={dominioedarlodostratamento}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{width: "100%"}}>
                    <FormSelect
                        name="dixestion_anaerobia"
                        label="Dixestión anaerobia"
                        options={dominiosino}
                        tooltipText="Existe dixestión anaerobia como estabilización dos lodos na planta?"
                    />
                </Grid>
            </ConcelloHiddenAuth>
        </Grid>
    );
};

export default EDARFormTramaentoLodosFields;
