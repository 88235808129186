import {createEntityService} from "base/entity/service";
import {
    createAglomeracion,
    createAglomeracions,
    aglomeracion_api_adapter,
    aglomeracions_api_adapter,
} from "saneamiento/aglomeracion/model";

const entityService = createEntityService(
    "/api/infrastructures/aglomeracions",
    createAglomeracion,
    createAglomeracions,
    aglomeracion_api_adapter,
    aglomeracions_api_adapter
);

const AglomeracionService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },
};

export default AglomeracionService;
