import {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const RejectChangeDialog = ({isDialogOpen, setIsDialogOpen, onRejectChange}) => {
    const [comments, setComments] = useState("");

    const handleCommentsChange = event => {
        setComments(event.target.value);
    };

    const handleDialog = isOpen => {
        setIsDialogOpen(isOpen);
    };

    const handleConfirmRejectChange = () => {
        setIsDialogOpen(false);
        onRejectChange(comments);
    };

    const handleCloseDialog = () => {
        handleDialog(false);
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="reject"
            aria-describedby="Si hace clic en Rechazar, este cambio se eliminará del sistema y no podrá aplicarse."
        >
            <DialogTitle id="reject">¿Desea rechazar este cambio?</DialogTitle>
            <DialogContent>
                <DialogContentText id={`reject-dialog`}>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            name={"comments"}
                            label="Observaciones"
                            value={comments}
                            onChange={handleCommentsChange}
                        />
                        <Typography>
                            Si hace clic en Rechazar, este cambio se eliminará del
                            sistema y no podrá aplicarse.
                        </Typography>
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus>
                    Cancelar
                </Button>
                <Button
                    onClick={handleConfirmRejectChange}
                    color="error"
                    variant="contained"
                >
                    Rechazar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RejectChangeDialog;
