import {AuthApiService} from "base/service";
import {createEntityService} from "base/entity/service";

import {ServiceRequestFormat, ServiceUtil} from "base/utilities";
import {
    createConexion,
    createConexiones,
    conexion_api_adapter,
    conexiones_api_adapter,
} from "conexion/model";

const entityService = createEntityService(
    "/api/infrastructures/conexiones",
    createConexion,
    createConexiones,
    conexion_api_adapter,
    conexiones_api_adapter
);

const basePath = "/api/infrastructures/conexiones";
const basePathDetailed = "/api/infrastructures/conexionesDetalladas";

const ConexionService = {
    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    delete(conexion) {
        return AuthApiService.delete(basePath + "/" + conexion.id);
    },

    create(tanque) {
        return entityService.create(tanque);
    },

    update(tanque) {
        return entityService.update(tanque);
    },

    getList(filter, sort, order, format = null) {
        return AuthApiService.get(
            `${basePathDetailed}?${ServiceUtil.getQueryString(
                null,
                filter,
                sort,
                order
            )}`,
            ServiceUtil.getAcceptHeader(format)
        ).then(response => {
            return response;
        });
    },

    getPaginatedList(filter, page, sort, order) {
        return AuthApiService.get(
            `${basePathDetailed}?${ServiceUtil.getQueryString(
                page,
                filter,
                sort,
                order
            )}`
        ).then(response => {
            return response;
        });
    },

    get(id) {
        return AuthApiService.get(`${basePathDetailed}/${id}`).then(response => {
            // Detailed conexions are in geojson format
            return createConexion(conexion_api_adapter(response.properties));
        });
    },

    getAllDetailed(filter) {
        delete filter["buffer"];
        return AuthApiService.get(
            `${basePathDetailed}?${ServiceUtil.getGeoFilterQueryString(filter)}`,
            ServiceUtil.getAcceptHeader(ServiceRequestFormat.GEOJSON)
        ).then(response => {
            response["crs"] = {
                type: "name",
                properties: {
                    name: "urn:ogc:def:crs:EPSG::25829",
                },
            };
            return response;
        });
    },

    bulkUpdate(isOrixe, elementType, elementId, conexiones) {
        return AuthApiService.put(
            `${basePath}/bulk/${
                isOrixe ? "orixe" : "destino"
            }/${elementType}/${elementId}`,
            conexiones
        ).then(response => {
            return createConexiones(conexiones_api_adapter(response));
        });
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default ConexionService;
