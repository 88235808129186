import {FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const SubaglomeracionFormRedeNaturezaFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal name="natureza_pluviais" label="Natureza pluviais" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal name="natureza_fecais" label="Natureza fecais" />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="natureza_unitarios"
                    label="Natureza unitarios"
                />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormRedeNaturezaFields;
