import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaFinanciamentoSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`financiamento/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Realízase periodicamente un estudo dos custos de explotación:",
            value: gobernanza.estudo_custos_explotacion_label,
        },
        {
            label: "Existe unha ordenanza fiscal dos servizos que regula os ingresos:",
            value: gobernanza.ordenanza_fiscal_ingresos_label,
        },
        {
            label: "Actualízanse anualmente as tarifas a cobrar polos servizos:",
            value: gobernanza.actualizacion_tarifas_label,
        },
        {
            label: "A recuperación dos custos de explotación é:",
            value: `${gobernanza.recuperacion_custos_explotacion_label} %`,
        },
        {
            label: "A recuperación dos custos de amortización é:",
            value: `${gobernanza.recuperacion_custos_amortizacion_label} %`,
        },
        {
            label: "A recuperación dos custos de renovación/novas actuacións é:",
            value: `${gobernanza.recuperacion_custos_renovacion_label} %`,
        },
        {
            label: "Realízase un diagnóstico periódico do estado das infraestruras:",
            value: gobernanza.diagnostico_estado_infraestructuras_label,
        },
        {
            label: "Existe un plan de investimentos nos servizos:",
            value: gobernanza.existe_plan_investimento_label,
        },
    ];

    return (
        <SisbagalSectionCard title="Financiamento" secondaryActions={secondaryActions}>
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_financiamento}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_financiamento,
                    "high"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaFinanciamentoSection;
