import {useNavigate, useParams} from "react-router-dom";
import {TextUtil} from "base/utilities";
import {ETAPService} from "abastecemento/service";

import {ConexionEntitySummaryPanel} from "conexion/container";
import {conexionElementTypesConstants} from "conexion/model";
import {SectionField} from "base/component/presentational";
import {useConcelloAuth} from "concello/auth";

const ViewETAPPanel = ({allowConnectionManagement = false}) => {
    const {idEtap} = useParams();
    const navigate = useNavigate();
    const {checkAllowed} = useConcelloAuth();

    const handleClickDetail = etap => {
        navigate(`/concellos/${etap.concello}/etaps/${etap.id}/summary`);
    };

    const getSectionData = etap => {
        return (
            <>
                {etap.sistema && (
                    <SectionField
                        label="Sistema PMAA:"
                        value={etap.sistema_label}
                        linkPath={`/concellos/${etap.concello}/sistemas/${etap.sistema}/summary`}
                    />
                )}
                <SectionField
                    label="Abastecemento autónomo:"
                    value={TextUtil.formatBoolean(etap.abastecemento_autonomo)}
                />
                <SectionField label="Código:" value={etap.codigo} />
                <SectionField label="Titular:" value={etap.titular_label} />
                <SectionField label="Xestión:" value={etap.xestion_label} />
                <SectionField label="Estado:" value={etap.estado_label} />
            </>
        );
    };

    return (
        <ConexionEntitySummaryPanel
            service={ETAPService}
            id={idEtap}
            title="Resumo de ETAP"
            getSectionTitle={entity => `ETAP: ${entity?.codigo || entity?.id}`}
            getSectionData={getSectionData}
            showClickDetailButton={entity => checkAllowed(entity.concello)}
            onClickDetailButton={handleClickDetail}
            allowConnectionsAsOrigen={allowConnectionManagement}
            allowConnectionsAsDestino={allowConnectionManagement}
            conexionElementType={conexionElementTypesConstants.ETAP}
        />
    );
};

export default ViewETAPPanel;
