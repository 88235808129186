import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";

import {TanqueService} from "saneamiento/service";
import {tanque_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {TanqueForm} from "../presentational/form";

const UpdateTanquePanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let tanque;
    [tanque] = useOutletContext();

    const handleSubmit = tanque => {
        TanqueService.update(tanque_view_adapter({...tanque}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <TanqueForm
                    tanque={tanque}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar tanque"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateTanquePanel;
