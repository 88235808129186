import {NumberUtil} from "base/utilities";

export function useTanqueTable() {
    const tableColumns = [
        {
            id: "codigo",
            label: "Código",
            width: 17,
        },
        {
            id: "nome",
            label: "Nome",
            width: 10,
        },
        {
            id: "capacidade",
            label: "Capacidade (m³)",
            formatFunction: element => NumberUtil.formatDecimal(element.capacidade),
            width: 10,
        },
        {
            id: "telecontrol_label",
            label: "Telecontrol",
            width: 10,
        },
        {
            id: "potencia",
            label: "Potencia instalada (kW)",
            formatFunction: element => NumberUtil.formatDecimal(element.potencia),
            width: 9,
        },
        {
            id: "consumo_electrico",
            label: "Consumo eléctrico (kWh/ano)",
            formatFunction: element =>
                NumberUtil.formatDecimal(element.consumo_electrico),
            width: 9,
        },
        {
            id: "concello_label",
            label: "Concello",
            width: 10,
        },
        {
            id: "aglomeracion_label",
            label: "Aglomeración",
            width: 15,
        },
        {
            id: "bombeo_label",
            label: "Bombeo",
            width: 10,
        },
    ];

    return {tableColumns};
}
