import {useNavigate} from "react-router-dom";

import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";
import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaCapacitacionMediosSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`capacitacion_medios/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label:
                "Nº de técnicos/ responsables adscritos aos servizos de saneamento e depuración:",
            value: gobernanza.numero_tecnicos_label,
        },
        {
            label:
                "Os técnicos teñen una formación que os capacita para o seu cargo ou ben recibiron formación para o cargo que ostentan:",
            value: gobernanza.tecnicos_formacion_label,
        },
        {
            label: "Os roles e funcións do/s técnico/s están claramente definidos:",
            value: gobernanza.roles_definidos_label,
        },
        {
            label: "Existen mecanismos para suplir baixas/vacacións/turnos:",
            value: gobernanza.existe_mecanismos_suplir_baixas_tecnicos_label,
        },
        {
            label: "Nº de operarios dos servizos:",
            value: gobernanza.numero_operarios_label,
        },
        {
            label: "Os operarios están capacitados:",
            value: gobernanza.operarios_formacion_label,
        },
        {
            label: "Os roles e funcións do/s operario/s están claramente difinidos:",
            value: gobernanza.roles_definidos_operarios_label,
        },
        {
            label: "Existen mecanismos para suplir as baixas/vacacións/turnos:",
            value: gobernanza.existe_mecanismos_suplir_baixas_operarios_label,
        },
        {
            label: "Existen mecanismos de comunicación con medios doutros concellos:",
            value: gobernanza.existe_mecanismos_comunicacion_concellos_label,
        },
        {
            label: "Existen mecanismos de comunicación con medios supramunicipais:",
            value: gobernanza.existe_mecanismos_comunicacion_supramunicipais_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Capacitación e medios"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_capacitacion_medios}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_capacitacion_medios,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaCapacitacionMediosSection;
