import {createEntityService} from "base/entity/service";
import {
    createETAP,
    createETAPs,
    etap_api_adapter,
    etaps_api_adapter,
} from "abastecemento/model";

const entityService = createEntityService(
    "/api/infrastructures/etaps",
    createETAP,
    createETAPs,
    etap_api_adapter,
    etaps_api_adapter
);

const ETAPService = {
    getList(filter, sort, order, format = null) {
        return entityService.getList(filter, null, sort, order, format);
    },

    getPaginatedList(filter, page, sort, order) {
        return entityService.getList(filter, page, sort, order);
    },

    getFeatures(filter) {
        return entityService.getFeatures(filter);
    },

    getBySearchText(searchText) {
        return entityService.getBySearchText(searchText);
    },

    get(id) {
        return entityService.get(id);
    },

    create(etap) {
        return entityService.create(etap);
    },

    update(etap) {
        return entityService.update(etap);
    },

    updateWithPatch(etap) {
        return entityService.updateWithPatch(etap);
    },

    delete(id) {
        return entityService.delete(id);
    },

    approveChanges(id) {
        return entityService.approveChanges(id);
    },

    rejectChanges(id, comments) {
        return entityService.rejectChanges(id, comments);
    },
};

export default ETAPService;
