import {FormProvider, useForm} from "react-hook-form";
import {FormUtil} from "base/utilities";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {GobernanzaModificationForm} from ".";

const GobernanzaForm = ({
    gobernanza = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(gobernanza?.id, null),
        existe_regulamento_servizo: FormUtil.getFormValue(
            gobernanza?.existe_regulamento_servizo
        ),
        regulada_xestion_baleirado: FormUtil.getFormValue(
            gobernanza?.regulada_xestion_baleirado
        ),
        existe_mecanismos_coordinacion_areas: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_coordinacion_areas
        ),
        existe_mecanismos_coordinacion_outras: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_coordinacion_outras
        ),
        existe_mecanismos_coordinacion_supra: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_coordinacion_supra
        ),
        existe_planificacion_tarefas: FormUtil.getFormValue(
            gobernanza?.existe_planificacion_tarefas
        ),
        existe_planificacion_actuacions: FormUtil.getFormValue(
            gobernanza?.existe_planificacion_actuacions
        ),

        conhece_estado_masas: FormUtil.getFormValue(gobernanza?.conhece_estado_masas),
        sabe_causas_mal_estado: FormUtil.getFormValue(
            gobernanza?.sabe_causas_mal_estado
        ),
        sabe_obxectivos_vertido: FormUtil.getFormValue(
            gobernanza?.sabe_obxectivos_vertido
        ),
        ten_inventariados_alivios: FormUtil.getFormValue(
            gobernanza?.ten_inventariados_alivios
        ),
        realiza_control_alivios: FormUtil.getFormValue(
            gobernanza?.realiza_control_alivios
        ),
        realiza_xestion_ciclo_auga: FormUtil.getFormValue(
            gobernanza?.realiza_xestion_ciclo_auga
        ),
        compara_volumes_auga: FormUtil.getFormValue(gobernanza?.compara_volumes_auga),
        sabe_organismos_conca: FormUtil.getFormValue(gobernanza?.sabe_organismos_conca),
        existe_mecanismo_coordinacion_organismos: FormUtil.getFormValue(
            gobernanza?.existe_mecanismo_coordinacion_organismos
        ),

        politicas_urbanisticas: FormUtil.getFormValue(
            gobernanza?.politicas_urbanisticas
        ),
        politicas_limpeza: FormUtil.getFormValue(gobernanza?.politicas_limpeza),
        politicas_parques: FormUtil.getFormValue(gobernanza?.politicas_parques),

        numero_tecnicos: FormUtil.getFormValue(gobernanza?.numero_tecnicos),
        tecnicos_formacion: FormUtil.getFormValue(gobernanza?.tecnicos_formacion),
        roles_definidos: FormUtil.getFormValue(gobernanza?.roles_definidos),
        existe_mecanismos_suplir_baixas_tecnicos: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_suplir_baixas_tecnicos
        ),
        numero_operarios: FormUtil.getFormValue(gobernanza?.numero_operarios),
        operarios_formacion: FormUtil.getFormValue(gobernanza?.operarios_formacion),
        roles_definidos_operarios: FormUtil.getFormValue(
            gobernanza?.roles_definidos_operarios
        ),
        existe_mecanismos_suplir_baixas_operarios: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_suplir_baixas_operarios
        ),
        existe_mecanismos_comunicacion_concellos: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_comunicacion_concellos
        ),
        existe_mecanismos_comunicacion_supramunicipais: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_comunicacion_supramunicipais
        ),

        existe_registro_infraestructuras: FormUtil.getFormValue(
            gobernanza?.existe_registro_infraestructuras
        ),
        existe_información_xeorreferenciada: FormUtil.getFormValue(
            gobernanza?.existe_información_xeorreferenciada
        ),
        sabe_porcentaxe_poboacion: FormUtil.getFormValue(
            gobernanza?.sabe_porcentaxe_poboacion
        ),
        existe_rexistro_infraestructuras: FormUtil.getFormValue(
            gobernanza?.existe_rexistro_infraestructuras
        ),
        existe_balances_custos_ingresos: FormUtil.getFormValue(
            gobernanza?.existe_balances_custos_ingresos
        ),
        existe_rexistro_investimentos: FormUtil.getFormValue(
            gobernanza?.existe_rexistro_investimentos
        ),
        existe_mecanismo_xestion_datos: FormUtil.getFormValue(
            gobernanza?.existe_mecanismo_xestion_datos
        ),

        estudo_custos_explotacion: FormUtil.getFormValue(
            gobernanza?.estudo_custos_explotacion
        ),
        ordenanza_fiscal_ingresos: FormUtil.getFormValue(
            gobernanza?.ordenanza_fiscal_ingresos
        ),
        recuperacion_custos_explotacion: FormUtil.getFormValue(
            gobernanza?.recuperacion_custos_explotacion
        ),
        actualizacion_tarifas: FormUtil.getFormValue(gobernanza?.actualizacion_tarifas),
        recuperacion_custos_amortizacion: FormUtil.getFormValue(
            gobernanza?.recuperacion_custos_amortizacion
        ),
        recuperacion_custos_renovacion: FormUtil.getFormValue(
            gobernanza?.recuperacion_custos_renovacion
        ),
        diagnostico_estado_infraestructuras: FormUtil.getFormValue(
            gobernanza?.diagnostico_estado_infraestructuras
        ),
        existe_plan_investimento: FormUtil.getFormValue(
            gobernanza?.existe_plan_investimento
        ),

        regulamento_servizos_adaptado_decreto: FormUtil.getFormValue(
            gobernanza?.regulamento_servizos_adaptado_decreto
        ),
        expidense_permisos_decreto: FormUtil.getFormValue(
            gobernanza?.expidense_permisos_decreto
        ),

        realizanse_experiencias_piloto: FormUtil.getFormValue(
            gobernanza?.realizanse_experiencias_piloto
        ),
        fomentanse_mecanismos_cooperacion_innovadores: FormUtil.getFormValue(
            gobernanza?.fomentanse_mecanismos_cooperacion_innovadores
        ),
        fomentanse_ferramentas_innovadoras: FormUtil.getFormValue(
            gobernanza?.fomentanse_ferramentas_innovadoras
        ),

        existe_mecanismos_consulta: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_consulta
        ),
        contas_separadas: FormUtil.getFormValue(gobernanza?.contas_separadas),
        procedemento_reclamacion_regulado: FormUtil.getFormValue(
            gobernanza?.procedemento_reclamacion_regulado
        ),
        xestions_web: FormUtil.getFormValue(gobernanza?.xestions_web),
        facilitase_info_xeral: FormUtil.getFormValue(gobernanza?.facilitase_info_xeral),
        facilitase_info_especifica: FormUtil.getFormValue(
            gobernanza?.facilitase_info_especifica
        ),

        identificadas_partes_interesadas: FormUtil.getFormValue(
            gobernanza?.identificadas_partes_interesadas
        ),
        informacion_compartida: FormUtil.getFormValue(
            gobernanza?.informacion_compartida
        ),
        existe_mecanismos_involucrar_interesados: FormUtil.getFormValue(
            gobernanza?.existe_mecanismos_involucrar_interesados
        ),
        posible_visitas_instalacions: FormUtil.getFormValue(
            gobernanza?.posible_visitas_instalacions
        ),

        existe_medidas_acceso_vulnerables: FormUtil.getFormValue(
            gobernanza?.existe_medidas_acceso_vulnerables
        ),
        existe_tarifas_sociais: FormUtil.getFormValue(
            gobernanza?.existe_tarifas_sociais
        ),
        analizase_capacidade_pago_usuarios: FormUtil.getFormValue(
            gobernanza?.analizase_capacidade_pago_usuarios
        ),
        consideranse_disparidades_xeograficas_actuacions: FormUtil.getFormValue(
            gobernanza?.consideranse_disparidades_xeograficas_actuacions
        ),
        consideranse_disparidades_xeograficas_tarifas: FormUtil.getFormValue(
            gobernanza?.consideranse_disparidades_xeograficas_tarifas
        ),

        cumprimentase_formulario_anualmente: FormUtil.getFormValue(
            gobernanza?.cumprimentase_formulario_anualmente
        ),
        avalianse_resultados_anualmente: FormUtil.getFormValue(
            gobernanza?.avalianse_resultados_anualmente
        ),
        compartense_resultados: FormUtil.getFormValue(
            gobernanza?.compartense_resultados
        ),
        realizanse_axustes: FormUtil.getFormValue(gobernanza?.realizanse_axustes),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            existe_regulamento_servizo: FormUtil.getDataValue(
                data.existe_regulamento_servizo
            ),
            regulada_xestion_baleirado: FormUtil.getDataValue(
                data.regulada_xestion_baleirado
            ),

            existe_mecanismos_coordinacion_areas: FormUtil.getDataValue(
                data.existe_mecanismos_coordinacion_areas
            ),
            existe_mecanismos_coordinacion_outras: FormUtil.getDataValue(
                data.existe_mecanismos_coordinacion_outras
            ),
            existe_mecanismos_coordinacion_supra: FormUtil.getDataValue(
                data.existe_mecanismos_coordinacion_supra
            ),
            existe_planificacion_tarefas: FormUtil.getDataValue(
                data.existe_planificacion_tarefas
            ),
            existe_planificacion_actuacions: FormUtil.getDataValue(
                data.existe_planificacion_actuacions
            ),

            conhece_estado_masas: FormUtil.getDataValue(data.conhece_estado_masas),
            sabe_causas_mal_estado: FormUtil.getDataValue(data.sabe_causas_mal_estado),
            sabe_obxectivos_vertido: FormUtil.getDataValue(
                data.sabe_obxectivos_vertido
            ),
            ten_inventariados_alivios: FormUtil.getDataValue(
                data.ten_inventariados_alivios
            ),
            realiza_control_alivios: FormUtil.getDataValue(
                data.realiza_control_alivios
            ),
            realiza_xestion_ciclo_auga: FormUtil.getDataValue(
                data.realiza_xestion_ciclo_auga
            ),
            compara_volumes_auga: FormUtil.getDataValue(data.compara_volumes_auga),
            sabe_organismos_conca: FormUtil.getDataValue(data.sabe_organismos_conca),
            existe_mecanismo_coordinacion_organismos: FormUtil.getDataValue(
                data.existe_mecanismo_coordinacion_organismos
            ),

            politicas_urbanisticas: FormUtil.getDataValue(data.politicas_urbanisticas),
            politicas_limpeza: FormUtil.getDataValue(data.politicas_limpeza),
            politicas_parques: FormUtil.getDataValue(data.politicas_parques),

            numero_tecnicos: FormUtil.getDataValue(data.numero_tecnicos),
            tecnicos_formacion: FormUtil.getDataValue(data.tecnicos_formacion),
            roles_definidos: FormUtil.getDataValue(data.roles_definidos),
            existe_mecanismos_suplir_baixas_tecnicos: FormUtil.getDataValue(
                data.existe_mecanismos_suplir_baixas_tecnicos
            ),
            numero_operarios: FormUtil.getDataValue(data.numero_operarios),
            operarios_formacion: FormUtil.getDataValue(data.operarios_formacion),
            roles_definidos_operarios: FormUtil.getDataValue(
                data.roles_definidos_operarios
            ),
            existe_mecanismos_suplir_baixas_operarios: FormUtil.getDataValue(
                data.existe_mecanismos_suplir_baixas_operarios
            ),
            existe_mecanismos_comunicacion_concellos: FormUtil.getDataValue(
                data.existe_mecanismos_comunicacion_concellos
            ),
            existe_mecanismos_comunicacion_supramunicipais: FormUtil.getDataValue(
                data.existe_mecanismos_comunicacion_supramunicipais
            ),

            existe_registro_infraestructuras: FormUtil.getDataValue(
                data.existe_registro_infraestructuras
            ),
            existe_información_xeorreferenciada: FormUtil.getDataValue(
                data.existe_información_xeorreferenciada
            ),
            sabe_porcentaxe_poboacion: FormUtil.getDataValue(
                data.sabe_porcentaxe_poboacion
            ),
            existe_rexistro_infraestructuras: FormUtil.getDataValue(
                data.existe_rexistro_infraestructuras
            ),
            existe_balances_custos_ingresos: FormUtil.getDataValue(
                data.existe_balances_custos_ingresos
            ),
            existe_rexistro_investimentos: FormUtil.getDataValue(
                data.existe_rexistro_investimentos
            ),
            existe_mecanismo_xestion_datos: FormUtil.getDataValue(
                data.existe_mecanismo_xestion_datos
            ),

            estudo_custos_explotacion: FormUtil.getDataValue(
                data.estudo_custos_explotacion
            ),
            ordenanza_fiscal_ingresos: FormUtil.getDataValue(
                data.ordenanza_fiscal_ingresos
            ),
            recuperacion_custos_explotacion: FormUtil.getDataValue(
                data.recuperacion_custos_explotacion
            ),
            actualizacion_tarifas: FormUtil.getDataValue(data.actualizacion_tarifas),
            recuperacion_custos_amortizacion: FormUtil.getDataValue(
                data.recuperacion_custos_amortizacion
            ),
            recuperacion_custos_renovacion: FormUtil.getDataValue(
                data.recuperacion_custos_renovacion
            ),
            diagnostico_estado_infraestructuras: FormUtil.getDataValue(
                data.diagnostico_estado_infraestructuras
            ),
            existe_plan_investimento: FormUtil.getDataValue(
                data.existe_plan_investimento
            ),

            regulamento_servizos_adaptado_decreto: FormUtil.getDataValue(
                data.regulamento_servizos_adaptado_decreto
            ),
            expidense_permisos_decreto: FormUtil.getDataValue(
                data.expidense_permisos_decreto
            ),

            realizanse_experiencias_piloto: FormUtil.getDataValue(
                data.realizanse_experiencias_piloto
            ),
            fomentanse_mecanismos_cooperacion_innovadores: FormUtil.getDataValue(
                data.fomentanse_mecanismos_cooperacion_innovadores
            ),
            fomentanse_ferramentas_innovadoras: FormUtil.getDataValue(
                data.fomentanse_ferramentas_innovadoras
            ),

            existe_mecanismos_consulta: FormUtil.getDataValue(
                data.existe_mecanismos_consulta
            ),
            contas_separadas: FormUtil.getDataValue(data.contas_separadas),
            procedemento_reclamacion_regulado: FormUtil.getDataValue(
                data.procedemento_reclamacion_regulado
            ),
            xestions_web: FormUtil.getDataValue(data.xestions_web),
            facilitase_info_xeral: FormUtil.getDataValue(data.facilitase_info_xeral),
            facilitase_info_especifica: FormUtil.getDataValue(
                data.facilitase_info_especifica
            ),

            identificadas_partes_interesadas: FormUtil.getDataValue(
                data.identificadas_partes_interesadas
            ),
            informacion_compartida: FormUtil.getDataValue(data.informacion_compartida),
            existe_mecanismos_involucrar_interesados: FormUtil.getDataValue(
                data.existe_mecanismos_involucrar_interesados
            ),
            posible_visitas_instalacions: FormUtil.getDataValue(
                data.posible_visitas_instalacions
            ),

            existe_medidas_acceso_vulnerables: FormUtil.getDataValue(
                data.existe_medidas_acceso_vulnerables
            ),
            existe_tarifas_sociais: FormUtil.getDataValue(data.existe_tarifas_sociais),
            analizase_capacidade_pago_usuarios: FormUtil.getDataValue(
                data.analizase_capacidade_pago_usuarios
            ),
            consideranse_disparidades_xeograficas_actuacions: FormUtil.getDataValue(
                data.consideranse_disparidades_xeograficas_actuacions
            ),
            consideranse_disparidades_xeograficas_tarifas: FormUtil.getDataValue(
                data.consideranse_disparidades_xeograficas_tarifas
            ),

            cumprimentase_formulario_anualmente: FormUtil.getDataValue(
                data.cumprimentase_formulario_anualmente
            ),
            avalianse_resultados_anualmente: FormUtil.getDataValue(
                data.avalianse_resultados_anualmente
            ),
            compartense_resultados: FormUtil.getDataValue(data.compartense_resultados),
            realizanse_axustes: FormUtil.getDataValue(data.realizanse_axustes),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>
                {updatedSection ? (
                    <EntityForm
                        onSubmit={formMethods.handleSubmit(onFormSubmit)}
                        onCancel={onFormCancel}
                    >
                        <GobernanzaModificationForm section={updatedSection} />
                    </EntityForm>
                ) : null}
            </FormProvider>
        </DomainProvider>
    );
};

export default GobernanzaForm;
