import {useNavigate} from "react-router-dom";
import {useGobernanzaEvaluation} from "saneamiento/gobernanza/hooks/GobernanzaEvaluationHook";

import {
    SectionCardHeaderAction,
    ItemsWithChipList,
} from "base/component/presentational";

import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const GobernanzaIntegridadeSection = ({gobernanza}) => {
    const navigate = useNavigate();

    const {getPrincipiosEvaluationColor} = useGobernanzaEvaluation();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`integridade/edit`);
            }}
            roles={[]}
        />,
    ];

    const listItems = [
        {
            label: "Existen mecanismos de consulta dos custos e investimentos:",
            value: gobernanza.existe_mecanismos_consulta_label,
        },
        {
            label: "Están separadas as contas dos servizos das do resto:",
            value: gobernanza.contas_separadas_label,
        },
        {
            label: "Está regulado o procedemento de reclamación:",
            value: gobernanza.procedemento_reclamacion_regulado_label,
        },
        {
            label: "Xestións na web:",
            value: gobernanza.xestions_web_label,
        },
        {
            label: "Facilítase información xeral dos servizos na web:",
            value: gobernanza.facilitase_info_xeral_label,
        },
        {
            label: "Facilítase información específica dos servizos aos abonados:",
            value: gobernanza.facilitase_info_especifica_label,
        },
    ];

    return (
        <SisbagalSectionCard
            title="Integridade e transparencia"
            secondaryActions={secondaryActions}
        >
            <ItemsWithChipList
                listItems={listItems}
                chipLabel={`Total de puntos: ${gobernanza.total_integridade}`}
                chipColor={getPrincipiosEvaluationColor(
                    gobernanza.total_integridade,
                    "medium"
                )}
            />
        </SisbagalSectionCard>
    );
};

export default GobernanzaIntegridadeSection;
