import {useDomain} from "domain/provider";
import {FormSelect} from "base/component/form";

import Grid from "@mui/material/Grid";

const EDARFormOutrasInstalacionsFields = ({layout = "row"}) => {
    const {dominioedartomamostras, dominiosino, dominioedarmedidacaudal} = useDomain();

    // TO-DO: Implement options & value name for punto_vertido

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="suministro_electrico"
                    label="Suministro eléctrico"
                    options={dominiosino}
                    tooltipText="Existencia de acometida eléctrica"
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelect
                    name="arqueta_mostras"
                    label="Arqueta tomamostras"
                    options={dominioedartomamostras}
                    tooltipText="Existencia de arqueta para a toma de mostras de análise na planta"
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelect
                    name="caudal_entrada"
                    label="Medición caudais entrada"
                    options={dominioedarmedidacaudal}
                    tooltipText="Existencia de sistema de medición de caudais na saída da planta"
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelect
                    name="caudal_saida"
                    label="Medición caudais saída"
                    options={dominioedarmedidacaudal}
                    tooltipText="Existencia de sistema de medición de caudais na entrada da planta"
                />
            </Grid>
            <Grid item xs={12} md={6} sx={{width: "100%"}}>
                <FormSelect
                    name="telecontrol"
                    label="Telecontrol"
                    options={dominiosino}
                />
            </Grid>
        </Grid>
    );
};

export default EDARFormOutrasInstalacionsFields;
