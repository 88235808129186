import {useState} from "react";
import {Outlet} from "react-router-dom";

import {SidebarPanelDrawer} from "base/component/presentational";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
    NO_CONTENT_HEIGHT,
    PAGE_MENU_DRAWER_WIDTH,
    SIDEBAR_PANEL_DRAWER_WIDTH,
} from "base/config/measurements";

const PageLayout = ({
    children = null,
    menu = null,
    context = [],
    subPage = false,
    disablePadding = false,
}) => {
    const [isSidebarPanelOpen, setSidebarPanelOpen] = useState(false);

    const menuWidth = menu || subPage ? PAGE_MENU_DRAWER_WIDTH : 0;
    const pageWidth = isSidebarPanelOpen
        ? menuWidth + SIDEBAR_PANEL_DRAWER_WIDTH + 25
        : menuWidth + 15;

    return (
        <Stack direction="row">
            {menu}
            <Box
                sx={{
                    p: subPage && !disablePadding ? 1 : "unset",
                    width: "100%",
                    minHeight: `calc(100vh - ${NO_CONTENT_HEIGHT}px)`,
                    maxWidth: `calc(100vw - ${pageWidth}px)`,
                }}
            >
                {children ? (
                    children
                ) : (
                    <Outlet
                        context={[...context, isSidebarPanelOpen, setSidebarPanelOpen]}
                    />
                )}
            </Box>
            {children && (
                <SidebarPanelDrawer isSidebarPanelOpen={isSidebarPanelOpen}>
                    <Outlet context={[...context, setSidebarPanelOpen]} />
                </SidebarPanelDrawer>
            )}
        </Stack>
    );
};

export default PageLayout;
