import {useOutletContext} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {VertidoGeneralDataSection} from "../presentational/section";
import {UpdateVertidoGeoPanel} from ".";

const ViewVertidoSummarySubPage = () => {
    let vertido;
    [vertido] = useOutletContext();

    const sections = [
        <VertidoGeneralDataSection vertido={vertido} />,
        <UpdateVertidoGeoPanel />,
        <EntityAuditSection entity={vertido} />,
    ];

    return (
        vertido && <EntityViewSubPage sections={sections} service={VertidoService} />
    );
};

export default ViewVertidoSummarySubPage;
