import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    EDARCargasDomesticaSection,
    EDARCargasNonDomesticaSection,
    EDARCargasTotalSection,
} from "../presentational/section";

const ViewEDARCargasSubPage = () => {
    let edar;
    [edar] = useOutletContext();

    const sections = [
        <EDARCargasTotalSection edar={edar} />,
        <EDARCargasDomesticaSection edar={edar} />,
        <EDARCargasNonDomesticaSection edar={edar} />,
    ];

    return edar && <EntityViewSubPage sections={sections} />;
};

export default ViewEDARCargasSubPage;
