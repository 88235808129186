import {useOutletContext} from "react-router-dom";
import {EntityLocationSection, EntityViewSubPage} from "base/entity";

const ViewPoligonoLocationSubPage = () => {
    let poligono;
    [poligono] = useOutletContext();

    const sections = [<EntityLocationSection geom={poligono.geom} />];

    return poligono && <EntityViewSubPage sections={sections} />;
};

export default ViewPoligonoLocationSubPage;
