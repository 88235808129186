import {useEffect, useState} from "react";

import {StatsService} from "stats/service";
import {DoughnutChart} from "base/component/chart";

import CircularProgress from "@mui/material/CircularProgress";
import {useStatsConfig} from "stats/provider";
import useInfrastructureStatsCountConfig from "./InfrastructuresStatsCountConfig";

const findLegendColor = (legendItems, label) => {
    return legendItems.find(legendItem => legendItem.label === label)?.color;
};

const ViewStatsInfrastructuresCountChart = ({statsFilter}) => {
    const {getInfrastructureTypeConfig} = useInfrastructureStatsCountConfig();
    const chartStatsConfig = getInfrastructureTypeConfig(
        statsFilter.infrastructure_type
    );

    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (statsFilter) {
            setLoading(true);
            setChartData(null);
            StatsService.getInfrastructuresCountData(statsFilter).then(data => {
                setLoading(false);
                const orderedData = chartStatsConfig.legendItems
                    .map(legendItem =>
                        data.find(labelResult => labelResult.label === legendItem.label)
                    )
                    .filter(n => n); // Remove nulls
                setChartData(orderedData);
            });
        }
    }, [statsFilter]);

    const getChartLabels = chartData => {
        return chartData.map(item => item.label);
    };

    const getChartColors = chartData => {
        return chartData.map(item =>
            findLegendColor(chartStatsConfig.legendItems, item.label)
        );
    };

    const getChartDataset = chartData => {
        const data = chartData.map(item => item.value);
        return [
            {
                label: chartStatsConfig.title,
                data,
                backgroundColor: getChartColors(chartData),
                borderWidth: 1,
            },
        ];
    };

    return loading ? (
        <CircularProgress size={20} sx={{ml: 1, mt: 1}} />
    ) : (
        chartData && (
            <DoughnutChart
                labels={getChartLabels(chartData)}
                datasets={getChartDataset(chartData)}
                title={chartStatsConfig.title}
            />
        )
    );
};
export default ViewStatsInfrastructuresCountChart;
