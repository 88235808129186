import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SistemaExplotacionDatosDescargaDomesticaSection,
    SistemaExplotacionDatosDescargaUrbanaSection,
    SistemaExplotacionDatosNonDescargaDomesticaSection,
} from "../presentational";

const ViewSistemaExplotacionDatosDescargaSubPage = () => {
    let sistemaExplotacion;
    [sistemaExplotacion] = useOutletContext();

    const sections = [
        <SistemaExplotacionDatosDescargaDomesticaSection
            sistemaExplotacion={sistemaExplotacion}
        />,
        <SistemaExplotacionDatosNonDescargaDomesticaSection
            sistemaExplotacion={sistemaExplotacion}
        />,
        <SistemaExplotacionDatosDescargaUrbanaSection
            sistemaExplotacion={sistemaExplotacion}
        />,
    ];

    return sistemaExplotacion && <EntityViewSubPage sections={sections} />;
};

export default ViewSistemaExplotacionDatosDescargaSubPage;
