import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {PoligonoService} from "saneamiento/service";
import {usePoligonoData} from "../hooks/PoligonoData";
import {PageLayout} from "base/layout";
import {PoligonoSubPageMenu} from "../menu";
import {useAppConfig} from "base/provider";
import {EntityPrintPDFAction} from "base/entity";

const ViewPoligonoPage = () => {
    const {setSubpagePath, setModuleActions} = useAppConfig();

    const {idPoligono} = useParams();
    const [poligono, setPoligono] = useState(null);
    const location = useLocation();
    const {getEntityData} = usePoligonoData();

    useEffect(() => {
        PoligonoService.get(idPoligono).then(data => {
            setPoligono(data);
            setSubpagePath([
                {name: "Listado de Polígonos", path: "poligonos"},
                {
                    name: data.nome || data.codigo || data.id,
                    path: `${data.id}/summary`,
                },
            ]);
            setModuleActions([
                <EntityPrintPDFAction entityData={getEntityData(data)} />,
            ]);
        });
        return () => {
            setPoligono(null);
            setSubpagePath([]);
            setModuleActions([]);
        };
    }, [idPoligono, location.state?.lastRefreshDate]);

    return (
        poligono && (
            <PageLayout
                menu={<PoligonoSubPageMenu poligono={poligono} />}
                context={[poligono]}
                subPage={true}
            />
        )
    );
};

export default ViewPoligonoPage;
