import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    BombeoCaracteristicasSection,
    BombeoExplotacionSection,
    BombeoElementosSection,
} from "../presentational/section";

const ViewBombeoDetailSubPage = () => {
    let bombeo;
    [bombeo] = useOutletContext();

    const sections = [
        <BombeoCaracteristicasSection bombeo={bombeo} />,
        <BombeoElementosSection bombeo={bombeo} />,
        <BombeoExplotacionSection bombeo={bombeo} />,
    ];

    return bombeo && <EntityViewSubPage sections={sections} />;
};

export default ViewBombeoDetailSubPage;
