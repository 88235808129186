import {EntityLocationSection} from "base/entity";
import {usePoligonoMap} from "saneamiento/poligono/hooks";

const PoligonoLocationSection = ({poligono}) => {
    const {mapConfig} = usePoligonoMap(poligono);

    return <EntityLocationSection entity={poligono} mapConfig={mapConfig} />;
};

export default PoligonoLocationSection;
