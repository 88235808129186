import {SubPageMenu} from "base/layout";
import {SubPageMenuListItemButton} from "base/component/presentational";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

const PerdidasSubPageMenu = ({perdidas}) => {
    return (
        <SubPageMenu headingPrimaryText="Datos de indicadores">
            <SubPageMenuListItemButton
                to="perdidas"
                text="Pérdidas"
                icon={<AssignmentOutlinedIcon />}
                key="perdidas"
            />
        </SubPageMenu>
    );
};

export default PerdidasSubPageMenu;
