//DateUtil

export const DATE_FORMATS = {
    CLIENT_DATEFORMAT: "dd/MM/yyyy",
    CLIENT_DATEMONTHFORMAT: "MM/yyyy",
    CLIENT_DATETIMEFORMAT: "dd/MM/yyyy HH:mm",
    CLIENT_TIMEFORMAT: "HH:mm",
    FILENAME_DATETIMEFORMAT: "yyyyMMddHHmmss",
    API_DATEFORMAT: "yyyy-MM-dd",
};

export {es as USED_LOCALE} from "date-fns/locale";

// NumberUtil

export const locale = "es-ES";

export const localCurrencyFormatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
});
export const localIntegerFormatter = new Intl.NumberFormat(locale);
export const localDecimalFormatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
