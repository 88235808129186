import {useNavigate} from "react-router-dom";
import {NumberUtil} from "base/utilities";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const TanqueCaracteristicasSection = ({tanque}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`caracteristicas/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Características"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={6}>
                    <SectionField
                        label="Capacidade:"
                        value={NumberUtil.formatDecimal2(tanque.capacidade)}
                        unit="m³"
                        tooltipText="Capacidade de almacenamento/retención"
                    />
                    <SectionField
                        label="Regulación:"
                        value={tanque.regulacion_label}
                        tooltipText="Sistema de regulación do valeirado do tanque"
                    />
                    <SectionField
                        label="Bombeo:"
                        value={tanque.bombeo_label}
                        linkPath={`/concellos/${tanque.concello}/bombeos/${tanque.bombeo}/summary`}
                        tooltipText="Bombeo valeirado"
                    />
                    <SectionField
                        label="Pretratamento:"
                        value={tanque.pretratamiento_label}
                        tooltipText="Sistema de pretratamento instalado"
                    />
                    <SectionField
                        label="Tratamento aliviadoiro:"
                        value={tanque.tratamento_aliviadoiro_label}
                        tooltipText="Sistema de tratamento no aliviadoiro"
                    />
                </Grid>
                <Grid item xs={6}>
                    <SectionField
                        label="Limpeza:"
                        value={tanque.sistema_limpeza_label}
                        tooltipText="Sistema de limpeza instalado"
                    />
                    <SectionField
                        label="Telecontrol:"
                        value={tanque.telecontrol_label}
                        tooltipText="Sistema de telecontrol"
                    />
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal2(tanque.potencia)}
                        unit="kW"
                        tooltipText="Potencia eléctrica instalada no tanque (sen incluir o sistema de bombeo)"
                    />
                    <SectionField
                        label="Consumo eléctrico:"
                        value={NumberUtil.formatDecimal2(tanque.consumo_electrico)}
                        unit="kWh/ano"
                        tooltipText="Consumo eléctrico anual medio dos últimos 5 anos"
                    />
                    <SectionField label="Problemas:" value={tanque.problemas_label} />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default TanqueCaracteristicasSection;
