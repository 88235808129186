import {useDomain} from "domain/provider";
import {FormSelectOffsetLabel} from "base/component/form";
import Grid from "@mui/material/Grid";

const GobernanzaFormFinanciamentoFields = ({layout = "row"}) => {
    const {dominiogobernanzasino, dominiogobernanzaporcentaxe} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="estudo_custos_explotacion"
                    label="Realízase periodicamente un estudo dos custos de explotación"
                    options={dominiogobernanzasino}
                    tooltipText="En que grao se recuperan mediante as tarifas os custos asociados á amortización das infraestruturas adscritas aos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="ordenanza_fiscal_ingresos"
                    label="Existe unha ordenanza fiscal dos servizos que regula os ingresos"
                    options={dominiogobernanzasino}
                    tooltipText="Existe unha ordenanza fiscal na que establezan os prezos que os abonados teñen que pagar polos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="actualizacion_tarifas"
                    label="Actualízanse anualmente as tarifas a cobrar polos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Cosiderado que os custos relacionados cos servizos se van incrementando anualmente, os ingresos deberían actualizarse na mesma proporción, co obxecto de no reducir a calidade do servizo."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="recuperacion_custos_explotacion"
                    label="A recuperación dos custos de explotación é (%)"
                    options={dominiogobernanzaporcentaxe}
                    tooltipText="En qué grao se recuperan mediante as tarifas os custos de explotación ordinaria dos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="recuperacion_custos_amortizacion"
                    label="A recuperación dos custos de amortización é (%)"
                    options={dominiogobernanzaporcentaxe}
                    tooltipText="En que grao se recuperan mediante as tarifas os custos asociados á amortización das infraestruturas adscritas aos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="recuperacion_custos_renovacion"
                    label="A recuperación dos custos de renovación/novas actuacións é (%)"
                    options={dominiogobernanzaporcentaxe}
                    tooltipText="En qué grao se recuperan mediante as tarifas os custos asociados á renovación das infraestruturas adscritas aos servizos, considerando que ao final da súa vida útil haberá que repoñelas."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="diagnostico_estado_infraestructuras"
                    label="Realízase un diagnóstico periódico do estado das infraestruras"
                    options={dominiogobernanzasino}
                    tooltipText="Realízase un diagnóstico continuo/periódico do estado das infraestruras adscritas aos servizos, que xustifique os investimentos necesarios nos servizos."
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSelectOffsetLabel
                    name="existe_plan_investimento"
                    label="Existe un plan de investimentos nos servizos"
                    options={dominiogobernanzasino}
                    tooltipText="Fundamentado nun diagnóstico previo do estado dos servizos, indicando os importes necesarios das actuacións, planificando no tempo as mesmas e asignando os responsables do seu financiamento."
                />
            </Grid>
        </Grid>
    );
};

export default GobernanzaFormFinanciamentoFields;
