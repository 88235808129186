import {useDepositoMap} from "abastecemento/deposito/hooks";
import {EntityLocationSection} from "base/entity";

const DepositoLocationSection = ({deposito}) => {
    const {mapConfig} = useDepositoMap();

    return <EntityLocationSection entity={deposito} mapConfig={mapConfig} />;
};

export default DepositoLocationSection;
