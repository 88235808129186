import {FieldUtil, NumberUtil} from "base/utilities";
import {useNucleoAglomeracionTable} from "nucleo/hooks";
import {NucleoService} from "nucleo/service";
import {SubaglomeracionService} from "saneamiento/service";
import {useSubaglomeracionAglomeracionTable} from "saneamiento/subaglomeracion/hooks";

export function useAglomeracionData(aglomeracion) {
    const {
        tableColumns: subaglomeracionColumns,
    } = useSubaglomeracionAglomeracionTable();
    const {tableColumns: nucleoColumns} = useNucleoAglomeracionTable();

    const getEntityData = aglomeracion => {
        return {
            name: `Aglomeración ${aglomeracion?.nome || aglomeracion?.codigo}`,
            sections: [
                {
                    head: "Principal",
                    content: [
                        ["Código:", FieldUtil.getValue(aglomeracion.codigo)],
                        ["Nome:", FieldUtil.getValue(aglomeracion.nome)],
                        [
                            "Código EDAR:",
                            FieldUtil.getValue(aglomeracion.stats.codigoedar),
                        ],
                        ["Nome EDAR:", FieldUtil.getValue(aglomeracion.stats.nomeedar)],
                        [
                            "Tratamento:",
                            FieldUtil.getValue(aglomeracion.stats.tratamento_label),
                        ],
                    ],
                },
                {
                    head: "Territorio",
                    content: [
                        [
                            "Superficie:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(aglomeracion.stats.superficie)
                            )} Ha.`,
                        ],
                        [
                            "Núcleos:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    aglomeracion.stats.nucleosconectados
                                )
                            ),
                        ],
                        [
                            "Superficie industrial:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.superficieindustrial
                                )
                            )} Ha.`,
                        ],
                        [
                            "Densidade:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(aglomeracion.stats.densidade)
                            )} Heq/Ha`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Variabilidade",
                    content: [
                        [
                            "Superficie:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.estacionalidade,
                                    1
                                )
                            )} %`,
                        ],
                        [
                            "Crecemento:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(aglomeracion.stats.crecemento)
                            )} %`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Total",
                    content: [
                        [
                            "Total:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.cargatotal
                                )
                            )} Heq`,
                        ],
                        [
                            "Urbana:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.cargaurbana
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Total",
                    content: [
                        [
                            "Doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.cargadomestica
                                )
                            )} Heq`,
                        ],
                        [
                            "Non doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.carganondomestica
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Doméstica",
                    content: [
                        [
                            "Fixa:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.cargadomesticafixa
                                )
                            )} Heq`,
                        ],
                        [
                            "Estacional:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.cargadomesticaestacional
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Conectada > Non doméstica",
                    content: [
                        [
                            "Industrial:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.carganondomesticaindustrial
                                )
                            )} Heq`,
                        ],
                        [
                            "Outras non domésticas:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.carganondomesticaoutros
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Non conectada",
                    content: [
                        [
                            "Industrial:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.domesticaautonomo
                                )
                            )} Heq`,
                        ],
                        [
                            "Sen depuración:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimalAsInteger(
                                    aglomeracion.stats.domesticasentratamento
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Explotación",
                    content: [
                        [
                            "Mantemento:",
                            FieldUtil.getValue(aglomeracion.stats.mantemento),
                        ],
                        [
                            "Telecontrol:",
                            FieldUtil.getValue(aglomeracion.stats.telecontrol),
                        ],
                        [
                            "Medición alivios:",
                            FieldUtil.getValue(aglomeracion.stats.medicionalivios),
                        ],
                        [
                            "Desbordamentos:",
                            FieldUtil.getValue(aglomeracion.stats.desbordamentos),
                        ],
                        [
                            "Reutilización de auga:",
                            FieldUtil.getValue(aglomeracion.stats.reutilizacion),
                        ],
                    ],
                },
                {
                    head: "Inventario > Total",
                    content: [
                        [
                            "Lonxitude rede:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.lonxitudecolector,
                                    3
                                )
                            )} Km.`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.potenciainstalada
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.consumoelectrico
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Bombeos",
                    content: [
                        [
                            "Totais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(aglomeracion.stats.bombeos)
                            )}`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.potenciabombeo
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.consumoelectricobombeo
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Tanques",
                    content: [
                        [
                            "Totais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(aglomeracion.stats.tanques)
                            )}`,
                        ],
                        [
                            "Volume:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.capacidadetanque
                                )
                            )} Kw`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.potenciainstaladatanque
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.consumoelectricotanque
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > EDAR",
                    content: [
                        [
                            "Antigüidade:",
                            `${FieldUtil.getValue(aglomeracion.stats.antiguidadeedar)}`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.potenciainstaladaedar
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.consumoelectricoedar
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Colectores",
                    content: [
                        [
                            "Antigüidade:",
                            `${FieldUtil.getValue(aglomeracion.stats.antiguidaderede)}`,
                        ],
                        [
                            "Tipoloxia de rede > Fecais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.naturezafecais,
                                    1
                                )
                            )} %`,
                        ],
                        [
                            "Tipoloxia de rede > Pluviais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.naturezapluviais,
                                    1
                                )
                            )} %`,
                        ],
                        [
                            "Tipoloxia de rede > Unitaria:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.naturezaunitarios,
                                    1
                                )
                            )} %`,
                        ],
                        [
                            "Materiais > Plásticos:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(aglomeracion.stats.plastico, 1)
                            )} %`,
                        ],
                        [
                            "Materiais > Formigón:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(aglomeracion.stats.formigon, 1)
                            )} %`,
                        ],
                        [
                            "Materiais > Fibrocemento:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    aglomeracion.stats.fibrocemento,
                                    1
                                )
                            )} %`,
                        ],
                    ],
                },
            ],
            serviceTableEntities: [
                {
                    columns: subaglomeracionColumns,
                    service: () => {
                        return SubaglomeracionService.getStatsList({
                            aglomeracion: aglomeracion.id,
                        });
                    },
                    title: "Sistemas colectores",
                },
                {
                    columns: nucleoColumns,
                    service: () => {
                        return NucleoService.getStatsList({
                            aglomeracion: aglomeracion.id,
                        });
                    },
                    title: "Núcleos - Conexión á rede",
                },
            ],
        };
    };

    return {getEntityData};
}
