import {FormProvider, useForm} from "react-hook-form";
import {FormUtil} from "base/utilities";

import {DomainProvider} from "domain/provider";
import {EntityForm} from "base/component/form";

import {BombeoCreationForm, BombeoModificationForm, BombeoGeoForm} from ".";

const BombeoForm = ({
    bombeo = null,
    concello = null,
    onSubmit,
    onCancel = null,
    updatedSection = null,
}) => {
    const defaultFormValues = {
        id: FormUtil.getFormValue(bombeo?.id),
        codigo: FormUtil.getFormValue(bombeo?.codigo),
        nome: FormUtil.getFormValue(bombeo?.nome),
        notas: FormUtil.getFormValue(bombeo?.notas),
        potencia: FormUtil.getFormValue(bombeo?.potencia),
        consumo_electrico: FormUtil.getFormValue(bombeo?.consumo_electrico),
        caudal_bombeo: FormUtil.getFormValue(bombeo?.caudal_bombeo),
        altura_bombeo: FormUtil.getFormValue(bombeo?.altura_bombeo),
        bombas_activas: FormUtil.getFormValue(bombeo?.bombas_activas),
        bombas_reserva: FormUtil.getFormValue(bombeo?.bombas_reserva),
        pretratamiento: FormUtil.getFormValue(bombeo?.pretratamiento, []),
        telecontrol: FormUtil.getFormValue(bombeo?.telecontrol),
        proteccion: FormUtil.getFormValue(bombeo?.proteccion, []),
        problemas: FormUtil.getFormValue(bombeo?.problemas, []),
        horas_funcionamento_diarias: FormUtil.getFormValue(
            bombeo?.horas_funcionamento_diarias
        ),
        caudal_medio_anual: FormUtil.getFormValue(bombeo?.caudal_medio_anual),
        // we must create an object with the same representation as expected search results
        aglomeracion: bombeo?.aglomeracion
            ? {id: bombeo.aglomeracion, nome: bombeo?.aglomeracion_label}
            : null,
        concello: bombeo?.concello
            ? {codigo: bombeo.concello, nome: bombeo?.concello_label}
            : {codigo: concello, nome: ""},
        geom: FormUtil.getFormValue(bombeo?.geom),
    };

    const formMethods = useForm({
        defaultValues: defaultFormValues,
        reValidateMode: "onSubmit",
    });

    const onFormSubmit = data => {
        onSubmit({
            id: FormUtil.getDataValue(data.id),
            codigo: FormUtil.getDataValue(data.codigo),
            nome: FormUtil.getDataValue(data.nome),
            notas: FormUtil.getDataValue(data.notas),
            potencia: FormUtil.getDataValue(data.potencia),
            consumo_electrico: FormUtil.getDataValue(data.consumo_electrico),
            caudal_bombeo: FormUtil.getDataValue(data.caudal_bombeo),
            altura_bombeo: FormUtil.getDataValue(data.altura_bombeo),
            bombas_activas: FormUtil.getDataValue(data.bombas_activas),
            bombas_reserva: FormUtil.getDataValue(data.bombas_reserva),
            pretratamiento: FormUtil.getDataValue(data.pretratamiento),
            proteccion: FormUtil.getDataValue(data.proteccion),
            telecontrol: FormUtil.getDataValue(data.telecontrol),
            problemas: FormUtil.getDataValue(data.problemas),
            horas_funcionamento_diarias: FormUtil.getDataValue(
                data.horas_funcionamento_diarias
            ),
            caudal_medio_anual: FormUtil.getDataValue(data.caudal_medio_anual),
            aglomeracion: FormUtil.getDataValue(data.aglomeracion?.id),
            concello: FormUtil.getDataValue(data.concello?.codigo),
            geom: FormUtil.getDataValue(data.geom),
        });
    };

    const onFormCancel = () => {
        onCancel();
    };

    const getComponent = () => {
        if (updatedSection === "geo") {
            return (
                <BombeoGeoForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                />
            );
        }
        if (updatedSection) {
            return (
                <EntityForm
                    onSubmit={formMethods.handleSubmit(onFormSubmit)}
                    onCancel={onFormCancel}
                >
                    <BombeoModificationForm section={updatedSection} />
                </EntityForm>
            );
        }
        return (
            <BombeoCreationForm
                onSubmit={formMethods.handleSubmit(onFormSubmit)}
                onCancel={onFormCancel}
            />
        );
    };

    return (
        <DomainProvider>
            <FormProvider {...formMethods}>{getComponent()}</FormProvider>
        </DomainProvider>
    );
};

export default BombeoForm;
