import {useAuth} from "base/auth";
import {AppMenu} from "base/layout";
import {AccountMenu} from "user/component/presentational";
import {SisbagalHelp, SisbagalMenuSearch, SisbagalSectorSelector} from ".";

import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

const SisbagalMenu = () => {
    const {ROLES, hasRole} = useAuth();

    let menuItems = [
        {
            name: "Concellos",
            to: "concellos",
            pathname: "concellos",
        },
        {
            name: "Aglomeracións",
            to: "aglomeracions",
            pathname: "aglomeracions",
        },
    ];
    if (!hasRole(ROLES.CONCELLO)) {
        menuItems = [
            ...menuItems,
            {
                name: "Medio",
                to: "medioreceptor",
                pathname: "medioreceptor",
            },
            {
                name: "Estatísticas",
                to: "stats",
                pathname: "stats",
            },
        ];
    }

    return (
        <AppMenu menuItems={menuItems}>
            <SisbagalMenuSearch />
            <SisbagalSectorSelector />
            <AccountMenu />
            <SisbagalHelp />
        </AppMenu>
    );
};

export default SisbagalMenu;
