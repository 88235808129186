import {useState} from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import {VertidoService} from "saneamiento/service";
import {vertido_view_adapter} from "saneamiento/model";
import {useNavigateWithReload} from "base/hooks";
import {EntityUpdatePanel} from "base/entity";
import {VertidoForm} from "../presentational/form";

const UpdateVertidoPanel = () => {
    const {section} = useParams();
    const location = useLocation();
    const baseLocationPath = location.pathname.split(section)[0];

    const [error, setError] = useState("");
    const navigate = useNavigateWithReload();

    let vertido;
    [vertido] = useOutletContext();

    const handleSubmit = vertido => {
        VertidoService.update(vertido_view_adapter({...vertido}))
            .then(() => {
                navigate(baseLocationPath, true);
            })
            .catch(error => {
                setError(error);
            });
    };

    const handleFormCancel = () => {
        navigate(baseLocationPath);
    };

    return (
        <EntityUpdatePanel
            form={
                <VertidoForm
                    vertido={vertido}
                    updatedSection={section}
                    onSubmit={handleSubmit}
                    onCancel={handleFormCancel}
                />
            }
            title="Modificar Vertido"
            error={error}
            onCancel={handleFormCancel}
        />
    );
};

export default UpdateVertidoPanel;
