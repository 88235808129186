import {FieldUtil, NumberUtil} from "base/utilities";
import {useNucleoConcelloTable} from "nucleo/hooks";
import {NucleoService} from "nucleo/service";
import {useEDARConcelloTable} from "saneamiento/edar/hooks";
import {EDARService, SubaglomeracionService} from "saneamiento/service";
import {useSubaglomeracionConcelloTable} from "saneamiento/subaglomeracion/hooks";

export function useConcelloData(concello) {
    const {tableColumns: subaglomeracionColumns} = useSubaglomeracionConcelloTable();
    const {tableColumns: nucleoColumns} = useNucleoConcelloTable();
    const {tableColumns: edarColumns} = useEDARConcelloTable();

    const getEntityData = concello => {
        return {
            name: `Concello ${concello?.nome}`,
            sections: [
                {
                    head: "Principal",
                    content: [
                        ["Provincia:", FieldUtil.getValue(concello.stats.provincia)],
                        ["Comarca:", FieldUtil.getValue(concello.stats.comarca)],
                        ["Código:", FieldUtil.getValue(concello.stats.codigo)],
                        [
                            "Superficie:",
                            `${NumberUtil.formatDecimal(
                                FieldUtil.getValue(concello.stats.superficietotal)
                            )} Ha.`,
                        ],
                        ["Núcleos:", FieldUtil.getValue(concello.stats.nucleos)],
                        [
                            "Superficie industrial:",
                            `${NumberUtil.formatDecimal(
                                FieldUtil.getValue(concello.stats.totalareaindustrial)
                            )} Ha.`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Variabilidade",
                    content: [
                        [
                            "Estacionalidade:",
                            `${NumberUtil.formatDecimal(
                                FieldUtil.getValue(concello.stats.estacionalidade),
                                1
                            )} %`,
                        ],
                        [
                            "Crecemento:",
                            `${NumberUtil.formatDecimal(
                                FieldUtil.getValue(concello.stats.factorcrecemento),
                                3
                            )} %`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Total",
                    content: [
                        [
                            "Rede > Urbana:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(concello.stats.cargaurbana, 0)
                            )} Heq`,
                        ],
                        [
                            "Rede > Doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomestica,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Rede > Non doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomestica,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Urbana:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargaurbanatotal,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomesticatotal,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Non doméstica:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomesticatotal,
                                    0
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Doméstica",
                    content: [
                        [
                            "Rede > Fixa:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomesticafixa,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Rede > Estacional:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomesticaestacional,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Fixa:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomesticafixatotal,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Estacional:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.cargadomesticaestacionaltotal,
                                    0
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Poboación > Non doméstica",
                    content: [
                        [
                            "Rede > Industrial:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomesticaindustrial,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Rede > Outras non domésticas:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomesticaoutros,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Industrial:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomesticaindustrialtotal,
                                    0
                                )
                            )} Heq`,
                        ],
                        [
                            "Concello > Outras non domésticas:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.carganondomesticaoutrostotal,
                                    0
                                )
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Total",
                    content: [
                        [
                            "Lonxitude rede:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.lonxitudecolector,
                                    3
                                )
                            )} Km.`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.potenciainstalada
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.consumoelectrico
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Resumo sistemas > Sistemas",
                    content: [
                        [
                            "Sistemas <250 Heq:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    concello.stats.edarrangopequenas
                                )
                            ),
                        ],
                        [
                            "Sistemas 250<Heq<2000:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    concello.stats.edarrangomedianas
                                )
                            ),
                        ],
                        [
                            "Sistemas Heq>2000:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    concello.stats.edarrangograndes
                                )
                            ),
                        ],
                    ],
                },
                {
                    head: "Resumo sistemas > Cargas",
                    content: [
                        [
                            "Sistemas <250 Heq:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(concello.stats.rangopequenos),
                                0
                            )} Heq`,
                        ],
                        [
                            "Sistemas 250<Heq<2000:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(concello.stats.rangomedianos),
                                0
                            )} Heq`,
                        ],
                        [
                            "Sistemas Heq>2000:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(concello.stats.rangograndes),
                                0
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Resumo sistemas > Impacto",
                    content: [
                        [
                            "Tratamento deficiente:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.conexiontratamento0
                                ),
                                0
                            )} Heq`,
                        ],
                        [
                            "Tratamento primario:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.conexiontratamento1
                                ),
                                0
                            )} Heq`,
                        ],
                        [
                            "Tratamento secundario:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.conexiontratamento2
                                ),
                                0
                            )} Heq`,
                        ],
                        [
                            "Tratamento terciario:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.conexiontratamento3
                                ),
                                0
                            )} Heq`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Total",
                    content: [
                        [
                            "Lonxitude rede:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    concello.stats.lonxitudecolecto
                                ),
                                3
                            )} Km.`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.potenciainstalada
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.consumoelectrico
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Bombeos",
                    content: [
                        [
                            "Totais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.bombeos)
                            )}`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(concello.stats.potenciabombeo)
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.consumoelectricobombeo
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > Tanques",
                    content: [
                        [
                            "Totais:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.tanques)
                            )}`,
                        ],
                        [
                            "Volume:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.capacidadetanque
                                )
                            )} Kw`,
                        ],
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.potenciainstaladatanque
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.consumoelectricotanque
                                )
                            )} Kwh/ano`,
                        ],
                    ],
                },
                {
                    head: "Inventario > EDAR",
                    content: [
                        [
                            "Potencia instalada:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.potenciainstaladaedar
                                )
                            )} Kw`,
                        ],
                        [
                            "Consumo:",
                            `${FieldUtil.getValue(
                                NumberUtil.formatDecimal(
                                    concello.stats.consumoelectricoedar
                                )
                            )} Kwh/ano`,
                        ],
                        [
                            "Suministro eléctrico:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(
                                    concello.stats.suministroelectricoedar
                                )
                            ),
                        ],
                        [
                            "Tratamento deficiente:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.edartratamento0)
                            ),
                        ],
                        [
                            "Tratamento primario:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.edartratamento1)
                            ),
                        ],
                        [
                            "Tratamento secundario:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.edartratamento2)
                            ),
                        ],
                        [
                            "Tratamento avanzado:",
                            FieldUtil.getValue(
                                NumberUtil.formatInteger(concello.stats.edartratamento3)
                            ),
                        ],
                    ],
                },
            ],
            serviceTableEntities: [
                {
                    columns: subaglomeracionColumns,
                    service: () => {
                        return SubaglomeracionService.getStatsList({
                            concello: concello.codigo,
                        });
                    },
                    title: "Aglomeracións delimitadas",
                },
                {
                    columns: nucleoColumns,
                    service: () => {
                        return NucleoService.getStatsList({
                            concello: concello.codigo,
                        });
                    },
                    title: "Saneamento núcleos",
                },
            ],
        };
    };

    return {getEntityData};
}
