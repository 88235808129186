import Alert from "@mui/material/Alert";
import {useSisbagalAppConfig} from "sisbagal/provider";

const ChangesAlertBox = ({pending_status, ...props}) => {
    const {consolidated} = useSisbagalAppConfig();

    return (
        !consolidated &&
        pending_status && (
            <Alert variant="outlined" severity="error" {...props}>
                Elemento con cambios non aprobados
            </Alert>
        )
    );
};

export default ChangesAlertBox;
