const gobernanza_api_adapter = gobernanza => {
    gobernanza["created_at"] = new Date(gobernanza["created_at"]);
    gobernanza["updated_at"] = new Date(gobernanza["updated_at"]);
    return gobernanza;
};

const gobernanza_view_adapter = gobernanza => {
    delete gobernanza["existe_regulamento_servizo_label"];
    delete gobernanza["regulada_xestion_baleirado_label"];
    delete gobernanza["existe_mecanismos_coordinacion_areas_label"];
    delete gobernanza["existe_mecanismos_coordinacion_outras_label"];
    delete gobernanza["existe_mecanismos_coordinacion_supra_label"];
    delete gobernanza["existe_planificacion_tarefas_label"];
    delete gobernanza["existe_planificacion_actuacions_label"];

    delete gobernanza["conhece_estado_masas_label"];
    delete gobernanza["sabe_causas_mal_estado_label"];
    delete gobernanza["sabe_obxectivos_vertido_label"];
    delete gobernanza["ten_inventariados_alivios_label"];
    delete gobernanza["realiza_control_alivios_label"];
    delete gobernanza["realiza_xestion_ciclo_auga_label"];
    delete gobernanza["compara_volumes_auga_label"];
    delete gobernanza["sabe_organismos_conca_label"];
    delete gobernanza["existe_mecanismo_coordinacion_organismos_label"];

    delete gobernanza["politicas_urbanisticas_label"];
    delete gobernanza["politicas_limpeza_label"];
    delete gobernanza["politicas_parques_label"];

    delete gobernanza["numero_tecnicos_label"];
    delete gobernanza["tecnicos_formacion_label"];
    delete gobernanza["roles_definidos_label"];
    delete gobernanza["existe_mecanismos_suplir_baixas_tecnicos_label"];
    delete gobernanza["numero_operarios_label"];
    delete gobernanza["operarios_formacion_label"];
    delete gobernanza["roles_definidos_operarios_label"];
    delete gobernanza["existe_mecanismos_suplir_baixas_operarios_label"];
    delete gobernanza["existe_mecanismos_comunicacion_concellos_label"];
    delete gobernanza["existe_mecanismos_comunicacion_supramunicipais_label"];

    delete gobernanza["existe_registro_infraestructuras_label"];
    delete gobernanza["existe_información_xeorreferenciada_label"];
    delete gobernanza["sabe_porcentaxe_poboacion_label"];
    delete gobernanza["existe_rexistro_infraestructuras_label"];
    delete gobernanza["existe_balances_custos_ingresos_label"];
    delete gobernanza["existe_rexistro_investimentos_label"];
    delete gobernanza["existe_mecanismo_xestion_datos_label"];

    delete gobernanza["estudo_custos_explotacion_label"];
    delete gobernanza["ordenanza_fiscal_ingresos_label"];
    delete gobernanza["actualizacion_tarifas_label"];
    delete gobernanza["recuperacion_custos_explotacion_label"];
    delete gobernanza["recuperacion_custos_amortizacion_label"];
    delete gobernanza["recuperacion_custos_renovacion_label"];
    delete gobernanza["diagnostico_estado_infraestructuras_label"];
    delete gobernanza["existe_plan_investimento_label"];

    delete gobernanza["regulamento_servizos_adaptado_decreto_label"];
    delete gobernanza["expidense_permisos_decreto_label"];

    delete gobernanza["realizanse_experiencias_piloto_label"];
    delete gobernanza["fomentanse_mecanismos_cooperacion_innovadores_label"];
    delete gobernanza["fomentanse_ferramentas_innovadoras_label"];

    delete gobernanza["existe_mecanismos_consulta_label"];
    delete gobernanza["contas_separadas_label"];
    delete gobernanza["procedemento_reclamacion_regulado_label"];
    delete gobernanza["xestions_web_label"];
    delete gobernanza["facilitase_info_xeral_label"];
    delete gobernanza["facilitase_info_especifica_label"];

    delete gobernanza["identificadas_partes_interesadas_label"];
    delete gobernanza["informacion_compartida_label"];
    delete gobernanza["existe_mecanismos_involucrar_interesados_label"];
    delete gobernanza["posible_visitas_instalacions_label"];

    delete gobernanza["existe_medidas_acceso_vulnerables_label"];
    delete gobernanza["existe_tarifas_sociais_label"];
    delete gobernanza["analizase_capacidade_pago_usuarios_label"];
    delete gobernanza["consideranse_disparidades_xeograficas_actuacions_label"];
    delete gobernanza["consideranse_disparidades_xeograficas_tarifas_label"];

    delete gobernanza["cumprimentase_formulario_anualmente_label"];
    delete gobernanza["avalianse_resultados_anualmente_label"];
    delete gobernanza["compartense_resultados_label"];
    delete gobernanza["realizanse_axustes_label"];

    delete gobernanza["concello_label"];

    delete gobernanza["folder"];
    delete gobernanza["featured_image"];
    delete gobernanza["featured_document"];
    delete gobernanza["created_at"];
    delete gobernanza["created_by"];
    delete gobernanza["updated_at"];
    delete gobernanza["updated_by"];
    return gobernanza;
};

const createGobernanza = ({
    id = null,
    existe_regulamento_servizo = null,
    existe_regulamento_servizo_label = null,
    regulada_xestion_baleirado = null,
    regulada_xestion_baleirado_label = null,
    existe_mecanismos_coordinacion_areas = null,
    existe_mecanismos_coordinacion_areas_label = null,
    existe_mecanismos_coordinacion_outras = null,
    existe_mecanismos_coordinacion_outras_label = null,
    existe_mecanismos_coordinacion_supra = null,
    existe_mecanismos_coordinacion_supra_label = null,
    existe_planificacion_tarefas = null,
    existe_planificacion_tarefas_label = null,
    existe_planificacion_actuacions = null,
    existe_planificacion_actuacions_label = null,
    total_roles_responsibilidades = null,
    conhece_estado_masas = null,
    conhece_estado_masas_label = null,
    sabe_causas_mal_estado = null,
    sabe_causas_mal_estado_label = null,
    sabe_obxectivos_vertido = null,
    sabe_obxectivos_vertido_label = null,
    ten_inventariados_alivios = null,
    ten_inventariados_alivios_label = null,
    realiza_control_alivios = null,
    realiza_control_alivios_label = null,
    realiza_xestion_ciclo_auga = null,
    realiza_xestion_ciclo_auga_label = null,
    compara_volumes_auga = null,
    compara_volumes_auga_label = null,
    sabe_organismos_conca = null,
    sabe_organismos_conca_label = null,
    existe_mecanismo_coordinacion_organismos = null,
    existe_mecanismo_coordinacion_organismos_label = null,
    total_consideracion_sistemas = null,
    politicas_urbanisticas = null,
    politicas_urbanisticas_label = null,
    politicas_limpeza = null,
    politicas_limpeza_label = null,
    politicas_parques = null,
    politicas_parques_label = null,
    total_coherencia_politicas = null,
    numero_tecnicos = null,
    numero_tecnicos_label = null,
    tecnicos_formacion = null,
    tecnicos_formacion_label = null,
    roles_definidos = null,
    roles_definidos_label = null,
    existe_mecanismos_suplir_baixas_tecnicos = null,
    existe_mecanismos_suplir_baixas_tecnicos_label = null,
    numero_operarios = null,
    numero_operarios_label = null,
    operarios_formacion = null,
    operarios_formacion_label = null,
    roles_definidos_operarios = null,
    roles_definidos_operarios_label = null,
    existe_mecanismos_suplir_baixas_operarios = null,
    existe_mecanismos_suplir_baixas_operarios_label = null,
    existe_mecanismos_comunicacion_concellos = null,
    existe_mecanismos_comunicacion_concellos_label = null,
    existe_mecanismos_comunicacion_supramunicipais = null,
    existe_mecanismos_comunicacion_supramunicipais_label = null,
    total_capacitacion_medios = null,
    total_eficacia = null,
    existe_registro_infraestructuras = null,
    existe_registro_infraestructuras_label = null,
    existe_información_xeorreferenciada = null,
    existe_información_xeorreferenciada_label = null,
    sabe_porcentaxe_poboacion = null,
    sabe_porcentaxe_poboacion_label = null,
    existe_rexistro_infraestructuras = null,
    existe_rexistro_infraestructuras_label = null,
    existe_balances_custos_ingresos = null,
    existe_balances_custos_ingresos_label = null,
    existe_rexistro_investimentos = null,
    existe_rexistro_investimentos_label = null,
    existe_mecanismo_xestion_datos = null,
    existe_mecanismo_xestion_datos_label = null,
    total_datos_informacion = null,
    estudo_custos_explotacion = null,
    estudo_custos_explotacion_label = null,
    ordenanza_fiscal_ingresos = null,
    ordenanza_fiscal_ingresos_label = null,
    actualizacion_tarifas = null,
    actualizacion_tarifas_label = null,
    recuperacion_custos_explotacion = null,
    recuperacion_custos_explotacion_label = null,
    recuperacion_custos_amortizacion = null,
    recuperacion_custos_amortizacion_label = null,
    recuperacion_custos_renovacion = null,
    recuperacion_custos_renovacion_label = null,
    diagnostico_estado_infraestructuras = null,
    diagnostico_estado_infraestructuras_label = null,
    existe_plan_investimento = null,
    existe_plan_investimento_label = null,
    total_financiamento = null,
    regulamento_servizos_adaptado_decreto = null,
    regulamento_servizos_adaptado_decreto_label = null,
    expidense_permisos_decreto = null,
    expidense_permisos_decreto_label = null,
    total_marcos_regulatorios = null,
    realizanse_experiencias_piloto = null,
    realizanse_experiencias_piloto_label = null,
    fomentanse_mecanismos_cooperacion_innovadores = null,
    fomentanse_mecanismos_cooperacion_innovadores_label = null,
    fomentanse_ferramentas_innovadoras = null,
    fomentanse_ferramentas_innovadoras_label = null,
    total_gobernanza_innovadora = null,
    total_eficiencia = null,
    existe_mecanismos_consulta = null,
    existe_mecanismos_consulta_label = null,
    contas_separadas = null,
    contas_separadas_label = null,
    procedemento_reclamacion_regulado = null,
    procedemento_reclamacion_regulado_label = null,
    xestions_web = null,
    xestions_web_label = null,
    facilitase_info_xeral = null,
    facilitase_info_xeral_label = null,
    facilitase_info_especifica = null,
    facilitase_info_especifica_label = null,
    total_integridade = null,
    identificadas_partes_interesadas = null,
    identificadas_partes_interesadas_label = null,
    informacion_compartida = null,
    informacion_compartida_label = null,
    existe_mecanismos_involucrar_interesados = null,
    existe_mecanismos_involucrar_interesados_label = null,
    posible_visitas_instalacions = null,
    posible_visitas_instalacions_label = null,
    total_compromiso = null,
    existe_medidas_acceso_vulnerables = null,
    existe_medidas_acceso_vulnerables_label = null,
    existe_tarifas_sociais = null,
    existe_tarifas_sociais_label = null,
    analizase_capacidade_pago_usuarios = null,
    analizase_capacidade_pago_usuarios_label = null,
    consideranse_disparidades_xeograficas_actuacions = null,
    consideranse_disparidades_xeograficas_actuacions_label = null,
    consideranse_disparidades_xeograficas_tarifas = null,
    consideranse_disparidades_xeograficas_tarifas_label = null,
    total_equidade = null,
    cumprimentase_formulario_anualmente = null,
    cumprimentase_formulario_anualmente_label = null,
    avalianse_resultados_anualmente = null,
    avalianse_resultados_anualmente_label = null,
    compartense_resultados = null,
    compartense_resultados_label = null,
    realizanse_axustes = null,
    realizanse_axustes_label = null,
    total_monitoreo_avaliacion = null,
    total_confianza = null,
    concello = null,
    concello_label = null,
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        existe_regulamento_servizo,
        existe_regulamento_servizo_label,
        regulada_xestion_baleirado,
        regulada_xestion_baleirado_label,
        existe_mecanismos_coordinacion_areas,
        existe_mecanismos_coordinacion_areas_label,
        existe_mecanismos_coordinacion_outras,
        existe_mecanismos_coordinacion_outras_label,
        existe_mecanismos_coordinacion_supra,
        existe_mecanismos_coordinacion_supra_label,
        existe_planificacion_tarefas,
        existe_planificacion_tarefas_label,
        existe_planificacion_actuacions,
        existe_planificacion_actuacions_label,
        total_roles_responsibilidades,
        conhece_estado_masas,
        conhece_estado_masas_label,
        sabe_causas_mal_estado,
        sabe_causas_mal_estado_label,
        sabe_obxectivos_vertido,
        sabe_obxectivos_vertido_label,
        ten_inventariados_alivios,
        ten_inventariados_alivios_label,
        realiza_control_alivios,
        realiza_control_alivios_label,
        realiza_xestion_ciclo_auga,
        realiza_xestion_ciclo_auga_label,
        compara_volumes_auga,
        compara_volumes_auga_label,
        sabe_organismos_conca,
        sabe_organismos_conca_label,
        existe_mecanismo_coordinacion_organismos,
        existe_mecanismo_coordinacion_organismos_label,
        total_consideracion_sistemas,
        politicas_urbanisticas,
        politicas_urbanisticas_label,
        politicas_limpeza,
        politicas_limpeza_label,
        politicas_parques,
        politicas_parques_label,
        total_coherencia_politicas,
        numero_tecnicos,
        numero_tecnicos_label,
        tecnicos_formacion,
        tecnicos_formacion_label,
        roles_definidos,
        roles_definidos_label,
        existe_mecanismos_suplir_baixas_tecnicos,
        existe_mecanismos_suplir_baixas_tecnicos_label,
        numero_operarios,
        numero_operarios_label,
        operarios_formacion,
        operarios_formacion_label,
        roles_definidos_operarios,
        roles_definidos_operarios_label,
        existe_mecanismos_suplir_baixas_operarios,
        existe_mecanismos_suplir_baixas_operarios_label,
        existe_mecanismos_comunicacion_concellos,
        existe_mecanismos_comunicacion_concellos_label,
        existe_mecanismos_comunicacion_supramunicipais,
        existe_mecanismos_comunicacion_supramunicipais_label,
        total_capacitacion_medios,
        total_eficacia,
        existe_registro_infraestructuras,
        existe_registro_infraestructuras_label,
        existe_información_xeorreferenciada,
        existe_información_xeorreferenciada_label,
        sabe_porcentaxe_poboacion,
        sabe_porcentaxe_poboacion_label,
        existe_rexistro_infraestructuras,
        existe_rexistro_infraestructuras_label,
        existe_balances_custos_ingresos,
        existe_balances_custos_ingresos_label,
        existe_rexistro_investimentos,
        existe_rexistro_investimentos_label,
        existe_mecanismo_xestion_datos,
        existe_mecanismo_xestion_datos_label,
        total_datos_informacion,
        estudo_custos_explotacion,
        estudo_custos_explotacion_label,
        ordenanza_fiscal_ingresos,
        ordenanza_fiscal_ingresos_label,
        actualizacion_tarifas,
        actualizacion_tarifas_label,
        recuperacion_custos_explotacion,
        recuperacion_custos_explotacion_label,
        recuperacion_custos_amortizacion,
        recuperacion_custos_amortizacion_label,
        recuperacion_custos_renovacion,
        recuperacion_custos_renovacion_label,
        diagnostico_estado_infraestructuras,
        diagnostico_estado_infraestructuras_label,
        existe_plan_investimento,
        existe_plan_investimento_label,
        total_financiamento,
        regulamento_servizos_adaptado_decreto,
        regulamento_servizos_adaptado_decreto_label,
        expidense_permisos_decreto,
        expidense_permisos_decreto_label,
        total_marcos_regulatorios,
        realizanse_experiencias_piloto,
        realizanse_experiencias_piloto_label,
        fomentanse_mecanismos_cooperacion_innovadores,
        fomentanse_mecanismos_cooperacion_innovadores_label,
        fomentanse_ferramentas_innovadoras,
        fomentanse_ferramentas_innovadoras_label,
        total_gobernanza_innovadora,
        total_eficiencia,
        existe_mecanismos_consulta,
        existe_mecanismos_consulta_label,
        contas_separadas,
        contas_separadas_label,
        procedemento_reclamacion_regulado,
        procedemento_reclamacion_regulado_label,
        xestions_web,
        xestions_web_label,
        facilitase_info_xeral,
        facilitase_info_xeral_label,
        facilitase_info_especifica,
        facilitase_info_especifica_label,
        total_integridade,
        identificadas_partes_interesadas,
        identificadas_partes_interesadas_label,
        informacion_compartida,
        informacion_compartida_label,
        existe_mecanismos_involucrar_interesados,
        existe_mecanismos_involucrar_interesados_label,
        posible_visitas_instalacions,
        posible_visitas_instalacions_label,
        total_compromiso,
        existe_medidas_acceso_vulnerables,
        existe_medidas_acceso_vulnerables_label,
        existe_tarifas_sociais,
        existe_tarifas_sociais_label,
        analizase_capacidade_pago_usuarios,
        analizase_capacidade_pago_usuarios_label,
        consideranse_disparidades_xeograficas_actuacions,
        consideranse_disparidades_xeograficas_actuacions_label,
        consideranse_disparidades_xeograficas_tarifas,
        consideranse_disparidades_xeograficas_tarifas_label,
        total_equidade,
        cumprimentase_formulario_anualmente,
        cumprimentase_formulario_anualmente_label,
        avalianse_resultados_anualmente,
        avalianse_resultados_anualmente_label,
        compartense_resultados,
        compartense_resultados_label,
        realizanse_axustes,
        realizanse_axustes_label,
        total_monitoreo_avaliacion,
        total_confianza,
        concello,
        concello_label,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {createGobernanza as default, gobernanza_api_adapter, gobernanza_view_adapter};
