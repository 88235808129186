import {useLocation, useOutletContext} from "react-router-dom";
import {ViewDocumentsSubPage} from "document/component/container";

const ViewDepositoDocumentsSubPage = () => {
    let deposito;
    [deposito] = useOutletContext();

    const location = useLocation();
    const basePath = location.pathname.split("/depositos/")[0];

    return (
        deposito && (
            <ViewDocumentsSubPage
                entity={deposito}
                basePath={`${basePath}/depositos`}
            />
        )
    );
};

export default ViewDepositoDocumentsSubPage;
