import {NumberUtil} from "base/utilities";
import {SectionCard, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";

const AglomeracionInventarioTotalSection = ({aglomeracion}) => {
    return (
        <SectionCard title="Total">
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Lonxitude rede:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.lonxitudecolector,
                            3
                        )}
                        unit="Km."
                        tooltipText="Lonxitude dixitalizada da rede (considerando só as conduccións de natureza fecal ou unitaria)"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Potencia instalada:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.potenciainstalada
                        )}
                        unit="Kw"
                        tooltipText="Potencia total instalada na aglomeracion"
                    />
                    <SectionField
                        label="Consumo:"
                        value={NumberUtil.formatDecimal(
                            aglomeracion.stats.consumoelectrico
                        )}
                        unit="Kwh/ano"
                        tooltipText="Consumo eléctrico anual na aglomeracion"
                    />
                </Grid>
            </Grid>
        </SectionCard>
    );
};

export default AglomeracionInventarioTotalSection;
