class Depositos extends Array {}

const deposito_api_adapter = deposito => {
    // abastecemento autonomo if titular veciñal (2) or privada (6)
    deposito["abastecemento_autonomo"] = [2, 6].includes(deposito["titular"]);

    if (deposito["geom"]) {
        deposito["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    deposito["created_at"] = new Date(deposito["created_at"]);
    deposito["updated_at"] = new Date(deposito["updated_at"]);
    return deposito;
};

const depositos_api_adapter = depositos => depositos.map(deposito_api_adapter);

const createDepositos = (data = []) => {
    const depositos = Depositos.from(data, deposito => createDeposito(deposito));
    return depositos;
};

const deposito_view_adapter = deposito => {
    delete deposito["folder"];
    delete deposito["featured_image"];
    delete deposito["featured_document"];
    delete deposito["created_at"];
    delete deposito["created_by"];
    delete deposito["updated_at"];
    delete deposito["updated_by"];
    return deposito;
};

const createDeposito = ({
    id = null,
    codigo = null,
    abastecemento_autonomo = false,
    titular = null,
    titular_label = null,
    xestion = null,
    xestion_label = null,
    estado = null,
    estado_label = null,
    descripcion = null,
    posicion = null,
    posicion_label = null,
    material = null,
    material_label = null,
    planta = null,
    planta_label = null,
    cloracion = null,
    cloracion_label = null,
    diametro = null,
    largo = null,
    ancho = null,
    alto = null,
    capacidade = null,
    proteccion = null,
    proteccion_label = null,
    suministro_electrico = null,
    suministro_electrico_label = null,
    telexestion = null,
    telexestion_label = null,
    caudalimetro = null,
    caudalimetro_label = null,
    notas = "",
    pending_status = null,
    concello = null,
    concello_label = null,
    sistema = null,
    sistema_label = null,
    geom = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        codigo,
        abastecemento_autonomo,
        titular,
        titular_label,
        xestion,
        xestion_label,
        estado,
        estado_label,
        descripcion,
        posicion,
        posicion_label,
        material,
        material_label,
        planta,
        planta_label,
        cloracion,
        cloracion_label,
        diametro,
        largo,
        ancho,
        alto,
        capacidade,
        proteccion,
        proteccion_label,
        suministro_electrico,
        suministro_electrico_label,
        telexestion,
        telexestion_label,
        caudalimetro,
        caudalimetro_label,
        notas,
        pending_status,
        concello,
        concello_label,
        sistema,
        sistema_label,
        geom,
        folder,
        featured_image,
        featured_document,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createDeposito as default,
    createDepositos,
    deposito_api_adapter,
    depositos_api_adapter,
    deposito_view_adapter,
};
