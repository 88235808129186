import {createContext, useContext, useState} from "react";

let StatsConfigContext = createContext(null);

export default function StatsConfigProvider({children}) {
    const [statsFilter, setStatsFilter] = useState(null);

    return (
        <StatsConfigContext.Provider
            value={{
                statsFilter,
                setStatsFilter,
            }}
        >
            {children}
        </StatsConfigContext.Provider>
    );
}

function useStatsConfig() {
    return useContext(StatsConfigContext);
}

export {useStatsConfig};
