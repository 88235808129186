import {MapMenu} from "base/component/geo/menu";
import {LayerMenu, TileLayerMenu} from "base/component/geo";
import {useMapProvider} from "sisbagal/map";
import {EditLayer, TileLayer} from "base/map";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const SisbagalMapMenu = () => {
    const {getMenuSections} = useMapProvider();

    const getLayerItem = (layerProvider, index) => {
        if (layerProvider.layer instanceof EditLayer) {
            return null;
        }
        if (layerProvider.layer instanceof TileLayer) {
            return <TileLayerMenu key={index} layerProvider={layerProvider} />;
        }
        return <LayerMenu key={index} layerProvider={layerProvider} />;
    };

    const isSectionVisible = section => {
        return section.providers.some(provider => provider.included === true);
    };

    return (
        <MapMenu>
            {getMenuSections().map(section => {
                return (
                    isSectionVisible(section) && (
                        <Box sx={{mb: 2}} key={section.name}>
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                sx={{pl: 1, mb: 1, borderBottom: "1px solid lightgrey"}}
                            >
                                {section.name}
                            </Typography>
                            {section.providers.map((layerProvider, index) =>
                                getLayerItem(layerProvider, index)
                            )}
                        </Box>
                    )
                );
            })}
        </MapMenu>
    );
};

export default SisbagalMapMenu;
