import {FormSection} from "base/component/form";
import {SistemaFormGeneralDataFields, SistemaFormLocationFields} from ".";

const SistemaCreationForm = () => {
    return (
        <>
            <FormSection title="Información general">
                <SistemaFormGeneralDataFields />
            </FormSection>
            <FormSection title="Ubicación">
                <SistemaFormLocationFields orientation="horizontal" />
            </FormSection>
        </>
    );
};

export default SistemaCreationForm;
