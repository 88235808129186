import {FormInputInteger, FormSelect} from "base/component/form";
import Grid from "@mui/material/Grid";
import {useDomain} from "domain/provider";

const SubaglomeracionFormRedeDatosFields = ({layout = "row"}) => {
    const {dominioaglomeraciondesbordamento} = useDomain();

    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormSelect
                    name="desbordamento"
                    label="Desbordamento"
                    options={dominioaglomeraciondesbordamento}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputInteger name="augas_brancas" label="Augas brancas" />
            </Grid>
        </Grid>
    );
};

export default SubaglomeracionFormRedeDatosFields;
