import autoTable from "jspdf-autotable";
import {DateUtil} from "base/utilities";
import {CUSTOM_COLORS} from "Theme";
import {APP_NAME} from "base/config/appInfo";
import {LOGO_AUGAS_NORMAL_DATA_URL} from "imageDataURLs";

const PDFElements = {
    pageMargin: 15,
    pagePaddingTop: 45,

    getPageMargins() {
        return this.pageMargin * 2;
    },

    getPageWidth(doc) {
        return doc.internal.pageSize.getWidth() - this.pageMargin;
    },

    getPageHeight(doc) {
        return doc.internal.pageSize.getHeight() - this.pageMargin;
    },

    getPDFDimensions(doc, imageDimensions) {
        const widthRatio = this.getPageWidth(doc) / imageDimensions.x;
        const heightRatio = this.getPageHeight(doc) / imageDimensions.y;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        let canvasWidth = imageDimensions.x * ratio - this.getPageMargins();
        let canvasHeight = imageDimensions.y * ratio - this.getPageMargins();

        if (canvasHeight > this.getPageHeight(doc) / 2) {
            canvasWidth = canvasWidth * 0.5;
            canvasHeight = canvasHeight * 0.5;
        }

        const imagePositionTop = PDFElements.pagePaddingTop - 10;
        const imagePositionLeft = (this.getPageWidth(doc) - canvasWidth) / 2;
        const imageWidth = canvasWidth + PDFElements.pageMargin;

        return {
            pageWidth: this.getPageWidth(doc),
            pageHeight: this.getPageHeight(doc),
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            imagePositionTop: imagePositionTop,
            imagePositionLeft: imagePositionLeft,
            imageWidth: imageWidth,
        };
    },

    drawHeader(doc, entityData) {
        const logoWidth = 45;
        const logoHeight = 13;
        const logoPositionRight = this.getPageWidth(doc) - logoWidth;
        const textPositionTop = 24;

        doc.setFont("XuntaSans-Bold", "normal");
        doc.setFontSize(18);
        doc.setTextColor(CUSTOM_COLORS.primary.main);
        doc.text(entityData.name, this.pageMargin, textPositionTop, {
            maxWidth: logoPositionRight,
            baseline: "bottom",
        });

        doc.addImage(
            LOGO_AUGAS_NORMAL_DATA_URL,
            "png",
            logoPositionRight,
            logoHeight,
            logoWidth,
            logoHeight
        );
        doc.setDrawColor(CUSTOM_COLORS.primary.main);
        doc.line(this.pageMargin, 30, this.getPageWidth(doc), 30);
    },

    drawFooter(doc) {
        const footerText = `${APP_NAME.toUpperCase()} – ${DateUtil.formatDate(
            new Date()
        )}`;
        doc.setFont("XuntaSans-Regular", "normal");
        doc.setFontSize(8);
        doc.setTextColor(CUSTOM_COLORS.grey["500"]);
        doc.text(footerText, this.getPageWidth(doc) - 28, this.getPageHeight(doc) + 5);
    },

    drawSummaryTable(doc, entityData, sectionData, summaryPositionTop) {
        const getTableStartY = () => {
            if (doc.lastAutoTable) return doc.lastAutoTable.finalY + 5;
            return summaryPositionTop;
        };

        autoTable(doc, {
            startY: getTableStartY(),
            margin: {top: this.pagePaddingTop - 10},
            theme: "plain",
            head: [
                [
                    {
                        content: sectionData?.head.toUpperCase(),
                        colSpan: 2,
                        styles: {
                            cellPadding: 0.75,
                            valign: "middle",
                            fillColor: CUSTOM_COLORS.primary.main,
                            textColor: CUSTOM_COLORS.white,
                            fontSize: 9,
                        },
                    },
                ],
            ],
            body: sectionData?.content,
            bodyStyles: {textColor: CUSTOM_COLORS.text.primary},
            columnStyles: {
                0: {cellPadding: 0.5, cellWidth: 75, fontSize: 9, fontStyle: "bold"},
                1: {cellPadding: 0.5, halign: "left", fontSize: 9},
            },
            pageBreak: "avoid",
            didDrawPage: function(data) {
                PDFElements.drawHeader(doc, entityData);
                PDFElements.drawFooter(doc);
            },
        });
    },
};

export default PDFElements;
