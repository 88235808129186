import {SistemaService} from "../../../service";

import {SearchAutocomplete} from "base/component/search";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const SistemaFormSearch = ({
    handleSelect = null,
    name = null,
    rules = {},
    disabled = false,
}) => {
    const optionComponent = option => {
        return (
            <Stack>
                <Typography>{option.nome}</Typography>
                <Typography variant="caption" sx={{ml: 1}}>
                    {option.codigo}
                </Typography>
            </Stack>
        );
    };

    return (
        <SearchAutocomplete
            name={name}
            label="Sistema"
            optionLabelAttribute="nome"
            optionLabelSecondAttribute="codigo"
            optionComponent={optionComponent}
            search={SistemaService.getBySearchText}
            handleSelect={handleSelect}
            rules={rules}
            disabled={disabled}
        />
    );
};

export default SistemaFormSearch;
