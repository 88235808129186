import {useCaptacionMap} from "abastecemento/captacion/hooks";
import {EntityLocationSection} from "base/entity";

const CaptacionLocationSection = ({captacion}) => {
    const {mapConfig} = useCaptacionMap();

    return <EntityLocationSection entity={captacion} mapConfig={mapConfig} />;
};

export default CaptacionLocationSection;
