import {FormInputDecimal} from "base/component/form";
import Grid from "@mui/material/Grid";

const PerdidasFormDatosFields = ({layout = "row"}) => {
    return (
        <Grid container columnSpacing={2} direction={layout}>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="porcentaxe_volume_perdidas"
                    label="Porcentaxe de volumen de pérdidas"
                    endAdornment="%"
                    placeholder="0,00"
                    rules={{
                        validate: {
                            validateRange: value => {
                                if (!value) {
                                    return;
                                }
                                const floatValue = parseFloat(value);
                                return (
                                    (floatValue >= 0 && floatValue <= 100) ||
                                    "A porcentaxe debe estar entre 0 e 100"
                                );
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormInputDecimal
                    name="cantidade_volume_perdidas"
                    label="Cantidade de volumen de pérdidas"
                    endAdornment="m³"
                    placeholder="0,00"
                />
            </Grid>
        </Grid>
    );
};

export default PerdidasFormDatosFields;
