import {useOutletContext} from "react-router-dom";
import {EntityViewSubPage} from "base/entity";
import {
    SubaglomeracionRedeMateriaisSection,
    SubaglomeracionRedeNaturezaSection,
    SubaglomeracionRedeDatosSection,
} from "../presentational/section";

const ViewSubaglomeracionRedeSubPage = () => {
    let subaglomeracion;
    [subaglomeracion] = useOutletContext();

    const sections = [
        <SubaglomeracionRedeDatosSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionRedeNaturezaSection subaglomeracion={subaglomeracion} />,
        <SubaglomeracionRedeMateriaisSection subaglomeracion={subaglomeracion} />,
    ];

    return subaglomeracion && <EntityViewSubPage sections={sections} />;
};

export default ViewSubaglomeracionRedeSubPage;
