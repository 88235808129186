class Sistemas extends Array {}

const sistema_api_adapter = edar => {
    if (edar["geom"]) {
        edar["geom"]["crs"] = {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:EPSG::25829",
            },
        };
    }

    edar["created_at"] = new Date(edar["created_at"]);
    edar["updated_at"] = new Date(edar["updated_at"]);
    return edar;
};

const sistemas_api_adapter = sistemas => sistemas.map(sistema_api_adapter);

const createSistemas = (data = []) => {
    const sistemas = Sistemas.from(data, sistema => createSistema(sistema));
    return sistemas;
};

const sistema_view_adapter = sistema => {
    delete sistema["featured_image"];
    delete sistema["featured_document"];
    delete sistema["concello_label"];
    delete sistema["titular_label"];
    delete sistema["xestion_label"];
    delete sistema["rexistrado_label"];

    delete sistema["created_at"];
    delete sistema["created_by"];
    delete sistema["updated_at"];
    delete sistema["updated_by"];
    return sistema;
};

const createSistema = ({
    id = null,
    nome = null,
    codigo = null,
    titular = null,
    titular_label = null,
    xestion = null,
    xestion_label = null,
    descripcion = null,
    porcentaxe_conexion = null,
    rexistrado = null,
    rexistrado_label = null,
    expediente = null,
    num_tratamentos_potabilizacion = null,
    notas = "",
    pending_status = null,
    concello = null,
    concello_label = null,
    geom = null,
    folder = "",
    featured_image = "",
    featured_document = "",
    created_by = "",
    created_at = null,
    updated_by = "",
    updated_at = null,
} = {}) => {
    const publicApi = {
        id,
        nome,
        codigo,
        titular,
        titular_label,
        xestion,
        xestion_label,
        descripcion,
        porcentaxe_conexion,
        rexistrado,
        rexistrado_label,
        expediente,
        num_tratamentos_potabilizacion,
        notas,
        pending_status,
        concello,
        concello_label,
        geom,
        folder,
        featured_image,
        featured_document,
        created_by,
        created_at,
        updated_by,
        updated_at,
    };

    return Object.freeze(publicApi);
};

export {
    createSistema as default,
    createSistemas,
    sistema_api_adapter,
    sistemas_api_adapter,
    sistema_view_adapter,
};
