import {Route} from "react-router-dom";
import {
    ViewSinteseInventarioSubPage,
    ViewSintesePage,
    ViewSintesePoboacionSubPage,
    ViewSintesePrincipalSubPage,
    ViewSinteseResumoSistemasSubPage,
} from "../container";

const sinteseRoutes = [
    <Route key="sintese-view" path="sintese" element={<ViewSintesePage />}>
        <Route
            key="concello-principal"
            path="principal"
            element={<ViewSintesePrincipalSubPage />}
        />
        <Route
            key="concello-poboacion"
            path="poboacion"
            element={<ViewSintesePoboacionSubPage />}
        />
        <Route
            key="concello-resumo-sistemas"
            path="resumosistemas"
            element={<ViewSinteseResumoSistemasSubPage />}
        />
        <Route
            key="concello-inventario"
            path="inventario"
            element={<ViewSinteseInventarioSubPage />}
        />
    </Route>,
];

export default sinteseRoutes;
