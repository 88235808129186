import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const EDARTratamentoConservacionSection = ({edar}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`conservacion/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard title="Conservación" secondaryActions={secondaryActions}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Conservación instalacións:"
                        value={edar.conservacion_instalacions_label}
                        tooltipText="Conservación das instalacions e procesos de depuración"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Conservación urbanización:"
                        value={edar.conservacion_urbanizacion_label}
                        tooltipText="Conservación da urbanización da parcela da EDAR"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default EDARTratamentoConservacionSection;
