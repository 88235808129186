import {useOutletContext} from "react-router-dom";
import {EntityAuditSection, EntityViewSubPage} from "base/entity";
import {PerdidasDatosSection} from "../presentational/section";

const ViewPerdidasSubPage = () => {
    let perdidas;
    [perdidas] = useOutletContext();

    const sections = [
        <PerdidasDatosSection perdidas={perdidas} />,
        <EntityAuditSection entity={perdidas} />,
    ];

    return perdidas && <EntityViewSubPage sections={sections} />;
};

export default ViewPerdidasSubPage;
