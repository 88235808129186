import {CaptacionFormCaracteristicasFields, CaptacionFormGeneralDataFields} from ".";

const CaptacionModificationForm = ({section}) => {
    if (section === "generaldata") {
        return <CaptacionFormGeneralDataFields layout="column" />;
    }
    if (section === "caracteristicas") {
        return <CaptacionFormCaracteristicasFields layout="column" />;
    }
    return null;
};

export default CaptacionModificationForm;
