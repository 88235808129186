import {FormSection} from "base/component/form";
import {
    EDARFormTratamentoPretratamentoPrimarioFields,
    EDARFormTratamentoSecundarioFields,
    EDARFormTratamentoTerciarioFields,
    EDARFormTramaentoLodosFields,
    EDARFormTratamentoConservacionFields,
    EDARFormTratamentoProblemasFields,
} from ".";
import {ConcelloHiddenAuth} from "concello/auth";

const EDARTratamentoFormSection = () => {
    return (
        <>
            <ConcelloHiddenAuth>
                <FormSection title="Conservación">
                    <EDARFormTratamentoConservacionFields />
                </FormSection>
            </ConcelloHiddenAuth>
            <FormSection title="Pretratamento e tratamento primario">
                <EDARFormTratamentoPretratamentoPrimarioFields />
            </FormSection>
            <FormSection title="Tratamento secundario">
                <EDARFormTratamentoSecundarioFields />
            </FormSection>
            <ConcelloHiddenAuth>
                <FormSection title="Tratamento terciario">
                    <EDARFormTratamentoTerciarioFields />
                </FormSection>
            </ConcelloHiddenAuth>
            <FormSection title="Problemas">
                <EDARFormTratamentoProblemasFields />
            </FormSection>
            <ConcelloHiddenAuth>
                <FormSection title="Lodos">
                    <EDARFormTramaentoLodosFields />
                </FormSection>
            </ConcelloHiddenAuth>
        </>
    );
};

export default EDARTratamentoFormSection;
