import {useNavigate} from "react-router-dom";

import {SectionCardHeaderAction, SectionField} from "base/component/presentational";

import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {SisbagalSectionCard} from "sisbagal/auth";

const SubaglomeracionRedeNaturezaSection = ({subaglomeracion}) => {
    const navigate = useNavigate();

    const secondaryActions = [
        <SectionCardHeaderAction
            key="edit"
            name="edit"
            text="Modificar"
            icon={<EditIcon />}
            onClick={() => {
                navigate(`redenatureza/edit`);
            }}
            roles={[]}
        />,
    ];

    return (
        <SisbagalSectionCard
            title="Natureza da rede"
            secondaryActions={secondaryActions}
        >
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Natureza pluviais:"
                        value={subaglomeracion.natureza_pluviais}
                        unit="%"
                        tooltipText="Porcentaxe da rede onde están implantadas tubaxes para a condución especifica de augas pluviais"
                    />
                    <SectionField
                        label="Natureza fecais:"
                        value={subaglomeracion.natureza_fecais}
                        unit="%"
                        tooltipText="Porcentaxe da rede onde non se permiten conexión de augas pluviais"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SectionField
                        label="Natureza unitarios:"
                        value={subaglomeracion.natureza_unitarios}
                        unit="%"
                        tooltipText="Porcentaxe da rede de funcionamento unitario"
                    />
                </Grid>
            </Grid>
        </SisbagalSectionCard>
    );
};

export default SubaglomeracionRedeNaturezaSection;
